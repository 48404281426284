import React from "react";
import { AppBar, Toolbar, Box, Typography } from "@mui/material";

const FooterAppbar = (props) => {
  const { currentRoute, navigate, authToken } = props;
  return (
    <AppBar sx={{ top: "auto", bottom: 0, backgroundColor: "#090929" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: currentRoute === "/m" ? "#fae990" : "white",
            backgroundColor: currentRoute === "/m" ? "#da1212" : "#090929",
            width: "25vw",
          }}
          onClick={() => navigate("/m")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/footer-home.png`}
            height="25vw"
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              height: "6vw",
            }}
          />
          <Typography sx={{ fontSize: "3.5vw", marginBottom: "5px" }}>
            Home
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: currentRoute === "/m/news" ? "#fae990" : "white",
            backgroundColor: currentRoute === "/m/news" ? "#da1212" : "#090929",
            width: "25vw",
          }}
          onClick={() => navigate("/m/news")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/footer-news.png`}
            height="25vw"
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              height: "6vw",
            }}
          />
          <Typography sx={{ fontSize: "3.5vw", marginBottom: "5px" }}>
            News
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: currentRoute === "/m/voucherCenter" ? "#fae990" : "white",
            backgroundColor:
              currentRoute === "/m/voucherCenter" ? "#da1212" : "#090929",
            width: "25vw",
          }}
          onClick={() =>
            authToken === null
              ? navigate("/m/login")
              : navigate("/m/voucherCenter")
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/footer-deposit.png`}
            height="25vw"
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              height: "6vw",
            }}
          />
          <Typography sx={{ fontSize: "3.5vw", marginBottom: "5px" }}>
            Deposit
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: currentRoute === "/m/notification" ? "#fae990" : "white",
            backgroundColor:
              currentRoute === "/m/notification" ? "#da1212" : "#090929",
            width: "25vw",
          }}
          onClick={() =>
            authToken === null
              ? navigate("/m/login")
              : navigate("/m/notification")
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/footer-bell.png`}
            height="25vw"
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              height: "6vw",
            }}
          />
          <Typography sx={{ fontSize: "3.5vw", marginBottom: "5px" }}>
            Notifications
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: currentRoute === "/m/member/home" ? "#fae990" : "white",
            backgroundColor:
              currentRoute === "/m/member/home" ? "#da1212" : "#090929",
            width: "25vw",
          }}
          onClick={() =>
            authToken === null
              ? navigate("/m/login")
              : navigate("/m/member/home")
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/footer-profile.png`}
            height="25vw"
            style={{
              marginBottom: "10px",
              marginTop: "10px",
              height: "6vw",
            }}
          />
          <Typography sx={{ fontSize: "3.5vw", marginBottom: "5px" }}>
            Profile
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default FooterAppbar;
