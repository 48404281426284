import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_HOST, TRANSACTION_RECORDS } from "../constants/index";

export const fetchEWallet = createAsyncThunk("player/ewallet", async (id) => {
  let response = {};
  setTimeout(() => {
    response = {
      ...response,
      data: {
        type: "GCASH",
        cardHolder: "John Dominique Doe",
        accountNumber: "200450044",
        transactionPassword:
          "$2y$10$05pSVp2ziZGkfb4zQMC02ePQTTF1lU2Hz2ImdeusMnTvy8GfyObHu",
        confirmPassword:
          "$2y$10$05pSVp2ziZGkfb4zQMC02ePQTTF1lU2Hz2ImdeusMnTvy8GfyObHu",
      },
    };
  }, 1000);

  return response.data;
});

export const updateEWallet = createAsyncThunk(
  "player/ewallet/update",
  async (id, eWallet) => {
    const {
      type,
      cardHolder,
      accountNumber,
      transactionPassword,
      confirmPassword,
    } = eWallet;
    try {
      const response = await axios.put(
        SERVER_HOST + TRANSACTION_RECORDS + `/${id}`,
        {
          data: {
            type,
            cardHolder,
            accountNumber,
            transactionPassword,
            confirmPassword,
          },
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const createWallet = createAsyncThunk(
  "player/ewallet/create",
  async (eWallet) => {
    const {
      type,
      cardHolder,
      accountNumber,
      transactionPassword,
      confirmPassword,
    } = eWallet;
    try {
      const response = await axios.post(SERVER_HOST + TRANSACTION_RECORDS, {
        data: {
          type,
          cardHolder,
          accountNumber,
          transactionPassword,
          confirmPassword,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  eWallet: {
    type: "",
    cardHolder: "",
    accountNumber: "",
    transactionPassword: "",
    confirmPassword: "",
  },
  status: "idle",
  error: null,
};

const eWalletSlice = createSlice({
  name: "eWallet",
  initialState,
  reducers: {
    setTransactionPassword: (state, action) => {
      state.eWallet.transactionPassword = action.payload;
    },
    setConfirmationPassword: (state, action) => {
      state.eWallet.confirmPassword = action.payload;
    },
    setType: (state, action) => {
      state.eWallet.type = action.type;
    },
    setCardHolder: (state, action) => {
      state.eWallet.cardHolder = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.eWallet.accountNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch wallet
      .addCase(fetchEWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.eWallet = action.payload;
      })
      .addCase(fetchEWallet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchEWallet.rejected, (state, action) => {
        state.status = "failed";
        state.eWallet = action.payload;
      })
      // create wallet
      .addCase(createWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createWallet.rejected, (state, action) => {
        state.status = "failed";
      })
      // update wallet
      .addCase(updateEWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.eWallet = action.payload;
      });
  },
});

export const {
  setAccountNumber,
  setCardHolder,
  setConfirmationPassword,
  setTransactionPassword,
  setType,
} = eWalletSlice.actions;

export default eWalletSlice.reducer;
