import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

const CustomToolbar = () => (
  <div id="toolbar">
    {/* Font family dropdown */}
    <select className="ql-font">
      <option value="sans-serif" selected>
        Sans Serif
      </option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
    </select>
    {/* Heading dropdown */}
    <select className="ql-header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="" selected>
        Normal
      </option>
    </select>
    {/* Other toolbar options */}
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    {/* Font color picker */}
    <select className="ql-color"></select>
    {/* Background color picker */}
    <select className="ql-background"></select>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    {/* Justification options */}
    <select className="ql-align">
      <option value="" selected>
        Left
      </option>
      <option value="center">Center</option>
      <option value="right">Right</option>
      <option value="justify">Justify</option>
    </select>
    {/* Link option */}
    <button className="ql-link"></button>
    <button className="ql-image"></button>
    <button className="ql-code"></button>
  </div>
);

const modules = {
  toolbar: {
    container: "#toolbar",
  },
};

const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "color",
  "background",
  "list",
  "align",
  "link",
  "image",
  "code",
];

export default function ReactQuillCustom({ content, setContent }) {
  return (
    <div style={{ paddingTop: 10 }}>
      <CustomToolbar />
      <ReactQuill
        theme="snow"
        value={content}
        onChange={setContent}
        modules={modules}
        formats={formats}
        style={{
          height: "200px",
          width: "100%",
        }}
      />
    </div>
  );
}
