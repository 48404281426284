import React, { useState } from "react";
import axiosInstance from "../../../axiosConfig";
import { TextField, Typography, Grid, Button, Dialog, DialogTitle, DialogContentText, DialogActions, Stack, Chip, InputAdornment, IconButton, DialogContent } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ResetPasswordMobile({ setPhone = "", setNewPassword = "", setOTP = "" }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([null]);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    mobile_no: setPhone,
    otp: setOTP,
    password: setNewPassword,
    show_password: false
  });

  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value
    setValues(values => ({
      ...values,
      [key]: value,
    }))
  }

  function generateOTP(e) {
    e.preventDefault();
    axiosInstance.post(`player/request-mobile-otp`, {
      mobile_no: values.mobile_no
    })
      .then(function (status) {
        setStatus(status.data.message)
      })
      .catch(function (errors) {
        setStatus(errors.response.data.message)
      })
  }

  const submit = (e) => {
    e.preventDefault();
    axiosInstance.post(`player/reset-password`, {
      mobile_no: values.mobile_no,
      otp: values.otp,
      password: values.password
    })
      .then(function (status) {
        setStatus(status.data.message)
        handleClickOpen()
      })
      .catch(function (errors) {
        setStatus(errors.response.data.message)
        handleClickOpen()
      });
  }

  const TogglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStatus([null])
    setOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="center" xs={12} gap={1}>
        <Grid item xs="10" >
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            <span style={{ color: "red" }}>*</span> Mobile No.
          </Typography>
          <TextField id="mobile_no"
            sx={{
              height: "auto",
              borderRadius: "40px",
              backgroundColor: "#16213e",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent', // Removes the border
                },
                '&:hover fieldset': {
                  borderColor: 'transparent', // Removes the hover border
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent', // Removes the focused border
                },
                '& input': {
                  color: 'white', // Optional: sets the text color inside the input
                },
              },
            }}
            type="number"
            focused={false}
            fullWidth
            required
            value={values.mobile_no}
            onChange={handleChange}
          />
          <Typography
            sx={{
              color: "#555556",
              marginBottom: "5px",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            Please enter 11 characters, only numbers allowed
          </Typography>
        </Grid>

        <Grid item xs="10">
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            <span style={{ color: "red" }}>*</span> OTP
          </Typography>
          <Grid item >
            <TextField id="otp"
              sx={{
                height: "auto",
                borderRadius: "40px",
                backgroundColor: "#16213e",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent', // Removes the border
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent', // Removes the hover border
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // Removes the focused border
                  },
                  '& input': {
                    color: 'white', // Optional: sets the text color inside the input
                  },
                },
              }}
              type="number"
              focused={false}
              fullWidth
              required
              value={values.otp}
              onChange={handleChange}
              InputProps={{
                inputProps: {
                  maxLength: 6,
                },
                style: {
                  height: "50px",
                  color: "#FFFFFF",
                },
                endAdornment: (
                  <Stack direction="row" spacing={1}>
                    <Chip
                      size="md"
                      label="Get OTP"
                      onClick={generateOTP}
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "red",
                        fontSize: "12px",
                        zIndex: 1000,
                      }}
                    />
                  </Stack>
                ),
              }}
            />
          </Grid>

          {status && (
            <Typography
              sx={{
                color: "red",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              {status}
            </Typography>
          )}

        </Grid>

        <Grid item xs="10" >
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            <span style={{ color: "red" }}>*</span> New Password
          </Typography>
          <TextField id="password"
            sx={{
              height: "auto",
              borderRadius: "40px",
              backgroundColor: "#16213e",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent', // Removes the border
                },
                '&:hover fieldset': {
                  borderColor: 'transparent', // Removes the hover border
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent', // Removes the focused border
                },
                '& input': {
                  color: 'white', // Optional: sets the text color inside the input
                },
              },
            }}
            InputProps={{
              style: {
                height: "50px",
                color: "#FFFFFF",
                marginLeft: "32px",
                width: "78%",
                marginTop: "5px",
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    position: "relative",
                    bottom: "17px",
                    left: "15px",
                  }}
                >
                  <IconButton
                    onClick={TogglePassword}
                    edge="end"
                    style={{
                      color: "white",
                      top: "15px",
                      left: "30px",
                    }}
                  >
                    {values.show_password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? "text" : "password"}
            focused={false}
            fullWidth
            required
            value={values.password}
            onChange={handleChange}
          />
          <Typography
            sx={{
              color: "#555556",
              marginBottom: "5px",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            Please enter 6 - 13 alphanumeric without special character.
          </Typography>
        </Grid>
        <Button
          disableRipple
          variant="contained"
          sx={{
            backgroundColor: "#da1212",
            borderRadius: "60px",
            width: "70%",
            fontWeight: "bold",
            fontSize: "24px",
            height: "44px",
            "&:hover": {
              background: "#da1212",
            },
          }}
          fullWidth
          type="submit"
          onClick={submit}
        >
          OK
        </Button>
      </Grid>

      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title " justifyContent="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            backgroundColor: '#334155'
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="yellow" className="size-6" width={48} height={48}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
        </DialogTitle>
        <DialogContent sx={{
          backgroundColor: '#334155',
          color: "white"
        }}>
          <DialogContentText id="alert-dialog-description" color={"white"} >
            {status}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          backgroundColor: "#da1212",
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} >
          <Button onClick={handleClose} autoFocus fullWidth style={{ color: 'white' }}  >
            OK
          </Button>
        </DialogActions>
      </Dialog >
    </>
  )
};

