import { Grid, Typography, Modal, Divider } from "@mui/material";

const SoiModal = ({ soiModal, handleSoi, soiRef }) => {
  return (
    <>
      <Modal
        open={soiModal}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "auto",
          outline: "none",
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            outline: "none",
          }}
        >
          <Grid
            item
            ref={soiRef}
            xs={12}
            sm={12}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontSize: "4vw",
                color: "#9e9e9e",
                marginLeft: "2%",
                paddingTop: "5%",
                marginBottom: "3%",
              }}
            >
              Source of Income
            </Typography>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            <Grid
              onClick={() => handleSoi("Employed")}
              container
              sx={{ height: "8vw", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "4.8vw",
                  marginTop: "1%",
                  color: "#2b2b2b",
                  marginLeft: "2%",
                  display: "flex",
                }}
              >
                Employed
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            <Grid
              onClick={() => handleSoi("Self Employed")}
              container
              sx={{
                height: "8vw",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "4.8vw",
                  marginTop: "1%",
                  color: "#2b2b2b",
                  marginLeft: "2%",
                  display: "flex",
                }}
              >
                Self Employed
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default SoiModal;
