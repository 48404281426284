import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const PromotionCard = ({ row, handleOpen }) => {
  return (
    <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
      <Card
        onClick={() => handleOpen(row)}
        container
        sx={{
          direction: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "16px",
          backgroundColor: "#090929",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: "100%", width: "100%", objectFit: "cover" }}
          image={row.src}
          title="green iguana"
        />
        <CardContent sx={{ backgroundColor: "#16213e", height: "20px" }}>
          <Typography
            gutterBottom
            variant="h5"
            align="left"
            fontWeight={"bold"}
            sx={{
              fontSize: {
                xs: "0.8rem",
                sm: "1rem",
                md: "1.2rem",
                lg: "1.4rem",
                xl: "1.6rem",
              },
              color: "white",
            }}
            component="div"
          >
            {row.title}
          </Typography>
        </CardContent>
        <CardActions
          style={{
            justifyContent: "center",
            backgroundColor: "#16213e",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#da1212",
              borderRadius: "8px",
              border: "2px solid #da1212",
              color: "white",
              width: "fit",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "capitalize",
              padding: "0px",
              "&:hover": {
                backgroundColor: "#da1212",
                borderColor: "white", // change background color on hover
              },
            }}
            onClick={() => handleOpen(row)}
          >
            More
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default PromotionCard;
