import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const DialogForgotPassword = ({
  open,
  onClose,
  openForgotPasswordPrompt,
  setOpenForgotPasswordPrompt,
  errorMessage,
}) => {
  const handleClose = () => {
    setOpenForgotPasswordPrompt(false);
  };

  return (
    <Dialog
      open={openForgotPasswordPrompt}
      onClose={onClose}
      maxWidth={false}
      sx={{
        borderRadius: "20px",
      }}
    >
      <DialogContent
        style={{
          boxSizing: "border-box",
          backgroundColor: "#2b3248",
          padding: "20px",
          width: "100%",
          textAlign: "center",
        }}
      >
        {errorMessage === "Registered Successfully" ||
        errorMessage === "Request successfully" ||
        errorMessage === "Password Successfully Changed" ? (
          <CheckCircleOutlineIcon sx={{ color: "#00ff37", fontSize: "40px" }} />
        ) : (
          <ErrorOutlineRoundedIcon
            sx={{ color: "#fbff0b", fontSize: "40px" }}
          />
        )}
        <br />
        <br />
        <Typography
          style={{
            textRendering: "optimizeLegibility",
            textAlign: "left",
            display: "inline-block",
            color: "#fff",
            fontFamily:
              "Barlow, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, sans-serif",
            fontWeight: 400,
            fontSize: "15px",
            paddingTop: "0px",
            paddingRight: "20px",
            paddingBottom: "0px",
            paddingLeft: "20px",
            outlineColor: "rgb(255, 255, 255)",
          }}
        >
          {errorMessage}
        </Typography>
      </DialogContent>
      <Typography
        variant="button"
        onClick={handleClose}
        style={{
          fontSize: "11px",
          color: "#fff",
          boxSizing: "border-box",
          backgroundColor: "rgba(218, 18, 18, 0.9)",
          padding: "12px 24px",
          cursor: "pointer",
          display: "block",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Confirm
      </Typography>
    </Dialog>
  );
};

export default DialogForgotPassword;
