import React from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const DialogMaximumRegistered = ({
  open,
  onClose,
  dialogMaximumLimit,
  setDialogMaximumLimit,
}) => {
  const handleClose = () => {
    setDialogMaximumLimit(false);
  };
  return (
    <Grid
      container
      sm={12}
      xs={12}
      sx={{ width: "100%", overflowY: "hidden", overflowX: "hidden" }}
    >
      <Dialog
        open={dialogMaximumLimit}
        onClose={onClose}
        sx={{
          borderRadius: "20px",
          width: "100%",
        }}
      >
        <Grid container sm={12} xs={12} sx={{ width: "100%" }}>
          <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
            <Box
              style={{
                backgroundColor: "#2b3248",
                textAlign: "center",
                width: "100%",
                border: "1px solid pink",
              }}
            >
              <CancelOutlinedIcon sx={{ color: "#f11", fontSize: "10vw" }} />

              <Grid item sm={12} xs={12}>
                <Typography
                  style={{
                    textRendering: "optimizeLegibility",
                    textAlign: "left",
                    display: "inline-block",
                    color: "#fff",
                    fontFamily:
                      "Barlow, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, sans-serif",
                    fontWeight: 400,
                    fontSize: "3.5vw",
                    paddingTop: "0px",
                    paddingRight: "20px",
                    paddingBottom: "0px",
                    paddingLeft: "20px",
                    outlineColor: "rgb(255, 255, 255)",
                  }}
                >
                  Maximum withdrawal bank card allowed met please contact
                  customer service.
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
            <Typography
              variant="button"
              onClick={handleClose}
              style={{
                fontSize: "4vw",
                color: "#fff",
                boxSizing: "border-box",
                backgroundColor: "rgba(218, 18, 18, 0.9)",
                padding: "12px 24px",
                cursor: "pointer",
                display: "block",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Confirm
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default DialogMaximumRegistered;
