// EmailTab.js
import React, { useState } from "react";
import { Box, Button, Typography, Tab, Tabs, IconButton, TextField, Grid, Dialog, DialogTitle, DialogContentText, DialogActions, Stack, Chip, InputAdornment, DialogContent } from "@mui/material";
import axiosInstance from "../../../axiosConfig";
import { CASINO_SERVER } from "../../../config";
import { styled } from "@mui/system";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
});

const commonStyles = {
  borderRadius: "20px",
  backgroundColor: "#16213e",
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
};

export default function EmailTab({ setUsername = "", setEmail = "" }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([null]);
  const [values, setValues] = useState({
    username: setUsername,
    email: setEmail
  });


  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value
    setValues(values => ({
      ...values,
      [key]: value,
    }))
  }

  const submit = (e) => {
    e.preventDefault();
    axiosInstance.post(`${CASINO_SERVER}player/request-email-otp`, {
      username: values.username,
      email: values.email
    })
      .then(function (status) {
        setStatus(status.data.message)
        handleClickOpen()
      })
      .catch(function (errors) {
        setStatus(errors.response.data.message)
        handleClickOpen()
      })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStatus([null])
    setOpen(false);
  };
  return (
    <Box
      sx={{
        gap: 2,
        padding: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          sx={{ color: "white", fontWeight: "bold", fontSize: 15, mb: 1 }}
        >
          <span style={{ color: "red" }}>*</span> Username
        </Typography>
        <TextField
          id="username"
          sx={commonStyles}
          fullWidth
          required
          variant="standard"
          value={values.username}
          onChange={handleChange}
          // error={!isUsernameValid}
          InputProps={{
            style: { height: 40, color: "#FFFFFF", marginLeft: 20 },
          }}
        />
        <Typography
          sx={{
            // color: isUsernameValid ? "#555556" : "red",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          Please enter 6-13 alphanumeric characters.
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ color: "white", fontWeight: "bold", fontSize: 15, mb: 1 }}
        >
          <span style={{ color: "red" }}>*</span> Email
        </Typography>
        <StyledTextField
          id="email"
          fullWidth
          required
          variant="standard"
          type="email"
          value={values.email}
          onChange={handleChange}
          InputProps={{
            style: { height: 40, color: "#FFFFFF", marginLeft: 20 },
          }}
          sx={commonStyles}
        />
      </Box>
      <Button
        variant="contained"
        color="error"
        fullWidth
        size="large"
        sx={{
          borderRadius: 10,
          textTransform: "capitalize",
          backgroundColor: "red",
          fontWeight: "bold",
          fontSize: "2vh",
          color: "white",
          width: "100%",
        }}
        onClick={submit}
      >
        Confirm
      </Button>

      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title " justifyContent="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            backgroundColor: '#334155'
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="yellow" className="size-6" width={48} height={48}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
        </DialogTitle>
        <DialogContent sx={{
          backgroundColor: '#334155',
          color: "white"
        }}>
          <DialogContentText id="alert-dialog-description" color={"white"} >
            {status}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          backgroundColor: "#da1212",
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} >
          <Button onClick={handleClose} autoFocus fullWidth style={{ color: 'white' }}  >
            OK
          </Button>
        </DialogActions>
      </Dialog >
    </Box>
  );
}
