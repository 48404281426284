import { Stack, Tab, Tabs, styled } from "@mui/material";
import { useState } from "react";
import Withdraw from "./withdraw/Withdraw";
import AccountManagement from "./account_management/AccountManagement";
import WithdrawalRecords from "./WithdrawalRecords";

const HeaderTabs = styled(Tabs)({
  borderBottom: "#666",
  color: "#666",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF0000",
  },
});

const HeaderTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#FF0000",
  },
});

export default function Withdrawal({balance}) {

  const [value, setValue] = useState("withdraw");
  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  const switchComponent = (newValue) => {
    let content = "";
    switch (newValue) {
      case "withdraw":
        content = <Withdraw balance={balance}/>;
        break;
      case "account_management":
        content = <AccountManagement />;
        break;
      default:
        break;
    }

    return content;
  };

  return (
    <Stack>
      <HeaderTabs value={value} onChange={handleValueChange}>
        <HeaderTab disableRipple label="Withdraw" value="withdraw" />
        <HeaderTab
          disableRipple
          label="Account Management"
          value="account_management"
        />
      </HeaderTabs>
      <Stack direction="row">
        {switchComponent(value)}
        <WithdrawalRecords />
      </Stack>
    </Stack>
  );
}
