import React from "react";
import { Box, Typography, Button, Chip, Paper } from "@mui/material";
import reward_image from "../../../../../assets/images/promotion/rubyreward.jpg";
import icon_12 from "../../../../../assets/icons/icon-12.png";
import gift_chip from "../../../../../assets/images/icons/gift_chip.svg";

export default function ViewModal() {
  // Array to loop over for rendering tasks
  const tasks = [
    "Move to security center",
    "Move to security center",
    "Move to security center",
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        height: "90%",
        backgroundColor: "#f0f0f0",
        position: "relative",
      }}
    >
      <Box
        sx={{
          p: 1,
          width: "100%",
          height: "50%",
          boxSizing: "border-box",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            p: 1,
            borderRadius: "10px",
            width: "50%",
            display: "flex",
            boxSizing: "border-box",
            backgroundSize: "cover",
            justifyContent: "center",
            backgroundPosition: "center",
            backgroundImage: `url(${reward_image})`,
          }}
        />

        <Box
          sx={{
            px: 2,
            py: 1,
            gap: 1,
            width: "50%",
            height: "95%",
            display: "flex",
            flexWrap: "wrap",
            overflowX: "auto",
            boxSizing: "border-box",
          }}
        >
          {tasks.map((task, index) => (
            <Box
              key={index}
              sx={{
                p: 1.5,
                gap: 1,
                width: "100%",
                height: "45%",
                display: "flex",
                borderRadius: "10px",
                boxSizing: "border-box",
                backgroundColor: "white",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={icon_12}
                  width={60}
                  height={60}
                  alt={`Icon ${task}`}
                />
              </Box>
              <Box sx={{ width: "60%", height: "100%" }}>
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                  ID Verification
                </Typography>{" "}
                <Typography sx={{ color: "#636363" }}>{task}</Typography>
              </Box>
              <Chip label="Status" color="primary" />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          p: 1,
          width: "50%",
          height: "50%",
          boxSizing: "border-box",
        }}
      >
        <Paper elevation={3} sx={{ height: "100%" }}>
          <Box sx={{ p: 2, height: "100%" }}>
            <Typography variant="caption" color="error" gutterBottom>
              Effective time of the task: 2024/05/08~2024/12/31
            </Typography>

            <Typography variant="h5" gutterBottom>
              Welcome Registration Bonus
            </Typography>

            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Welcome Registration Bonus!
            </Typography>

            <Typography variant="body1" gutterBottom>
              Promotion Overview:
            </Typography>

            <Typography variant="body2">
              Because you are a newly registered player, you deserve to have a{" "}
              <Box component="span" fontWeight="bold">
                Welcome Registration Bonus of P20.
              </Box>
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          p: 1,
          width: "50%",
          height: "50%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          boxSizing: "border-box",
          justifyContent: "end",
        }}
      >
        <Typography variant="body1" gutterBottom>
          Complete: 1/3
        </Typography>
        <Button variant="contained" sx={{ width: "90%", borderRadius: "20px" }}>
          Click to Receive
        </Button>
      </Box>

      <Box
        sx={{
          gap:1,
          top: "20%",
          left: "-42%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "300px",
          height: "50px",
          position: "absolute",
        }}
      >
        {" "}
        <Box
          sx={{
            width: "75%",
            height: "60%",
            borderRadius: "10px",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize:"15px",
            fontWeight: "500",
          }}
        >
          Welcome Registration Bonus!
        </Box>
        <Box sx={{backgroundColor:"white", display:"flex", justifyItems:"center", alignItems:"center", padding:1, borderRadius:"50%"}}>
          <img src={gift_chip} width={35} height={35} alt="Gift Chip" />
        </Box>
      </Box>
    </Box>
  );
}
