import { useState } from "react";
import { Tab, Toolbar, styled } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import AllRecords from "./transaction_records/AllRecords";

const StyledTab = styled(Tab)({
  textTransform: "none",
  "&.Mui-selected": {
    color: "red",
  },
});

const StyledTabList = styled(TabList)({
  ".MuiTabs-indicator": {
    backgroundColor: "red",
  },
});

const AccountRecord = () => {
  // Initialization
  const defaultTransactionType = "transaction_records";

  const [selectedTransactionType, setSelectedTransactionType] = useState(
    defaultTransactionType
  );

  const handleChangeTransactionTab = (event, newValue) => {
    setSelectedTransactionType(newValue);
  };

  return (
    <>
      <TabContext
        value={selectedTransactionType}
        textColor="danger" //red
      >
        <StyledTabList onChange={handleChangeTransactionTab}>
          <StyledTab label="Transaction Records" value="transaction_records" />
          <StyledTab label="Deposit Records" value="deposit_records" />
          <StyledTab label="Withdrawal Records" value="withdrawal_records" />
        </StyledTabList>
      </TabContext>

      <Toolbar variant="dense">
        {selectedTransactionType && (
          <AllRecords selectedTransactionType={selectedTransactionType} />
        )}
      </Toolbar>
    </>
  );
};
export default AccountRecord;
