import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  styled,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import "./DepositDetails.css";
import axiosInstance from "../../../../axiosConfig";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const DepositButton = styled(Button)({
  borderRadius: "50px",
  backgroundColor: "#22d72a",
  color: "#FFFFFF",
  paddingLeft: "30px",
  paddingRight: "30px",
  marginX: "10px",
  "&:hover": {
    backgroundColor: "#25ED2F",
    color: "#FFFFFF",
  },
  "&:active": {
    backgroundColor: "#25ED2F",
    color: "#FFFFFF",
  },
});

const WithdrawButton = styled(Button)({
  border: "1px solid #9F9F9F",
  borderRadius: "50px",
  color: "#9F9F9F",
  marginLeft: "10px",
  marginRight: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
});

const SecurityDepositIcon = styled("img")({
  width: 32,
  height: 32,
  marginRight: 20,
});

export default function DepositDetails({
  setActiveComponent,
  balance,
  setBalance,
}) {
  const username = localStorage.getItem("username");
  const ip_address = localStorage.getItem("ip_address");
  const [lastDeposit, setLastDeposit] = useState("");
  const [lastWithdraw, setLastWithdraw] = useState("");
  const [openCopiedPrompt, setOpenCopiedPrompt] = useState(false);
  const [playerIDVerification, setPlayerIDVerification] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`player/lasttransaction/${username}`)
      .then((res) => {
        setLastDeposit(res.data.deposit.real_amount);
        setLastWithdraw(res.data.withdraw.real_amount);
      })
      .catch((error) => {
        console.error("Error fetching Casino server data:", error);
      });

    axiosInstance
      .get(`member/getMemberData`)
      .then((response) => {
        setPlayerIDVerification(response.data.display);
      })
      .catch((error) => {});
  }, []);

  const handleRefresh = (e) => {
    const refreshBalance = document.getElementById("refreshBalance");
    refreshBalance.classList.add("open");
    axiosInstance
      .get(`player/balance`)
      .then((response) => {
        setBalance(response.data.balance);
        setTimeout(() => {
          refreshBalance.classList.remove("open");
          refreshBalance.classList.add("close");
        }, 1000);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTimeout(() => {
          refreshBalance.classList.remove("open");
          refreshBalance.classList.add("close");
        }, 1000);
      });
  };

  const handleActiveComponent = (content) => {
    setActiveComponent(content);
  };

  const handleCopy = () => {
    setOpenCopiedPrompt(true);
  };

  const handleClose = () => {
    setOpenCopiedPrompt(false);
  };

  return (
    <Box
      sx={{
        borderRadius: "25px",
        marginX: "20px",
        marginY: "40px",
        boxShadow: "0 7px 62px 0 rgba(170,106,226,.19)",
        maxHeight: "540px",
      }}
    >
      <Grid
        sx={{
          backgroundImage:
            "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/personal-topbg.028ad50d.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          textAlign: "center",
          color: "#FFFFFF",
          height: "203px",
          width: "330px",
        }}
      >
        <div
          style={{
            fontSize: "small",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography variant="body3">Username:&nbsp;</Typography>
          <Typography
            sx={{
              borderRadius: "25px",
              backgroundColor: "rgb(256, 256, 256, 0.5)",
              paddingTop: "2.5px",
              paddingBottom: "2.5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontWeight: "bold",
            }}
            variant="body3"
          >
            {username}
            <CopyToClipboard text={username} onCopy={handleCopy}>
              <ContentCopyRoundedIcon
                sx={{
                  fontSize: "small",
                  transform: "rotate(360deg)",
                }}
              />
            </CopyToClipboard>
          </Typography>
          <span>
            <img
              style={{
                width: "38px",
                borderRadius: "50%",
                padding: "10px",
              }}
              src="https://images.21947392.com/TCG_PROD_IMAGES/B2C/01_PROFILE/PROFILE/0.png"
            />
          </span>
        </div>
        <Grid
          sx={{
            marginTop: "10px",
          }}
        >
          <Typography variant="body2">Available Balance</Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="h4">₱</Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {balance ? <div>{balance.toFixed(2)}</div> : <div>0.00</div>}
            </Typography>
            <Typography>
              <ReplayIcon
                id="refreshBalance"
                style={{ fontSize: "2.5em", cursor: "pointer" }}
                className="reload-arrow"
                onClick={handleRefresh}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              backgroundColor: "rgb(256, 256, 256, 0.5)",
              padding: "8px",
              borderRadius: "50%",
              color: "#FFFFFF",
              textAlign: "start",
            }}
          >
            <NorthIcon />
          </div>
          <div
            style={{
              textAlign: "start",
            }}
          >
            <Typography variant="body2">₱ {lastDeposit}</Typography>
            <Typography variant="body2" style={{ color: "#AFAFAF" }}>
              Last Deposit
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "rgb(256, 256, 256, 0.5)",
              padding: "8px",
              borderRadius: "50%",
              color: "#FFFFFF",
              textAlign: "start",
            }}
          >
            <SouthIcon />
          </div>
          <div
            style={{
              textAlign: "start",
            }}
          >
            <Typography variant="body2">
              ₱ {lastWithdraw.replace("-", "")}
            </Typography>
            <Typography variant="body2" style={{ color: "#AFAFAF" }}>
              Last withdrawal
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Stack
        sx={{
          paddingTop: "20px",
        }}
      >
        <Grid
          sx={{
            margin: "0 auto",
          }}
        >
          <DepositButton onClick={() => handleActiveComponent("deposit")}>
            Deposit
          </DepositButton>
          <WithdrawButton onClick={() => handleActiveComponent("withdrawal")}>
            Withdraw
          </WithdrawButton>
        </Grid>
        <Grid
          sx={{
            padding: "20px",
            fontSize: "small",
            color: "",
            display: "flex",
            margin: "0 auto",
            width: "100%",
          }}
        >
          <SecurityDepositIcon src="https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/security-deposit.7e87c1b5.png" />
          <div
            style={{
              borderBottom: "1px dashed #dfdfdf",
              paddingBottom: "15px",
              width: "100%",
            }}
          >
            <span>0</span>
            <span> deposit request processing.</span>
            <br />
            <span style={{ color: "#9f9f9f" }}>{Date.now()}</span>
          </div>
        </Grid>

        <Grid
          sx={{
            padding: "20px",
            fontSize: "small",
            color: "",
            display: "flex",
            margin: "0 auto",
            width: "100%",
          }}
        >
          <SecurityDepositIcon src="https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/security-withdrawal.43dd108c.png" />
          <div
            style={{
              borderBottom: "1px dashed #dfdfdf",
              paddingBottom: "15px",
              width: "100%",
            }}
          >
            <span>0</span>
            <span> withdrawal request processing.</span>
            <br />
            <span style={{ color: "#9f9f9f" }}>
              {Date.now().toLocaleString()}
            </span>
          </div>
        </Grid>

        {playerIDVerification ? (
          <Grid
            sx={{
              padding: "20px",
              fontSize: "small",
              color: "",
              display: "flex",
              margin: "0 auto",
              width: "100%",
            }}
          >
            <SecurityDepositIcon src="https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/lastlogin.052a9a3c.png" />
            <div
              style={{
                paddingBottom: "15px",
              }}
            >
              <span>Last Login: </span>
              <span> Now</span>
              <br />
              <span>IP Address: </span>
              <span>{ip_address}</span>
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Stack>
      {openCopiedPrompt && (
        <Dialog
          open={openCopiedPrompt}
          maxWidth={false}
          sx={{
            borderRadius: "20px",
            zIndex: "9999",
          }}
        >
          <DialogContent
            style={{
              boxSizing: "border-box",
              backgroundColor: "#2b3248",
              padding: "20px",
              width: "300px",
              textAlign: "center",
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ color: "#00ff37", fontSize: "40px" }}
            />

            <br />
            <br />
            <Typography
              style={{
                textRendering: "optimizeLegibility",
                textAlign: "left",
                display: "inline-block",
                color: "#fff",
                fontFamily:
                  "Barlow, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, sans-serif",
                fontWeight: 400,
                fontSize: "15px",
                paddingTop: "0px",
                paddingRight: "20px",
                paddingBottom: "0px",
                paddingLeft: "20px",
                outlineColor: "rgb(255, 255, 255)",
              }}
            >
              Copied
            </Typography>
          </DialogContent>
          <Typography
            variant="button"
            onClick={handleClose}
            style={{
              fontSize: "11px",
              color: "#fff",
              boxSizing: "border-box",
              backgroundColor: "rgba(218, 18, 18, 0.9)",
              padding: "12px 24px",
              cursor: "pointer",
              display: "block",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Confirm
          </Typography>
        </Dialog>
      )}
    </Box>
  );
}
