import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperSlideMobile from "./SwiperSlideMobile";

const GameTypesIcon = ({ gameTypes }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        position: "relative",
        width: "100%",
        marginLeft: "5px",
      }}
    >
      <Swiper
        slidesPerView={5.3}
        spaceBetween={1}
        freeMode={true}
        modules={[FreeMode, Pagination]}
        className="swiperjs"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SwiperSlide key={100}>
          <SwiperSlideMobile
            id={100}
            onClick={() => navigate("/m/games?type=&provider=")}
            description={"All Games"}
            imageSrc={"all_games"}
            style={{ width: "12px" }}
          />
        </SwiperSlide>

        <SwiperSlide key={99}>
          <SwiperSlideMobile
            id={99}
            onClick={() => navigate("/m/games?type=0&provider=1")}
            description={"Exclusives"}
            imageSrc={"exclusives"}
          />
        </SwiperSlide>

        {gameTypes?.map((gameType) => (
          <SwiperSlide key={gameType.id}>
            <SwiperSlideMobile
              id={gameType.id}
              onClick={() => navigate(`/m/games?type=${gameType.id}&provider=`)}
              description={gameType.game_type_name}
              imageSrc={gameType.game_type_code}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GameTypesIcon;
