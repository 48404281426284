import { usePlayer } from "../../../../../contexts/PlayerContext";
import { Button } from "@mui/material";

export default function SortButton({ children, onClick, status, symbol }) {
  const { isMobile } = usePlayer();

  return (
    <>
      {isMobile ? (
        <Button
          onClick={onClick}
          sx={{
            lineHeight: "1",
            paddingLeft: "18px",
            color: "#FFFFFF",
            backgroundColor: status ? "red" : "#16213e",
            height: "30px",
            marginTop: "40px",
            minWidth: "unset",
            width: "80%",
            fontSize: "12px",
            marginLeft: "30px",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: status ? "red" : "#16213e",
              color: "#FFFFFF",
            },
          }}
        >
          {children}
          <span
            style={{
              fontSize: "35px",
              verticalAlign: "middle",
              marginTop: "-4px",
            }}
          >
            {symbol}
          </span>
        </Button>
      ) : (
        <Button
          onClick={onClick}
          sx={{
            lineHeight: "1.5",
            marginLeft: "10px",
            padding: "0px",
            color: "#FFFFFF",
            backgroundColor: status ? "red" : "#16213e",
            height: "41px",
            marginTop: "36px",
            minWidth: "unset",
            width: "35px",
            fontSize: "12px",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: status ? "red" : "#16213e",
              color: "#FFFFFF",
            },
          }}
        >
          {children}
          <span
            style={{
              fontSize: "25px",
              verticalAlign: "middle",
              marginLeft: "5px",
            }}
          >
            {symbol}
          </span>
        </Button>
      )}
    </>
  );
}
