import React from "react";
import { usePlayer } from "../../../contexts/PlayerContext";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  Stack,
  Grid,
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import "./about.css";
import HelpCenterContent from "./HelpCenterContent";
import HelpCenterNavButton from "./HelpCenterNavButton";
import { footerContent } from "../../../utils/content";

const { FooterLogo } = footerContent;

const modalStyle = {
  height: "500px",
  width: "980px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
};

const mainStyle = {
  display: "flex",
  bgcolor: "transparent",
};

const navStyle = {
  backgroundColor: "#090929",
  color: "white",
  width: "220px",
  padding: "12px 10px",
  height: "476px",
  overflowY: "auto",
  overflowX: "hidden",
  marginRight: "20px",
  borderRadius: "12px",
  boxShadow: "0 0 14px 1px hsla(0,0%,100%,.45)",
};

const contentStyle = {
  color: "#ffffff",
  width: "1096px",
  bgcolor: "#090929",
  borderRadius: "12px",
  overflowY: "auto",
  maxHeight: "436px",
  padding: 4,
};

const footerTextStyle = {
  color: "#ffffff",
  bgcolor: "#090929",
  overflowY: "auto",
  display: "flex",
  justifyContent: "center",
  p: 1,
  height: "340px",
};

const footerTextLink = {
  cursor: "pointer",
};

function Footer({
  // isMobile,
  handleCloseModal,
  selectedModalMenu,
  handleOpenModalParent,
  isOpen,
}) {
  const { isMobile } = usePlayer();

  return (
    <>
      {isMobile ? (
        <>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
            justifyContent="center"
            alignItems="center"
            sx={{ overflowX: "hidden", marginBottom: "100px" }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                <Box sx={{ padding: "0 20px" }}>
                  {/* Added padding to avoid text cutting off */}
                  <Grid sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        marginTop: "15px",
                        marginBottom: "15px",
                        color: "white",
                      }}
                    >
                      FBM E-motion - Your Ultimate Fun Destination!
                    </Typography>

                    <Box width="100%">
                      <Typography fontSize={"14px"} sx={{ color: "white" }}>
                        Are you ready to take your gaming experience to the next
                        level? Look no further than FBM E-motion, the
                        cutting-edge iGaming remote platform that offers a
                        thrilling array of entertainment options, including
                        E-Bingo, Casino, Slots, Fishing Games, and Table Games.
                        With FBM E-motion, winning like never before has never
                        been easier! Maglaro at manalo, anytime, anywhere.
                      </Typography>
                    </Box>

                    <img
                      src={FooterLogo}
                      alt="logo"
                      style={{
                        marginTop: "15px",
                        display: "block",
                        width: "250px",
                        height: "60px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />

                    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                      <Typography fontSize={"10px"} sx={{ color: "white" }}>
                        Follow us
                      </Typography>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={"21px"}
                        sx={{ color: "white" }}
                      >
                        fbmemotion
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <FacebookIcon sx={{ fontSize: 24, color: "#1877F2" }} />
                      <InstagramIcon sx={{ fontSize: 24, color: "#f1a886" }} />
                      <YouTubeIcon sx={{ fontSize: 24, color: "#FF0000" }} />
                      <TwitterIcon sx={{ fontSize: 24, color: "#1DA1F2" }} />
                    </Box>

                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={footerTextLink}
                      fontWeight={"bold"}
                      marginTop={"25px"}
                      marginBottom={"1rem"}
                    >
                      <p
                        onClick={() => handleOpenModalParent("about")}
                        style={{ color: "white", fontSize: "14px" }}
                      >
                        About
                      </p>
                      <p
                        onClick={() => handleOpenModalParent("terms")}
                        style={{ color: "white", fontSize: "14px" }}
                      >
                        Terms and Conditions
                      </p>
                      <p
                        onClick={() => handleOpenModalParent("privacy")}
                        style={{ color: "white", fontSize: "14px" }}
                      >
                        Privacy Policy
                      </p>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={footerTextLink}
                      fontWeight={"bold"}
                      marginTop={"25px"}
                      marginBottom={"1rem"}
                    >
                      <p
                        onClick={() => handleOpenModalParent("cookies")}
                        style={{ color: "white", fontSize: "14px" }}
                      >
                        Cookies
                      </p>
                      <p
                        onClick={() => handleOpenModalParent("faqs")}
                        style={{ color: "white", fontSize: "14px" }}
                      >
                        FAQs
                      </p>
                      <p
                        onClick={() => handleOpenModalParent("responsible")}
                        style={{ color: "white", fontSize: "14px" }}
                      >
                        Responsible Gaming
                      </p>
                    </Stack>
                    <Typography fontSize={"13px"} sx={{ color: "white" }}>
                      ©2023 FBM E-Motion | All Rights Reserved.
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
            <Box sx={footerTextStyle}>
              <Grid sx={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  FBM E-motion - Your Ultimate Fun Destination!
                </Typography>

                <Box width={900} marginLeft={""}>
                  <Typography fontSize={"17px"} style={{}}>
                    Are you ready to take your gaming experience to the next
                    level? Look no further than FBM E-motion, the cutting-edge
                    iGaming remote platform that offers a thrilling array of
                    entertainment options, including E-Bingo, Casino, Slots,
                    Fishing Games, and Table Games. With FBM E-motion, winning
                    like never before has never been easier! Maglaro at manalo,
                    anytime, anywhere.
                  </Typography>
                </Box>

                <img
                  src={FooterLogo}
                  alt="logo"
                  style={{
                    marginTop: "15px",
                    marginLeft: "13rem",
                    marginRight: "auto",
                    display: "block",
                    width: "250px",
                    height: "60px",
                  }}
                />

                <Box sx={{ marginLeft: "21rem", marginTop: "-3.8rem" }}>
                  <Typography fontSize={"10px"} marginRight={"4.6rem"}>
                    Follow us{" "}
                  </Typography>
                  <Typography fontWeight={"bold"} fontSize={"21px"}>
                    fbmemotion{" "}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginLeft: "21rem",
                  }}
                >
                  <FacebookIcon sx={{ fontSize: 24, color: "#1877F2" }} />
                  <InstagramIcon sx={{ fontSize: 24, color: "#f1a886" }} />
                  <YouTubeIcon sx={{ fontSize: 24, color: "#FF0000" }} />
                  <TwitterIcon sx={{ fontSize: 24, color: "#1DA1F2" }} />
                </Box>

                <Stack
                  marginLeft={"10rem"}
                  marginTop={"25px"}
                  marginBottom={"1rem"}
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  style={footerTextLink}
                  fontWeight={"bold"}
                >
                  <p onClick={() => handleOpenModalParent("about")}>About</p>
                  <p onClick={() => handleOpenModalParent("terms")}>
                    Terms and Conditions
                  </p>
                  <p onClick={() => handleOpenModalParent("privacy")}>
                    Privacy Policy
                  </p>
                  <p onClick={() => handleOpenModalParent("cookies")}>
                    Cookies
                  </p>
                  <p onClick={() => handleOpenModalParent("faqs")}>FAQs</p>
                  <p onClick={() => handleOpenModalParent("responsible")}>
                    Responsible Gaming
                  </p>
                </Stack>

                <Typography fontSize={"13px"}>
                  ©2023 FBM E-Motion | All Rights Reserved.
                </Typography>
              </Grid>
            </Box>
          </Box>

          <div className="modal">
            <Modal
              open={isOpen}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-menu"
              aria-describedby="modal-modal-menu-description"
            >
              <Box sx={modalStyle}>
                {/* Header */}
                <Box
                  sx={{
                    display: "flex",
                    color: "#fff",
                    paddingBottom: 2,
                  }}
                >
                  <VolumeUpIcon
                    fontSize="large"
                    sx={{
                      mx: 2,
                    }}
                  ></VolumeUpIcon>
                  <Typography
                    id="modal-modal-menu"
                    variant="h4"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Help Center
                  </Typography>
                </Box>

                <Box sx={mainStyle}>
                  {/* Navigation */}
                  <Box sx={navStyle}>
                    <nav>
                      <List>
                        <ListItem
                          disablePadding
                          onClick={() => {
                            handleOpenModalParent("about");
                          }}
                        >
                          <HelpCenterNavButton
                            id="about"
                            desc="About"
                            stat={selectedModalMenu}
                          />
                        </ListItem>

                        <ListItem
                          disablePadding
                          onClick={() => {
                            handleOpenModalParent("terms");
                          }}
                        >
                          <HelpCenterNavButton
                            id="terms"
                            desc="Terms and Conditions"
                            stat={selectedModalMenu}
                          />
                        </ListItem>

                        <ListItem
                          disablePadding
                          onClick={() => {
                            handleOpenModalParent("privacy");
                          }}
                        >
                          <HelpCenterNavButton
                            id="privacy"
                            desc="Privacy Policy"
                            stat={selectedModalMenu}
                          />
                        </ListItem>

                        <ListItem
                          disablePadding
                          onClick={() => {
                            handleOpenModalParent("cookies");
                          }}
                        >
                          <HelpCenterNavButton
                            id="cookies"
                            desc="Cookies"
                            stat={selectedModalMenu}
                          />
                        </ListItem>

                        <ListItem
                          disablePadding
                          onClick={() => {
                            handleOpenModalParent("faqs");
                          }}
                        >
                          <HelpCenterNavButton
                            id="faqs"
                            desc="FAQs"
                            stat={selectedModalMenu}
                          />
                        </ListItem>

                        <ListItem
                          disablePadding
                          onClick={() => {
                            handleOpenModalParent("responsible");
                          }}
                        >
                          <HelpCenterNavButton
                            id="responsible"
                            desc="Responsible Gaming"
                            stat={selectedModalMenu}
                          />
                        </ListItem>
                      </List>
                    </nav>
                  </Box>

                  {/* Display Area */}
                  <Box sx={contentStyle}>
                    <HelpCenterContent selectedMenu={{ selectedModalMenu }} />
                  </Box>
                </Box>
              </Box>
            </Modal>
          </div>
        </>
      )}
      <></>
    </>
  );
}

export default Footer;
