import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/deposit-form.css";
import axiosInstance from "../../../../axiosConfig";

export const DepositForm = ({ paymentProviderData }) => {
  const username = localStorage.getItem("username");
  const minAmount = 100;
  const maxAmount = 49999;
  const [amount, setAmount] = useState(minAmount);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!amount || parseInt(amount) < 1) {
      toast.error("Please provide amount", { theme: "colored" });
      return;
    }

    if (amount < minAmount) {
      toast.error("Minimum amount is " + minAmount, { theme: "colored" });
      return;
    }
    if (amount > maxAmount) {
      toast.error("Maximum amount is " + maxAmount, { theme: "colored" });
      return;
    }

    try {
      const generateUniqueId = (prefix) => {
        return `${prefix}-${Math.floor(Math.random() * 100000)}`;
      };
      const transaction_id = generateUniqueId("DEP");
      const request_id = generateUniqueId("RDEP");
      const depositData = {
        user: username,
        real_amount: amount,
        transaction_id: transaction_id,
        request_id: request_id,
        payment_method: localStorage.getItem("payment_platform"),
        // promo_id: 1,
      };

      if (
        localStorage.getItem("payment_platform") == null ||
        !Object.keys(paymentProviderData).includes(
          localStorage.getItem("payment_platform")
        )
      ) {
        toast.error("Deposit unsuccessful. Please try again.", {
          theme: "colored",
        });
        return;
      }

      if (localStorage.getItem("payment_platform") == 0) {
        const result = await axiosInstance.post(
          `player/deposit/xendit`,
          depositData
        );
        if (result.data.status === "OK") {
          // toast.success("Deposit successful", {
          //   theme: "colored",
          //   autoClose: 300,
          // });
          const width = 600;
          const height = 400;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;
          const specs = `width=${width},height=${height},left=${left},top=${top}`;
          window.open(result.data.checkout_url, "_blank", specs);
        } else {
          console.log(result.data);
          toast.error("Deposit unsuccessful. Please try again.", {
            theme: "colored",
          });
        }
      }

      if (localStorage.getItem("payment_platform") == 1) {
        const result = await axiosInstance.post(
          `player/deposit/paymaya`,
          depositData
        );
        if (result.data.status === "OK") {
          // toast.success("Deposit successful", {
          //   theme: "colored",
          //   autoClose: 300,
          // });
          const width = 600;
          const height = 400;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;
          const specs = `width=${width},height=${height},left=${left},top=${top}`;
          window.open(result.data.checkout_url, "_blank", specs);
        } else {
          toast.error("Deposit unsuccessful. Please try again.", {
            theme: "colored",
          });
        }
      }

      if (localStorage.getItem("payment_platform") == 2) {
        const result = await axiosInstance.post(
          `player/deposit/grab-pay`,
          depositData
        );
        if (result.data.status === "OK") {
          console.log(result.data);
          // toast.success("Deposit successful", {
          //   theme: "colored",
          //   autoClose: 300,
          // });
          const width = 600;
          const height = 400;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;
          const specs = `width=${width},height=${height},left=${left},top=${top}`;
          window.open(result.data.checkout_url, "_blank", specs);
        } else {
          toast.error("Deposit unsuccessful. Please try again.", {
            theme: "colored",
          });
        }
      }
    } catch (error) {
      toast.error("Deposit unsuccessful. Please try again.", {
        theme: "colored",
      });
    }
  };

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      <ToastContainer />
      <div className="deposit-player-header">
        <p>Deposit Info</p>
      </div>
      <div className="selected-payment-provider-row">
        <div className="selected-payment-provider">
          <div className="fbm-like">
            <img src="FBMLike.png" />
          </div>
          <p className="selected-provider-p">{paymentProviderData[0].name}</p>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <label
            className="deposit-form-label"
            style={{ fontFamily: "inherit" }}
          >
            Deposit Amount:
          </label>
          <input
            name="amount"
            id="amount"
            className="deposit-amount"
            type="number"
            value={amount}
            onChange={handleChange}
            placeholder="Deposit Amount"
          />
          <br />
          <label
            style={{
              color: "red",
              fontSize: "14px",
              fontFamily: "inherit",
              marginLeft: "147px",
              marginTop: "10px",
            }}
          >
            Minimum amount : {minAmount}, Maximum amount : {maxAmount}
          </label>
          <div
            style={{
              marginTop: "40%",
              borderTop: "1px solid #d3d3d3",
              width: "100%",
            }}
          >
            <button className="deposit-button">Apply for Deposit</button>
          </div>
        </form>
      </div>
    </div>
  );
};
