import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import axiosInstance from "../../../../../../axiosConfig";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useQuery } from "react-query";

const styles = {
  inputStyle: {
    display: "inline-block",
    verticalAlign: "middle",
    height: "34px",
    width: "200px",
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#f5f5f5",
    color: "#646464",
    outline: "0",
    paddingLeft: "5px",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0, 0, 0, .08) inset",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f5f5f5",
      borderBottom: "none",
    },
    "& label": {
      fontSize: "12px",
    },
    "& .MuiInputBase-root": {
      fontSize: "12px",
      marginTop: "4px",
    },
  },
  container: {
    height: "542px",
    padding: "25px",
    width: "352px",
    color: "#666",
  },
  header: {
    paddingLeft: "30px",
    borderLeft: "5px solid #22D72A",
    marginBottom: "25px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "25px",
  },
  input: {
    display: "inline-block",
    verticalAlign: "middle",
    height: "34px",
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#f5f5f5",
    color: "#646464",
    outline: "0",
    paddingLeft: "5px",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0, 0, 0, .08) inset",
  },
  errorText: {
    marginTop: "5px",
  },
  footer: {
    width: "100%",
    borderTop: "1px solid #efefef",
  },
  button: {
    position: "relative",
    display: "inline-block",
    height: "34px",
    lineHeight: "34px",
    padding: "0 10px",
    backgroundColor: "#ff0000",
    color: "#fff",
    verticalAlign: "middle",
    textAlign: "center",
    borderRadius: "20px",
    cursor: "pointer",
    width: "98px",
    transition: ".2s ease-in-out",
    marginTop: "10px",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },
};

const TransactionPassword = ({
  setDialogTransactionOpen,
  setDialogWrongPassword,
  toggleDrawer,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isCPasswordValid, setIsCPasswordValid] = useState(false);

  const [touchedValue, setTouchedValue] = useState(false);
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [touchedCPassword, setTouchedCPassword] = useState(false);
  const [currentPasswordHelperText, setCurrentPasswordHelperText] =
    useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
    useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [functionalSettings, setFunctionalSettings] = useState("");

  const SAPMSWA = /^[A-Za-z][A-Za-z0-9]*$/;
  const SEF = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const SAP = /^[a-zA-Z0-9]+$/;
  const SR1LA1NMIU = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d_]+$/;
  const NO = /^[0-9]*$/;
  const SAPWALOUC = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/;
  const AASOALOA = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
  const ABSNA = /^\S+$/;
  const YYYYMMDD = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  const AASALTAWS = /^(?=.*[A-Za-z].*[A-Za-z])[A-Za-z ]+$/;
  const MMDDYYYY = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  const BAANR = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;

  useEffect(() => {
    axiosInstance
      .get(`platform/management/functional/settings`)
      .then((response) => {
        setFunctionalSettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const fetchData = async () => {
    const response = await axiosInstance.get(`player/playerkeys`);
    if (response.status !==200) {
      throw new Error("Network response was not ok");
    }
    return response.data;
  };

  const { data, isLoading } = useQuery("player keys", fetchData);

  const validatePassword = (input) => {
    const upperCaseAndLowerAndDigitAndSpecial = /^[A-Za-z0-9]{6,12}$/;
    return upperCaseAndLowerAndDigitAndSpecial.test(input);
  };

  const handlePasswordValidation = (e, row, fieldName) => {
    const { value } = e.target;
    let errors = false;
    let helperTexts = "";

    if (row.pattern === "Any") {
      errors = false;
      helperTexts = "";
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern, must start with alphabetical"
    ) {
      if (SAPMSWA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must start with a letter and be followed by alphanumeric characters only";
      }
    } else if (row.pattern === "Standard email format") {
      if (SEF.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter a valid email address";
      }
    } else if (row.pattern === "Standard alphanumeric pattern") {
      if (SAP.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Please enter only letters and numbers. Special characters and spaces are not allowed";
      }
    } else if (
      row.pattern ===
      "Strictly require 1 letter and 1 number, may include underscore"
    ) {
      if (SR1LA1NMIU.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must include at least one letter and one number. An underscore (_) is optional";
      }
    } else if (row.pattern === "Number only") {
      if (NO.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter only alphanumeric characters";
      }
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern with at least one uppercase character"
    ) {
      if (SAPWALOUC.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and numbers, with at least one uppercase letter";
      }
    } else if (
      row.pattern === "Alphabet and spacing only, at least one alphabet"
    ) {
      if (AASOALOA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Only alphabetic characters and spaces allowed. Must include at least one letter";
      }
    } else if (row.pattern === "Any but spacing not allowed") {
      if (ABSNA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Only non-spacing characters are allowed";
      }
    } else if (row.pattern === "yyyy-MM-dd") {
      if (YYYYMMDD.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format yyyy-MM-dd";
      }
    } else if (
      row.pattern ===
      "Alphabet and spacing only, at least two alphabets with spacing"
    ) {
      if (AASALTAWS.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and spaces, with at least two letters and at least one space.";
      }
    } else if (row.pattern === "mm-dd-yyyy") {
      if (MMDDYYYY.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format MM-DD-YYYY";
      }
    } else if (row.pattern === "Both alphabets and numbers required") {
      if (BAANR.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Must include both letters and numbers";
      }
    }
    if (fieldName === "current_password") {
      setCurrentPassword(e.target.value);
      setTouchedPassword(true);
      setCurrentPasswordError(errors);
      setCurrentPasswordHelperText(helperTexts);
    } else if (fieldName === "password") {
      setNewPassword(e.target.value);
      setTouchedValue(true);
      setIsPasswordValid(validatePassword(e.target.value));
      setPasswordError(errors);
      setPasswordHelperText(helperTexts);
    } else if (fieldName === "confirm_password") {
      setConfirmPassword(e.target.value);
      setTouchedCPassword(true);
      setConfirmPasswordError(errors);
      setConfirmPasswordHelperText(helperTexts);
      if (e.target.value !== newPassword) {
        setIsCPasswordValid(false);
      } else {
        setIsCPasswordValid(true);
      }
    }
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.data.transaction_password !== null) {
      axiosInstance
        .post(`player/transactionrecord/transactionpassword`, {
          current_password: currentPassword,
          transaction_password: newPassword,
        })
        .then(() => {
          toggleDrawer("");
          setDialogTransactionOpen(true);
        })
        .catch(() => {
          setDialogWrongPassword(true);
        });
    } else {
      axiosInstance
        .post(`player/transactionrecord/transactionpassword`, {
          transaction_password: newPassword,
        })
        .then(() => {
          toggleDrawer("");
          setDialogTransactionOpen(true);
        })
        .catch(() => {
          setDialogWrongPassword(true);
        });
    }
  };

  if (isLoading) {
    return <div>loading</div>;
  }

  return (
    <Stack sx={{ width: "415px" }}>
      <Stack sx={styles.container}>
        <Typography variant="body2" sx={styles.header}>
          Transaction Password
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Grid container spacing={1} sm={12}>
            {data.data.transaction_password !== null && (
              <>
                {functionalSettings &&
                  functionalSettings.map((row) => {
                    if (
                      row.userInfo === "transaction_password" &&
                      row.info_enabled === 1
                    ) {
                      return (
                        <Stack
                          direction="row"
                          sx={{
                            marginBottom: "12px",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "12px",
                          }}
                        >
                          <Typography variant="body2">
                            Current Password :
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "200px",
                            }}
                          >
                            <TextField
                              variant="standard"
                              type={showNewPassword ? "text" : "password"}
                              value={currentPassword}
                              onChange={(e) =>
                                handlePasswordValidation(
                                  e,
                                  row,
                                  "current_password"
                                )
                              }
                              sx={styles.inputStyle}
                              style={{
                                marginBottom: currentPasswordHelperText
                                  ? "20px"
                                  : "0px",
                              }}
                              error={currentPasswordError}
                              helperText={currentPasswordHelperText}
                              FormHelperTextProps={{
                                style: {
                                  fontSize: "9px",
                                  textAlign: "left",
                                  marginTop: "10px",
                                },
                              }}
                              inputProps={{
                                minLength: row.min,
                                maxLength: row.max,
                              }}
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={
                                        handleToggleNewPasswordVisibility
                                      }
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              disabled={row.info_update !== 1}
                            />
                          </Box>
                        </Stack>
                      );
                    }
                  })}
              </>
            )}

            {functionalSettings &&
              functionalSettings.map((row) => {
                if (
                  row.userInfo === "transaction_password" &&
                  row.info_enabled === 1
                ) {
                  return (
                    <Stack
                      direction="row"
                      sx={{
                        marginBottom: "12px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "35px",
                      }}
                    >
                      <Typography variant="body2">New Password:</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "200px",
                        }}
                      >
                        <TextField
                          variant="standard"
                          type={showPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={(e) =>
                            handlePasswordValidation(e, row, "password")
                          }
                          sx={styles.inputStyle}
                          style={{
                            marginBottom: passwordHelperText ? "20px" : "0px",
                          }}
                          error={passwordError}
                          helperText={passwordHelperText}
                          FormHelperTextProps={{
                            style: {
                              fontSize: "9px",
                              textAlign: "left",
                              marginTop: "10px",
                            },
                          }}
                          inputProps={{
                            minLength: row.min,
                            maxLength: row.max,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleTogglePasswordVisibility}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled={row.info_update !== 1}
                        />
                      </Box>
                    </Stack>
                  );
                }
              })}
            {functionalSettings &&
              functionalSettings.map((row) => {
                if (
                  row.userInfo === "transaction_password" &&
                  row.info_enabled === 1
                ) {
                  return (
                    <Stack
                      direction="row"
                      sx={{
                        marginBottom: "12px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "12px",
                      }}
                    >
                      <Typography variant="body2">Confirm Password:</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "200px",
                        }}
                      >
                        <TextField
                          variant="standard"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={(e) =>
                            handlePasswordValidation(e, row, "confirm_password")
                          }
                          sx={styles.inputStyle}
                          style={{
                            marginBottom: confirmPasswordHelperText
                              ? "20px"
                              : "0px",
                          }}
                          error={confirmPasswordError}
                          helperText={confirmPasswordHelperText}
                          FormHelperTextProps={{
                            style: {
                              fontSize: "9px",
                              textAlign: "left",
                              marginTop: "10px",
                            },
                          }}
                          inputProps={{
                            minLength: row.min,
                            maxLength: row.max,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={
                                    handleToggleConfirmPasswordVisibility
                                  }
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled={row.info_update !== 1}
                        />
                      </Box>
                    </Stack>
                  );
                }
              })}
          </Grid>
        </Box>
        <Stack
          sx={{
            marginLeft: "-20px",
            marginTop: "360px",
          }}
        >
          <Box sx={styles.footer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#fd2f2f",
                border: "none",
                cursor: "pointer",
                borderRadius: "25px",
                height: "30px",
                marginTop: "15px",
                marginLeft: "20px",
              }}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default TransactionPassword;
