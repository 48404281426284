import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Grid,
} from "@mui/material";
import axiosInstance from "../../axiosConfig";

const WinnerPageMobile = () => {
  const [winners, setWinners] = useState([]);
  const [shouldScroll, setShouldScroll] = useState(true);
  const tableRef = useRef(null);

  useEffect(() => {
    axiosInstance
      .get("/player/winnersPage")
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          setWinners(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching winners:", error);
      });
  }, []);

  const hideName = (player) => {
    if (player.length <= 3) {
      return "*".repeat(player.length);
    }
    const maskedName = "***" + player.substring(3);
    return maskedName;
  };

  const maskedData = winners.map((user) => ({
    ...user,
    username: hideName(user.username),
  }));

  const duplicatedData = [...maskedData, ...maskedData];

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (shouldScroll && tableRef.current) {
        tableRef.current.scrollTop += 1;
        if (
          tableRef.current.scrollTop + tableRef.current.clientHeight >=
          tableRef.current.scrollHeight
        ) {
          tableRef.current.scrollTop = 0;
        }
      }
    }, 40);

    return () => clearInterval(scrollInterval);
  }, [shouldScroll]);

  const handleMouseEnter = () => setShouldScroll(false);
  const handleMouseLeave = () => setShouldScroll(true);

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Grid
        sx={{
          backgroundColor: "#090929",
          height: { sm: "93vh", xl: "93%" },
          width: "93%",
          minHeight: "100vh",
          pb: "4rem",
        }}
      >
        <Box
          sx={{
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            marginTop: "60px",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            px: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              background: "#da1212",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "700",
            }}
          >
            FBM E. MOTION WINNERS
          </Typography>
          <Box
            sx={{
              py: 1,
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: "#16213e",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              DATE
            </Typography>
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              PLAYER
            </Typography>
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              AMOUNT WIN
            </Typography>
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              GAME NAME
            </Typography>
          </Box>
          <TableContainer
            ref={tableRef}
            sx={{
              height: "35vh",
              overflow: "hidden",
              backgroundColor: "#fff",
              borderRadius: 0,
            }}
            component={Paper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Table stickyHeader size="small">
              <TableBody>
                {duplicatedData.map((user, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#eee",
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.5rem",
                        fontWeight: "bold",
                        border: "none",
                      }}
                    >
                      {user.betting_time}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.5rem",
                        fontWeight: "bold",
                        border: "none",
                      }}
                    >
                      {user.username}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.5rem",
                        fontWeight: "bold",
                        border: "none",
                      }}
                    >
                      ₱{user.amount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.5rem",
                        fontWeight: "bold",
                        border: "none",
                      }}
                    >
                      {user.game}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Box>
  );
};

export default WinnerPageMobile;
