import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  Box,
  IconButton,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import FormatListNumberedRoundedIcon from "@mui/icons-material/FormatListNumberedRounded";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";
import StackedLineChartRoundedIcon from "@mui/icons-material/StackedLineChartRounded";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PersonalCenterContent from "./components/PersonalCenterContent";
import "./styles/personal-center.css";
import axiosInstance from "../../../axiosConfig";

const mainStyle = {
  width: "1290px",
  height: "650px",
  padding: "0px 0px",
  maxHeight: "650px",
  overflowY: "hidden",
};

const listItemStyle = {
  paddingLeft: "10px",
  cursor: "pointer",
  height: "50px",
  transition: "background-color 0.2s ease",
  "&:hover": {
    backgroundColor: "red",
  },
};

const StyledIcon = styled("span")({
  margin: "3px",
  display: "inline-flex",
  alignItems: "center",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "1px",
  right: "1px",
  zIndex: 999,
});

export const PlayerDetails = ({
  open,
  handleClose,
  activeComponent,
  setActiveComponent,
  balance,
  setBalance,
  setSelectedNavBarPath,
  setGameIframe,
}) => {

  const handleUpdateTurnover = () => {
    axiosInstance
      .post(`player/update/turnover`)
      .then((res) => {
        console.log("post done")
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleListItemClick = (index) => {
    if (index === "withdrawal") {
      handleUpdateTurnover();
    }
    setActiveComponent(index);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            maxHeight: "650px",
            overflowX: "hidden",
            overflowY: "hidden",
          },
        }}
      >
        <DialogContent sx={mainStyle}>
          {/*Left Navigation */}
          <Box className="mainContentStyle">
            {/* Header */}
            <Box className="leftNavigationHeaderStyle">Personal Center</Box>

            {/* Navigation Items */}
            <List className="leftNavigationListStyle">
              <ListItem
                sx={
                  activeComponent === "my_account"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("my_account")}
              >
                <StyledIcon as={PaidIcon} /> My Account
              </ListItem>
              <ListItem
                sx={
                  activeComponent === "deposit"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("deposit")}
              >
                <StyledIcon as={CreditScoreIcon} /> Deposit
              </ListItem>
              <ListItem
                sx={
                  activeComponent === "withdrawal"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("withdrawal")}
              >
                <StyledIcon as={CallToActionIcon} /> Withdrawal
              </ListItem>
              <ListItem
                sx={
                  activeComponent === "betting_record"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("betting_record")}
              >
                <StyledIcon as={FormatListNumberedRoundedIcon} /> Betting Record
              </ListItem>

              <ListItem
                sx={
                  activeComponent === "account_record"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("account_record")}
              >
                <StyledIcon as={PermContactCalendarRoundedIcon} /> Account
                Record
              </ListItem>

              <ListItem
                sx={
                  activeComponent === "profit_and_loss"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("profit_and_loss")}
              >
                <StyledIcon as={StackedLineChartRoundedIcon} /> Profit and Loss
              </ListItem>
              <ListItem
                sx={
                  activeComponent === "reward_center"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("reward_center")}
              >
                <StyledIcon as={StarsRoundedIcon} /> Reward Center
              </ListItem>
              <ListItem
                sx={
                  activeComponent === "internal_message"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("internal_message")}
              >
                <StyledIcon as={AlternateEmailIcon} /> Internal Message
              </ListItem>
              <ListItem
                sx={
                  activeComponent === "feedback"
                    ? { ...listItemStyle, backgroundColor: "red" }
                    : listItemStyle
                }
                onClick={() => handleListItemClick("feedback")}
              >
                <StyledIcon as={DescriptionRoundedIcon} /> Feedback
              </ListItem>
            </List>
          </Box>

          {/* Selected Navigation Content */}
          <Box>
            <CloseButton onClick={handleClose}>
              <CancelRoundedIcon
                sx={{ fontSize: "35px", color: "rgba(0, 0, 0, 0.75)" }}
              />
            </CloseButton>
            <PersonalCenterContent
              activeComponent={activeComponent}
              setActiveComponent={setActiveComponent}
              balance={balance}
              setBalance={setBalance}
              setSelectedNavBarPath={setSelectedNavBarPath}
              setGameIframe={setGameIframe}
              handleClose={handleClose}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
