import React from "react";
import { Typography } from "@mui/material";

const About = () => {
  return (
    <div>
      {/* <CustomModal open={open}> */}
      <Typography
        id="about-modal"
        variant="h6"
        component="h2"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
          marginBottom: "40px",
        }}
      >
        ABOUT
      </Typography>
      <Typography
        variant="body1"
        sx={{ display: "inline", fontWeight: "bold" }}
      >
        FBM E-Motion{" "}
      </Typography>
      <Typography variant="body1" sx={{ display: "inline" }}>
        is an online gaming platform in the Philippines that ensures convenient
        access to all games through the internet.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "30px" }}>
        Licensed by PAGCOR, E-Motion offers a user-friendly gaming experience,
        allowing players to engage in games easily and intuitively, anytime and
        anywhere.
      </Typography>
      {/* </CustomModal> */}
    </div>
  );
};

export default About;
