import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  Button,
  Typography,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../axiosConfig";

const AllBettingRecords = ({ selectedTransactionType }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterType, setFilterType] = useState("today");
  const [transactionType, setTransactionType] = useState("All");
  const [isDecimal, setIsDecimal] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const username = localStorage.getItem("username");

  const fetchPlayerTransactionsData = async (
    selectedTransactionType,
    username
  ) => {
    let endpoint;
    switch (selectedTransactionType) {
      case "transaction_records":
        endpoint = `player/transactionrecords`;
        break;
      case "deposit_records":
        endpoint = `player/depositrecords/${username}`;
        break;
      default:
        endpoint = `player/withdrawalrecords/${username}`;
    }
    const response = await axiosInstance.get(`${endpoint}`);
    if (response.status !==200) {
      throw new Error("Network response was not ok");
    }
    return response.data;
  };

  useEffect(() => {
    fetchPlayerTransactionsData(selectedTransactionType, username)
      .then((data) => {
        setData(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [selectedTransactionType, username]);


  const applyFilters = () => {
    if (!data) return;
    let filtered = data.filter((row) => {
      const rowDate = new Date(row.created_at);
      const currentDate = new Date();

      if (filterType === "today") {
        return (
          rowDate >= new Date(currentDate.setHours(0, 0, 0, 0)) &&
          rowDate < new Date(currentDate.getTime() + 86400000)
        );
      } else if (filterType === "yesterday") {
        const yesterday = new Date(currentDate);
        yesterday.setDate(yesterday.getDate() - 1);
        return (
          rowDate >= new Date(yesterday.setHours(0, 0, 0, 0)) &&
          rowDate < new Date(yesterday.getTime() + 86400000)
        );
      } else if (filterType === "last7days") {
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        return rowDate >= new Date(sevenDaysAgo.setHours(0, 0, 0, 0));
      } else if (startDate && endDate) {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        startDateObj.setHours(0, 0, 0, 0);
        endDateObj.setHours(23, 59, 59, 999);
        return rowDate >= startDateObj && rowDate <= endDateObj;
      }
      return true;
    });

    filtered = filtered.filter(
      (row) =>
        transactionType === "All" ||
        row.trans_type === transactionType
    );
    filtered = filtered.map((row) => ({
      ...row,
      created_at: dayjs(row.created_at).format(
        "MM-DD-YYYY HH:mm:ss"
      ),
    }));

    setFilteredData(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [data, startDate, endDate, filterType, transactionType]);

  const handleFilterType = (type) => {
    setFilterType(type);
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFilterType("");
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFilterType("");
  };

  const handleTransactionTypeChange = (event) => {
    const selectedTransactionType = event.target.value;
    setTransactionType(selectedTransactionType);
  };

  const handleDecimalChange = (event) => {
    setIsDecimal(event.target.checked);
  };

  const calculateTotals = (data) => {
    return data.reduce(
      (acc, row) => {
        acc.plannedBet += parseFloat(row.player_transaction_amount || 0.0);
        acc.effectiveBet += parseFloat(row.player_transaction_time || 0.0);
        acc.winningAmount += parseFloat(row.sample2 || 0.0);
        return acc;
      },
      { plannedBet: 0.0, effectiveBet: 0.0, winningAmount: 0.0 }
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Game name",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "created_at",
      headerName: "Bet time",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "real_amount",
      headerName: "Planned bet amount",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "post_balance",
      headerName: "Effective bet amount",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "real_amount",
      headerName: "Winning amount",
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "trans_state",
      headerName: "Status",
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: "trans_id",
      headerName: "Order reference number",
      width: 180,
      editable: false,
      sortable: false,
      filterable: false,
    },
  ];

  const totals = calculateTotals(filteredData);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "13px",
            padding: "16px",
            flexWrap: "wrap",
          }}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="today"
                control={
                  <Radio
                    checked={filterType === "today"}
                    onClick={() => handleFilterType("today")}
                    sx={{
                      "&.Mui-checked": {
                        color: filterType === "today" ? "#fb322a" : "none",
                      },
                    }}
                  />
                }
                label="Today"
              />
              <FormControlLabel
                value="yesterday"
                control={
                  <Radio
                    onClick={() => handleFilterType("yesterday")}
                    sx={{
                      "&.Mui-checked": {
                        color: filterType === "yesterday" ? "#fb322a" : "none",
                      },
                    }}
                  />
                }
                label="Yesterday"
              />
              <FormControlLabel
                value="last7days"
                control={
                  <Radio
                    onClick={() => handleFilterType("last7days")}
                    sx={{
                      "&.Mui-checked": {
                        color: filterType === "last7days" ? "#fb322a" : "none",
                      },
                    }}
                  />
                }
                label="7-Days"
              />
            </RadioGroup>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                marginLeft: "-25px",
                height: 36,
                width: 120,
                "& input": { fontSize: "12px" }, // Adjust the font size using CSS
                "& label": { fontSize: "13px", marginTop: "-5px" },
                "& .MuiInputBase-input": { padding: "12px" },
                "& .MuiSvgIcon-root": { fontSize: "25px" }, // Modify the icon size
              }}
            />
          </LocalizationProvider>
          <Typography sx={{ alignItems: "center", marginTop: "10px" }}>
            Vendor:
          </Typography>
          <Select
            value={transactionType}
            onChange={handleTransactionTypeChange}
            size="small"
            label="Transaction Type"
            sx={{
              height: 40,
              width: 80,
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Transfer In">FBMDS LIMITED</MenuItem>
          </Select>
          <Typography sx={{ alignItems: "center", marginTop: "10px" }}>
            Types:
          </Typography>
          <Select
            value={transactionType}
            onChange={handleTransactionTypeChange}
            size="small"
            label="Transaction Type"
            sx={{
              height: 40,
              width: 100,
            }}
          >
            <MenuItem value="All">Settled</MenuItem>
            <MenuItem value="Transfer In">Unsettlement</MenuItem>
          </Select>
          <Typography sx={{ alignItems: "center", marginTop: "10px" }}>
            Status:
          </Typography>
          <Select
            value={transactionType}
            onChange={handleTransactionTypeChange}
            size="small"
            label="Transaction Type"
            sx={{
              height: 40,
              width: 80,
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Transfer In">Win</MenuItem>
            <MenuItem value="Lose">Lose</MenuItem>
            <MenuItem value="Tie">Tie</MenuItem>
            <MenuItem value="Cancel">Cancel</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#fd2f2f",
              border: "none",
              cursor: "pointer",
              borderRadius: "25px",
              height: "40px",
              width: "10%",
              "&:hover": {
                backgroundColor: "#fd2f2f",
              },
            }}
          >
            Search
          </Button>
        </Box>
        <Box
          sx={{
            height: "450px", // Set a fixed height for the container
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            sx={{
              overflow: "hidden",
              backgroundColor: "#efefef",
            }}
            components={{
              Footer: () => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                    backgroundColor: "#e3e2e2",
                    height: "20px", // Set a fixed height for the footer
                  }}
                >
                  <Typography
                    style={{
                      marginLeft: "200px",

                      fontSize: "12px",
                    }}
                  >
                    Total
                  </Typography>

                  <Typography sx={{ fontSize: "12px" }}>
                    {isDecimal
                      ? totals.plannedBet.toFixed(4)
                      : Math.round(totals.plannedBet)}
                  </Typography>

                  <Typography sx={{ fontSize: "12px" }}>
                    {isDecimal
                      ? totals.plannedBet.toFixed(4)
                      : Math.round(totals.plannedBet)}
                  </Typography>

                  <Typography sx={{ fontSize: "12px", marginRight: "400px" }}>
                    {isDecimal
                      ? totals.plannedBet.toFixed(4)
                      : Math.round(totals.plannedBet)}
                  </Typography>
                </Box>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>Decimal Point</Typography>
          <Switch
            checked={isDecimal}
            onChange={handleDecimalChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default AllBettingRecords;
