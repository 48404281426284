import { Box, TextField, Typography, Grid, Button } from "@mui/material";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuillCustom from "./ReactQuillCustom/ReactQuillCustom";
import ImageUpload from "./ImageUpload/ImageUpload";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";

export default function PlayerFeedback() {
  const [mailContent, setMailContent] = useState("Please enter content");

  const handleSendMail = () => {
    console.log("mailContent: ", mailContent);
  };

  return (
    <>
      <Box sx={{ width: "75%", marginLeft: "16%" }}>
        <Box
          sx={{
            display: "flex",
            gap: 5,
            marginTop: "4vh",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={1.4}>
              <Typography>Type of Issue: </Typography>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                disablePortal
                size="small"
                options={[
                  "Deposit",
                  "Withdrawal",
                  "Game",
                  "Affiliate",
                  "Other",
                ]}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Questions" />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid container sx={{ gap: 0, marginTop: "1vh" }}>
          <Grid item xs={12}>
            <Typography>Suggestion</Typography>
          </Grid>
          <Grid item xs={7.2}>
            <ReactQuillCustom
              content={mailContent}
              setContent={setMailContent}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ gap: 0 }}>
          <Grid item xs={11}>
            <Box
              sx={{
                border: "dashed",
                height: "15vh",
                width: "58vh",
                color: "grey",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <UploadFileTwoToneIcon sx={{ fontSize: "7vh" }} />
              <Typography>Upload File</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "1vh" }}>
          <Grid item xs={1.7}>
            <Typography sx={{ marginRight: "1vh", marginTop: "1vh" }}>
              Verification code:{" "}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Verification Code"
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ borderRadius: "50px", fontWeight: "bold", marginTop: "1vh" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
