import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function PromptModal() {
  const [open, setOpen] = useState(true);
  const [accepted, setAccepted] = useState(false);

  const handleClose = () => {
    if (accepted) {
      setOpen(false);
    }
  };

  const handleAccept = () => {
    setAccepted(true);
    setOpen(false);
    sessionStorage.setItem("accepted", true);
  };

  const handleTermsCondition = () => {
    alert("Help Center Components || Terms and Conditions clicked");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={!accepted} // Disable backdrop click to prevent closing when clicking outside
      disableEscapeKeyDown={!accepted} // Disable escape key to prevent closing when pressing ESC
      sx={{
        backdropFilter: "blur(7px)",
        "& .MuiDialog-paper": {
          width: "676px",
          height: "640px",
          borderRadius: "8px",
          backgroundColor: "#090929",
          fontFamily:
            "Barlow, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, sans-serif",
        },
      }}
    >
      <DialogTitle style={{ textAlign: "center", marginTop: "140px" }}>
        <img
          src="/prompt-img/fbm-logo.png"
          alt="FBM E MOTION"
          style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          BY ENTERING THIS WEBSITE I ACKNOWLEDGE AND <br />
          CONFIRM <br />I AM 21 YEARS OF AGE AND I AGREE WITH <br />
        </DialogContentText>

        <DialogContentText
          id="alert-dialog-description"
          style={{
            color: "red",
            textAlign: "center",
            fontSize: "14px",
            marginTop: "15px",
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: 800,
          }}
          onClick={handleTermsCondition}
        >
          TERMS AND CONDITIONS
        </DialogContentText>

        <DialogActions style={{ justifyContent: "center", marginTop: "10px" }}>
          <Button
            onClick={handleAccept}
            style={{
              borderRadius: "8px",
              width: "100px",
              padding: "0px 22px",
              fontSize: "13px",
              height: "51px",
              color: "white",
              alignItems: "center",
              backgroundColor: "red",
              fontWeight: "700",
              marginTop: "5px",
            }}
            variant="contained"
            color="primary"
          >
            Accept
          </Button>
        </DialogActions>

        <DialogTitle style={{ textAlign: "center" }}>
          <img
            src="/prompt-img/footer-info.01b57432.png"
            alt="Footer"
            style={{ maxWidth: "70%", height: "51px", marginTop: "26px" }}
          />
          <p style={{ color: "gray", marginTop: "10px", fontSize: "16px" }}>
            ©2023 FBM E-Motion | All Rights Reserved.
          </p>
        </DialogTitle>
      </DialogContent>
    </Dialog>
  );
}
