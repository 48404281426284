import { DateRange } from "react-date-range";
import { Divider, Grid, Modal, Typography } from "@mui/material";

const AccountRecordCalendar = ({
  calendarOpen,
  calendarRef,
  startDate,
  endDate,
  handleClearButton,
  setDateRange,
  dateRange,
  handleCloseCalendar,
}) => {
  return (
    <>
      <Modal
        open={calendarOpen}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          outline: "none",
          height: "100%",
        }}
      >
        <Grid
          ref={calendarRef}
          container
          sx={{
            backgroundColor: "#FFFFFF",
            outline: "none",
          }}
        >
          <Grid
            ref={calendarRef}
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "2%",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.7vw",
                color: "#898989",
                fontWeight: "bold",
                marginLeft: "36%",
              }}
            >
              {startDate.substring(5, 10).replace(/-/g, "/")}{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "3.7vw",
                color: "#898989",
                fontWeight: "bold",
                marginLeft: "3%",
              }}
            >
              -
            </Typography>
            <Typography
              sx={{
                fontSize: "3.7vw",
                color: "#898989",
                fontWeight: "bold",
                marginLeft: "3%",
              }}
            >
              {endDate.substring(5, 10).replace(/-/g, "/")}{" "}
            </Typography>
            <Typography
              onClick={handleClearButton}
              sx={{
                fontSize: "4vw",
                color: "#4c84f7",
                marginLeft: "23%",
              }}
            >
              Clear
            </Typography>
          </Grid>
          <Divider sx={{ width: "100%", marginTop: ".5%", height: "100%" }} />
          <Grid item xs={12} sm={12} sx={{ height: "100%", width: "100%" }}>
            <DateRange
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              showDateDisplay={false}
              months={1}
              className="calendarElement"
              showMonthAndYearPickers={false}
            />
          </Grid>
          <Grid
            onClick={handleCloseCalendar}
            item
            xs={12}
            sm={12}
            sx={{
              height: "10vw",
              backgroundColor: "#4c84f7",
            }}
          >
            <Typography
              sx={{
                fontSize: "4vw",
                color: "#FFFFFF",
                textAlign: "center",
                marginTop: "2%",
              }}
            >
              OK
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AccountRecordCalendar;
