import { useState } from "react";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import OutputIcon from "@mui/icons-material/Output";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Button, Modal, Box } from "@mui/material";
import PersonalCenterContent from "../player-details/components/PersonalCenterContent";
import { useNavigate } from "react-router-dom";
export const ShortcutIcons = ({
  setShowLogin,
  openProfile,
  setSelectedNavBarPath,
}) => {
  const [showPersonalCenter, setShowPersonalCenter] = useState(false);

  const navigate = useNavigate();

  const handleNews = () => {
    navigate("/news");
  };

  const handleDepositButton = () => {
    if (localStorage.getItem("user_id") === null) {
      setShowLogin(true);
    } else {
      openProfile("deposit");
    }
  };

  const handleWithdrawButton = () => {
    if (localStorage.getItem("user_id") === null) {
      setShowLogin(true);
    } else {
      openProfile("withdrawal");
    }
  };

  const handleNotificationsButton = () => {
    if (localStorage.getItem("user_id") === null) {
      setShowLogin(true);
    } else {
      openProfile("internal_message");
    }
  };

  const handleClose = () => {
    setShowPersonalCenter(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: 10,
        marginBottom: 25,
        marginTop: 25,
        position: "relative",
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <Button
        onClick={handleNews}
        disableRipple
        sx={{
          background: "#16213e",
          width: "100%",
          padding: "15px 20px 15px 20px",
          color: "#FFFFFF",
          borderRadius: "8px",
          height: "50px",
          "&:hover": {
            background: "#151f3c",
          },
          textTransform: "none",
          gap: "10px",
        }}
      >
        <img
          src="footer-news.png"
          style={{ width: "26px", height: "26px", marginRight: "5px" }}
        />
        News
      </Button>
      <Button
        onClick={handleDepositButton}
        disableRipple
        sx={{
          background: "#16213e",
          width: "100%",
          padding: "15px 20px 15px 20px",
          color: "#FFFFFF",
          borderRadius: "8px",
          height: "50px",
          "&:hover": {
            background: "#151f3c",
          },
          textTransform: "none",
          gap: "10px",
        }}
      >
        <img
          src="footer-deposit.png"
          style={{ width: "26px", height: "26px", marginRight: "5px" }}
        />
        Deposit
      </Button>
      <Button
        onClick={handleWithdrawButton}
        disableRipple
        sx={{
          background: "#16213e",
          width: "100%",
          padding: "15px 20px 15px 20px",
          color: "#FFFFFF",
          borderRadius: "8px",
          height: "50px",
          "&:hover": {
            background: "#151f3c",
          },
          textTransform: "none",
          gap: "10px",
        }}
      >
        <img
          src="shortcut-withdraw.png"
          style={{ width: "26px", height: "26px", marginRight: "5px" }}
        />
        <span style={{ marginTop: "5px" }}>Withdraw</span>
      </Button>
      <Button
        onClick={handleNotificationsButton}
        disableRipple
        sx={{
          background: "#16213e",
          width: "100%",
          padding: "15px 20px 15px 20px",
          color: "#FFFFFF",
          borderRadius: "8px",
          height: "50px",
          "&:hover": {
            background: "#151f3c",
          },
          textTransform: "none",
          gap: "10px",
        }}
      >
        <img
          src="footer-bell.png"
          style={{ width: "26px", height: "26px", marginRight: "5px" }}
        />
        Notifications
      </Button>

      <Modal
        open={showPersonalCenter}
        onClose={handleClose}
        aria-labelledby="personal-center-modal-title"
        aria-describedby="personal-center-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PersonalCenterContent />
        </Box>
      </Modal>
    </div>
  );
};
