import React from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

export default function SwiperSlideMobile({
  id,
  description,
  imageSrc,
  onClick,
}) {
  const [searchParams] = useSearchParams();
  const paramGameType = searchParams.get("type");
  const paramProvider = searchParams.get("provider") || "";

  const pxToVw = (pxWidth) => {
    const screenWidth = window.innerWidth;
    return (pxWidth / screenWidth) * 100;
  };

  const widthInButton = pxToVw(55);
  const widthInFontSize = pxToVw(10);
  const widthInImg = pxToVw(25);
  const widthInHeightInButton = pxToVw(55);
  const widthInHeightInImg = pxToVw(25);
  const minWidthInButton = pxToVw(20);

  let handleAllAndExclusive = "";
  if (paramGameType === "" && paramProvider === "") {
    handleAllAndExclusive = 100;
  } else if (paramGameType === "0" && paramProvider === "1") {
    handleAllAndExclusive = 99;
  }

  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor:
          paramGameType == id || handleAllAndExclusive == id
            ? "#da1212"
            : "#16213e",
        width: `${widthInButton}vw`,
        color: "#FFFFFF",
        borderRadius: "10px",
        height: `${widthInHeightInButton}vw`,
        "&:hover": {
          background: "#da1212",
        },
        display: "flex",
        textTransform: "none",
        flexDirection: "column",
        alignItems: "center",
        fontSize: `${widthInFontSize}vw`,
        position: "relative",
        textAlign: "center",
        flexShrink: "0",
        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.08) inset",
        padding: "0px",
        minWidth: `${minWidthInButton}vw`,
      }}
    >
      <img
        src={`../${imageSrc}.png`}
        style={{
          height: `${widthInHeightInImg}vw`,
          width: `${widthInImg}vw`,
        }}
      />
      {description}
    </Button>
  );
}
