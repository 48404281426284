import { Grid, Typography, Modal, Divider } from "@mui/material";
import { dataOccupation } from "../../../staticData/dataOccupation";
const OccupationModal = ({
  occupationModal,
  handleOccupation,
  occupationRef,
}) => {
  // const dataOccupation = [
  //   "Cooperate executives",
  //   "Managers",
  //   "Supervisors",
  //   "Professionals",
  //   "Associate professionals",
  //   "Technicians",
  //   "Clerks",
  //   "Service workers",
  //   "Farmers",
  //   "Forestry workers",
  //   "Fishermen",
  //   "Traders",
  //   "Operators",
  //   "Machine assemblers",
  //   "Laborers",
  //   "Unskilled workers",
  //   "Special occupations",
  //   "Others",
  // ];

  return (
    <>
      <Modal
        open={occupationModal}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "auto",
          outline: "none",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            height: "50vh",
            maxHeight: "50vh",
            overflowY: "auto",
            outline: "none",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Grid ref={occupationRef} item xs={12} sm={12}>
            <Typography
              sx={{
                fontSize: "4vw",
                color: "#9e9e9e",
                marginLeft: "2%",
                paddingTop: "5%",
                marginBottom: "3%",
              }}
            >
              Occupation
            </Typography>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            {dataOccupation &&
              dataOccupation.map((row, index) => (
                <>
                  <Grid
                    key={index}
                    onClick={() => handleOccupation(row)}
                    container
                    sx={{ height: "8vw", alignItems: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "4.8vw",
                        marginTop: "1%",
                        color: "#2b2b2b",
                        marginLeft: "2%",
                        display: "flex",
                      }}
                    >
                      {row}
                    </Typography>
                  </Grid>
                  <Divider sx={{ width: "100%", marginTop: ".5%" }} />
                </>
              ))}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default OccupationModal;
