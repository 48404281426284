import { useEffect, useState } from "react";
import { DepositForm } from "./components/DepositForm";
import { PaymentProviderList } from "./components/PaymentProviderList";

const paymentProviderData = [
  {
    id: 1,
    logo: "https://seeklogo.com/images/G/gcash-logo-E93133FDA5-seeklogo.com.png",
    name: "GCASH",
    status: true,
  },
  {
    id: 2,
    logo: "https://logowik.com/content/uploads/images/maya-payments996.logowik.com.webp",
    name: "MAYA",
    status: true,
  },
  {
    id: 3,
    logo:
      "https://assets.grab.com/wp-content/uploads/sites/12/2019/10/29171306/GrabPay-header.jpg",
    name: "GRAB PAY",
    status: true,
  }
];

const DepositPage = ({ closeProfile }) => {
  const [paymentProviderList, setPaymentProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState({});
  const playerId = localStorage.getItem("user_id") || null;

  useEffect(() => {
    setPaymentProviderList(paymentProviderData);
  }, []);

  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
      }}
    >
      <PaymentProviderList
        paymentProviderList={paymentProviderList}
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
      />
      <DepositForm
        setSelectedProvider={setSelectedProvider}
        closeProfile={closeProfile}
        selectedProvider={selectedProvider}
        playerId={playerId}
        paymentProviderData={paymentProviderData}
      />
    </div>
  );
};

export default DepositPage;
