import {
  GameProviderHotIcon,
  GameProviderNewIcon,
} from "../../../../../assets/icons/Icons";

export default function GameProviderIcon({ isHot, isNew }) {
  return (
    <>
      {isHot === true && <GameProviderHotIcon />}
      {isNew === true && <GameProviderNewIcon moveRight={isHot === true} />}
    </>
  );
}
