import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Divider,
  Grid,
  Typography,
  Card,
  Box,
  CircularProgress,
  Modal,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";
import Logout from "../../pages/landingpage/Buttons/Logout";
import axiosInstance from "../../axiosConfig";
import { CASINO_SERVER } from "../../config";
import axios from "axios";

const CircularProgressWithLabel = (props) => {
  return (
    <Grid
      container
      sm={12}
      xs={12}
      sx={{
        height: "100%",
      }}
    >
      <CircularProgress
        variant="determinate"
        size={"22vw"}
        sx={{ color: blue[800] }}
        {...props}
      />
      <Box
        item
        sm={12}
        xs={12}
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: "6vw" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Grid>
  );
};

const SecurityCenter = () => {
  const [isLoading, setLoading] = useState(true);
  const [personalInformation, setPersonalInformation] = useState([]);
  const [bindEwallet, setBindEWallet] = useState([]);
  const [password, setPassword] = useState([]);
  const [transactionPassword, setTransactionPassword] = useState([]);
  const [myAccountUpload, setMyAccountUpload] = useState([]);
  const [isVerifiedId, setIsVerifiedId] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [functionalSettings, setFunctionalSettings] = useState("");
  const username = localStorage.getItem("username");

  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {};

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axiosInstance
      .get(`player/profile/my-account/get/upload`)
      .then((response) => {
        const data = response.data;
        if (
          data[0].front_pic !== null &&
          data[0].selfie !== null &&
          data[0].is_verified_ID !== 0
        ) {
          setIsVerifiedId(true);
        } else {
          setIsVerifiedId(false);
        }
        setMyAccountUpload(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/player=profile`)
      .then((response) => {
        const data = response.data;
        setPersonalInformation(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/paymentmethod`)
      .then((response) => {
        const data = response.data[0];
        setBindEWallet(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    axiosInstance
      .get(`player/resetpassword`)
      .then((response) => {
        const data = response.data;
        setPassword(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/transactionrecord/get-password`)
      .then((response) => {
        const data = response.data;
        setTransactionPassword(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    axios
      .get(`${CASINO_SERVER}platform/management/functional/settings`)
      .then((response) => {
        setFunctionalSettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const validatePersonalInformation = (data) => {
    return Object.values(data).every((value) => value !== null);
  };

  const validateBindEWallet = (data) => {
    return data && data.account_number !== "";
  };

  const validateTransactionPassword = (data) => {
    return Object.values(data).every(
      (value) => value.transaction_password !== null
    );
  };

  const isPersonalInformationValid =
    username && validatePersonalInformation(personalInformation);

  const isPhoneVerification = username
    ? validatePersonalInformation(personalInformation)
    : false;
  const isBindEWallet = username ? validateBindEWallet(bindEwallet) : false;

  const isPasswordValid = username
    ? validatePersonalInformation(password)
    : false;

  const isTransactionPasswordValid = username
    ? validateTransactionPassword(transactionPassword)
    : false;

  const validations = username
    ? [
        isPersonalInformationValid,
        bindEwallet,
        isVerifiedId,
        isPasswordValid,
        isTransactionPasswordValid,
      ]
    : [false, false, false, false, false];

  const validCount = validations.filter((isValid) => isValid).length;
  const getSafetyPercentage = validCount <= 2 ? "Low" : "High";
  const newProgressValue = Math.min(validCount * 20, 100);
  const targetValueRef = useRef(newProgressValue);
  const progressValueRef = useRef(progressValue);

  const animationDuration = 1000;

  useEffect(() => {
    targetValueRef.current = newProgressValue;
  }, [newProgressValue]);

  useEffect(() => {
    const startTime = performance.now();
    const initialValue = progressValueRef.current;

    const updateProgress = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progressFraction = Math.min(elapsedTime / animationDuration, 1);
      const increment =
        initialValue +
        progressFraction * (targetValueRef.current - initialValue);

      setProgressValue(Math.min(increment, targetValueRef.current));

      if (increment < targetValueRef.current) {
        requestAnimationFrame(updateProgress);
      }
    };

    requestAnimationFrame(updateProgress);
  }, [newProgressValue]);

  useEffect(() => {
    progressValueRef.current = progressValue;
  }, [progressValue]);

  useEffect(() => {
    progressValueRef.current = progressValue;
  }, [progressValue]);

  const handleBackButton = () => {
    navigate("/m/member/home");
  };
  const handleMyAccount = () => {
    navigate("/m/myAccount/index");
  };
  const handleBindEWallet = () => {
    navigate("/m/myEWallet");
  };
  const handleAccountVerification = () => {
    navigate("/m/accountVerification");
  };
  const handleLoginPassword = () => {
    navigate("/m/securityCenter/loginChange");
  };
  const handleTransactionPassword = () => {
    navigate("/m/securityCenter/FundsPasswordBox");
  };

  if (isLoading) {
    return (
      <Modal
        open={isLoading}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          outline: "none",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#4d4d4d",
            opacity: "90%",
            p: 3,
            borderRadius: "5%",
            textAlign: "center",
            flexDirection: "column",
            outline: "none",
          }}
        >
          <CircularProgress sx={{ color: "#FFFFFF", fontSize: "13vw" }} />
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          bgcolor: "#F5F5F9",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.4vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.2vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography
              container
              xs={6}
              sm={6}
              sx={{
                marginTop: "15%",
                fontSize: "3.8vw",
                color: "#fff",
              }}
            >
              Security Center
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          sx={{
            marginTop: "6.5%",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <Grid container xs={12} sm={12} sx={{}}>
            <Card
              sx={{
                height: "25vw",
                width: "100%",
                display: "flex",
                boxShadow: "1",
                paddingTop: "3.5%",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgressWithLabel
                        variant="determinate"
                        value={progressValue}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8} sx={{}}>
                  <Box sx={{}}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "4vw" }}>
                      Safety percentage:
                      {getSafetyPercentage}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <FlashOnIcon
                        key={index}
                        sx={{
                          color: index < validCount ? "#fde006" : "#cdcdcd",
                          fontSize: "6.5vw",
                        }}
                      />
                    ))}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography sx={{ fontSize: "3.5vw", color: "#878787" }}>
                      Last login ip: 172.30.10.101
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography sx={{ fontSize: "3.5vw", color: "#878787" }}>
                      Last login time: 2024-06-25 15:42:19
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              onClick={handleMyAccount}
              sx={{
                display: "flex",
                paddingY: "2%",
                boxShadow: "1",
                width: "100%",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PermIdentityOutlinedIcon
                    sx={{
                      fontSize: "10vw",
                      color: blue[800],
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "4vw",
                    }}
                  >
                    Personal Information
                    {isPersonalInformationValid && (
                      <CheckCircleIcon
                        sx={{
                          fontSize: "5.5vw",
                          marginLeft: "5%",
                          color: "#00E354",
                        }}
                      />
                    )}
                    <BorderColorOutlinedIcon
                      sx={{
                        fontSize: "5.5vw",
                        marginLeft: "5%",
                        color: "#878787",
                      }}
                    />
                  </Typography>

                  {
                    <Typography
                      sx={{
                        color: "#878787",
                        fontSize: "4vw",
                      }}
                    >
                      Complete personal Information
                    </Typography>
                  }
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "8vw" }} />
                </Grid>
              </Grid>
            </Card>
            <Divider sx={{ width: "100%" }} />
            <Card
              onClick={handleBindEWallet}
              sx={{
                display: "flex",
                marginTop: "2.5%",
                paddingY: "2%",
                boxShadow: "1",
                width: "100%",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AccountBalanceWalletOutlinedIcon
                    sx={{
                      fontSize: "10vw",
                      color: blue[800],
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "4vw",
                    }}
                  >
                    Bind E-wallet
                    {isBindEWallet && (
                      <CheckCircleIcon
                        sx={{
                          fontSize: "5.5vw",
                          marginLeft: "5%",
                          color: "#00E354",
                        }}
                      />
                    )}
                    <BorderColorOutlinedIcon
                      sx={{
                        fontSize: "5.5vw",
                        marginLeft: "5%",
                        color: "#878787",
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878787",
                      fontSize: "4vw",
                    }}
                  >
                    Bind E-wallet for withdrawal
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "8vw" }} />
                </Grid>
              </Grid>
            </Card>
            <Divider sx={{ width: "100%" }} />
            <Card
              onClick={handleAccountVerification}
              sx={{
                display: "flex",
                marginTop: "2.5%",
                paddingY: "2%",
                boxShadow: "1",
                width: "100%",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BadgeOutlinedIcon
                    sx={{
                      fontSize: "10vw",
                      color: blue[800],
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "4vw",
                    }}
                  >
                    Account Verification
                    {isVerifiedId && (
                      <CheckCircleIcon
                        sx={{
                          fontSize: "5.5vw",
                          marginLeft: "5%",
                          color: "#00E354",
                        }}
                      />
                    )}
                    <BorderColorOutlinedIcon
                      sx={{
                        fontSize: "5.5vw",
                        marginLeft: "5%",
                        color: "#878787",
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878787",
                      fontSize: "4vw",
                    }}
                  >
                    Verify to secure your account
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "8vw" }} />
                </Grid>
              </Grid>
            </Card>
            <Divider sx={{ width: "100%" }} />
            <Card
              onClick={handleLoginPassword}
              sx={{
                display: "flex",
                marginTop: "2.5%",
                paddingY: "2%",
                boxShadow: "1",
                width: "100%",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LockOutlinedIcon
                    sx={{
                      fontSize: "10vw",
                      color: blue[800],
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "4vw",
                    }}
                  >
                    Change login password
                    {isPasswordValid && (
                      <CheckCircleIcon
                        sx={{
                          fontSize: "5.5vw",
                          marginLeft: "3%",
                          color: "#00E354",
                        }}
                      />
                    )}
                    <BorderColorOutlinedIcon
                      sx={{
                        fontSize: "5.5vw",
                        marginLeft: "1%",
                        color: "#878787",
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878787",
                      fontSize: "4vw",
                    }}
                  >
                    Recommended letter and number combination
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "8vw" }} />
                </Grid>
              </Grid>
            </Card>
            <Divider sx={{ width: "100%" }} />
            {functionalSettings &&
              functionalSettings.map((row) => {
                if (
                  row.userInfo === "transaction_password" &&
                  row.info_enabled === 1
                ) {
                  return (
                    <Card
                      onClick={handleTransactionPassword}
                      sx={{
                        display: "flex",
                        marginTop: "2.5%",
                        paddingY: "2%",
                        boxShadow: "1",
                        width: "100%",
                      }}
                    >
                      <Grid container xs={12} sm={12}>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <PinOutlinedIcon
                            sx={{
                              fontSize: "10vw",
                              color: blue[800],
                            }}
                          />
                        </Grid>
                        <Grid item xs={8} sm={8}>
                          <Typography
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              fontSize: "4vw",
                            }}
                          >
                            Transaction Password
                            {isTransactionPasswordValid && (
                              <CheckCircleIcon
                                sx={{
                                  fontSize: "5.5vw",
                                  marginLeft: "3%",
                                  color: "#00E354",
                                }}
                              />
                            )}
                            <BorderColorOutlinedIcon
                              sx={{
                                fontSize: "5.5vw",
                                marginLeft: "3%",
                                color: "#878787",
                              }}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              color: "#878787",
                              fontSize: "4vw",
                            }}
                          >
                            Set a fund password to improve the security of fund
                            operations
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ArrowForwardIosIcon sx={{ fontSize: "8vw" }} />
                        </Grid>
                      </Grid>
                    </Card>
                  );
                }
              })}

            <Divider sx={{ width: "100%" }} />
            <Card
              sx={{
                display: "flex",
                marginTop: "2.5%",
                paddingY: "2%",
                boxShadow: "1",
                width: "100%",
                bgcolor: "#CCC",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PhoneAndroidOutlinedIcon
                    sx={{
                      fontSize: "10vw",
                      color: "#ggg",
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "4vw",
                    }}
                  >
                    Phone verification
                    {isPhoneVerification && (
                      <CheckCircleIcon
                        sx={{
                          fontSize: "5.5vw",
                          marginLeft: "3%",
                          color: "#00E354",
                        }}
                      />
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878787",
                      fontSize: "4vw",
                    }}
                  >
                    Phone verification
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Grid>
              </Grid>
            </Card>
            <Divider sx={{ width: "100%" }} />

            <Card
              sx={{
                display: "flex",
                marginTop: "2.5%",
                paddingY: "2%",
                boxShadow: "1",
                width: "100%",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LogoutOutlinedIcon
                    sx={{
                      fontSize: "10vw",
                      color: blue[800],
                    }}
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: "4vw",
                    }}
                  >
                    <Logout />
                  </Typography>
                  <Typography
                    sx={{
                      color: "#878787",
                      fontSize: "4vw",
                    }}
                  >
                    Logout safely
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Grid>
              </Grid>
            </Card>
            <Divider sx={{ width: "100%", marginBottom: "10%" }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SecurityCenter;
