import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Dialog,
  IconButton,
  Stack,
  Container,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Link } from "react-router-dom";
import DialogForgotPassword from "./DialogForgotPassword";
import ResetPasswordMobile from "./ResetPasswordMobile";
import ResetPasswordEmail from "./ResetPasswordEmail";

const ResetPasswordForm = (props) => {
  const { open, closeForgotPassword, setShowForgotPassword } = props;
  const [mobileForm, setMobileForm] = useState(true);
  const [emailForm, setEmailForm] = useState(false);
  const [openForgotPasswordPrompt, setOpenForgotPasswordPrompt] = useState(
    false
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseForgot = () => {
    setShowForgotPassword(false);
  };

  const handleMobileForm = () => {
    setMobileForm(true);
    setEmailForm(false);
  };

  const handleEmailForm = () => {
    setMobileForm(false);
    setEmailForm(true);
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeForgotPassword}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: "rgb(0, 0, 0, 0)",
            boxShadow: "0px 0px 7px 0px white",
            overflow: "hidden",
            borderRadius: "30px",
          },
        }}
        sx={{
          backdropFilter: "blur(7px)",
          alignItems: "center",
          "& .MuiPaper-root": {
            backgroundColor: "rgb(0,0,0,0)",
          },
        }}
      >
        <Container
          component="main"
          maxWidth="lg"
          style={{
            background: "#090929",
            width: "600px",
            height: "700px",
            borderRadius: "30px",
          }}
        >
          <IconButton sx={{ marginLeft: "95%" }} onClick={handleCloseForgot}>
            <CloseRoundedIcon
              sx={{
                color: "white",
                fontSize: "30px",
                marginTop: "5px",
              }}
            />
          </IconButton>
          <Grid container justifyContent="center">
            <Typography
              gutterBottom
              sx={{
                marginTop: "1rem",
                color: "#FFFFFF",
                fontSize: "50px",
                fontWeight: "700",
              }}
            >
              Forgot Password
            </Typography>
          </Grid>
          <Stack spacing={2}
            direction="row"
            justifyContent="center"
            marginBottom="1rem"
          >
            <IconButton onClick={handleEmailForm}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: emailForm ? "#da1212" : "#25252e",
                  '&::after': {
                    content: emailForm ? '""' : 'none',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    height: '3px', // Adjust the height for underline thickness
                    backgroundColor: 'red', // Adjust color as needed
                    borderRadius: '2px', // Optional: rounded corners
                  },
                }}
              >
                Email
              </Typography>
            </IconButton>
            <IconButton onClick={handleMobileForm}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: mobileForm ? "#da1212" : "#25252e",
                  '&::after': {
                    content: mobileForm ? '""' : 'none',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    height: '3px', // Adjust the height for underline thickness
                    backgroundColor: 'red', // Adjust color as needed
                    borderRadius: '2px', // Optional: rounded corners
                  },
                }}

              >
                Mobile
              </Typography>
            </IconButton>
          </Stack>

          {mobileForm && (
            <ResetPasswordMobile />
          )}
          {emailForm && (
            <ResetPasswordEmail />
          )}
          <Link onClick={{}}>
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                textDecoration: "underline",
                fontSize: "15px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Need Help?
            </Typography>
          </Link>

        </Container>
      </Dialog>
      <DialogForgotPassword
        openForgotPasswordPrompt={openForgotPasswordPrompt}
        setOpenForgotPasswordPrompt={setOpenForgotPasswordPrompt}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default ResetPasswordForm;
