import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import axiosInstance from "../../axiosConfig";

const WinnerPage = () => {
  const [winners, setWinners] = useState([]);
  const tableRef = useRef(null);
  const [scrolling, setScrolling] = useState(true);

  useEffect(() => {
    axiosInstance
      .get("/player/winnersPage")
      .then((response) => {
        setWinners(response.data);
      })
      .catch((error) => {
        console.error("Error fetching winners:", error);
      });
  }, []);

  const hideName = (player) => {
    if (player.length <= 3) {
      return "*".repeat(player.length);
    }
    const visiblePart = player.slice(-3);
    const maskedPart = "*".repeat(player.length - 3);
    return maskedPart + visiblePart;
  };

  const maskedData = winners.map((user) => ({
    ...user,
    username: hideName(user.username),
  }));

  const extendedData = [...maskedData, ...maskedData];

  useEffect(() => {
    const table = tableRef.current;
    if (!table) return;

    const handleScroll = () => {
      if (table.scrollTop + table.clientHeight >= table.scrollHeight) {
        table.scrollTop = 1;
      }
    };

    const scrollInterval = setInterval(() => {
      if (table && scrolling) {
        table.scrollTop += 1;
        handleScroll();
      }
    }, 40);

    return () => clearInterval(scrollInterval);
  }, [extendedData, scrolling]);

  const handleMouseOver = () => {
    setScrolling(false);
  };

  const handleMouseOut = () => {
    setScrolling(true);
  };

  return (
    <>
      <Box sx={{ marginTop: "60px" }}>
        <Box
          container
          sx={{
            backgroundColor: "#090929",
            width: "100%",
            height: "100%",
            paddingTop: 7,
            display: "flex",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              width: "80%",
              color: "#fff",
              display: "flex",
              flexDirection: "column",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                py: 1.5,
                background: "#da1212",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "700",
              }}
            >
              FBM E. MOTION WINNERS
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                backgroundColor: "#16213e",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                DATE
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                PLAYER
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                AMOUNT WIN
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                GAME NAME
              </Typography>
            </Box>
            <TableContainer
              sx={{
                position: "relative",
                height: "30vh",
                backgroundColor: "#fff",
                borderRadius: 0,
                overflow: "hidden",
              }}
              component={Paper}
              ref={tableRef}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <Box className="table-content">
                <Box className="table-content-inner">
                  <Table stickyHeader size="small">
                    <TableBody>
                      {extendedData.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            No winners found
                          </TableCell>
                        </TableRow>
                      ) : (
                        extendedData.map((user, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 === 0 ? "#fff" : "#eee",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                width: "28%",
                                fontSize: "0.8vw",
                                fontWeight: "bold",
                                pl: 12,
                                border: "none",
                              }}
                            >
                              {user.betting_time}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "17%",
                                fontSize: "0.8vw",
                                fontWeight: "bold",
                                py: 0.5,
                                border: "none",
                              }}
                            >
                              {user.username}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "25%",
                                fontSize: "0.8vw",
                                fontWeight: "bold",
                                py: 0.5,
                                border: "none",
                              }}
                            >
                              ₱{user.amount}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "30%",
                                fontSize: "0.8vw",
                                fontWeight: "bold",
                                py: 0.5,
                                pr: 15,
                                border: "none",
                              }}
                            >
                              {user.game}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WinnerPage;
