// ForgotPasswordMobile.js
import React, { useState } from "react";
import { Box, Button, Typography, Tab, Tabs, IconButton, TextField, Grid, Dialog, DialogTitle, DialogContentText, DialogActions, Stack, Chip, InputAdornment, DialogContent } from "@mui/material";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import MobileNumberTab from "../pages/ForgotPasswordComponents/MobileNumberTab";
import EmailTab from "../pages/ForgotPasswordComponents/EmailTab";

const StyledBox = styled(Box)(({ theme }) => ({
  gap: 20,
  height: "100vh",
  display: "flex",
  color: "white",
  position: "relative",
  alignItems: "center",
  boxSizing: "border-box",
  flexDirection: "column",
  paddingTop: theme.spacing(8),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  backgroundColor: "#090929",
}));

const ForgotPasswordMobile = () => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };


  const handleClose = () => {

    setOpen(false);
    navigate("/m/login");
  };




  return (
    <StyledBox>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
          color: "white",
        }}
      >
        <Close />
      </IconButton>

      <Typography variant="h5" sx={{ fontWeight: 600, textAlign: "center" }}>
        Forgot password
      </Typography>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          textColor="inherit"
          indicatorColor="primary"
          sx={{
            width: "fit-content",
            "& .MuiTabs-indicator": {
              backgroundColor: "red",
            },
          }}
        >
          <Tab
            label="Mobile number"
            sx={{ textTransform: "capitalize", fontWeight: 600 }}
          />
          <Tab
            label="Email"
            sx={{ textTransform: "capitalize", fontWeight: 600 }}
          />
        </Tabs>
      </Box>

      {tab === 0 ? (
        <MobileNumberTab />
      ) : (
        <EmailTab />
      )}


    </StyledBox>
  );
};

export default ForgotPasswordMobile;
