import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Link,
  Checkbox,
  Box,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BACKEND_SANCTUM } from "../../config";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../../axiosConfig";

const LoginFormMobile = ({ open, handleOpenModalParent }) => {
  const [playerPassword, setPlayerPassword] = useState("");
  const [playerUname, setPlayerUname] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [circularLoading, setCircularLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const handleSubmit = async (e) => {
    setCircularLoading(true);
    e.preventDefault();
    try {
      await axiosInstance.get(`${BACKEND_SANCTUM}sanctum/csrf-cookie`);
      const loginResponse = await axiosInstance.post(`player/login`, {
        username: playerUname,
        password: playerPassword,
      });

      if (loginResponse.status === 200) {
        const { token, user, ip_address } = loginResponse.data;
        localStorage.setItem("authToken", token);
        localStorage.setItem("username", user.username);
        localStorage.setItem("ip_address", ip_address);
        localStorage.setItem("user_id", user.player_id);
        setPlayerPassword("");
        setErrorMessage(null);
        setTermsChecked(false);
        if (user) {
          navigate("/m");
          setCircularLoading(false);
        } else {
          setErrorMessage("error logging in");
          setCircularLoading(false);
        }
      } else {
        console.error("Login error response:", loginResponse);
        if (loginResponse.status === 401) {
          setErrorMessage("Invalid username or password");
          setCircularLoading(false);
        } else {
          setErrorMessage("Invalid username or password");
          setCircularLoading(false);
        }
      }
    } catch (error) {
      setErrorMessage("Invalid username or password");
      setCircularLoading(false);
      console.error("Error during login:", error);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    navigate("/m");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPlayerPassword(e.target.value);
    setIsPasswordValid(validatePassword(newPassword));
  };

  const validatePassword = (input) => {
    const upperCaseAndLowerAndDigitAndSpecial = /^[A-Za-z0-9]{6,12}$/;
    return upperCaseAndLowerAndDigitAndSpecial.test(input);
  };

  const handleTermsChange = () => {
    setTermsChecked((prev) => !prev);
  };

  const handleForgotPasswordModal = (e) => {
    e.preventDefault();
    navigate("/m/forget");
  };

  const handleTermsOfUse = (data) => {
    navigate(data);
  };
  const handleOpenRegister = () => {
    setPlayerUname("");
    setPlayerPassword("");
    setIsUsernameValid(true);
    setIsPasswordValid(true);
    setShowPassword(false);
    setErrorMessage(null);
    setTermsChecked(false);
    navigate("/m/register");
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setPlayerUname(newUsername);
    setIsUsernameValid(validateUsername(newUsername));
  };

  const validateUsername = (input) => {
    const usernameRegex = /^[A-Za-z\d]{6,13}$/;
    return usernameRegex.test(input);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleTerms = () => {
    // handleOpenModalParent("terms");
    handleClose();
    setPlayerUname("");
    setPlayerPassword("");
    setIsUsernameValid(true);
    setIsPasswordValid(true);
    setShowPassword(false);
    setErrorMessage(null);
    setTermsChecked(false);
    navigate("/m/helpterms");
  };

  const handlePrivacy = () => {
    // handleOpenModalParent("privacy");
    // handleClose();
    setPlayerUname("");
    setPlayerPassword("");
    setIsUsernameValid(true);
    setIsPasswordValid(true);
    setShowPassword(false);
    setErrorMessage(null);
    setTermsChecked(false);
  };

  return (
    <>
      <Container
        sx={{
          background: "#090929",
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingY: 1,
            }}
          >
            <CloseIcon
              onClick={handleClose}
              sx={{ color: "white", fontWeight: "bold" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <img
              alt="logo"
              src="../fbmlogoform.png"
              style={{ width: "60vw" }}
            />
          </Box>
          <Typography
            variant="h5"
            component="div"
            color="#fafafa"
            fontWeight="bold"
            textAlign="center"
            mt={2}
          >
            Login
          </Typography>
          <Typography
            variant="h7"
            color="#fafafa"
            fontWeight="bold"
            textAlign="center"
          >
            Welcome back player!
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "15px",
              mt: 3,
              alignSelf: "start",
              width: "85%",
            }}
          >
            <span style={{ color: "red", marginLeft: "30px" }}>*</span> Username
          </Typography>
          <TextField
            sx={{
              height: "40px",
              borderRadius: "20px",
              backgroundColor: "#16213e",
              width: "85%",
              mt: 1,
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
              "&:hover .MuiInput-underline:before": {
                borderBottom: "none !important",
              },
              "&:hover .MuiInput-underline:after": {
                borderBottom: "none !important",
              },
            }}
            focused={false}
            fullWidth
            required
            variant="standard"
            onChange={handleUsernameChange}
            error={!isUsernameValid}
            InputProps={{
              style: {
                height: "50px",
                color: "#FFFFFF",
                marginLeft: "20px",
              },
            }}
          />
          <Typography
            sx={{
              color: isUsernameValid ? "#555556" : "red",
              fontSize: "11px",
              textAlign: "center",
              mt: 1,
            }}
          >
            Please enter 6 - 13 alphanumeric without special character
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "15px",
              mt: 3,
              alignSelf: "start",
              width: "85%",
            }}
          >
            <span style={{ color: "red", marginLeft: "30px" }}>*</span> Password
          </Typography>
          <TextField
            fullWidth
            sx={{
              height: "40px",
              borderRadius: "20px",
              backgroundColor: "#16213e",
              width: "85%",
              mt: 1,
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
              "&:hover .MuiInput-underline:before": {
                borderBottom: "none !important",
              },
              "&:hover .MuiInput-underline:after": {
                borderBottom: "none !important",
              },
            }}
            focused={false}
            required
            variant="standard"
            type={showPassword ? "text" : "password"}
            value={playerPassword}
            onChange={handlePasswordChange}
            autoComplete="new-password"
            error={!isPasswordValid}
            InputProps={{
              style: {
                height: "50px",
                color: "#FFFFFF",
                marginLeft: "20px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    style={{ color: "white", marginRight: "30px" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            sx={{
              color: !isPasswordValid ? "red" : "#555556",
              fontSize: "11px",
              textAlign: "center",
              marginBottom: "25px",
              marginTop: "5px",
              ".MuiOutlinedInput-input": {
                border: "none !important",
              },
            }}
          >
            Please enter 6 - 12 alphanumeric without special character
          </Typography>
          {errorMessage && (
            <Typography variant="body2" style={{ color: "red" }}>
              {errorMessage}
            </Typography>
          )}

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                onClick={handleForgotPasswordModal}
                style={{
                  color: "#fafafa",
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginRight: "40px",
                  cursor: "pointer",
                }}
              >
                Forgot Password
              </Link>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "85%",
              height: 75,
              fontSize: "14px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "end",
              position: "relative",
            }}
          >
            {circularLoading ? (
              <>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{
                    pointerEvents: circularLoading ? "none" : "auto",
                    borderRadius: "30px",
                    padding: "2px 20px",
                    fontSize: "18px",
                    color: "#fafafa",
                    backgroundColor: circularLoading ? "gray" : "#fd2f2f",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    width: "130px",
                  }}
                  disabled={circularLoading}
                >
                  Login
                </Button>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "green",
                    position: "absolute",
                    top: "62%",
                    left: "22%",
                  }}
                />
              </>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{
                  pointerEvents:
                    !termsChecked || playerUname === "" || playerPassword === ""
                      ? "none"
                      : "auto",
                  borderRadius: "30px",
                  padding: "2px 20px",
                  fontSize: "18px",
                  color: "#fafafa",
                  backgroundColor:
                    !termsChecked || playerUname === "" || playerPassword === ""
                      ? "gray"
                      : "#fd2f2f",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  width: "130px",
                }}
                disabled={
                  !termsChecked || playerUname === "" || playerPassword === ""
                }
              >
                Login
              </Button>
            )}
            <Button
              sx={{
                width: "130px",
                backgroundColor: "#16213e",
                fontWeight: "bold",
                padding: "2px 20px",
                fontSize: "18px",
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
            >
              <Link
                onClick={handleOpenRegister}
                style={{ color: "#fafafa", textDecoration: "none" }}
              >
                Register
              </Link>
            </Button>
          </Box>
          <span
            style={{
              color: "#fafafa",
              marginLeft: "150px",
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: 14,
            }}
          >
            No account yet?
          </span>
          <Grid item sx={{ marginTop: "20px" }}>
            <Grid sx={{ marginTop: "-10px", marginLeft: "23px" }}>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Checkbox
                  checked={termsChecked}
                  onChange={handleTermsChange}
                  sx={{
                    color: "#FFFFFF",
                    "&.Mui-checked": { color: "#66bb6a" },
                  }}
                />
                <Typography variant="body2" sx={{ color: "white", ml: 1 }}>
                  I am at least 21 years of age and I accept the
                  <Link
                    onClick={(e) => handleTermsOfUse("/m/helpterms#about")}
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    Terms of Use
                  </Link>
                  &nbsp;and
                  <Link
                    onClick={(e) => handleTermsOfUse("/m/helpterms#privacy")}
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default LoginFormMobile;
