import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import Game from "./components/Game";
import useStyles from "../../css/useStylesPlatform";

export default function GameSectionScrollable({
  description,
  tag,
  games,
  onClickViewAll,
  openLogin,
  setGameIframe,
  setGameIframeUrl,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const gameCards = games.map((game, key) => {
    return (
      <>
        <SwiperSlide key={key} className="swiper-slide-container">
          <Game
            key={game.id}
            data={game}
            openLogin={openLogin}
            setGameIframe={setGameIframe}
            setGameIframeUrl={setGameIframeUrl}
          />
        </SwiperSlide>
      </>
    );
  });

  return (
    <Grid className={classes.gameListContainer}>
      <Grid className={classes.headerList}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "16px",
            marginBottom: "5px",
            marginLeft: "12px",
          }}
        >
          {description}
        </Typography>

        <Button
          variant="filled"
          sx={{
            background: "#16213e",
            color: "#FFFFFF",
            border: "none",
            borderRadius: "18px",
            width: "90px",
            height: "1.4rem",
            fontWeight: "bold",
            fontSize: "14px",
            textTransform: "none",
            marginTop: "20px",
            marginRight: "10px",
            "&:hover": {
              background: "#16213e",
              color: "#FFFFFF",
            },
          }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            onClickViewAll(tag);
          }}
        >
          View All
        </Button>
      </Grid>

      <Grid className="game-list">
        <Swiper
          slidesPerView={3.2}
          spaceBetween={1}
          freeMode={true}
          modules={[FreeMode, Pagination]}
          className="swiperjs"
        >
          {gameCards.slice(0, 6)}
        </Swiper>
      </Grid>
    </Grid>
  );
}
