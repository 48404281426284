import React, { useState, useEffect, useRef, useCallback } from "react";
import { usePlayer } from "../../../../contexts/PlayerContext.js";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { CASINO_SERVER } from "../../../../config";
import GameList from "./components/GameList";
import LoadingModal from "../../../../utils/LoadingModal";
import { Grid } from "@mui/material";
import GameNameSearch from "./components/GameNameSearch.js";
import SortButton from "./components/SortButton.js";
import StraightIcon from "@mui/icons-material/Straight";
import FavoritesButton from "./components/FavoritesButton.js";

const HotGamesPage = ({ setGameIframe, setGameIframeUrl, openLogin }) => {
  const { isMobile } = usePlayer();
  const [filteredGameList, setFilteredGameList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const pageSize = 18;

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const skipPageRef = useRef(false);
  const prevParamPage = useRef(1);
  const prevParamSearchQuery = useRef("");
  const prevParamSortingType = useRef("default");
  const [sortingType, setSortingType] = useState("default");

  const [favoriteButtonState, setFavoriteButtonState] = useState(false);

  const handleSortAZ = () => {
    if (sortingType !== "ascending") {
      setSortingType("ascending");
    } else {
      setSortingType("default");
    }
  };

  const handleSortZA = () => {
    if (sortingType !== "descending") {
      setSortingType("descending");
    } else {
      setSortingType("default");
    }
  };

  const handleFavoriteClick = () => {
    setFavoriteButtonState((prevState) => !prevState);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 900);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const resetPageParams = () => {
    setFilteredGameList([]);
    setHasMore(true);
    setInitialLoad(true);

    if (page !== 1) {
      setPage(1);
      skipPageRef.current = true;
    }
  };

  useEffect(() => {
    if (prevParamSearchQuery.current !== debouncedQuery) {
      resetPageParams();
      fetchHotGames(1);
    } else if (prevParamPage.current !== page) {
      if (skipPageRef.current) {
        skipPageRef.current = false;
        return;
      }
      fetchHotGames(page);
    } else if (prevParamSortingType.current !== sortingType) {
      resetPageParams();
      fetchHotGames(1);
    } else {
      fetchHotGames(1);
    }

    prevParamPage.current = page;
    prevParamSearchQuery.current = debouncedQuery;
    prevParamSortingType.current = sortingType;
  }, [page, debouncedQuery, sortingType]);

  const fetchHotGames = async (paramPage) => {
    setLoading(true);
    const params = {
      page: paramPage ? paramPage : 1,
      size: pageSize,
      search: searchQuery,
      sorting: sortingType,
    };

    try {
      const response = await axios.get(`${CASINO_SERVER}game/list/hot`, {
        params,
      });
      const newGames = response.data.data.filter((game) => game !== null);
      if (newGames.length > 0) {
        setTimeout(() => {
          setLoading(false);
          setFilteredGameList((prevGames) => [...prevGames, ...newGames]);
        }, 200);

        if (newGames.length < pageSize) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setHasMore(false);
      }
    } catch (err) {
      console.error(err);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const lastGameElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          if (initialLoad) {
            setInitialLoad(false);
          } else {
            setPage((prevPage) => prevPage + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, initialLoad]
  );

  return (
    <>
      {isMobile ? (
        <Grid
          container
          sx={{
            marginTop: "-45px",
            marginBottom: "70px",
          }}
        >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <GameNameSearch
              searchValue={searchQuery}
              setSearchValue={setSearchQuery}
            />
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <SortButton
              onClick={handleSortAZ}
              status={sortingType === "ascending"}
              symbol={
                <StraightIcon
                  sx={{
                    marginTop: "12px",
                    fontSize: "28px",
                    marginLeft: "-7px",
                  }}
                />
              }
            >
              A<br />Z
            </SortButton>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <SortButton
              onClick={handleSortZA}
              status={sortingType === "descending"}
              symbol={
                <StraightIcon
                  sx={{
                    rotate: "180deg",
                    marginTop: "12px",
                    fontSize: "28px",
                    marginLeft: "-7px",
                  }}
                />
              }
            >
              Z<br />A
            </SortButton>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <FavoritesButton
              state={favoriteButtonState}
              onClick={handleFavoriteClick}
            />
          </Grid>

          <GameList
            filteredGameList={filteredGameList}
            loading={loading}
            setGameIframe={setGameIframe}
            setGameIframeUrl={setGameIframeUrl}
            openLogin={openLogin}
          />

          {loading && <LoadingModal loading={loading} />}

          <div ref={lastGameElementRef}></div>
        </Grid>
      ) : (
        <>
          <GameList
            filteredGameList={filteredGameList}
            loading={loading}
            setGameIframe={setGameIframe}
            setGameIframeUrl={setGameIframeUrl}
            openLogin={openLogin}
          />

          {loading && <LoadingModal loading={loading} />}

          <div ref={lastGameElementRef}></div>
        </>
      )}
    </>
  );
};

export default HotGamesPage;
