import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Modal,
} from "@mui/material";
import { WALLET } from "../../config";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import useStyles from "../../pages/css/useStylesPlatform";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import axiosInstance from "../../axiosConfig";
import CircularProgress from "@mui/material/CircularProgress";

const paymentChannels = [
  {
    id: 1,
    type: "GCASH",
    name: "GCASH",
    minimum: "500",
    maximum: "49,999",
  },
  {
    id: 2,
    type: "GCASH500",
    name: "GCASH500",
    minimum: "1,000",
    maximum: "49,999",
  },
];

const DepositPageMobile = () => {
  const username = localStorage.getItem("username");
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {};

  const [selectedMethod, setSelectedMethod] = useState("GCASH");
  const [selectedChannel, setSelectedChannel] = useState("GCASH");
  const [minimumAmount, setMinimumAmount] = useState("500");
  const [maximumAmount, setMaximumAmount] = useState("49,999");
  const [depositAmount, setDepositAmount] = useState("");
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [successDeposit, setSuccessDeposit] = useState(false);
  const [depositMethod, setDepositMethod] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`payment/providers`)
      .then((response) => {
        setDepositMethod(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (successDeposit) {
      const timer = setTimeout(() => {
        setSuccessDeposit(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [successDeposit]);

  const handleBackButton = () => {
    if (from === "/m") {
      navigate("/m");
    } else if (from === "/m/member/home") {
      navigate("/m/member/home");
    } else {
      navigate(-1);
    }
  };

  const handleSelectedMethod = (data) => {
    setSelectedMethod(data);
  };

  const handleSelectedChannel = (name, minimum, maximum) => {
    setSelectedChannel(name);
    setMinimumAmount(minimum);
    setMaximumAmount(maximum);
    validateDepositAmount(depositAmount, minimum, maximum);
  };

  const validateDepositAmount = (amount, minimum, maximum) => {
    const parsedAmount = parseInt(amount.replace(/\D/g, ""));
    const parsedMin = parseInt(minimum.replace(/,/g, ""));
    const parsedMax = parseInt(maximum.replace(/,/g, ""));
    if (parsedAmount >= parsedMin && parsedAmount <= parsedMax) {
      setIsAmountValid(true);
    } else {
      setIsAmountValid(false);
    }
  };
  const handleDepositAmount = (e) => {
    const amount = e.target.value.replace(/\D/g, "");
    const formattedAmount = amount
      ? new Intl.NumberFormat().format(amount)
      : "";
    setDepositAmount(formattedAmount);
    validateDepositAmount(formattedAmount, minimumAmount, maximumAmount);
  };

  const handleDepositRecord = () => {
    navigate("/m/vouReport");
  };
  const handleSubmitButton = async (e) => {
    e.preventDefault();
    try {
      const generateUniqueId = (prefix) =>
        `${prefix}-${Math.floor(Math.random() * 100000)}`;
      const transaction_id = generateUniqueId("DEP");
      const request_id = generateUniqueId("RMDEP");
      const depositData = {
        user: username,
        real_amount: depositAmount,
        transaction_id,
        request_id,
      };
      const handleDeposit = async (endpoint) => {
        const result = await axiosInstance.post(endpoint, depositData);
        if (result.data.status === "OK") {
          window.open(result.data.checkout_url);
        } else {
          alert("Deposit unsuccessful. Please try again.");
        }
      };
      const methodEndpoints = {
        GCASH: "player/deposit/paymongo",
        "GCASH Paymongo": "player/deposit/paymongo",
        PAYMAYA: "player/deposit/paymaya",
        "GRAB_PAY Paymongo": "player/deposit/grab-pay",
        "GCASH Xendit": "player/deposit/xendit"
      };
      if (methodEndpoints[selectedMethod]) {
        await handleDeposit(methodEndpoints[selectedMethod]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          backgroundColor: "#f5f5f9",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "15%", fontSize: "3.8vw" }}>
                Deposit
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                height: "100%",
                alignContent: "center",
                justifyContent: "flex-end",
              }}
            >
              <ContentPasteOutlinedIcon
                onClick={handleDepositRecord}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6vw",
                  marginRight: "11%",
                  marginTop: "2%",
                }}
              />
              <ErrorOutlineOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "7vw", marginRight: "10%" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ backgroundColor: "#FFFFFF" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ display: "flex" }}
          >
            <Typography
              sx={{
                color: "#fb9e00",
                fontSize: "6vw",
                marginTop: ".8%",
                marginRight: "1%",
                marginLeft: "2.5%",
              }}
            >
              •
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontWeight: "bold",
                fontSize: "3.3vw",
                marginTop: "2.5%",
                marginLeft: "-.5%",
              }}
            >
              Deposit method
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: "-.9%",
            }}
          >
            {depositMethod.map((row, index) => (
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box
                  onClick={() => handleSelectedMethod(row.name)}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "6%",
                    border:
                      selectedMethod === row.name
                        ? "1px solid rgb(255, 0, 0)"
                        : "1px solid #e4e4e4",
                    height: "22vw",
                    width: "22.5vw",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    marginBottom: "15%",
                    marginLeft: "3%",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      zIndex: "1",
                    }}
                  >
                    <img
                      src="/../../../FBMLike.png"
                      alt="FBM Like"
                      style={{ width: "3.3vw", height: "4.2vw" }}
                    />
                  </Grid>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <img
                      alt="provider logo"
                      src={`${process.env.PUBLIC_URL}/payment-method/${row.name}.png`}
                      style={{
                        width: "10vw",
                        marginTop: "-10%",
                        height: "9.5vw",
                      }}
                    />
                  </Grid>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <Typography
                      sx={{
                        color: selectedMethod === row.name ? "red" : "#000000",
                        fontSize: "2.5vw",
                        fontWeight: "bold",
                        // marginTop: "20%",
                        textAlign: "center",
                      }}
                    >
                      {row.name}
                    </Typography>
                  </Grid>
                  {selectedMethod === row.name && (
                    <Grid
                      container
                      sx={{
                        zIndex: "1",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        src="/../../../FBMCheck.svg"
                        alt="FBM Check"
                        style={{ width: "4.5vw", marginTop: "-5%" }}
                      />
                    </Grid>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "2%", backgroundColor: "#FFFFFF" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex" }}
            >
              <Typography
                sx={{
                  color: "#65cac0",
                  fontSize: "6vw",
                  marginTop: "-1%",
                  marginRight: "1%",
                  marginLeft: "2.5%",
                }}
              >
                •
              </Typography>
              <Typography
                sx={{
                  color: "#333333",
                  fontWeight: "bold",
                  fontSize: "3.3vw",
                  marginTop: "1%",
                }}
              >
                Payment channels
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                marginTop: "-.9%",
              }}
            >
              {paymentChannels.map((row, index) => (
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Box
                    onClick={() =>
                      handleSelectedChannel(row.name, row.minimum, row.maximum)
                    }
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "6%",
                      border:
                        selectedChannel === row.name
                          ? "1px solid rgb(255, 0, 0)"
                          : "1px solid #e4e4e4",
                      height: "12vw",
                      width: "30vw",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      marginTop: "5%",
                      marginLeft: "3%",
                      marginBottom: "15%",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        zIndex: "1",
                      }}
                    >
                      <img
                        src="/../../../FBMLike.png"
                        alt="FBM Like"
                        style={{ width: "3.3vw", height: "4.2vw" }}
                      />
                    </Grid>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <Typography
                        sx={{
                          color:
                            selectedChannel === row.name ? "red" : "#00000",
                          fontSize: "3vw",
                          fontWeight: "bold",
                          marginTop: "-15%",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "2%", backgroundColor: "#FFFFFF" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ display: "flex" }}
          >
            <Typography
              sx={{
                color: "#b56f85",
                fontSize: "6vw",
                marginRight: "1%",
                marginLeft: "2.5%",
                marginTop: ".3vw",
              }}
            >
              •
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontWeight: "bold",
                fontSize: "3.3vw",
                marginTop: "2%",
              }}
            >
              Deposit Amount
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ marginRight: "5%", width: "100%" }}
          >
            <TextField
              onChange={handleDepositAmount}
              value={depositAmount}
              placeholder={`${minimumAmount} - ${maximumAmount}`}
              variant="outlined"
              sx={{
                width: "100%",
                marginLeft: "2.5%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.3vw",
                  "& fieldset": {
                    borderColor: isAmountValid ? "#c4c4c4" : "red",
                  },
                  "&:hover fieldset": {
                    borderColor: isAmountValid ? "#c4c4c4" : "red",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isAmountValid ? "#c4c4c4" : "red",
                    borderWidth: "1px",
                  },
                },
                "& input::placeholder": {
                  fontSize: "3.5vw",
                },
              }}
              InputProps={{
                style: {
                  height: "9.5vw",
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      color: "#000000",
                    }}
                  >
                    <Typography sx={{ fontSize: "3.5vw" }}>₱</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {!isAmountValid && (
            <Typography
              sx={{
                color: "red",
                fontSize: "3vw",
                marginTop: "2%",
                marginLeft: "4%",
                marginBottom: "5%",
              }}
            >
              *Minimum amount: {minimumAmount}, Maximum amount:
              {maximumAmount}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          sx={{ height: "45vh", backgroundColor: "#f5f5f9" }}
        ></Grid>
        <Button
          onClick={handleSubmitButton}
          disabled={!isAmountValid}
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: isAmountValid ? "#ec2529" : "#cdcdcd",
            textAlign: "center",
            textTransform: "none",
            height: "15vw",
            borderRadius: "0vw",
            "&:hover": {
              backgroundColor: "#ec2529",
            },
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              marginTop: "2%",
            }}
          >
            Next
          </Typography>
        </Button>
        {successDeposit && (
          <Modal
            open={successDeposit}
            BackdropProps={{
              style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
            }}
            className={classes.myAccountsModal}
          >
            <Box
              sx={{
                backgroundColor: "#4d4d4d",
                opacity: "90%",
                p: 3,
                borderRadius: "5%",
                textAlign: "center",
                flexDirection: "column",
                outline: "none",
              }}
            >
              <CheckCircleOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "13vw" }}
              />
              <Typography sx={{ color: "#FFFFFF", fontSize: "3.5vw" }}>
                Deposit Successful
              </Typography>
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default DepositPageMobile;
