import React from "react";
import About from "./About";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Cookies from "./Cookies";
import Faq from "./Faq";
import ResponsibleGaming from "./ResponsibleGaming";

function HelpCenterContent({ selectedMenu }) {
  let content;

  switch (selectedMenu.selectedModalMenu) {
    case "about":
      content = <About />;
      break;
    case "terms":
      content = <TermsAndConditions />;
      break;
    case "privacy":
      content = <PrivacyPolicy />;
      break;
    case "cookies":
      content = <Cookies />;
      break;
    case "faqs":
      content = <Faq />;
      break;
    case "responsible":
      content = <ResponsibleGaming />;
      break;
    default:
      break;
  }

  return content;
}

export default HelpCenterContent;
