import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { usePlayer } from "../../contexts/PlayerContext";
import axiosInstance from "../../axiosConfig";

const MyCardsMobile = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const { setIsVisible } = usePlayer();

  useEffect(() => {
    axiosInstance
      .get(`player/paymentmethod`)
      .then((response) => {
        const data = response.data;
        setData(data);
        setRowCount(data.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleBackButton = () => {
    navigate("/m/member/home");
  };

  const handleForm = () => {
    navigate("/m/myEWallet", { state: { from: "/m/myWithdrawCards" } });
    setIsVisible(true);
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "15%", fontSize: "3.8vw" }}>
                My Cards
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: "flex", marginTop: "1%" }}
        >
          <Typography
            sx={{
              color: "#fb9e00",
              fontSize: "5.5vw",
              marginTop: "1%",
              marginRight: "1%",
              marginLeft: "2.5%",
            }}
          >
            •
          </Typography>
          <Typography
            sx={{
              color: "#333333",
              fontSize: "3.5vw",
              marginTop: "2%",
              marginLeft: "-.5%",
            }}
          >
            E-wallet added: {rowCount}
          </Typography>
        </Grid>
        {data.map((row, index) => (
          <Grid
            container
            sx={{
              flexDirection: "column",
              display: "flex",
              position: "relative",
            }}
            key={index}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "3%",
                display: "flex",
                marginBottom: "12%",
                position: "relative",
              }}
            >
              <img
                src={`../../../MyCards_bg${index + 1}.png`}
                style={{
                  width: "96.5%",
                  height: "22.5vw",
                  position: "absolute",
                }}
                alt="Banner Background"
              />
              <img
                src="../../../gcash.png"
                style={{
                  width: "22.5vw",
                  height: "7.8vw",
                  marginLeft: "2%",
                  marginTop: "3.5%",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "2vw",
                  paddingTop: "1.5%",
                  paddingBottom: "1.5%",
                  zIndex: "1",
                }}
                alt="Banner Background"
              />
              <Typography
                sx={{
                  position: "absolute",
                  marginLeft: "26.5%",
                  fontSize: "3vw",
                  marginTop: "4%",
                  color: "#75777c",
                }}
              >
                {row.type}
              </Typography>
              <Typography
                sx={{
                  fontSize: "4.5vw",
                  zIndex: 1,
                  color: "#75777c",
                  marginLeft: "2%",
                  marginTop: "9%",
                  fontWeight: "100",
                  letterSpacing: "-1px",
                }}
              >
                *******
              </Typography>
              <Typography
                sx={{
                  fontSize: "3.2vw",
                  zIndex: 1,
                  color: "#75777c",
                  marginLeft: ".3%",
                  marginTop: "9.2%",
                }}
              >
                {String(row.account_number).substring(7)}
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.5vw",
                  zIndex: 1,
                  color: "#75777c",
                  marginLeft: "30%",
                  marginTop: "17%",
                }}
              >
                {row.created_at.substring(0, 10)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {rowCount < 2 && (
        <AddCircleIcon
          onClick={handleForm}
          sx={{
            color: "#ff1931",
            fontSize: "12vw",
            borderRadius: "10vw",
            position: "fixed",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "pointer",
          }}
        />
      )}
    </>
  );
};

export default MyCardsMobile;
