import { Box, Button, Typography, styled } from "@mui/material";

const ButtonContainer = styled(Box)({
  borderTop: "1px solid #D3D3D3",
  paddingTop: "10px",
  paddingLeft: "10px",
  display: "flex",
  alignItems: "center",
  gap: "25px",
});

const StyledButton = styled(Button)({
  backgroundColor: "#FF0000",
  borderRadius: "20px",
  color: "#FFFFFF",
  width: "100px",
  "&:hover": {
    backgroundColor: "#FF0000",
  },
  "&.Mui-disabled": {
    backgroundColor: "#A9A9A9",
    color: "#FFFFFF",
  },
});

export default function TransactButton({
  handleSubmit,
  remainingTurnover,
  balance,
  minimumBalanceWithdraw,
  totalAttempt
}) {



  return (
    <ButtonContainer>
      <StyledButton
        onClick={handleSubmit}
        disabled={
          parseFloat(remainingTurnover) !== 0.0 ||
          parseFloat(balance) < parseFloat(minimumBalanceWithdraw) || totalAttempt === 0
        }
      >
        Submit
      </StyledButton>
      <Typography variant="body2">
        Remaining Number of Withdrawal Today :
        <span
          style={{
            color: "#FF0000",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {totalAttempt}
        </span>
      </Typography>
    </ButtonContainer>
  );
}
