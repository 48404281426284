import { useState } from "react";
import { Modal, Grid, Typography, Box, Avatar, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import "./../css/style.css";
import useStyles from "../../pages/css/useStylesPlatform";
import axiosInstance from "../../axiosConfig";

const MyAccount = (props) => {
  const { balance, setBalance } = props;
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1500);
    return () => clearTimeout(timer);
  };

  const handleBackButton = () => {
    navigate("/m");
  };

  const handleBalanceRefresh = () => {
    setIsRefreshed(true);
    setTimeout(() => {
      setIsRefreshed(false);
    }, 1000);

    axiosInstance
      .get(`player/balance`)
      .then((response) => {
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleNavigateButton = (path, from) => {
    navigate(path, { state: { from: from } });
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <img
          src="../../../../my-account-banner-bg.png"
          style={{
            width: "100%",
            position: "absolute",
          }}
          alt="Banner Background"
        />
        <Grid container className={classes.headerContainer}>
          <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
            <Grid className={classes.arrowButton}>
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "90%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5} xl={7.5}>
            <Grid
              sx={{
                color: "#FFFFFF",
                alignItems: "center",
                height: "100%",
                fontSize: "60%",
              }}
            >
              My Account
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "5%" }}>
          <Grid item xs={0.8} sm={0.8} md={0.8} lg={0.8} xl={0.8}></Grid>
          <Grid item xs={11.2} sm={11.2} md={11.2} lg={11.2} xl={11.2}>
            <Box
              sx={{
                display: "flex",
                borderRadius: "15px 0px 0px 15px",
                position: "absolute",
                backgroundImage: "linear-gradient(to right, #e5edf4, #b4bbc6)",
                paddingTop: "2%",
                paddingBottom: "2%",
                paddingRight: "2%",
                paddingLeft: "2%",
              }}
            >
              <img
                src="../../../../my-account-border.png"
                style={{
                  width: "100%",
                  height: "55vw",
                }}
                alt="Profile border"
              />
              <img
                src="../../../../my-account-badge.png"
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "75%",
                  transform: "translate(-50%, -50%)",
                  width: "43%",
                  height: "auto",
                  zIndex: 1,
                }}
                alt="Profile badge"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ zIndex: 1 }}>
          <Grid item xs={3} sm={3}>
            <Avatar
              src="../../../../my-account-profile-picture.png"
              sx={{
                width: "53%",
                height: "53%",
                border: "0.9vw  solid #caa86d",
                marginLeft: "45%",
                marginTop: "45%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            sx={{ marginTop: "13%" }}
          >
            <Grid container alignItems="center" sx={{ marginLeft: "5%" }}>
              <Typography sx={{ fontSize: "4vw", fontWeight: "bold" }}>
                {username}
              </Typography>
              <CopyToClipboard text={username} onCopy={handleCopy}>
                <ContentCopyRoundedIcon
                  sx={{
                    fontSize: "5vw",
                    marginLeft: "2%",
                    marginTop: "-2%",
                    transform: "rotate(360deg)",
                  }}
                />
              </CopyToClipboard>
            </Grid>
            <Grid container alignItems="center" sx={{ marginLeft: "5%" }}>
              <Typography
                sx={{
                  fontSize: "5vw",
                  color: "#6b6e72",
                  fontWeight: "bold",
                }}
              >
                ₱{balance.toFixed(2)}
              </Typography>
              <RefreshIcon
                onClick={handleBalanceRefresh}
                sx={{
                  fontSize: "7vw",
                  marginLeft: "2%",
                  marginTop: "-2%",
                  color: "#6b6e72",
                  animation: isRefreshed ? "rotate 1s ease-in-out" : "none",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ marginLeft: "11%" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "3.2vw",
                marginTop: "5%",
                color: "#989da3",
              }}
            >
              <span
                style={{
                  fontSize: "6vw",
                  color: "#b9c6d8",
                  marginRight: "1%",
                  marginTop: "-1%",
                }}
              >
                •
              </span>
              <Typography
                sx={{
                  fontSize: "inherit",
                  color: "inherit",
                }}
              >
                Last login time: 2024/06/18 10:54:37
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ zIndex: 1, marginTop: "2%", marginLeft: "12%" }}>
          <Grid
            onClick={() => handleNavigateButton("/m/voucherCenter")}
            className={classes.cardButton}
          >
            <Typography
              sx={{ color: "#454545", fontSize: "3vw", fontWeight: "bold" }}
            >
              Deposit
            </Typography>
          </Grid>
          <Grid
            onClick={() => handleNavigateButton("/m/withdraw")}
            className={classes.cardButton}
            sx={{ marginLeft: "5%" }}
          >
            <Typography
              sx={{ color: "#454545", fontSize: "3vw", fontWeight: "bold" }}
            >
              Withdrawal
            </Typography>
          </Grid>
          <Grid
            onClick={() => handleNavigateButton("/m/myWithdrawCards")}
            className={classes.cardButton}
            sx={{ marginLeft: "5%" }}
          >
            <Typography
              sx={{ color: "#454545", fontSize: "3vw", fontWeight: "bold" }}
            >
              My Cards
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "14%" }}>
          <Grid item sx={{ marginLeft: "7%" }}>
            <Typography
              sx={{
                fontSize: "2.5vw",
                backgroundColor: "#dfdfdf",
                paddingLeft: "3vw",
                paddingRight: "3vw",
                paddingTop: "1vw",
                borderRadius: "10vw",
                whiteSpace: "nowrap",
              }}
            >
              Member Center
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider sx={{ width: "100%", marginTop: "3%" }} />
          </Grid>
        </Grid>
        <Grid container className={classes.menuButton}>
          <Grid item xs={3} sm={3}>
            <Box
              onClick={() => handleNavigateButton("/m/rewardCenter")}
              className={classes.myAccountsIconsBox}
            >
              <EmojiEventsOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Reward <br />
                Center
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            onClick={() => handleNavigateButton("/m/player/bettingrecords")}
          >
            <Box className={classes.myAccountsIconsBox}>
              <StarBorderIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Betting <br /> Record
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Box className={classes.myAccountsIconsBox}>
              <AttachMoneyIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Profit <br />
                And Loss
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Box
              onClick={() => handleNavigateButton("/m/vouReport")}
              className={classes.myAccountsIconsBox}
            >
              <PaidOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Deposit
                <br /> Record
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.myAccountsIconsContainer}>
          <Grid item xs={3} sm={3}>
            <Box
              onClick={() => handleNavigateButton("/m/withdrawReport")}
              className={classes.myAccountsIconsBox}
            >
              <ReceiptLongOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Withdrawal Record
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            onClick={() => handleNavigateButton("/m/player/accountrecords")}
          >
            <Box className={classes.myAccountsIconsBox}>
              <PortraitOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Account Record
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Box
              onClick={() =>
                handleNavigateButton("/m/myAccount/index", "/m/member/home")
              }
              className={classes.myAccountsIconsBox}
            >
              <AccountCircleOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{ fontSize: "3.4vw", marginTop: "10%", color: "#565659" }}
              >
                My Account
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            onClick={() => handleNavigateButton("/m/securityCenter")}
          >
            <Box className={classes.myAccountsIconsBox}>
              <AddModeratorOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Security <br />
                Center
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.myAccountsIconsContainer}>
          <Grid item xs={3} sm={3}>
            <Box className={classes.myAccountsIconsBox}>
              <AlternateEmailOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Internal Message
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Box className={classes.myAccountsIconsBox}>
              <MarkUnreadChatAltOutlinedIcon
                sx={{
                  color: "#d1a24f",
                  backgroundColor: "#fff2db",
                  borderRadius: "20vw",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "2vw",
                  paddingBottom: "2vw",
                  fontSize: "8.5vw",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.4vw",
                  marginTop: "10%",
                  textAlign: "center",
                  lineHeight: "1",
                  color: "#565659",
                }}
              >
                Suggestion
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {copied && (
          <Modal
            open={copied}
            BackdropProps={{
              style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
            }}
            className={classes.myAccountsModal}
          >
            <Box
              sx={{
                backgroundColor: "#4d4d4d",
                opacity: "90%",
                p: 3,
                borderRadius: "5%",
                textAlign: "center",
                flexDirection: "column",
                outline: "none",
              }}
            >
              <CheckCircleOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "13vw" }}
              />
              <Typography sx={{ color: "#FFFFFF", fontSize: "3.5vw" }}>
                {username}
              </Typography>
              <Typography sx={{ color: "#FFFFFF", fontSize: "3.5vw" }}>
                Copy successful
              </Typography>
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default MyAccount;
