import React from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, Typography } from "@mui/material";
const DialogWalletModal = ({
  onClose,
  dialogWalletModal,
  setDialogWalletModal,
}) => {
  const navigate = useNavigate();
  const handleDeposit = () => {
    navigate("/m/voucherCenter");
  };

  const handleWithdrawal = () => {
    navigate("/m/withdraw");
  };

  return (
    <Dialog
      open={dialogWalletModal}
      onClose={onClose}
      sx={{
        borderRadius: "20px",
        marginBottom: "150%",
      }}
    >
      <DialogContent
        style={{
          boxSizing: "border-box",
          backgroundColor: "#090929",
          padding: "20px 5px",
          width: "220px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          onClick={handleDeposit}
          style={{
            fontSize: "12px",
            color: "#fff",
            boxSizing: "border-box",
            padding: "6px 12px",
            cursor: "pointer",
            display: "block",
            textAlign: "center",
            fontWeight: "bold",
            border: "1px solid #fff",
            marginRight: "10px",
            borderRadius: "10px",
          }}
        >
          Deposit
        </Typography>{" "}
        <Typography
          onClick={handleWithdrawal}
          style={{
            fontSize: "12px",
            color: "#fff",
            boxSizing: "border-box",
            padding: "6px 12px",
            cursor: "pointer",
            display: "block",
            textAlign: "center",
            fontWeight: "bold",
            border: "1px solid #fff",
            borderRadius: "10px",
          }}
        >
          Withdraw
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default DialogWalletModal;
