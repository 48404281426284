let CASINO_SERVER = "http://localhost:8000/api/";
let WALLET = "http://wallet.ntt.lan:8000/api/";
let WEB_SOCKET = "ws://172.30.10.125:8080/ws";
let BACKEND_SANCTUM = "http://localhost:8000/";

if (process.env.REACT_APP_API_URL === "local") {
  CASINO_SERVER = "http://localhost:8000/api/";
} else if (process.env.REACT_APP_API_URL === "staging") {
  CASINO_SERVER = "http://player.ntt.lan:8000/api/";
  BACKEND_SANCTUM = "http://player.ntt.lan:8000/";
} else if (process.env.REACT_APP_API_URL === "cloud") {
  CASINO_SERVER = "https://player.nttplatform.com/api/";
  BACKEND_SANCTUM = "https://player.nttplatform.com/";
} else if (process.env.REACT_APP_API_URL === "dmz") {
  CASINO_SERVER = "https://player.nttplatform.com/api/";
  BACKEND_SANCTUM = "https://player.nttplatform.com/";
  WALLET = "https://wallet.nttplatform.com/api/";
  WEB_SOCKET = "wss://websocket.nttplatform.com/ws";
}
export { CASINO_SERVER, WALLET, WEB_SOCKET, BACKEND_SANCTUM };
