import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
const DialogMaximumWarning = ({
  onClose,
  dialogMaximumWarning,
  setdialogMaximumWarning,
}) => {
  const handleClose = () => {
    setdialogMaximumWarning(false);
  };
  return (
    <Dialog
      open={dialogMaximumWarning}
      onClose={onClose}
      sx={{
        borderRadius: "20px",
      }}
    >
      <DialogContent
        style={{
          boxSizing: "border-box",
          backgroundColor: "#2b3248",
          padding: "20px",
          width: "400px",
          textAlign: "center",
        }}
      >
        <img
          src="prompt-favorite/circle-warning.svg"
          alt="Warning Icon"
          style={{
            width: "42px",
            height: "42px",
            paddingBottom: "20px",
          }}
        />

        <Typography
          style={{
            textRendering: "optimizeLegibility",
            textAlign: "left",
            display: "inline-block",
            color: "#fff",
            fontFamily:
              "Barlow, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, sans-serif",
            fontWeight: 400,
            fontSize: "15px",
            paddingTop: "0px",
            paddingRight: "20px",
            paddingBottom: "0px",
            paddingLeft: "20px",
            outlineColor: "rgb(255, 255, 255)",
          }}
        >
          Number of bank account already meet the maximum bank account allowed.
          if you need to modify or delete, please contact customer service
        </Typography>
      </DialogContent>
      <Typography
        variant="button"
        onClick={handleClose}
        style={{
          fontSize: "11px",
          color: "#fff",
          boxSizing: "border-box",
          backgroundColor: "rgba(218, 18, 18, 0.9)",
          padding: "12px 24px",
          cursor: "pointer",
          display: "block",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Confirm
      </Typography>
    </Dialog>
  );
};

export default DialogMaximumWarning;
