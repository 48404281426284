import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Modal,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import "./../../css/style.css";
import dayjs from "dayjs";
import { Calendar } from "react-date-range";
import OccupationModal from "./OccupationModal";
import SoiModal from "./SoiModal";
import axiosInstance from "../../../axiosConfig";
import { useQuery } from "react-query";

const MyAccountDetails = () => {
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {};

  const [profile, setProfile] = useState({});
  const [socMed, setSocMed] = useState({});
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [qq, setQQ] = useState("");
  const [weChat, setWeChat] = useState("");
  const [line, setLine] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [zalo, setZalo] = useState("");

  const [idNumber, setIdNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [sourceOfIncome, setSourceOfIncome] = useState("");
  const [occupation, setOccupation] = useState("");
  const [accountData, setAccountData] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [soiModal, setSoiModal] = useState(false);
  const [occupationModal, setOccupationModal] = useState(false);
  const [isBirthdayValid, setIsBirthdayValid] = useState(true);
  const [submitPrompt, setSubmitPrompt] = useState(false);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);

  const [emailHelperText, setEmailHelperText] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullNameHelperText, setFullNameHelperText] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [IDNumberHelperText, setIDNumberHelperText] = useState("");
  const [IDNumberError, setIDNumberError] = useState("");
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [QQHelperText, setQQHelperText] = useState("");
  const [QQError, setQQError] = useState("");
  const [weChatHelperText, setWeChatHelperText] = useState("");
  const [weChatError, setWeChatError] = useState("");
  const [lineHelperText, setLineHelperText] = useState("");
  const [lineError, setLineError] = useState("");
  const [whatsAppHelperText, setWhatsAppHelperText] = useState("");
  const [whatsAppError, setWhatsAppError] = useState("");
  const [facebookHelperText, setFacebookHelperText] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [zaloHelperText, setZaloHelperText] = useState("");
  const [zaloError, setZaloError] = useState("");

  const calendarRef = useRef(null);
  const soiRef = useRef(null);
  const occupationRef = useRef(null);

  const SAPMSWA = /^[A-Za-z][A-Za-z0-9]*$/;
  const SEF = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const SAP = /^[a-zA-Z0-9]+$/;
  const SR1LA1NMIU = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d_]+$/;
  const NO = /^[0-9]*$/;
  const SAPWALOUC = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/;
  const AASOALOA = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
  const ABSNA = /^\S+$/;
  const YYYYMMDD = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  const AASALTAWS = /^(?=.*[A-Za-z].*[A-Za-z])[A-Za-z ]+$/;
  const MMDDYYYY = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  const BAANR = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
  const numberAccount = /^09\d{9}$/;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (calendarOpen || soiModal || occupationModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarOpen, soiModal, occupationModal]);

  const fetchData = () => {
    setLoading(true);
    axiosInstance
      .get(`player/player-profile`)
      .then((response) => {
        const data = response.data;
        setAccountData(data);
        setBirthday(data.birthdate);
        setEmail(data.email);
        setFullName(data.full_name);
        setIdNumber(data.id_number);
        setMobileNumber(data.mobile_no);
        setQQ(data.qq);
        setWeChat(data.wechat);
        setLine(data.line);
        setWhatsApp(data.whatsapp);
        setFacebook(data.facebook);
        setZalo(data.zalo);
        setSourceOfIncome(data.soi);
        setOccupation(data.occupation);
        setProfile(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const fetchDataFunctionalSettings = async () => {
    const response = await axiosInstance.get(
      `platform/management/functional/settings`
    );
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }
    return response.data;
  };

  const {
    data: dataFunctionalSetings,
    isLoading: isLoadingFunctionalSetings,
    refetch: refetchFunctionalSetings,
  } = useQuery("playerFunctionalSettings", fetchDataFunctionalSettings);

  const handleChange = (e, row, fieldName) => {
    const { value } = e.target;
    let errors = false;
    let helperTexts = "";

    if (row.pattern === "Any") {
      errors = false;
      helperTexts = "";
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern, must start with alphabetical"
    ) {
      if (SAPMSWA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must start with a letter and be followed by alphanumeric characters only";
      }
    } else if (row.pattern === "Standard email format") {
      if (SEF.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter a valid email address";
      }
    } else if (row.pattern === "Standard alphanumeric pattern") {
      if (SAP.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Please enter only letters and numbers. Special characters and spaces are not allowed";
      }
    } else if (
      row.pattern ===
      "Strictly require 1 letter and 1 number, may include underscore"
    ) {
      if (SR1LA1NMIU.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must include at least one letter and one number. An underscore (_) is optional";
      }
    } else if (row.pattern === "Number only") {
      if (NO.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter only alphanumeric characters";
      }
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern with at least one uppercase character"
    ) {
      if (SAPWALOUC.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and numbers, with at least one uppercase letter";
      }
    } else if (
      row.pattern === "Alphabet and spacing only, at least one alphabet"
    ) {
      if (AASOALOA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Only alphabetic characters and spaces allowed. Must include at least one letter";
      }
    } else if (row.pattern === "Any but spacing not allowed") {
      if (ABSNA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Only non-spacing characters are allowed";
      }
    } else if (row.pattern === "yyyy-MM-dd") {
      if (YYYYMMDD.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format yyyy-MM-dd";
      }
    } else if (
      row.pattern ===
      "Alphabet and spacing only, at least two alphabets with spacing"
    ) {
      if (AASALTAWS.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and spaces, with at least two letters and at least one space.";
      }
    } else if (row.pattern === "mm-dd-yyyy") {
      if (MMDDYYYY.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format MM-DD-YYYY";
      }
    } else if (row.pattern === "Both alphabets and numbers required") {
      if (BAANR.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Must include both letters and numbers";
      }
    }
    if (fieldName === "email") {
      setEmail(value);
      setEmailError(errors);
      setEmailHelperText(helperTexts);
    } else if (fieldName === "fullName") {
      setFullName(value);
      setFullNameError(errors);
      setFullNameHelperText(helperTexts);
    } else if (fieldName === "qq") {
      setQQ(value);
      setQQError(errors);
      setQQHelperText(helperTexts);
    } else if (fieldName === "wechat") {
      setWeChat(value);
      setWeChatError(errors);
      setWeChatHelperText(helperTexts);
    } else if (fieldName === "line") {
      setLine(value);
      setLineError(errors);
      setLineHelperText(helperTexts);
    } else if (fieldName === "whatsapp") {
      setWhatsApp(value);
      setWhatsAppError(errors);
      setWhatsAppHelperText(helperTexts);
    } else if (fieldName === "facebook") {
      setFacebook(value);
      setFacebookError(errors);
      setFacebookHelperText(helperTexts);
    } else if (fieldName === "zalo") {
      setZalo(value);
      setZaloError(errors);
      setZaloHelperText(helperTexts);
    } else if (fieldName === "idNumber") {
      setIdNumber(value);
      setIDNumberError(errors);
      setIDNumberHelperText(helperTexts);
    } else if (fieldName === "mobileNO") {
      setMobileNumber(value);
      setMobileNumberError(errors);
      setMobileNumberHelperText(helperTexts);
      if (numberAccount.test(value)) {
        setIsAccountNumberValid(true);
      } else {
        setIsAccountNumberValid(false);
      }
    }
  };

  const handleSourceOfIncome = () => {
    setSoiModal(true);
  };

  const handleOccupationModal = () => {
    setOccupationModal(true);
  };

  const handleOccupation = (data) => {
    setOccupation(data);
    setOccupationModal(false);
  };

  const handleSoi = (data) => {
    setSourceOfIncome(data);
    setSoiModal(false);
  };

  const handleBackButton = () => {
    if (from === "/m/member/home") {
      navigate("/m/member/home");
    } else if (from === "/m/securityCenter") {
      navigate("/m/securityCenter");
    } else if (from === "/m/rewardCenter") {
      navigate("/m/rewardCenter");
    } else {
      navigate(-1);
    }
  };

  const handleCalendar = () => {
    if (accountData.birthdate === null) {
      setCalendarOpen(true);
    } else {
      return;
    }
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
    if (soiRef.current && !soiRef.current.contains(event.target)) {
      setSoiModal(false);
    }
    if (
      occupationRef.current &&
      !occupationRef.current.contains(event.target)
    ) {
      setOccupationModal(false);
    }
  };

  const handleSelectDate = (date) => {
    setCalendarOpen(false);
    const formattedDate = date.toISOString().split("T")[0];
    setBirthday(formattedDate);
  };

  const submitValidation =
    emailError ||
    fullNameError ||
    IDNumberError ||
    mobileNumberError ||
    QQError ||
    weChatError ||
    lineError ||
    whatsAppError ||
    facebookError ||
    zaloError ||
    (mobileNumber && !isAccountNumberValid && accountData.mobile_no === null);

  const handleSubmit = () => {
    const selectedDate = dayjs(birthday);
    const twentyOneYearsAgo = dayjs().subtract(21, "year");
    if (selectedDate.isAfter(twentyOneYearsAgo)) {
      setIsBirthdayValid(false);
      return;
    }
    axiosInstance
      .post(`player/personalinformation`, {
        fullName,
        birthday,
        email,
        idNumber,
        mobileNumber,
        qq,
        weChat,
        line,
        whatsApp,
        facebook,
        zalo,
        sourceOfIncome,
        occupation,
      })
      .then((res) => {
        setSubmitPrompt(true);
        setIsBirthdayValid(true);
        const timer = setTimeout(() => {
          setSubmitPrompt(false);
          fetchData();
          navigate("/m/member/home");
        }, 1500);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBirthday = (e) => {
    if (accountData.birthdate === null) {
      let birthday = e.target.value.replace(/\D/g, "");
      if (birthday.length > 4) {
        birthday = birthday.slice(0, 4) + "-" + birthday.slice(4);
      }
      if (birthday.length > 7) {
        birthday = birthday.slice(0, 7) + "-" + birthday.slice(7);
      }
      if (birthday.length > 10) {
        birthday = birthday.slice(0, 10);
      }
      setBirthday(birthday);
    } else {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  if (loading || Object.keys(accountData).length === 0) {
    return (
      <Modal
        open={loading}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          outline: "none",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#4d4d4d",
            opacity: "90%",
            p: 3,
            borderRadius: "5%",
            textAlign: "center",
            flexDirection: "column",
            outline: "none",
          }}
        >
          <CircularProgress sx={{ color: "#FFFFFF", fontSize: "13vw" }} />
        </Box>
      </Modal>
    );
  }

  if (isLoadingFunctionalSetings) {
    return <div>loading</div>;
  }

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.4vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid container xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.2vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid container xs={4} sm={4} sx={{ justifyContent: "center" }}>
            <Typography
              sx={{
                color: "#FFFFFF",
                marginTop: "14.5%",
                fontSize: "3.8vw",
              }}
            >
              My Account
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          sx={{
            marginTop: "6.5%",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <Grid container xs={12} sm={12}>
            <Typography sx={{ fontSize: "4.5vw" }}>
              Username: {username}
            </Typography>
          </Grid>
          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "birthday" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                        // "& .MuiOutlinedInput-root": {
                        //   "& fieldset": {
                        //     borderColor: "#efefef",
                        //   },
                        //   "&:hover fieldset": {
                        //     borderColor: "#efefef",
                        //   },
                        //   "&.Mui-focused fieldset": {
                        //     borderColor: !accountData.birthdate
                        //       ? "#407eda"
                        //       : "#efefef",
                        //     borderWidth: ".2vw",
                        //   },
                        //   "& .Mui-disabled": {
                        //     backgroundColor: "#c1c1c1",
                        //     borderBottom: "none",
                        //   },
                        // },
                      }}
                      variant="outlined"
                      value={
                        accountData.birthdate === null
                          ? birthday
                          : accountData.birthdate.slice(0, 2) +
                            "*".repeat(accountData.birthdate.length - 2)
                      }
                      placeholder="YYYY-MM-DD"
                      onChange={
                        accountData.birthdate === null
                          ? handleBirthday
                          : undefined
                      }
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.birthdate === null ? false : true,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.birthdate === null &&
                              row.info_update !== 1) ||
                            accountData.birthdate !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-2%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <CakeOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6.7vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment>
                            <CalendarMonthIcon
                              onClick={handleCalendar}
                              sx={{
                                color:
                                  accountData.birthdate === null
                                    ? "#3b79f3"
                                    : "#919191",
                                fontSize: "6.7vw",
                                pointerEvents:
                                  profile.birthdate !== null ||
                                  row.info_update !== 1
                                    ? "none"
                                    : "auto",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      disabled={
                        profile.birthdate !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}
          {!isBirthdayValid && (
            <Grid item xs={12} sm={12} sx={{ marginTop: "1.5%" }}>
              <Typography
                sx={{ fontSize: "3vw", fontWeight: "bold", color: "red" }}
              >
                Player should be 21 years old and above
              </Typography>
            </Grid>
          )}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "email" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.email === null
                          ? email
                          : accountData.email.slice(0, 3) +
                            "*".repeat(accountData.email.length - 3)
                      }
                      placeholder="Please enter your email"
                      onChange={(e) => handleChange(e, row, "email")}
                      style={{
                        marginBottom: emailHelperText ? "30px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.email ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.email === null &&
                              row.info_update !== 1) ||
                            accountData.email !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: ".1%",
                              marginRight: "4%",
                            }}
                          >
                            <EmailOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={emailError}
                      helperText={emailHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.email !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "full_name" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.full_name === null
                          ? fullName
                          : accountData.full_name.slice(0, 1) +
                            "*".repeat(accountData.full_name.length - 1)
                      }
                      placeholder="Please enter your Full Name"
                      onChange={(e) => handleChange(e, row, "fullName")}
                      style={{
                        marginBottom: fullNameHelperText ? "30px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.full_name !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.full_name === null &&
                              row.info_update !== 1) ||
                            accountData.full_name !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={fullNameError}
                      helperText={fullNameHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.full_name !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          <Grid item xs={12} sm={12} sx={{ marginTop: "1.5%" }}>
            <Typography
              sx={{ fontSize: "3vw", fontWeight: "bold", color: "red" }}
            >
              Please ensure that the name is match with the withdrawal
              information
            </Typography>
          </Grid>
          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "id_number" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "3%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.id_number === null
                          ? idNumber
                          : accountData.id_number.slice(0, 2) +
                            "*".repeat(accountData.id_number.length - 2)
                      }
                      placeholder="Please enter your ID number"
                      onChange={(e) => handleChange(e, row, "idNumber")}
                      style={{
                        marginBottom: IDNumberHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.id_number ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.id_number === null &&
                              row.info_update !== 1) ||
                            accountData.id_number !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: ".1%",
                              marginRight: "4%",
                            }}
                          >
                            <CreditCardOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={IDNumberError}
                      helperText={IDNumberHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      maxLength="11"
                      disabled={
                        profile.id_number !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}
          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "mobile_no" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "3%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.mobile_no === null
                          ? mobileNumber
                          : accountData.mobile_no.slice(0, 2) +
                            "*".repeat(accountData.mobile_no.length - 2)
                      }
                      placeholder="Please enter your mobile number"
                      onChange={(e) => handleChange(e, row, "mobileNO")}
                      style={{
                        marginBottom: mobileNumberHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.mobile_no ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.mobile_no === null &&
                              row.info_update !== 1) ||
                            accountData.mobile_no !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: ".1%",
                              marginRight: "4%",
                            }}
                          >
                            <CreditCardOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={mobileNumberError}
                      helperText={mobileNumberHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      maxLength="11"
                      disabled={
                        profile.mobile_no !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                    <Box>
                      {mobileNumber &&
                        !isAccountNumberValid &&
                        accountData.mobile_no === null && (
                          <>
                            <Typography
                              style={{
                                color: "#d32f2f",
                                marginTop: 5,
                                fontSize: "11px",
                                marginLeft: 14,
                              }}
                            >
                              Please enter 11 characters and start with 09, only numbers allowed.
                            </Typography>
                          </>
                        )}
                    </Box>
                  </Grid>
                );
              }
            })}
          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "qq" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.qq === null
                          ? qq
                          : accountData.qq.slice(0, 1) +
                            "*".repeat(accountData.qq.length - 1)
                      }
                      placeholder="Please enter your QQ"
                      onChange={(e) => handleChange(e, row, "qq")}
                      style={{
                        marginBottom: QQHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.qq !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.qq === null &&
                              row.info_update !== 1) ||
                            accountData.qq !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={QQError}
                      helperText={QQHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.qq !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "wechat" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.wechat === null
                          ? weChat
                          : accountData.wechat.slice(0, 1) +
                            "*".repeat(accountData.wechat.length - 1)
                      }
                      placeholder="Please enter your WeChat"
                      onChange={(e) => handleChange(e, row, "wechat")}
                      style={{
                        marginBottom: weChatHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.wechat !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.wechat === null &&
                              row.info_update !== 1) ||
                            accountData.wechat !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={weChatError}
                      helperText={weChatHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.wechat !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "line" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.line === null
                          ? line
                          : accountData.line.slice(0, 1) +
                            "*".repeat(accountData.line.length - 1)
                      }
                      placeholder="Please enter your Line"
                      onChange={(e) => handleChange(e, row, "line")}
                      style={{
                        marginBottom: lineHelperText ? "30px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.line !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.line === null &&
                              row.info_update !== 1) ||
                            accountData.line !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={lineError}
                      helperText={lineHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.line !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "whatsapp" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.whatsapp === null
                          ? whatsApp
                          : accountData.whatsapp.slice(0, 1) +
                            "*".repeat(accountData.whatsapp.length - 1)
                      }
                      placeholder="Please enter your WhatsApp"
                      onChange={(e) => handleChange(e, row, "whatsapp")}
                      style={{
                        marginBottom: whatsAppHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.whatsapp !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.whatsapp === null &&
                              row.info_update !== 1) ||
                            accountData.whatsapp !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={whatsAppError}
                      helperText={whatsAppHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.whatsapp !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "facebook" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.facebook === null
                          ? facebook
                          : accountData.facebook.slice(0, 1) +
                            "*".repeat(accountData.facebook.length - 1)
                      }
                      placeholder="Please enter your facebook"
                      onChange={(e) => handleChange(e, row, "facebook")}
                      style={{
                        marginBottom: facebookHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        readOnly: accountData.facebook !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.facebook === null &&
                              row.info_update !== 1) ||
                            accountData.facebook !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={facebookError}
                      helperText={facebookHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.facebook !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          {dataFunctionalSetings &&
            dataFunctionalSetings.data.map((row) => {
              if (row.userInfo === "zalo" && row.info_enabled === 1) {
                return (
                  <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
                    <TextField
                      sx={{
                        borderRadius: "1.5vw",
                        height: "13.8vw",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={
                        accountData.zalo === null
                          ? zalo
                          : accountData.zalo.slice(0, 1) +
                            "*".repeat(accountData.zalo.length - 1)
                      }
                      placeholder="Please enter your zalo"
                      onChange={(e) => handleChange(e, row, "zalo")}
                      style={{
                        marginBottom: zaloHelperText ? "30px" : "0px",
                      }}
                      InputProps={{
                        readOnly: accountData.zalo !== null ? true : false,
                        style: {
                          color: "#919191",
                          fontSize: "4vw",
                          height: "100%",
                          paddingTop: "1%",
                          backgroundColor:
                            (accountData.zalo === null &&
                              row.info_update !== 1) ||
                            accountData.zalo !== null
                              ? "#c1c1c1"
                              : "#efefef",
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              marginTop: "-1%",
                              marginLeft: "-.5%",
                              marginRight: "4%",
                            }}
                          >
                            <AccountBoxOutlinedIcon
                              sx={{
                                color: "#a5a5a5",
                                fontSize: "6vw",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      error={zaloError}
                      helperText={zaloHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.zalo !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                );
              }
            })}

          <Grid
            onClick={handleSourceOfIncome}
            item
            xs={10.3}
            sm={10.3}
            sx={{ marginTop: "4.5%" }}
          >
            <TextField
              sx={{
                borderRadius: "1.5vw 0vw 0vw 1.5vw",
                backgroundColor: "#efefef",
                height: "13.8vw",
                width: "100%",
                fieldset: {
                  borderRadius: "1.5vw 0vw 0vw 1.5vw",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#efefef",
                  },
                  "&:hover fieldset": {
                    borderColor: "#efefef",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#efefef",
                    borderWidth: ".2vw",
                  },
                },
              }}
              required
              variant="outlined"
              value={sourceOfIncome}
              placeholder={
                accountData.soi ? accountData.soi : "Source of Income"
              }
              InputProps={{
                readOnly: true,
                style: {
                  color: "#919191",
                  fontSize: "4vw",
                  height: "100%",
                  paddingTop: "2.5%",
                  width: "100%",
                },
                startAdornment: (
                  <InputAdornment
                    sx={{
                      marginTop: "-1%",
                      marginLeft: ".1%",
                      marginRight: "4%",
                    }}
                  >
                    <PersonOutlinedIcon
                      sx={{
                        color: "#a5a5a5",
                        fontSize: "6vw",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            onClick={handleSourceOfIncome}
            container
            xs={1.7}
            sm={1.7}
            sx={{ marginTop: "4.5%" }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#3b79f3",
                width: "100%",
                height: "13.8vw",
                borderRadius: "0vw 1.5vw 1.5vw 0vw",
              }}
            >
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  color: "#FFFFFF",
                  fontSize: "12vw",
                  marginTop: "6%",
                  marginLeft: "2%",
                }}
              />
            </Grid>
          </Grid>

          <Grid
            onClick={handleOccupationModal}
            item
            xs={10.3}
            sm={10.3}
            sx={{ marginTop: "4.5%" }}
          >
            <TextField
              sx={{
                borderRadius: "1.5vw 0vw 0vw 1.5vw",
                backgroundColor: "#efefef",
                height: "13.8vw",
                width: "100%",
                fieldset: {
                  borderRadius: "1.5vw 0vw 0vw 1.5vw",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#efefef",
                  },
                  "&:hover fieldset": {
                    borderColor: "#efefef",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#efefef",
                    borderWidth: ".2vw",
                  },
                },
              }}
              required
              variant="outlined"
              value={occupation}
              placeholder={
                accountData.occupation ? accountData.occupation : "Occupation"
              }
              InputProps={{
                readOnly: true,
                style: {
                  color: "#919191",
                  fontSize: "4vw",
                  height: "100%",
                  paddingTop: "2.5%",
                  width: "100%",
                },
                startAdornment: (
                  <InputAdornment
                    sx={{
                      marginTop: "-1%",
                      marginLeft: ".1%",
                      marginRight: "4%",
                    }}
                  >
                    <PersonOutlinedIcon
                      sx={{
                        color: "#a5a5a5",
                        fontSize: "6vw",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            onClick={handleOccupationModal}
            item
            xs={1.7}
            sm={1.7}
            sx={{ marginTop: "4.5%" }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#3b79f3",
                width: "100%",
                height: "13.8vw",
                borderRadius: "0vw 1.5vw 1.5vw 0vw",
              }}
            >
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  color: "#FFFFFF",
                  fontSize: "12vw",
                  marginTop: "6%",
                  marginLeft: "2%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ marginTop: "4.5%" }}>
            <Typography sx={{ fontSize: "4.2vw", color: "#64b1f7" }}>
              We care about your privacy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ marginTop: "3%" }}>
            <Typography sx={{ fontSize: "3.1vw", color: "#838383" }}>
              All the user data are encrypted to ensure your personal privacy is
              protected
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              marginTop: "3%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                backgroundColor: submitValidation ? "#d5d5d5" : "#ff1931",
                height: "12vw",
                width: "100%",
                borderRadius: "2vw",
                boxShadow: submitValidation ? "0px 0px 1px 0px #d5d5d5" : "0px 0px 1px 0px #ff1931",
                "&:hover": {
                  backgroundColor: submitValidation ? "#d5d5d5" : "#ff1931",
                },
              }}
            >
              <Button
                item
                xs={12}
                sm={12}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "4.5vw",
                  width: "100%",
                  height: "12vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleSubmit}
                disabled={submitValidation}
              >
                OK
              </Button>
            </Grid>
          </Grid>
          {disabled && (
            <Modal
              open={disabled}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#4b4b4b",
                  opacity: "93%",
                  p: 3,
                  borderRadius: "1vw",
                  textAlign: "center",
                  flexDirection: "column",
                  outline: "none",
                }}
              >
                <Typography sx={{ fontSize: "3vw", color: "#FFFFFF" }}>
                  Please contact our customer <br />
                  service to modify
                </Typography>
              </Box>
            </Modal>
          )}
          {calendarOpen && (
            <Modal
              open={calendarOpen}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
                marginLeft: "5%",
              }}
            >
              <Grid
                ref={calendarRef}
                item
                xs={12}
                sm={12}
                sx={{ outline: "none" }}
              >
                <Calendar
                  date={new Date()}
                  onChange={handleSelectDate}
                  moveRangeOnFirstSelection={false}
                  showDateDisplay={false}
                  months={1}
                  className="myAccountCalendar"
                />
              </Grid>
            </Modal>
          )}
          {soiModal && (
            <SoiModal
              soiModal={soiModal}
              handleSoi={handleSoi}
              soiRef={soiRef}
            />
          )}
          {occupationModal && (
            <OccupationModal
              occupationModal={occupationModal}
              handleOccupation={handleOccupation}
              occupationRef={occupationRef}
            />
          )}
          {submitPrompt && (
            <Modal
              open={submitPrompt}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#4d4d4d",
                  opacity: "90%",
                  p: 3,
                  borderRadius: "5%",
                  textAlign: "center",
                  flexDirection: "column",
                  outline: "none",
                }}
              >
                <Typography sx={{ color: "#FFFFFF", fontSize: "3.5vw" }}>
                  Update successful
                </Typography>
              </Box>
            </Modal>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default MyAccountDetails;
