import React, { useState, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-creative";
import "./css/style.css";
import { EffectCreative, Pagination } from "swiper/modules";
import axiosInstance from "../../../../../axiosConfig";

const CardContainer = styled(Box)({
  background:
    "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/ewallet1.da1db03c.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "356px",
  height: "130px",
});

const WalletName = styled(Typography)({
  fontSize: "1.25rem",
  color: "#FFFFFF",
  paddingTop: "18px",
  paddingBottom: "18px",
  paddingLeft: "60px",
  paddingRight: "auto",
});

const WalletAccountNumber = styled(Typography)({
  paddingLeft: "20px",
  paddingRight: "auto",
  fontSize: "1.2rem",
  color: "#FFFFFF",
});

const WalletRegistrationDate = styled(Typography)({
  paddingTop: "8px",
  paddingBottom: "10px",
  paddingLeft: "20px",
  paddingRight: "auto",
  fontSize: "1rem",
  color: "#FFFFFF",
});

const swiperStyles = {
  swiperContainer: {
    width: "356px",
    overflow: "unset",
    position: "relative",
    paddingBottom: "40px",
    "--swiper-pagination-color": "#FF0000",
  },
  swiperPagination: {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

export default function EwalletCard() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`player/paymentmethod`)
      .then((response) => {
        const data = response.data;
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Box sx={{ padding: "10px", width: "100%" }}>
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, -16, -800],
            scale: 0.9,
            zIndex: 3,
            rotate: [180, 0, 0],
          },
          next: {
            shadow: true,
            translate: [0, 0, -800],
            zIndex: 6,
            rotate: [180, 0, 0],
          },
        }}
        modules={[EffectCreative, Pagination]}
        style={swiperStyles.swiperContainer}
        pagination={{
          clickable: true,
        }}
        loop={true}
      >
        {data.map((row) => (
          <SwiperSlide style={{ textAlign: "start" }}>
            <CardContainer>
              <WalletName variant="body1">{row.type}</WalletName>
              <WalletAccountNumber variant="body2">
                {"******" + row.account_number.substring(7)}
              </WalletAccountNumber>
              <WalletRegistrationDate variant="body2">
                {row.created_at.substring(0, 10)}
              </WalletRegistrationDate>
            </CardContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
