import { useState } from "react";
import { Grid } from "@mui/material";
import ProfileDetails from "./profile_details/ProfileDetails";
import TicketRecords from "./ticket_records/TicketRecords";

export default function RewardsCenter({
  setActiveComponent,
  data,
  balance,
  setBalance,
}) {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
    setPanelOpen(false);
  };

  return (
    <Grid
      sx={{
        height: "100vh",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        justifyContent: "start",
      }}
    >
      <ProfileDetails
        setActiveComponent={setActiveComponent}
        balance={balance}
        setBalance={setBalance}
        setModalOpen={setModalOpen}
        setPanelOpen={setPanelOpen}
      />
      <TicketRecords
        isPanelOpen={isPanelOpen}
        setPanelOpen={setPanelOpen}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </Grid>
  );
}
