import React, { useEffect, useState } from "react";
import { Backdrop, Box, Drawer, Stack, styled } from "@mui/material";
import icon_10 from "../../../../assets/icons/icon-10.png";
import CloseIcon from "@mui/icons-material/Close";
import WithdrawSettings from "./components/WithdrawSettings";
import EwalletCard from "./components/EwalletCard";
import BalanceDetails from "./components/BalanceDetails";
import WithdrawalNotes from "./components/WithdrawalNotes";
import TransactButton from "./components/TransactButton";
import WithdrawalRequirements from "./components/WithdrawalRequirements";
import BindEWallet from "./components/BindEWallet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../../../axiosConfig";
import CircularProgress from "@mui/material/CircularProgress";

const DrawerStyle = styled(Drawer)({
  "& .MuiDrawer-paper": {
    position: "absolute",
    width: "100%",
    maxWidth: "840px",
    overflow: "visible",
    border: "none",
    borderRadius: "0px",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const Triangle = styled(Box)({
  position: "absolute",
  top: "33px",
  left: "-34px",
  width: "0",
  height: "0",
  borderTop: "0px solid transparent",
  borderBottom: "13px solid transparent",
  borderRight: "34px solid #FEFEFE",
});

const CloseButtonContainer = styled(Box)({
  position: "absolute",
  top: "0px",
  left: "-33px",
  backgroundColor: "#FEFEFE",
  padding: "8px",
});

const CloseButton = styled(Box)({
  backgroundColor: "#FF0000",
  padding: "0.5px",
  color: "#FFFFFF",
  borderRadius: "100%",
  fontSize: "0.15rem",
  cursor: "pointer",
});

const FloatingContainer = styled(Stack)({
  position: "absolute",
  top: "87px",
  left: "-57px",
});

export default function Withdraw({ balance }) {
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [amount, setAmount] = useState();
  const [txnPw, setTxnPw] = useState();
  const username = localStorage.getItem("username");
  const [remainingTurnover, setRemainingTurnover] = useState(0);
  const [minimumBalanceWithdraw, setMinimumBalanceWithdraw] = useState(0);
  const [maxAttempt, setMaxAttempt] = useState(0);
  const [withdrawAttempt, setWithdrawAttempt] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`player/withdraw`)
      .then((response) => {
        const data = response.data;
        setRemainingTurnover(data.remaining_turnover);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/withdrawal/rules`)
      .then((response) => {
        const data = response.data;
        setMinimumBalanceWithdraw(data[0].minimum_balance_to_withdraw);
        setMaxAttempt(data[0].max_withdraw_attempt);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
      axiosInstance
      .get(`player/withdrawal/attempt`)
      .then((response) => {
        const data = response.data;
        setWithdrawAttempt(data)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [username]);

  const totalAttempt = maxAttempt - withdrawAttempt;

  const toggleDrawer = () => {
    setOpenWithdraw(!openWithdraw);
  };

  const handleSubmit = async (e) => {
    const generateUniqueId = (prefix) => {
      return `${prefix}-${Math.floor(Math.random() * 100000)}`;
    };
    const transaction_id = generateUniqueId("WTL");
    const request_id = generateUniqueId("RWTL");
    const withdrawalData = {
      user: username,
      real_amount: amount,
      password: txnPw,
      payment_method: 0, //Set default to GCASH for now.
    };

    try {
      const result = await axiosInstance.post(
        `player/withdraw/xendit`,
        withdrawalData
      );
      if (result.data.statusCode === 401) {
        toast.error("Transaction password do not match.", {
          theme: "colored",
        });
        //will change status based on withdraw xendit response
      } else if (result.data.status === "ACCEPTED") {
        setAmount("");
        setTxnPw("");
        toast.success("Withdrawal successful", {
          theme: "colored",
          autoClose: 300,
        });
      } else {
        toast.error("Withdrawal unsuccessful. Please try again.", {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Withdrawal unsuccessful. Please try again.", {
        theme: "colored",
      });
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100vw",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <ToastContainer />
          <Stack direction="row" sx={{ width: "820px", height: "650px" }}>
            <Stack direction="column">
              <Box sx={{ width: "800px", padding: "10px", height: "80%" }}>
                <WithdrawSettings toggleDrawer={toggleDrawer} />
                <Stack direction="row" sx={{ width: "100%" }}>
                  <Stack direction="column" sx={{ height: "100%" }}>
                    <EwalletCard />
                    <BalanceDetails
                      amount={amount}
                      setAmount={setAmount}
                      txnPw={txnPw}
                      setTxnPw={setTxnPw}
                      remainingTurnover={remainingTurnover}
                      balance={balance}
                      minimumBalanceWithdraw={minimumBalanceWithdraw}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <WithdrawalNotes />
                    <WithdrawalRequirements
                      remainingTurnover={remainingTurnover}
                    />
                  </Stack>
                </Stack>
              </Box>
              <TransactButton
                handleSubmit={handleSubmit}
                remainingTurnover={remainingTurnover}
                balance={balance}
                minimumBalanceWithdraw={minimumBalanceWithdraw}
                totalAttempt={totalAttempt}
              />
            </Stack>
            <Backdrop onClick={toggleDrawer} open={openWithdraw}></Backdrop>
            <DrawerStyle
              variant="persistent"
              anchor="right"
              transitionDuration={{ enter: 1500, exit: 1500 }}
              open={openWithdraw}
              onClose={toggleDrawer}
            >
              <Triangle />
              <CloseButtonContainer onClick={toggleDrawer}>
                <CloseButton>
                  <CloseIcon sx={{ fontSize: "1rem" }} />
                </CloseButton>
              </CloseButtonContainer>
              <FloatingContainer>
                <div style={{ cursor: "pointer" }}>
                  <img
                    src={icon_10}
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
              </FloatingContainer>
              <BindEWallet openWithdraw={openWithdraw} />
            </DrawerStyle>
          </Stack>
        </>
      )}
    </>
  );
}
