import { useEffect, useState } from "react";
import { WALLET } from "../../config";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider, Grid, Typography, Button, Modal, Box } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import axiosInstance from "../../axiosConfig";
import CircularProgress from "@mui/material/CircularProgress";

const WithdrawPageMobile = (props) => {
  const { balance, setBalance } = props;
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [userData, setUserData] = useState("");
  const [isRecalledBalance, setIsRecalledBalance] = useState(false);
  const [remainingTurnover, setRemainingTurnover] = useState(0);
  const [maxAttempt, setMaxAttempt] = useState(0);
  const [withdrawAttempt, setWithdrawAttempt] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { from } = location.state || {};

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`player/paymentmethod`)
      .then((response) => {
        const data = response.data;
        setData(data);
        setRowCount(data.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/withdraw`)
      .then((response) => {
        const data = response.data;
        setRemainingTurnover(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/profile/my-account`)
      .then((response) => {
        const data = response.data[0];
        setUserData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/withdrawal/rules`)
      .then((response) => {
        const data = response.data[0];
        setMaxAttempt(data.max_withdraw_attempt);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axiosInstance
      .get(`player/withdrawal/attempt`)
      .then((response) => {
        const data = response.data;
        setWithdrawAttempt(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    setLoading(false);
    if (isRecalledBalance) {
      const timer = setTimeout(() => {
        setIsRecalledBalance(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, []);

  const totalAttempt = maxAttempt - withdrawAttempt;

  const handleBackButton = () => {
    if (from === "/m/member/home") {
      navigate("/m/member/home");
    } else {
      navigate(-1);
    }
  };

  const handleRecallBalance = () => {
    setIsRecalledBalance(true);
  };

  const handleRecallBalanceOK = () => {
    axiosInstance
      .get(`${WALLET}casino/recall/${username}`)
      .then((response) => {
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setIsRecalledBalance(false);
  };

  const handleWithdrawalButtonOK = () => {
    if (totalAttempt === 0) {
      return;
    } else {
      alert("Request sent");
    }
  };

  const handleWithdrawalRecord = () => {
    navigate("/m/withdrawReport");
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "15%", fontSize: "3.8vw" }}>
                Withdrawal
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Grid
              container
              sx={{
                height: "100%",
                alignContent: "center",
                justifyContent: "flex-end",
              }}
            >
              <ContentPasteOutlinedIcon
                onClick={handleWithdrawalRecord}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6vw",
                  marginRight: "11%",
                  marginTop: "2%",
                }}
              />
              <ErrorOutlineOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "7vw", marginRight: "10%" }}
              />
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100vw",
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <img
                  src="../../../../ewallet_icon.png"
                  style={{
                    width: "8%",
                    marginRight: "6.5%",
                  }}
                  alt="Banner Background"
                />
                <Typography
                  sx={{ fontSize: "3.5vw", marginTop: "2%", color: "red" }}
                >
                  GCASH
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ justifyContent: "center" }}>
              <Divider
                sx={{ borderColor: "red", borderWidth: ".4vw", width: "95%" }}
              ></Divider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginLeft: "5%", marginTop: "4%" }}
            >
              <Typography sx={{ color: "#5e5e5e", fontSize: "4vw" }}>
                Bound GCASH ({rowCount}/2)
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ justifyContent: "center" }}
            >
              <Swiper
                modules={[Pagination]}
                pagination={{ clickable: true }}
                slidesPerView={1}
                spaceBetween={40}
                style={{
                  width: "80%",
                  height: "29vw",
                  paddingBottom: "9%",
                  position: "absolute",
                  marginTop: "3.5%",
                  "--swiper-pagination-bullet-size": "2vw",
                }}
              >
                {data.map((row, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={`../../../../ewallet_bg${index + 1}.png`}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                      }}
                      alt="Banner Background"
                    />
                    <Typography
                      sx={{
                        fontSize: "4.5vw",
                        zIndex: 1,
                        color: "#FFFFFF",
                        marginLeft: "-55%",
                        marginTop: "-15.4%",
                        position: "absolute",
                      }}
                    >
                      GCASH
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "5.5vw",
                        zIndex: 1,
                        color: "#FFFFFF",
                        marginLeft: "-80%",
                        marginTop: "8%",
                        fontWeight: "bold",
                        letterSpacing: "-2px",
                        position: "absolute",
                      }}
                    >
                      *******
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "4.5vw",
                        zIndex: 1,
                        color: "#FFFFFF",
                        marginLeft: "-52%",
                        marginTop: "6%",
                        fontWeight: "bold",
                        position: "absolute",
                      }}
                    >
                      {String(row.account_number).substring(7)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "2.8vw",
                        zIndex: 1,
                        color: "#FFFFFF",
                        marginLeft: "-75%",
                        marginTop: "22%",
                        position: "absolute",
                      }}
                    >
                      {row.created_at.substring(0, 10)}
                    </Typography>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
                marginTop: "50%",
                flexDirection: "column",
                marginRight: "6%",
              }}
            >
              <Typography sx={{ fontSize: "3vw", color: "#c0c0c0" }}>
                Withdrawal time: 00:00 - 23:59
              </Typography>
              <Typography sx={{ fontSize: "3vw", color: "#c0c0c0" }}>
                Tips：Note: For withdrawal requests, please allow 24-48 hours
                upon the submission of your request. Due to security reasons,
                please take note that 1 withdrawal request is permitted per hour
                at a time.
              </Typography>
              <Typography
                sx={{ fontSize: "3vw", color: "#c0c0c0", marginTop: "11%" }}
              >
                Daily withdrawal {maxAttempt}(Times), Remaining withdrawal
                {totalAttempt}(Times)
              </Typography>
              <Typography sx={{ fontSize: "3vw", color: "#c0c0c0" }}>
                Main Wallet: ₱{balance.toFixed(2)}
              </Typography>
              <Typography sx={{ fontSize: "3vw", color: "#c0c0c0" }}>
                Available Amount: ₱{balance.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              onClick={handleRecallBalance}
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                justifyContent: "center",
                marginTop: "2.5%",
              }}
            >
              <Grid
                item
                sx={{
                  backgroundColor: "#d7e7fe",
                  padding: "1% 1% 1% 1%",
                  borderRadius: "35px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "36vw",
                  height: "10vw",
                  display: "flex",
                }}
              >
                <RefreshIcon
                  sx={{
                    borderRadius: "10vw",
                    color: "#d7e7fe",
                    backgroundColor: "#3b79f3",
                    paddingLeft: ".5vw",
                    paddingRight: ".5vw",
                    paddingTop: ".5vw",
                    paddingBottom: ".5vw",
                    fontSize: "5vw",
                    marginRight: "4%",
                  }}
                />

                <Typography
                  sx={{ color: "#447ff3", fontSize: "3.5vw", marginTop: "3%" }}
                >
                  Recall Balance
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginTop: "5%",
                justifyContent: "center",
              }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#818181",
                  }}
                >
                  Withdrawal turnover requirements
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "3.1vw",
                    color: "#ea604b",
                    alignItems: "center",
                    fontWeight: "bold",
                    marginTop: "2.5%",
                  }}
                >
                  Please complete the required turnover for withdrawal
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginTop: "2%" }}
            >
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Game type</th>
                      <th>Remaining turnover</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>General</td>
                      <td className="red-text">
                        {remainingTurnover.remaining_turnover}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginTop: "5.5%" }}
            >
              <Grid
                onClick={handleWithdrawalButtonOK}
                item
                sx={{ textAlign: "center" }}
              >
                <Button
                  sx={{
                    backgroundColor: totalAttempt === 0 ? "#e6e6e6" : "#ff1931",
                    width: "87.5vw",
                    height: "12vw",
                    borderRadius: "3vw",
                    boxShadow:
                      totalAttempt === 0
                        ? "0px 0px 1px 0px #e6e6e6"
                        : "0px 0px 1px 0px #ff1931",
                    "&:hover": {
                      backgroundColor: "#ff1931",
                    },
                  }}
                >
                  <Typography sx={{ color: "#FFFFFF", fontSize: "4.5vw" }}>
                    OK
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {isRecalledBalance && (
          <Modal
            open={isRecalledBalance}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              outline: "none",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                opacity: "90%",
                borderRadius: "2vw",
                textAlign: "center",
                flexDirection: "column",
                outline: "none",
                width: "75vw",
                height: "50vw",
              }}
            >
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "4.5vw",
                  marginBottom: "6%",
                  marginTop: "7%",
                }}
              >
                Confirmation
              </Typography>
              <Typography sx={{ color: "#898989", fontSize: "4vw" }}>
                Balance will transfer back to main <br /> wallet
              </Typography>
              <Grid sx={{ display: "flex", width: "100%" }}>
                <Grid
                  onClick={() => setIsRecalledBalance(false)}
                  item
                  sx={{
                    marginTop: "10%",
                    borderTop: ".1vw solid #dddddd",
                    width: "50%",
                    marginBottom: "-29%",
                  }}
                >
                  <Typography sx={{ fontSize: "4.5vw", marginTop: "13%" }}>
                    Cancel
                  </Typography>
                </Grid>
                <Grid
                  onClick={handleRecallBalanceOK}
                  item
                  sx={{
                    marginTop: "10%",
                    borderTop: ".1vw solid #dddddd",
                    borderLeft: ".1vw solid #dddddd",
                    width: "50%",
                    marginBottom: "-29%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "4.5vw",
                      color: "#108ee9",
                      marginTop: "13%",
                    }}
                  >
                    Ok
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default WithdrawPageMobile;
