import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CASINO_SERVER } from "../../../config";
import axios from "axios";
import GameSection from "./GameSection";
import { Box, CircularProgress } from "@mui/material";

const fetchGameListData = async () => {
  try {
    const response = await axios.get(`${CASINO_SERVER}game/list/landing`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const LandingPageGames = ({
  setGameIframe,
  setGameIframeUrl,
  openLogin,
}) => {
  const navigate = useNavigate();
  const [gameListData, setGameListData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGameListData = async () => {
      setLoading(true);
      const data = await fetchGameListData();
      setGameListData(data);
      setLoading(false);
    };
    loadGameListData();
  }, []);

  const handleClickViewAll = (tag) => {
    if (tag === 0) {
      navigate(`/games/hot`);
    } else {
      navigate(`/games?type=${tag}&provider=`);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      {gameListData.map((category) => (
        <GameSection
          key={category.id}
          description={category.game_type_name}
          tag={category.id}
          onClickViewAll={handleClickViewAll}
          games={category.games}
          openLogin={openLogin}
          setGameIframe={setGameIframe}
          setGameIframeUrl={setGameIframeUrl}
        />
      ))}
    </Suspense>
  );
};
