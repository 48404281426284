import React, { useEffect, useRef } from "react";
import { usePlayer } from "../../../contexts/PlayerContext";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import GamesIcon from "@mui/icons-material/Games";
import PhishingIcon from "@mui/icons-material/Phishing";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import StyleIcon from "@mui/icons-material/Style";
import FeedIcon from "@mui/icons-material/Feed";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { sideDrawer } from "../../../utils/content";
import { useState } from "react";
import "../../landingpage/GlobalFont.css";
import { Link, useNavigate } from "react-router-dom";
import ScannerIcon from "@mui/icons-material/Scanner";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";

export default function SideDrawer({ gameTypes, setGameTypeMobile }) {
  const { isMobile } = usePlayer();
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(100);
  const [activeItem, setActiveItem] = useState("");
  const isPopoverOpen = Boolean(anchor);
  const sideDrawerRef = useRef(null);

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [isPopoverOpen]);

  const handleClickOutside = (event) => {
    if (
      sideDrawerRef.current &&
      !sideDrawerRef.current.contains(event.target)
    ) {
      setAnchor(null);
    }
  };

  const handleMenuIconClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const getIcon = (code) => {
    switch (code) {
      case "slots":
        return <PointOfSaleIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "live_dealer":
        return <LiveTvIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "mini_games":
        return <GamesIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "fishing":
        return <PhishingIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "e_lottery":
        return <MonetizationOnIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "lotto":
        return <ScannerIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "pvp":
        return <SportsKabaddiIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      case "table_games":
        return <TableRestaurantIcon sx={{ color: "#fff", fontSize: "2rem" }} />;
      default:
        return (
          <img
            src="../loading.png"
            alt="Loading..."
            style={{ width: "2.5rem", height: "2.5rem" }}
          />
        );
    }
  };

  const handleSelectedSideDrawer = (page) => {
    navigate(page);
  };

  const handleSelectedPage = (data, path) => {
    setSelectedPage(data);
    navigate(path);
  };

  const handleSelectedSideDrawerMobile = (page) => {
    setGameTypeMobile(page);
  };

  const { FbmDrawer } = sideDrawer;
  const DrawerList = (
    <Box
      ref={sideDrawerRef}
      sx={{
        width: isMobile ? 200 : 328,
        height: "100%",
        backgroundColor: "#090929",
        color: "white",
        overflow: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 1.0)",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      overflow={"hidden !important"}
    >
      <CloseRoundedIcon
        style={{
          fontSize: "45px",
          color: "#fff",
          position: "absolute",
          padding: "14px",
        }}
        onClick={() => setAnchor(null)}
      ></CloseRoundedIcon>
      {isMobile ? (
        <img
          alt="fbmdrawer"
          src={FbmDrawer}
          style={{
            marginTop: "65px",
            marginLeft: "30px",
            marginBottom: "35px",
            height: "50px",
          }}
          onClick={() => navigate("/m")}
        />
      ) : (
        <img
          alt="fbmdrawer"
          src={FbmDrawer}
          style={{
            marginTop: "65px",
            marginLeft: "70px",
            marginBottom: "35px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
      )}

      <hr
        style={{
          borderColor: "red",
          width: "75%",
        }}
      />
      <List>
        <div>
          {[
            <div
              onClick={() => {
                if (isMobile) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/m");
                } else {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/");
                }
              }}
              style={{ marginBottom: "10px", cursor: "pointer" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon
                      sx={{
                        color: "#fff",
                        fontWeight: "bolder",
                        fontSize: "2.1rem",
                      }}
                    />
                  </ListItemIcon>
                  <p
                    style={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "16px",
                      marginLeft: "-15px",
                      fontFamily: "Barlow, sans-serif",
                      textDecoration:
                        activeItem === "platform" ? "underline" : "none",
                    }}
                  >
                    Home
                  </p>
                </ListItemButton>
              </ListItem>
            </div>,

            <div
              onClick={() => {
                if (isMobile) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(`/m/games?type=&provider=1`);
                } else {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(`/games?type=&provider=1`);
                }
              }}
              style={{ marginBottom: "10px", cursor: "pointer" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <img
                      width={60}
                      style={{ marginRight: 10 }}
                      src="../fbmlogo.png"
                    />
                  </ListItemIcon>
                  <p
                    style={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "16px",
                      fontFamily: "Barlow, sans-serif",
                      textDecoration:
                        activeItem === "exclusive" ? "underline" : "none",
                    }}
                  >
                    Exclusive
                  </p>
                </ListItemButton>
              </ListItem>
            </div>,
            <>
              {gameTypes &&
                gameTypes?.map((gameType) => {
                  return (
                    <div
                      onClick={() => {
                        if (isMobile) {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          navigate(`/m/games?type=${gameType.id}&provider=`);
                        } else {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          navigate(`/games?type=${gameType.id}&provider=`);
                        }
                      }}
                      style={{ marginBottom: "10px", cursor: "pointer" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {getIcon(gameType.game_type_code)}
                          </ListItemIcon>
                          <p
                            style={{
                              color: "#fff",
                              fontWeight: 500,
                              fontSize: "16px",
                              marginLeft: "-15px",
                              fontFamily: "Barlow, sans-serif",
                              textDecoration:
                                activeItem === "tablegames"
                                  ? "underline"
                                  : "none",
                            }}
                            primary="Table Games"
                          >
                            {gameType.game_type_name}
                          </p>
                        </ListItemButton>
                      </ListItem>
                    </div>
                  );
                })}
            </>,
            <div
              onClick={() => {
                if (isMobile) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(`/m/games?type=&provider=`);
                } else {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(`/games?type=&provider=`);
                }
              }}
              style={{ marginBottom: "10px", cursor: "pointer" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <StyleIcon sx={{ color: "#fff", fontSize: "2rem" }} />
                  </ListItemIcon>
                  <p
                    style={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "16px",
                      marginLeft: "-15px",
                      fontFamily: "Barlow, sans-serif",
                      textDecoration:
                        activeItem === "allgames" ? "underline" : "none",
                    }}
                    primary="All Games"
                  >
                    All Games
                  </p>
                </ListItemButton>
              </ListItem>
            </div>,
          ]}
        </div>
      </List>
      <hr
        style={{
          borderColor: "red",
          width: "75%",
        }}
      />
      <List>
        <div>
          {[
            <div
              style={{ marginLeft: "-15px" }}
              onClick={() => {
                if (isMobile) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/m/activity");
                } else {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(`/promotions`);
                }
              }}
            >
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <CampaignIcon sx={{ color: "#fff", fontSize: "2rem" }} />
                  </ListItemIcon>

                  <p
                    style={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "16px",
                      marginLeft: "-15px",
                      fontFamily: "Barlow, sans-serif",
                    }}
                  >
                    Promotions
                  </p>
                </ListItemButton>
              </ListItem>
            </div>,

            <div
              style={{ marginLeft: "-15px", marginTop: "-5px" }}
              onClick={() => {
                if (isMobile) {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/m/news");
                } else {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  handleSelectedSideDrawer("/news");
                }
              }}
            >
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <FeedIcon sx={{ color: "#fff", fontSize: "2rem" }} />
                  </ListItemIcon>
                  <p
                    style={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "16px",
                      marginLeft: "-15px",
                      fontFamily: "Barlow, sans-serif",
                    }}
                  >
                    News
                  </p>
                </ListItemButton>
              </ListItem>
            </div>,

            <div style={{ marginLeft: "-15px", marginTop: "-5px" }}>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <SupportAgentIcon
                      sx={{ color: "#fff", fontSize: "2rem" }}
                    />
                  </ListItemIcon>
                  <p
                    style={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "16px",
                      marginLeft: "-15px",
                      fontFamily: "Barlow, sans-serif",
                    }}
                  >
                    Customer Support
                  </p>
                </ListItemButton>
              </ListItem>
            </div>,
          ]}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      {isMobile ? (
        <>
          <MenuIcon
            onClick={handleMenuIconClick}
            sx={{ color: "white", marginLeft: "10px" }}
          />

          <BasePopup
            anchor={anchor}
            anchorOrigin={{
              vertical: "80px",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={isPopoverOpen}
            onClose={() => {
              setAnchor(null);
            }}
            sx={{
              position: "absolute",
              marginLeft: "10px",
            }}
            PaperProps={{
              sx: {
                position: "absolute",
                marginTop: "20px",
                height: "100%",
                overflow: "hidden",
              },
            }}
            style={{
              position: "absolute",
              left: "0px",
              zIndex: 1500,
              top: "-40px",
              height: "100vh",
            }}
          >
            {DrawerList}
          </BasePopup>
        </>
      ) : (
        <>
          <MenuIcon
            onClick={handleMenuIconClick}
            sx={{ color: "white", fontSize: "40px" }}
          />

          <BasePopup
            anchor={anchor}
            anchorOrigin={{
              vertical: "80px",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={isPopoverOpen}
            onClose={() => {
              setAnchor(null);
            }}
            sx={{
              position: "absolute",
              marginLeft: "10px",
            }}
            PaperProps={{
              sx: {
                position: "absolute",
                marginTop: "20px",
                height: "100%",
                overflow: "hidden",
              },
            }}
            style={{
              position: "absolute",
              left: "0px",
              zIndex: 1500,
              top: "-40px",
              height: "99vh",
            }}
          >
            {DrawerList}
          </BasePopup>
        </>
      )}
    </div>
  );
}
