import { Grid, Typography } from "@mui/material";

const NotificationMobile = () => {
  return (
    <Grid container>
      <Typography sx={{ color: "white", marginTop: "20px" }}>
        NotificationMobile
      </Typography>
    </Grid>
  );
};

export default NotificationMobile;
