import React from "react";
import { usePlayer } from "../../../../../contexts/PlayerContext";
import { Box, Typography, Grid } from "@mui/material";
import Game from "../../../games/components/Game";

export default function GameList({
  filteredGameList,
  loading,
  setGameIframe,
  setGameIframeUrl,
  openLogin,
}) {
  const { isMobile } = usePlayer();

  return (
    <>
      {isMobile ? (
        <Box>
          <Grid
            container
            sx={{
              gap: 0.5,
              alignItems: "left",
              display: "flex",
              marginX: "15px",
              marginTop: "20px",
            }}
          >
            {filteredGameList && filteredGameList.length > 0 ? (
              filteredGameList.map((data, key) => (
                <Game
                  key={key}
                  gameId={data.id}
                  data={data}
                  setGameIframe={setGameIframe}
                  setGameIframeUrl={setGameIframeUrl}
                  openLogin={openLogin}
                />
              ))
            ) : !loading ? (
              <Typography
                variant="body1"
                sx={{
                  color: "#ffffff",
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              >
                Game not found.
              </Typography>
            ) : null}
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            margin: "0 auto",
            display: "flex",
            flexWrap: "wrap",
            gap: 2.5,
            justifyContent: "flex-start",
            marginTop: "10px",
          }}
        >
          <div className="game-list">
            {filteredGameList && filteredGameList.length > 0 ? (
              filteredGameList.map((data, key) => (
                <Game
                  key={key}
                  gameId={data.id}
                  data={data}
                  setGameIframe={setGameIframe}
                  setGameIframeUrl={setGameIframeUrl}
                  openLogin={openLogin}
                />
              ))
            ) : !loading ? (
              <Typography
                variant="body1"
                sx={{
                  color: "#ffffff",
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              >
                Game not found.
              </Typography>
            ) : null}
          </div>
        </Box>
      )}
    </>
  );
}
