import React, { useState } from "react";
import { Button, Grid, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  const handleLogout = () => {
    try {
      localStorage.clear();
      navigate("/m");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  return (
    <Box>
      <Grid>
        <Typography
          sx={{
            fontSize: "4vw",
            color: "#000",
            fontWeight: "bold",
          }}
          onClick={handleLogout}
        >
          Logout
        </Typography>
      </Grid>
    </Box>
  );
}

export default Logout;
