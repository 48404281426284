import { useState } from "react";
import { Tab, Toolbar, styled } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import AllProfitAndLossRecords from "./AllProfitAndLossRecords";

const StyledTab = styled(Tab)({
  textTransform: "none",
  "&.Mui-selected": {
    color: "red",
  },
});

const StyledTabList = styled(TabList)({
  ".MuiTabs-indicator": {
    backgroundColor: "red",
  },
});

const ProfitAndLoss = () => {
  // Initialization
  const defaultTransactionType = "transaction_records";

  const [selectedTransactionType, setSelectedTransactionType] = useState(
    defaultTransactionType
  );

  const handleChangeTransactionTab = (event, newValue) => {
    setSelectedTransactionType(newValue);
  };

  return (
    <>
      <TabContext value={selectedTransactionType} textColor="danger">
        <StyledTabList onChange={handleChangeTransactionTab}>
          <StyledTab label="All" value="transaction_records" />
          <StyledTab label="Bingo" value="deposit_records" />
          <StyledTab label="Slot" value="withdrawal_records" />
          <StyledTab label="Live" value="Live" />
          <StyledTab label="Fishing" value="Fishing" />
          <StyledTab label="PVP" value="PVP" />
        </StyledTabList>
      </TabContext>

      <Toolbar variant="dense">
        {selectedTransactionType && (
          <AllProfitAndLossRecords
            selectedTransactionType={selectedTransactionType}
          />
        )}
      </Toolbar>
    </>
  );
};
export default ProfitAndLoss;
