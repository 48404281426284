import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useQuery } from "react-query";
import DialogMaximumRegistered from "../../pages/profile/withdrawal/withdraw/components/DialogMaximumRegistered";
import DialogMaximumWarning from "../../pages/profile/withdrawal/withdraw/components/DialogMaximumWarning";
import DialogInvalidTransactionPassword from "../../pages/profile/withdrawal/withdraw/components/DialogInvalidTransactionPassword";
import axiosInstance from "../../axiosConfig";
import { usePlayer } from "../../contexts/PlayerContext";
import { ToastContainer, toast } from "react-toastify";

const BindEwalletTextContainer = styled(Box)({
  borderLeft: "5px solid #23e63a",
  marginBottom: "20px",
});

const FormGroup = styled(Stack)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
});

const SelectedContainer = styled(Box)({
  borderRadius: "6px",
  border: "1px solid #FF0000",
  paddingTop: "10px",
  paddingBottom: "10px",
  width: "233px",
  textAlign: "center",
  backgroundColor: "rgba(255,48,57,.06)",
});

const styles = {
  input: {
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    color: "#646464",
    fontSize: "4vw",
    verticalAlign: "middle",
    width: "100%",
  },
  select: {
    borderRadius: "3%",
    border: "1px solid #e5e5e5",
    backgroundColor: "#f5f5f5",
    color: "#646464",
    fontSize: "3.5vw",
    boxShadow: "0 1px 5px rgba(0,0,0,.08) inset",
    height: "13.8vw",
    verticalAlign: "middle",
    width: "70%",
    padding: "0 34px 0 5px",
  },
};

const BindEWalletMobile = (props) => {
  const { openWithdraw, isAcoountManagement } = props;
  const location = useLocation();
  const { from } = location.state || {};

  const navigate = useNavigate();

  const [accountNumber, setAccountNumber] = useState("");
  const [name, setName] = useState("");
  const [touched, setTouched] = useState(false);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);
  const [dialogMaximumLimit, setDialogMaximumLimit] = useState(false);
  const [dialogMaximumWarning, setdialogMaximumWarning] = useState(false);
  const [dialogInvalidTransactionPass, setDialogInvalidTransactionPass] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [fullName, setFullName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [transactionPassword, setTransactionPassword] = useState("");
  const { isVisible, setIsVisible } = usePlayer();

  const toggleVisibility = () => {
    if (!disabled) {
      setIsVisible(!isVisible);
    }
  };

  const numberAccount = /^09\d{9}$/;

  const placeholder =
    fullName === "null null" || fullName === null
      ? ""
      : fullName.substring(0, 1) + "*".repeat(Math.max(0, fullName.length - 1));

  useEffect(() => {
    axiosInstance
      .get(`player/player-profile`)
      .then((response) => {
        setFullName(response.data.full_name);
      })
      .catch((err) => console.error(err));
  }, []);

  const fetchDataPlayerKeys = async () => {
    const response = await axiosInstance.get(`player/playerkeys`);
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }
    return response.data;
  };

  const { data: dataPlayerKeys, isLoading: isLoadingPlayerKeys } = useQuery(
    "player keys",
    fetchDataPlayerKeys
  );

  const maskAccountNumber = (accountNumber) => {
    accountNumber = String(accountNumber);
    const visibleDigits = 4;
    const maskedPart = "*".repeat(accountNumber.length - visibleDigits);
    const visiblePart = accountNumber.slice(-visibleDigits);
    return maskedPart + visiblePart;
  };

  const fetchData = async () => {
    const response = await axiosInstance.get(`player/paymentmethod`);
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery("playerEWalletBind", fetchData);

  useEffect(() => {
    if (data && data.length === 2) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data, openWithdraw]);

  if (isLoading && isLoadingPlayerKeys) {
    return <div>loading</div>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  const handleBackButton = () => {
    if (from === "/m/myWithdrawCards") {
      navigate("/m/myWithdrawCards");
    } else if (from === "/m/securityCenter") {
      navigate("/m/securityCenter");
    } else {
      navigate(-1);
    }
    setIsVisible(false);
  };

  const handleAccountNumber = (number) => {
    setTouched(true);
    setAccountNumber(number);
    if (numberAccount.test(number)) {
      setIsAccountNumberValid(true);
    } else {
      setIsAccountNumberValid(false);
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleChange = (e) => {
    setTransactionPassword(e.target.value.trim());
  };

  const handleSubmit = () => {
    if (data.length <= 1) {
      axiosInstance
        .post(`paymentmethod/update/ewallet`, {
          type: "GCASH",
          account_number: accountNumber,
          name: fullName,
          transaction_password: transactionPassword,
        })
        .then((res) => {
          refetch();
          if (res.data.msg === "E-Wallet bind successfully") {
            setIsVisible(false);
            toast.success("E-Wallet bind successfully.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setAccountNumber("");
            setTransactionPassword("");
          }
          if (
            res.data.msg ===
            "Existing account number. Use another account number."
          ) {
            toast.error(
              "Existing account number. Use another account number.",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
            setIsVisible(true);
          } else if (res.data.msg === "Transaction Password does not match") {
            toast.error("Transaction Password does not match.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setIsVisible(true);
          }
        })
        .catch((error) => {
          setIsVisible(false);
        });
    } else {
      setDialogMaximumLimit(true);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.4vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid container xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.2vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid container xs={4} sm={4}>
            <Typography
              sx={{
                color: "#FFFFFF",
                marginTop: "14.5%",
                fontSize: "3.8vw",
              }}
            >
              Bind E-Wallet
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          sx={{
            marginTop: "6.5%",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          {!isVisible && (
            <Grid>
              <Grid container xs={12} sm={12}>
                <Typography sx={{ fontSize: "4.5vw" }}>
                  {data && <> E-Wallet added : {data.length}</>}
                </Typography>
              </Grid>
              <Grid container xs={12} sm={12} sx={{}}>
                {data &&
                  data.map((row, index) => (
                    <>
                      {index === 0 ? (
                        <>
                          <Grid
                            container
                            sm={12}
                            xs={12}
                            sx={{
                              bgcolor: "#FEF2E5",
                              padding: "4%",
                              borderRadius: "4vw",
                            }}
                          >
                            <Grid item sm={5} xs={5} sx={{ display: "flex" }}>
                              <Box key={index} sx={{}}>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    backgroundColor: "#FFF",
                                    borderRadius: "3vw",
                                  }}
                                  src="https://images.b51613.com:42666/mcs-images/bank/mcs_1632999242223_V3-MCS-CONSOLE-UPLOAD.jpg"
                                />
                              </Box>
                            </Grid>
                            <Grid container sm={7} xs={7} sx={{}}>
                              <Box item sm={7} xs={7} sx={{ paddingX: "2%" }}>
                                <Typography sx={{ fontSize: "4vw" }}>
                                  {row.e_wallet_type}
                                </Typography>
                                <Typography
                                  sx={{ color: "#666", fontSize: "4vw" }}
                                >
                                  {maskAccountNumber(row.account_number)}
                                </Typography>
                              </Box>
                            </Grid>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                                height: "4vw",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#999",
                                  paddingBottom: "10%",
                                  fontSize: "4vw",
                                }}
                              >
                                {formatDate(row.created_at)}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            container
                            sm={12}
                            xs={12}
                            sx={{
                              bgcolor: "#FEF2E5",
                              marginTop: "5%",
                              padding: "4%",
                              borderRadius: "4vw",
                            }}
                          >
                            <Grid item sm={5} xs={5} sx={{ display: "flex" }}>
                              <Box key={index} sx={{}}>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    backgroundColor: "#FFF",
                                    borderRadius: "3vw",
                                  }}
                                  src="https://images.b51613.com:42666/mcs-images/bank/mcs_1632999242223_V3-MCS-CONSOLE-UPLOAD.jpg"
                                />
                              </Box>
                            </Grid>
                            <Grid container sm={7} xs={7} sx={{}}>
                              <Box item sm={7} xs={7} sx={{ paddingX: "2%" }}>
                                <Typography sx={{ fontSize: "4vw" }}>
                                  {row.e_wallet_type}
                                </Typography>
                                <Typography
                                  sx={{ color: "#666", fontSize: "4vw" }}
                                >
                                  {maskAccountNumber(row.account_number)}
                                </Typography>
                              </Box>
                            </Grid>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                                height: "4vw",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#999",
                                  paddingBottom: "10%",
                                  fontSize: "4vw",
                                }}
                              >
                                {formatDate(row.created_at)}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </>
                  ))}
              </Grid>
              <Grid
                container
                sm="12"
                xs="12"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Box sx={{}}>
                  <Typography
                    sx={{
                      marginTop: "10%",
                      fontSize: "4vw",
                    }}
                  >
                    Maximum 2 allowed
                  </Typography>
                  <Grid
                    disabled={data && data.length === 2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <AddCircleIcon
                      disabled={data && data.length === 2}
                      sx={{
                        color: disabled ? "#ccc" : "#ff1931",
                        marginY: "10%",
                        fontSize: "10vw",
                      }}
                      onClick={toggleVisibility}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
          {isVisible && (
            <Grid container sm={12} xs={12} sx={{ height: "auto" }}>
              <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
                <Box sx={{}}>
                  {isAcoountManagement ? (
                    <Typography
                      sx={{
                        fontSize: "4vw",
                      }}
                    >
                      E-Wallet
                    </Typography>
                  ) : (
                    <BindEwalletTextContainer>
                      <Typography
                        sx={{
                          fontSize: "4vw",
                        }}
                      >
                        Bind E-Wallet
                      </Typography>
                    </BindEwalletTextContainer>
                  )}
                  <FormGroup direction="row">
                    <Typography sx={{ fontSize: "4vw" }}>E-wallet :</Typography>
                    <select style={styles.select}>
                      <option value="1">GCASH</option>
                    </select>
                  </FormGroup>
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
                <Box sx={{}}>
                  <FormGroup direction="row">
                    <Typography sx={{ fontSize: "4vw" }}>
                      Name on Card :
                    </Typography>
                    <TextField
                      sx={{
                        height: "13.8vw",
                        width: "60%",
                        fontSize: "4vw",
                      }}
                      type="text"
                      placeholder={placeholder}
                      // value={}
                      onChange={handleName}
                      disabled={
                        fullName === "null null" || fullName === null
                          ? false
                          : true
                      }
                      InputProps={{
                        style: {
                          color: "#333",
                          fontSize: "4vw",
                          height: "100%",
                          textAlignLast: "left",
                        },
                      }}
                    />
                  </FormGroup>
                </Box>
              </Grid>

              <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
                <Box>
                  <FormGroup direction="row">
                    <Typography sx={{ fontSize: "4vw" }}>
                      E-wallet Type :
                    </Typography>
                    <SelectedContainer>
                      <Typography
                        sx={{
                          color: "#FF0000",
                          fontSize: "4vw",
                        }}
                      >
                        GCASH
                      </Typography>
                    </SelectedContainer>
                  </FormGroup>
                </Box>
              </Grid>

              <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
                <Box>
                  <FormGroup direction="row">
                    <Typography sx={{ fontSize: "4vw" }}>
                      Account Number :
                    </Typography>
                    <TextField
                      sx={{
                        width: "100%",
                        fontSize: "4vw",
                      }}
                      type="number"
                      placeholder="Please fill in Account Number"
                      style={styles.input}
                      value={accountNumber}
                      onChange={(e) => handleAccountNumber(e.target.value)}
                      InputProps={{
                        style: {
                          color: "#333",
                          fontSize: "4vw",
                          width: "100%",
                          textAlignLast: "left",
                        },
                      }}
                    />
                  </FormGroup>
                </Box>
              </Grid>
              <Box>
                {!isAccountNumberValid && touched ? (
                  <>
                    <Typography
                      style={{
                        color: "#ed5650",
                        fontSize: "12px",
                        marginLeft: 105,
                        marginTop: -20,
                      }}
                    >
                      Please enter 11 characters and start with 09, only numbers allowed
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography />
                  </>
                )}
                {dataPlayerKeys.data.transaction_password !== null && (
                  <>
                    <FormGroup direction="row">
                      <Typography sx={{ fontSize: "4vw" }}>
                        Transaction Password :
                      </Typography>
                      <TextField
                        sx={{
                          width: "100%",
                          fontSize: "4vw",
                          "& .MuiFormHelperText-root": {
                            color: "red",
                            fontSize: "3vw",
                            marginLeft: 0,
                            textAlign: "left",
                          },
                        }}
                        type="password"
                        placeholder="Please fill in the Transaction Password"
                        style={styles.input}
                        value={transactionPassword}
                        onChange={handleChange}
                        InputProps={{
                          style: {
                            color: "#333",
                            fontSize: "3vw",
                            width: "100%",
                            textAlignLast: "left",
                          },
                        }}
                      />
                    </FormGroup>
                  </>
                )}
                {dataPlayerKeys.data.transaction_password === null && (
                  <FormHelperText
                    id="component-error-text"
                    sx={{ color: "red" }}
                  >
                    Please set up your transaction password first. Go to{" "}
                    <span style={{ fontWeight: "bold" }}>Security Center</span>{" "}
                    <span style={{ fontWeight: "bold" }}>{">"}</span>{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Transaction Password
                    </span>
                  </FormHelperText>
                )}
              </Box>

              <Button
                sx={{
                  backgroundColor:
                    isAccountNumberValid &&
                    dataPlayerKeys.data.transaction_password === null
                      ? "#d5d5d5"
                      : isAccountNumberValid
                      ? "#fd2f2f"
                      : "#d5d5d5",

                  border: "none",
                  width: "30%",
                  borderRadius: "20px",
                  color: "#fff",
                  cursor: "pointer",
                  boxShadow: "0 0 10px #d5d5d5",
                  marginTop: "10px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: isAccountNumberValid
                      ? "#fd2f2f"
                      : "#d5d5d5",
                  },
                }}
                disabled={dataPlayerKeys.data.transaction_password === null}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          )}
          <ToastContainer />
        </Grid>

        {dialogInvalidTransactionPass && (
          <DialogInvalidTransactionPassword
            dialogInvalidTransactionPass={dialogInvalidTransactionPass}
            setDialogInvalidTransactionPass={setDialogInvalidTransactionPass}
            errorMessage={errorMessage}
          />
        )}
        {dialogMaximumWarning && openWithdraw && (
          <DialogMaximumWarning
            dialogMaximumWarning={dialogMaximumWarning}
            setdialogMaximumWarning={setdialogMaximumWarning}
          />
        )}
        {dialogMaximumLimit && (
          <DialogMaximumRegistered
            dialogMaximumLimit={dialogMaximumLimit}
            setDialogMaximumLimit={setDialogMaximumLimit}
          />
        )}
      </Grid>
    </>
  );
};

export default BindEWalletMobile;
