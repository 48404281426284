import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CASINO_SERVER } from "../config";
import axiosInstance from "../axiosConfig";

const initialState = {
  profile: {},
  details: {},
  error: null,
  status: "idle",
};

export const fetchPlayerProfile = createAsyncThunk(
  "player/profile",
  async (id) => {
    try {
      const response = await axiosInstance.get(
        `player/profile/my-account/${id}`
      );
      return response.data[0];
    } catch (error) {
      return error;
    }
  }
);

export const fetchPlayerDetails = createAsyncThunk(
  "player/profile/details",
  async (id) => {
    try {
      const response = await axiosInstance.get(
        `player/profile/my-account/${id}`
      );
      return response.data[0];
    } catch (error) {
      return error;
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    // updateExistingProfile: (state, action) => {
    //   const { id, user } = action.payload;
    //   const existingUser = state.users.find((user) => user.id === id);
    //   if (existingUser) {
    //     existingUser = user;
    //   }
    // },
    storeProfile: (state, action) => {
      state.profile = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlayerProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profile = action.payload;
      })
      .addCase(fetchPlayerProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      .addCase(fetchPlayerDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlayerDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.details = action.payload;
      })
      .addCase(fetchPlayerDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      });
  },
});

// export const subscriptionMiddleware = (store) => (next) => (action) => {
//   if (action.type.startsWith('player/') && action.type.endsWith('/fulfilled')) {
//     store.dispatch(fetchPlayerDetails(action.payload));
//   }
//   return next(action);
// };

export const { /** updateExistingUser, */ storeProfile } = profileSlice.actions;

export default profileSlice.reducer;
