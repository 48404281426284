import { Box, Stack, Typography, styled } from "@mui/material";

const GameTypeColumn = styled(Box)({
  textAlign: "center",
  typography: "body2",
  marginTop: "10px",
  width: "174px",
});

const GameTypeHeader = styled(Typography)({
  padding: "5px 50px",
  backgroundColor: "#E6E6E6",
  borderRadius: "12px 0 0 0",
  borderRight: "1px solid #D4D4D4",
  color: "#5E5E5E",
  fontSize: "0.8rem",
  whiteSpace: "nowrap",
});

const RemainingTurnoverHeader = styled(Typography)({
  padding: "5px 30px",
  backgroundColor: "#E6E6E6",
  borderRadius: "0 12px 0 0",
  borderLeft: "1px solid #D4D4D4",
  color: "#5E5E5E",
  fontSize: "0.8rem",
  whiteSpace: "nowrap",
});

const TurnoverCell = styled(Typography)({
  padding: "5px 50px",
  backgroundColor:
    "linear-gradient(#f7f7f7,#f7f7f7),linear-gradient(#efefef,#efefef)",
  borderRight: "1px solid #F0F0F0",
  borderLeft: "1px solid #F0F0F0",
  color: "#8a8a8a",
  borderBottom: "1px solid #F0F0F0",
  fontSize: "0.9rem",
});

const WithdrawalRequirementsHeader = styled(Typography)({
  marginTop: "10px",
  fontWeight: "bold",
  color: "#646464",
  fontSize: "1.1rem",
});

const WithdrawalRequirementsSubHeader = styled(Typography)({
  marginTop: "10px",
  fontWeight: "bold",
  color: "#FF0000",
  fontSize: "0.9rem",
});

export default function WithdrawalRequirements({
  remainingTurnover,
}) {

  return (
    <>
      {parseFloat(remainingTurnover) !== 0.0 && (
        <>
          <WithdrawalRequirementsHeader variant="body1">
            Withdrawal turnover requirements
          </WithdrawalRequirementsHeader>
          <WithdrawalRequirementsSubHeader variant="body1">
            Please complete the required turnover for withdrawal.
          </WithdrawalRequirementsSubHeader>
          <Stack direction="row">
            <GameTypeColumn>
              <GameTypeHeader>Game Type</GameTypeHeader>
              <TurnoverCell>General</TurnoverCell>
            </GameTypeColumn>
            <GameTypeColumn>
              <RemainingTurnoverHeader>
                Remaining Turnover
              </RemainingTurnoverHeader>
              <TurnoverCell>
                <span style={{ color: "#FF0000" }}>{remainingTurnover}</span>
              </TurnoverCell>
            </GameTypeColumn>
          </Stack>
        </>
      )}
    </>
  );
}
