import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
const PlayerContext = createContext();

export const PlayerProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const device = navigator.userAgent.toLowerCase();

  useEffect(() => {
    if (device.indexOf("android") > -1 || device.indexOf("iphone") > -1) {
      setIsMobile(true);
      if (location.pathname === "/") {
        navigate("/m");
      } else {
        <Navigate to="/m" replace={true} />;
      }
    } else {
      setIsMobile(false);
      if (location.pathname === "/m") {
        navigate("/");
      } else {
        <Navigate to="/" replace={true} />;
      }
    }
  }, [device]);

  return (
    <PlayerContext.Provider value={{ isMobile, setIsVisible, isVisible }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => useContext(PlayerContext);
