import React from "react";
import { ListItemButton, ListItemText, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function HelpCenterNavButton({ id, desc, stat }) {
  return (
    <ListItemButton
      sx={{
        background: id === stat ? "red" : "inherit",
        borderRadius: "50px",
      }}
    >
      <FiberManualRecordIcon
        fontSize="small"
        sx={{
          minWidth: 0,
          mr: 1,
        }}
      ></FiberManualRecordIcon>
      <ListItemText
        primary={
          <Typography
            fontSize="13px"
            fontWeight={id === stat ? "700" : "inherit"}
          >
            {desc}
          </Typography>
        }
      />

      <KeyboardArrowRightIcon fontSize="small"></KeyboardArrowRightIcon>
    </ListItemButton>
  );
}

export default HelpCenterNavButton;
