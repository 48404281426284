import { React } from "react";
import { Grid } from "@mui/material";
import WalletIcon from "@mui/icons-material/Wallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Typography } from "@mui/material";
import "../../../css/platform.css";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { EventNoteRounded } from "@mui/icons-material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { usePlayer } from "../../../../contexts/PlayerContext";

const DropDownContent = ({
  handleMenu,
  menuOpen,
  user,
  handleProfile,
  balance,
  handleMenuNavigate,
  handleLogout,
}) => {
  const { isMobile } = usePlayer();

  return (
    <>
      {isMobile ? (
        <>
          <AccountCircleIcon
            onClick={handleMenu}
            sx={{
              cursor: "pointer",
              fontSize: "7vw",
              color: "#FFFFFF",
              marginLeft: "70%",
              marginTop: "20%",
            }}
          />
        </>
      ) : (
        <>
          <AccountCircleIcon
            onClick={handleMenu}
            sx={{
              cursor: "pointer",
              fontSize: "35px",
              color: "#FFFFFF",
              marginLeft: "15px",
            }}
          />
        </>
      )}
      {menuOpen && (
        <>
          {isMobile ? (
            <>
              <Grid
                alignContent="center"
                container
                sx={{
                  backgroundColor: "#16213e",
                  position: "fixed",
                  zIndex: 2,
                  height: "75vw",
                  width: "40vw",
                  marginTop: "3%",
                  marginLeft: "-30%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "5%",
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={
                      "https://images.21947392.com/TCG_PROD_IMAGES/B2C/01_PROFILE/PROFILE/0.png"
                    } //change this in the future from the picture of the specific user from db -> table
                    style={{
                      width: "12vw",
                      height: "12vw",
                      borderRadius: "50%",
                      marginLeft: "5%",
                      marginTop: "10%",
                      border: ".15rem solid red",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "3.5vw",
                      textAlign: "center",
                      marginTop: "18%",
                      marginLeft: "6%",
                    }}
                  >
                    {user.profile.player_uname ??
                      localStorage.getItem("username")}
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleMenuNavigate("Member")}
                  container
                  sx={{
                    width: "90%",
                    height: "11vw",
                    display: "flex",
                    marginTop: "13%",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#2e3852",
                    borderRadius: "2vw",
                  }}
                >
                  <AccountCircleIcon
                    sx={{ color: "#FFFFFF", fontSize: "7vw", marginLeft: "2%" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "3vw",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "4%",
                      marginTop: "5%",
                    }}
                  >
                    Members
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleMenuNavigate("Account Record")}
                  container
                  sx={{
                    width: "90%",
                    height: "11vw",
                    display: "flex",
                    marginTop: "3%",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#2e3852",
                    borderRadius: "2vw",
                  }}
                >
                  <ReceiptLongIcon
                    sx={{ color: "#FFFFFF", fontSize: "7vw", marginLeft: "2%" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "3vw",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "4%",
                      marginTop: "5%",
                    }}
                  >
                    Account Record
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleMenuNavigate("Betting Record")}
                  container
                  sx={{
                    width: "90%",
                    height: "11vw",
                    display: "flex",
                    marginTop: "3%",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#2e3852",
                    borderRadius: "2vw",
                  }}
                >
                  <EventNoteRounded
                    sx={{ color: "#FFFFFF", fontSize: "7vw", marginLeft: "2%" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "3vw",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "4%",
                      marginTop: "5%",
                    }}
                  >
                    Betting Record
                  </Typography>
                </Grid>
                <Grid
                  onClick={handleLogout}
                  container
                  sx={{
                    width: "90%",
                    height: "11vw",
                    display: "flex",
                    marginTop: "3%",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#2e3852",
                    borderRadius: "2vw",
                  }}
                >
                  <LogoutRoundedIcon
                    sx={{ color: "#FFFFFF", fontSize: "7vw", marginLeft: "2%" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "3vw",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "4%",
                      marginTop: "5%",
                    }}
                  >
                    Logout
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                alignItems="center"
                sx={{
                  backgroundColor: "#16213e",
                  position: "fixed",
                  zIndex: 2,
                  height: "360px",
                  width: "270px",
                  marginTop: "425px",
                  marginLeft: "1110px",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "17px 17px 17px 17px",
                }}
              >
                <img
                  src={
                    "https://images.21947392.com/TCG_PROD_IMAGES/B2C/01_PROFILE/PROFILE/0.png"
                  } //change this in the future from the picture of the specific user from db -> table
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    marginBottom: "10px",
                    marginTop: "20px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    marginBottom: "10px",
                  }}
                >
                  {user.profile.player_uname ??
                    localStorage.getItem("username")}
                </Typography>
                <Grid sx={{ marginLeft: "15px" }}>
                  <Grid sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "30px 30px 30px 30px",
                        width: "140px",
                        color: "black",
                        marginLeft: "-5px",
                        display: "flex",
                      }}
                    >
                      <WalletIcon
                        onClick={() => handleProfile("deposit")}
                        sx={{
                          color: "white",
                          backgroundColor: "red",
                          borderRadius: "20px",
                          cursor: "pointer",
                          fontSize: "26px",
                          p: 0.1,
                          zIndex: 1,
                        }}
                      />
                      {balance ? (
                        <Typography
                          sx={{
                            marginRight: "6px",
                            textAlign: "center",
                            alignContent: "center",
                          }}
                        >
                          ₱ {balance.toFixed(2)}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            marginRight: "6px",
                            textAlign: "center",
                            alignContent: "center",
                          }}
                        >
                          ₱ 0.00
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  onClick={() => handleMenuNavigate("Member")}
                  container
                  sx={{
                    width: "60%",
                    display: "flex",
                    marginTop: "15px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <AccountCircleIcon
                    sx={{ color: "#FFFFFF", fontSize: "30px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    Members
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleMenuNavigate("Account Record")}
                  container
                  sx={{
                    width: "60%",
                    display: "flex",
                    marginTop: "15px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ReceiptLongIcon
                    sx={{ color: "#FFFFFF", fontSize: "30px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    Account Record
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleMenuNavigate("Betting Record")}
                  container
                  sx={{
                    width: "60%",
                    display: "flex",
                    marginTop: "15px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <EventNoteRounded
                    sx={{ color: "#FFFFFF", fontSize: "30px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    Betting Record
                  </Typography>
                </Grid>
                <Grid
                  onClick={handleLogout}
                  container
                  justifyContent="center"
                  sx={{
                    width: "60%",
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <LogoutRoundedIcon sx={{ color: "red", fontSize: "32px" }} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "red",
                      marginLeft: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Sign Out
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DropDownContent;
