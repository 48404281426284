import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  Button,
  Typography,
  styled,
  Stack,
} from "@mui/material";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import axiosInstance from "../../axiosConfig";

const LoginPasswordMobile = ({
  setDialogOpen,
  setDialogWrongPassword,
  toggleDrawer,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isCPasswordValid, setIsCPasswordValid] = useState(false);
  const [touchedValue, setTouchedValue] = useState(false);
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [touchedCPassword, setTouchedCPassword] = useState(false);

  const navigate = useNavigate();
  const validatePassword = (input) => {
    const upperCaseAndLowerAndDigitAndSpecial = /^[A-Za-z0-9]{6,12}$/;
    return upperCaseAndLowerAndDigitAndSpecial.test(input);
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    setTouchedPassword(true);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setTouchedValue(true);
    setIsPasswordValid(validatePassword(e.target.value));
  };

  const handleCPassword = (e) => {
    setCPassword(e.target.value);
    setTouchedCPassword(true);
    if (e.target.value !== password) {
      setIsCPasswordValid(false);
    } else {
      setIsCPasswordValid(true);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axiosInstance
      .post(`player/resetpassword`, {
        current_password: currentPassword,
        password,
      })
      .then(() => {
        toggleDrawer("");
        setDialogOpen(true);
      })
      .catch(() => {
        console.error("This is error");
      });
  };

  const handleBackButton = () => {
    navigate("/m/securityCenter");
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.4vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid container xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.2vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography
              container
              xs={6}
              sm={6}
              sx={{
                marginTop: "10%",
                fontSize: "3.8vw",
                color: "#fff",
              }}
            >
              Change login password
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          sx={{
            marginTop: "6.5%",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <Grid item xs={12} sm={12}>
            <TextField
              sx={{
                borderRadius: "1.5vw",
                height: "13.8vw",
                width: "100%",
                backgroundColor: "#efefef",
                color: "#333",
                fieldset: {
                  borderRadius: "1.5vw",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#efefef",
                  },
                  "&:hover fieldset": {
                    borderColor: "#efefef",
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: ".2vw",
                  },
                },
                "& .MuiFormHelperText-root": {
                  color: "red",
                  fontSize: "3vw",
                  marginLeft: 0,
                  textAlign: "left",
                },
              }}
              placeholder="Enter your current password"
              variant="outlined"
              id="machine_id"
              type={showNewPassword ? "text" : "password"}
              value={currentPassword}
              onChange={handleCurrentPassword}
              error={currentPassword === "" && touchedValue}
              helperText={
                currentPassword === "" && touchedValue
                  ? "Please enter a value"
                  : ""
              }
              InputProps={{
                style: {
                  color: "#333",
                  fontSize: "4vw",
                  height: "100%",
                  paddingTop: "1%",
                  textAlignLast: "left",
                },
                startAdornment: (
                  <LockOutlinedIcon
                    sx={{
                      color: "#919191",
                      fontSize: "6.7vw",
                      marginRight: "5%",
                    }}
                  />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleNewPasswordVisibility}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <VisibilityOff
                          sx={{ color: "#919191", fontSize: "6.7vw" }}
                        />
                      ) : (
                        <Visibility
                          sx={{ color: "#919191", fontSize: "6.7vw" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ marginTop: "5.5%" }}>
            <TextField
              sx={{
                borderRadius: "1.5vw",
                height: "13.8vw",
                width: "100%",
                backgroundColor: "#efefef",
                fieldset: {
                  borderRadius: "1.5vw",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#efefef",
                  },
                  "&:hover fieldset": {
                    borderColor: "#efefef",
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: ".2vw",
                  },
                },
                "& .MuiFormHelperText-root": {
                  color: "red",
                  fontSize: "3vw",
                  marginLeft: 0,
                  textAlign: "left",
                },
              }}
              placeholder="Please enter a new password"
              variant="outlined"
              id="machine_id"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePassword}
              error={!isPasswordValid && touchedPassword}
              helperText={
                !isPasswordValid && touchedPassword
                  ? "Please enter 6 - 13 alphanumeric without special character"
                  : ""
              }
              InputProps={{
                style: {
                  color: "#333",
                  fontSize: "4vw",
                  height: "100%",
                  paddingTop: "1%",
                  textAlignLast: "left",
                },
                startAdornment: (
                  <LockPersonOutlinedIcon
                    sx={{
                      color: "#919191",
                      fontSize: "6.7vw",
                      marginRight: "5%",
                    }}
                  />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{ color: "#919191", fontSize: "6.7vw" }}
                        />
                      ) : (
                        <Visibility
                          sx={{ color: "#919191", fontSize: "6.7vw" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ marginTop: "5.5%" }}>
            <TextField
              sx={{
                borderRadius: "1.5vw",
                height: "13.8vw",
                width: "100%",
                backgroundColor: "#efefef",
                fieldset: {
                  borderRadius: "1.5vw",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#efefef",
                  },
                  "&:hover fieldset": {
                    borderColor: "#efefef",
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: ".2vw",
                  },
                },
                "& .MuiFormHelperText-root": {
                  color: "red",
                  fontSize: "3vw",
                  marginLeft: 0,
                  textAlign: "left",
                },
              }}
              placeholder="Confirm new password"
              variant="outlined"
              id="machine_id"
              type={showConfirmPassword ? "text" : "password"}
              value={cPassword}
              onChange={handleCPassword}
              error={!isCPasswordValid && touchedCPassword}
              helperText={
                !isCPasswordValid && touchedCPassword
                  ? "New password doesn't match the confirm password!"
                  : ""
              }
              InputProps={{
                style: {
                  color: "#000000",
                  fontSize: "4vw",
                  height: "100%",
                  paddingTop: "1%",
                  textAlignLast: "left",
                },
                startAdornment: (
                  <LockResetOutlinedIcon
                    sx={{
                      color: "#919191",
                      fontSize: "6.7vw",
                      marginRight: "5%",
                    }}
                  />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff
                          sx={{ color: "#919191", fontSize: "6.7vw" }}
                        />
                      ) : (
                        <Visibility
                          sx={{ color: "#919191", fontSize: "6.7vw" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item xs={12} sm={12} sx={{ marginY: "6%" }}>
              <Grid
                sx={{
                  backgroundColor:
                    isCPasswordValid && touchedCPassword
                      ? "#ff1931"
                      : "#D8D8D8",
                  width: "100%",
                  height: "12vw",
                  borderRadius: "1vw",
                  "&:hover": {
                    backgroundColor:
                      isCPasswordValid && touchedCPassword
                        ? "#ff1931"
                        : "#D8D8D8",
                  },
                  boxShadow:
                    isCPasswordValid && touchedCPassword
                      ? "0 .3rem .4rem 0 rgba(255, 0, 16, .4)"
                      : "0 .3rem .4rem 0 rgba(5, 0, 0, .11)",
                }}
                onClick={handleSubmit}
                disabled={
                  !isPasswordValid ||
                  !isCPasswordValid ||
                  !touchedPassword ||
                  !touchedCPassword
                }
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "4.5vw",
                    textTransform: "capitalize",
                    width: "100%",
                    height: "12vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Submit
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPasswordMobile;
