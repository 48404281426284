import { useState } from "react";
import { useLocation } from "react-router-dom";
import { usePlayer } from "../../../../../contexts/PlayerContext";
import { Button } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

export default function FavoritesButton({ state, onClick }) {
  const { isMobile } = usePlayer();
  const [hoverFavoritesButton, setHoverFavoritesButton] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <>
      {isMobile ? (
        <Button
          sx={{
            marginTop: "40px",
            color: "#FFFFFF",
            backgroundColor: state ? "red" : "#16213e",
            textTransform: "none",
            marginLeft: "-20px",
            fontSize: "16px",
            borderRadius: "8px",
            marginLeft: currentRoute === "/m" ? "0px" : "30px",
            height: "33px",
            width: "90%",
            minWidth: "10px",
            padding: "0px",
            position: "relative",
            "&:hover": {
              backgroundColor: "red",
              color: "#FFFFFF",
            },
          }}
          onMouseEnter={() => setHoverFavoritesButton(true)}
          onMouseLeave={() => setHoverFavoritesButton(false)}
          onClick={onClick}
        >
          <StarRoundedIcon
            sx={{
              color: "#ffc107",
              marginRight: "5px",
              border: "40px",
              position: "relative",
              width: "20px",
              zIndex: 1,
            }}
          />
          <StarRoundedIcon
            sx={{
              color: "#white",
              position: "absolute",
              width: "20px",
              marginRight: "5px",
              border: "40px",
              fontSize: "30px",
              zIndex: 0,
              marginLeft: "-53.5px",
              display: hoverFavoritesButton || state ? "inline" : "none",
            }}
          />
          <span style={{ fontSize: "12px" }}>Favorites</span>
        </Button>
      ) : (
        <Button
          sx={{
            marginTop: "40px",
            color: "#FFFFFF",
            backgroundColor: state ? "red" : "#16213e",
            textTransform: "none",
            marginLeft: "13px",
            fontSize: "16px",
            borderRadius: "15px",
            height: "35px",
            position: "relative",
            "&:hover": {
              backgroundColor: "red",
              color: "#FFFFFF",
            },
          }}
          onMouseEnter={() => setHoverFavoritesButton(true)}
          onMouseLeave={() => setHoverFavoritesButton(false)}
          onClick={onClick}
        >
          <StarRoundedIcon
            sx={{
              color: "#ffc107",
              marginRight: "5px",
              border: "40px",
              position: "relative",
              zIndex: 1,
            }}
          />
          <StarRoundedIcon
            sx={{
              color: "white",
              position: "absolute",
              marginRight: "5px",
              border: "40px",
              fontSize: "30px",
              zIndex: 0,
              marginLeft: "-73.9px",
              display: hoverFavoritesButton || state ? "inline" : "none",
            }}
          />
          <span style={{ marginTop: "5px", marginRight: "5px" }}>
            Favorites
          </span>
        </Button>
      )}
    </>
  );
}
