import React, { Suspense, useEffect, useState } from "react";
import { usePlayer } from "../../../../contexts/PlayerContext";
import { useSearchParams } from "react-router-dom";
import { Typography, Grid, Button, Box, CircularProgress } from "@mui/material";
import { navbarContent } from "../../../../utils/content";
import Drawer from "../../Drawer/SideDrawer";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { GameTypeHotIcon } from "../../../../assets/icons/Icons";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { ProfileComponentv1 } from "../profile-component/ProfileComponentv1";
import axiosInstance from "../../../../axiosConfig";

const { Logo } = navbarContent;

const MainNavbarv1 = (props) => {
  const {
    handleOpen,
    handleRegistration,
    handleProfile,
    setSelectedNavBarPath,
    selectedNavBarPath,
    setGameIframe,
    gameIframe,
    balance,
    setData,
    gameTypes,
    setGameTypes,
  } = props;
  const { isMobile } = usePlayer();
  const location = useLocation();
  const user = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const paramGameType = searchParams.get("type") || "";

  useEffect(() => {
    const fetchGameTypes = async () => {
      setLoading(true);
      setError(null);

      try {
        const params = {};
        params.device = "web";
        if (isMobile) {
          params.device = "mobile";
        }

        const response = await axiosInstance.get(`game/list/type`, {
          params,
        });

        setGameTypes(response.data);
      } catch (error) {
        setError("Error fetching game type");
        console.error("Error fetching game type:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameTypes();
  }, [isMobile]);

  const handleLogo = () => {
    if (isMobile) {
      navigate("/m");
    } else {
      setGameIframe(false);
      navigate("/");
    }
  };

  const handleLogin = () => {
    if (isMobile) {
      navigate("/m/login");
    } else {
      handleOpen(true);
    }
  };

  const handleRegisterModal = () => {
    if (isMobile) {
      navigate("/m/register");
    } else {
      handleRegistration();
    }
  };

  const handleSelectedPage = (path) => {
    navigate(path);
  };

  const handleLightMode = () => {
    return;
  };

  return (
    <>
      {isMobile ? (
        <>
          <Grid
            container
            sx={{
              overflowY: "hidden",
              overflowX: "hidden",
              position: "fixed",
              zIndex: "2",
            }}
          >
            <Grid
              container
              sx={{
                height: "12vw",
                backgroundColor: "#090929",
              }}
            >
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Drawer gameTypes={gameTypes} />
                </Grid>
              </Grid>
              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                <Grid
                  container
                  sx={{
                    justifyContent: "left",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <img
                    onClick={handleLogo}
                    alt="logo"
                    src={Logo}
                    style={{
                      cursor: "pointer",
                      width: "45vw",
                      marginLeft: "2%",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Grid
                  container
                  sx={{
                    color: "#FFFFFF",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  {localStorage.getItem("authToken") !== null ? (
                    <>
                      <ProfileComponentv1
                        handleProfile={handleProfile}
                        user={user}
                        selectedNavBarPath={selectedNavBarPath}
                        gameIframe={gameIframe}
                        setSelectedNavBarPath={setSelectedNavBarPath}
                        setGameIframe={setGameIframe}
                        setData={setData}
                        balance={balance}
                      />
                    </>
                  ) : (
                    <>
                      <Grid item xs={6} sm={6}>
                        <Grid
                          container
                          sx={{
                            color: "#FFFFFF",
                            justifyContent: "center",
                            alignContent: "center",
                            height: "100%",
                          }}
                        >
                          <Button
                            onClick={handleLogin}
                            variant="contained"
                            sx={{
                              borderRadius: 4,
                              backgroundColor: "#da1212",
                              color: "white",
                              textTransform: "none",
                              height: "5vw",
                              marginRight: "50%",
                              width: "3vw",
                              "&:hover": {
                                backgroundColor: "#da1212",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "3vw",
                                fontWeight: "bold",
                              }}
                            >
                              Login
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid
                          container
                          sx={{
                            color: "#FFFFFF",
                            justifyContent: "center",
                            alignContent: "center",
                            height: "100%",
                          }}
                        >
                          <Button
                            onClick={() => navigate("/m/register")}
                            variant="contained"
                            sx={{
                              borderRadius: 4,
                              backgroundColor: "#16213e",
                              border: "1px solid red",
                              borderColor: "red",
                              color: "#ffffff",
                              fontWeight: "bold",
                              marginRight: "20%",
                              textTransform: "none",
                              height: "5vw",
                              width: "3vw",
                              "&:hover": {
                                backgroundColor: "#16213e",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "3vw",
                                fontWeight: "bold",
                              }}
                            >
                              Register
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {!loading && (
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Grid
                container
                alignContent="center"
                sx={{
                  py: 1.5,
                  height: "54px",
                  display: "flex",
                  alignItems: "center",
                  position: "fixed",
                  zIndex: 2,
                  width: "100%",
                  backgroundColor: "#090929",
                  flexWrap: "nowrap",
                }}
              >
                <Drawer gameTypes={gameTypes} style={{ fontSize: "15px" }} />
                <img
                  onClick={handleLogo}
                  alt="logo"
                  src={Logo}
                  style={{
                    height: "20px",
                    marginRight: "58px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                />
                <Typography
                  onClick={() => handleSelectedPage(`/games?type=0&provider=1`)}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "15px",
                    display: "inline-block",
                    marginTop: "5px",
                    cursor: "pointer",
                    textDecoration:
                      parseInt(paramGameType) === 0 ? "underline" : "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  FBM Exclusives
                  <GameTypeHotIcon />
                </Typography>
                {gameTypes?.map((gameType) => (
                  <Typography
                    key={gameType.id}
                    onClick={() =>
                      handleSelectedPage(
                        `/games?type=${gameType.id}&provider=0`
                      )
                    }
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "15px",
                      marginLeft: "32px",
                      marginTop: "5px",
                      textDecoration:
                        parseInt(paramGameType) === gameType.id
                          ? "underline"
                          : "none",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {gameType.game_type_name}
                  </Typography>
                ))}
                <Typography
                  onClick={() => handleSelectedPage(`/games?type=&provider=`)}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "15px",
                    marginLeft: "23px",
                    marginTop: "5px",
                    textDecoration:
                      location.pathname === "/games" &&
                      new URLSearchParams(location.search).get("type") === "" &&
                      new URLSearchParams(location.search).get("provider") ===
                        ""
                        ? "underline"
                        : "none",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                >
                  All Games
                </Typography>
                <Button
                  onClick={handleLightMode}
                  disableRipple
                  variant="contained"
                  sx={{
                    borderRadius: 4,
                    backgroundColor: "#16213e",
                    color: "white",
                    textTransform: "none",
                    height: "30px",
                    marginLeft: gameIframe ? "200px" : "50px",
                    width: "120px",
                    "&:hover": {
                      backgroundColor: "#16213e",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "3px",
                      marginRight: "3px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Light Mode
                  </Typography>
                  <WbSunnyIcon sx={{ color: "#f7dd52" }} />
                </Button>
                {localStorage.getItem("authToken") !== null ? (
                  <>
                    <ProfileComponentv1
                      handleProfile={handleProfile}
                      user={user}
                      selectedNavBarPath={selectedNavBarPath}
                      gameIframe={gameIframe}
                      setSelectedNavBarPath={setSelectedNavBarPath}
                      setGameIframe={setGameIframe}
                      setData={setData}
                      balance={balance}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      onClick={handleLogin}
                      variant="contained"
                      sx={{
                        borderRadius: 4,
                        backgroundColor: "#da1212",
                        color: "white",
                        textTransform: "none",
                        height: "30px",
                        marginLeft: "10px",
                        width: "90px",
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "#da1212",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        Login
                      </Typography>
                    </Button>
                    <Button
                      onClick={handleRegisterModal}
                      variant="contained"
                      sx={{
                        borderRadius: 4,
                        backgroundColor: "#16213e",
                        border: "1px solid red",
                        borderColor: "red",
                        color: "#ffffff",
                        fontWeight: "bold",
                        textTransform: "none",
                        marginLeft: "10px",
                        height: "30px",
                        width: "90px",
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "#16213e",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        Register
                      </Typography>
                    </Button>
                  </>
                )}
              </Grid>
            </Suspense>
          )}
        </>
      )}
    </>
  );
};

export default MainNavbarv1;
