import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CASINO_SERVER } from "../config";

export const fetchAllGames = createAsyncThunk("player/games", async () => {
  try {
    const response = await axios.get(`${CASINO_SERVER}player/games/list`);
    return response.data;
  } catch (error) {
    return error;
  }
});

/**
 * game_name
 * game_url
 * game_img
 * game_provider_logo
 */
const initialState = {
  games: [],
  status: "idle",
  error: null,
};

const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllGames.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.games = action.payload;
      })
      .addCase(fetchAllGames.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      });
  },
});

export default gamesSlice.reducer;
