import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Collapse,
  ButtonBase,
  Button,
  Grid,
} from "@mui/material";
import "./dateRange.css";
import format from "date-fns/format";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";

const TicketsTable = () => {
  const [selectedInterval, setSelectedInterval] = useState("today");
  const [ticketType, setTicketType] = useState("All");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);
  const refOne = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (refOne.current && !refOne.current.contains(e.target)) {
        setIsDatePickerOpen(false);
      }
    };

    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const handleIntervalChange = (event) => {
    const { value } = event.target;
    setSelectedInterval(value);
    setIsDatePickerOpen(value === "custom");

    switch (value) {
      case "today":
        setSelectionRange({
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        });
        break;
      case "yesterday":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        setSelectionRange({
          startDate: yesterday,
          endDate: yesterday,
          key: "selection",
        });
        break;
      case "7days":
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 6);
        setSelectionRange({
          startDate: weekAgo,
          endDate: new Date(),
          key: "selection",
        });
        break;
      default:
        break;
    }
  };

  const handleTicketTypeChange = (event) => {
    setTicketType(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          paddingY: 1,
          borderRadius: 2,
          paddingX: "10px",
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <RadioGroup
            aria-label="interval"
            name="interval"
            value={selectedInterval}
            onChange={handleIntervalChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "fit-content",
              borderRight: 1,
              borderColor: "grey.400",
            }}
          >
            <FormControlLabel
              value="today"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#D70040",
                    },
                  }}
                />
              }
              label={<Typography variant="body2">Today</Typography>}
              sx={{ display: "flex" }}
            />
            <FormControlLabel
              value="yesterday"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#D70040",
                    },
                  }}
                />
              }
              label={<Typography variant="body2">Yesterday</Typography>}
              sx={{ display: "flex" }}
            />
            <FormControlLabel
              value="7days"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#D70040",
                    },
                  }}
                />
              }
              label={<Typography variant="body2">7-Days</Typography>}
              sx={{ display: "flex" }}
            />
            <FormControlLabel
              value="custom"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#D70040",
                    },
                  }}
                />
              }
              label={
                <ButtonBase
                  className="inputBox"
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 4,
                    padding: "8px",
                    width: 120,
                    textAlign: "center",
                  }}
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                    {`${format(range[0].startDate, "MM/dd")} to ${format(
                      range[0].endDate,
                      "MM/dd"
                    )}`}
                  </Typography>
                </ButtonBase>
              }
              sx={{ display: "flex" }}
            />
          </RadioGroup>
        </Box>

        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
            Ticket type:
          </Typography>
          <Select
            value={ticketType}
            onChange={handleTicketTypeChange}
            size="small"
            sx={{ minWidth: 100, fontSize: "0.875rem" }}
          >
            <MenuItem value="All" style={{ fontSize: "12px" }}>
              All
            </MenuItem>
            <MenuItem value="Lucky Angpao" style={{ fontSize: "12px" }}>
              Lucky Angpao
            </MenuItem>
            <MenuItem value="Golden Egg" style={{ fontSize: "12px" }}>
              Golden Egg
            </MenuItem>
            <MenuItem value="Prize Wheel" style={{ fontSize: "12px" }}>
              Prize Wheel
            </MenuItem>
            <MenuItem value="Cash voucher" style={{ fontSize: "12px" }}>
              Cash voucher
            </MenuItem>
          </Select>
          <Button variant="contained" color="error" size="small">
            Search
          </Button>
        </Box>
      </Box>

      <Collapse
        in={isDatePickerOpen}
        sx={{
          position: "absolute",
          zIndex: 1000,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Box
          ref={refOne}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginTop: 1,
            backgroundColor: "#ffffff",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            padding: 2,
            borderRadius: 1,
          }}
        >
          <DateRange
            onChange={(item) => setRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        </Box>
      </Collapse>

      <Grid container sx={{ height: "75%" }}>
        <Grid
          item
          xs={3}
          sx={{
            padding: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#5c5f5d",
          }}
        >
          Ticket Name
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            padding: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#5c5f5d",
          }}
        >
          Ticket Type
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            padding: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#5c5f5d",
          }}
        >
          Used Time
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            padding: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#5c5f5d",
          }}
        >
          Product Name
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            color: "#5c5f5d",
          }}
        >
          No matched data found
        </Grid>
      </Grid>
    </>
  );
};

export default TicketsTable;
