import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Modal,
  Divider,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { format, subDays } from "date-fns";
import "./../css/style.css";
import CasinoIcon from "@mui/icons-material/Casino";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InboxIcon from "@mui/icons-material/Inbox";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import axiosInstance from "../../axiosConfig";

const DepositRecordPageMobile = () => {
  const username = localStorage.getItem("username");
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState("Today");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [initialData, setInitialData] = useState([]);
  const [scrollData, setScrollData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [filterButton, setFilterButton] = useState("All");
  const [filterOpen, setFilterOpen] = useState(false);
  const calendarRef = useRef(null);
  const filterRef = useRef(null);
  const prevStartDate = useRef();
  const prevEndDate = useRef();
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const transactionStatus = {
    0: "Failed",
    1: "Success",
    2: "Pending",
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, page]);

  useEffect(() => {
    setScrollData([...scrollData, ...initialData]);
  }, [initialData]);

  useEffect(() => {
    if (calendarOpen || filterOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarOpen, filterOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight && !loading && hasMore) {
        setPage(page + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  const fetchData = () => {
    setLoading(true);
    axiosInstance
      .get(`player/depositrecords/${username}`, {
        params: {
          page: page,
          pageSize: 5,
          startDate: startDate,
          endDate: endDate,
        },
      })
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          const transformedData = data.map((item) => ({
            ...item,
            trans_state:
              transactionStatus[item.trans_state] || item.trans_state,
          }));
          setInitialData(transformedData);
          setLoading(false);
          setHasMore(true);
        } else {
          setHasMore(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    prevStartDate.current = startDate;
    prevEndDate.current = endDate;
  };

  const handleTodayFilter = () => {
    const today = format(new Date(), "yyyy-MM-dd");
    if (startDate !== today || endDate !== today) {
      setScrollData([]);
      setSelectedFilter("Today");
      setPage(0);
      setStartDate(format(new Date(), "yyyy-MM-dd"));
      setEndDate(format(new Date(), "yyyy-MM-dd"));
    } else {
      setSelectedFilter("Today");
      setPage(0);
      setStartDate(format(new Date(), "yyyy-MM-dd"));
      setEndDate(format(new Date(), "yyyy-MM-dd"));
    }
  };

  const handleYesterdayFilter = () => {
    const yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");
    if (startDate !== yesterday || endDate !== yesterday) {
      setScrollData([]);
      setSelectedFilter("Yesterday");
      setPage(0);
      setStartDate(format(subDays(new Date(), 1), "yyyy-MM-dd"));
      setEndDate(format(subDays(new Date(), 1), "yyyy-MM-dd"));
    } else {
      setSelectedFilter("Yesterday");
      setPage(0);
      setStartDate(format(subDays(new Date(), 1), "yyyy-MM-dd"));
      setEndDate(format(subDays(new Date(), 1), "yyyy-MM-dd"));
    }
  };

  const handle7DaysFilter = () => {
    const sevenDays = format(subDays(new Date(), 6), "yyyy-MM-dd");
    if (startDate !== sevenDays || endDate !== sevenDays) {
      setScrollData([]);
      setSelectedFilter("7-Days");
      setPage(0);
      setStartDate(format(subDays(new Date(), 6), "yyyy-MM-dd"));
      setEndDate(format(new Date(), "yyyy-MM-dd"));
    } else {
      setSelectedFilter("7-Days");
      setPage(0);
      setStartDate(format(subDays(new Date(), 6), "yyyy-MM-dd"));
      setEndDate(format(new Date(), "yyyy-MM-dd"));
    }
  };

  const handleCloseCalendar = () => {
    setPage(0);
    const formatDate = (date) => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };
    if (
      formatDate(dateRange[0].startDate) !== prevStartDate.current ||
      formatDate(dateRange[0].endDate) !== prevEndDate.current
    ) {
      setScrollData([]);
    }
    setStartDate(formatDate(dateRange[0].startDate));
    setEndDate(formatDate(dateRange[0].endDate));
    setCalendarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterOpen(false);
    }
  };

  const handleFilterButton = () => {
    setFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setFilterOpen(false);
  };

  const handleClearButton = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setEndDate(format(new Date(), "yyyy-MM-dd"));
  };

  const handleBackButton = () => {
    navigate("/m/member/home");
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "14.5%", fontSize: "3.8vw" }}>
                Deposit Record
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "3.5%" }}>
          <Grid
            onClick={selectedFilter !== "Today" ? handleTodayFilter : undefined}
            container
            xs={4}
            sm={4}
            sx={{
              justifyContent: "center",
              borderBottom:
                selectedFilter === "Today"
                  ? ".6vw solid #1c93ea"
                  : ".1vw solid #dddddd",
              paddingBottom: "1.7%",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.8vw",
                color: selectedFilter === "Today" ? "#1c93ea" : "#000000",
              }}
            >
              Today
            </Typography>
          </Grid>
          <Grid
            onClick={
              selectedFilter !== "Yesterday" ? handleYesterdayFilter : undefined
            }
            container
            xs={4}
            sm={4}
            sx={{
              justifyContent: "center",
              borderBottom:
                selectedFilter === "Yesterday"
                  ? ".6vw solid #1c93ea"
                  : ".1vw solid #dddddd",
              paddingBottom: "1.7%",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.8vw",
                color: selectedFilter === "Yesterday" ? "#1c93ea" : "#000000",
              }}
            >
              Yesterday
            </Typography>
          </Grid>
          <Grid
            onClick={
              selectedFilter !== "7-Days" ? handle7DaysFilter : undefined
            }
            container
            xs={4}
            sm={4}
            sx={{
              justifyContent: "center",
              borderBottom:
                selectedFilter === "7-Days"
                  ? ".6vw solid #1c93ea"
                  : ".1vw solid #dddddd",
              paddingBottom: "1.7%",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.8vw",
                color: selectedFilter === "7-Days" ? "#1c93ea" : "#000000",
              }}
            >
              7-Days
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ marginTop: "1.2%", height: "8vw", marginBottom: "11.4%" }}
        >
          <Grid onClick={handleFilterButton} container xs={6} sm={6}>
            <Grid
              container
              sx={{
                border: ".4vw solid #1c93ea",
                borderRadius: "1vw",
                width: "27%",
                height: "100%",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "8%",
              }}
            >
              <Typography
                sx={{ fontSize: "3.3vw", color: "#1c93ea", marginTop: "4.5%" }}
              >
                {filterButton}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
            container
            xs={6}
            sm={6}
          >
            <Grid
              container
              sx={{
                border: ".4vw solid #1c93ea",
                borderRadius: "1vw",
                width: "56%",
                alignContent: "center",
                justifyContent: "center",
                marginLeft: "-63%",
              }}
            >
              <CalendarMonthIcon
                sx={{ marginLeft: "-5%", fontSize: "4.5vw", color: "#1c93ea" }}
              />
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                  marginTop: "1.5%",
                  marginLeft: "5%",
                }}
              >
                {startDate.substring(5, 10).replace(/-/g, "/")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                  marginTop: "1.5%",
                }}
              >
                -
              </Typography>
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                  marginTop: "1.5%",
                }}
              >
                {endDate.substring(5, 10).replace(/-/g, "/")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {scrollData.map((row, index) => (
          <Grid container sx={{ marginBottom: "7%" }}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                marginLeft: "4.5%",
                marginRight: "5%",
                marginBottom: "2%",
                backgroundColor: "#f6f6f6",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  backgroundColor: "#FFFFFF",
                  paddingBottom: "1%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "4vw",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  {row.payment_provider?.name ?? ""}
                  </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    zIndex: 1,
                    color: "#333333",
                    fontWeight: "100",
                  }}
                >
                  {row.created_at.substring(0, 19).replace("T", " ")}
                </Typography>
              </Grid>
              <Grid item sx={{ marginLeft: "2.5%", marginTop: "3%" }}>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                    marginBottom: "4%",
                    marginTop: "2%",
                  }}
                >
                  Deposit ref#: {row.trans_id}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                    marginBottom: "4%",
                  }}
                >
                  Postscript:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                    marginBottom: "4%",
                  }}
                >
                  Received time: -
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                    marginBottom: "4%",
                  }}
                >
                  Handling fee: -
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                    marginBottom: "3.5%",
                  }}
                >
                  Activity: -
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                    marginBottom: "1%",
                  }}
                >
                  Remarks: -
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12}>
              <Grid item xs={4} sm={4} sx={{ flexDirection: "column" }}>
                <Typography sx={{ fontSize: "3.9vw", marginLeft: "20%" }}>
                  Request
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    marginLeft: "20%",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {row.real_amount}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "3.9vw",
                    textAlign: "center",
                  }}
                >
                  Received amount
                </Typography>
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "3.9vw",
                    textAlign: "center",
                  }}
                >
                  -
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "3.9vw",
                    textAlign: "center",
                    marginLeft: "-37%",
                  }}
                >
                  Status
                </Typography>
                <Typography
                  sx={{
                    color: "#1c93ea",
                    fontSize: "3.9vw",
                    textAlign: "center",
                    marginLeft: "-25%",
                  }}
                >
                  {row.trans_state}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
          </Grid>
        ))}
        {calendarOpen && (
          <Modal
            open={calendarOpen}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              outline: "none",
              height: "100%",
            }}
          >
            <Grid
              ref={calendarRef}
              container
              sx={{
                backgroundColor: "#FFFFFF",
                outline: "none",
              }}
            >
              <Grid
                ref={calendarRef}
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "2%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#898989",
                    fontWeight: "bold",
                    marginLeft: "36%",
                  }}
                >
                  {startDate.substring(5, 10).replace(/-/g, "/")}{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#898989",
                    fontWeight: "bold",
                    marginLeft: "3%",
                  }}
                >
                  -
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#898989",
                    fontWeight: "bold",
                    marginLeft: "3%",
                  }}
                >
                  {endDate.substring(5, 10).replace(/-/g, "/")}{" "}
                </Typography>
                <Typography
                  onClick={handleClearButton}
                  sx={{
                    fontSize: "4vw",
                    color: "#4c84f7",
                    marginLeft: "23%",
                  }}
                >
                  Clear
                </Typography>
              </Grid>
              <Divider
                sx={{ width: "100%", marginTop: ".5%", height: "100%" }}
              />
              <Grid item xs={12} sm={12} sx={{ height: "100%", width: "100%" }}>
                <DateRange
                  onChange={(item) => setDateRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  showDateDisplay={false}
                  months={1}
                  className="calendarElement"
                  showMonthAndYearPickers={false}
                />
              </Grid>
              <Grid
                onClick={handleCloseCalendar}
                item
                xs={12}
                sm={12}
                sx={{
                  height: "10vw",
                  backgroundColor: "#4c84f7",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "4vw",
                    color: "#FFFFFF",
                    textAlign: "center",
                    marginTop: "2%",
                  }}
                >
                  OK
                </Typography>
              </Grid>
            </Grid>
          </Modal>
        )}
        {filterOpen && (
          <Modal
            open={filterOpen}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              marginTop: "auto",
              outline: "none",
              height: "100%",
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                outline: "none",
              }}
            >
              <Grid
                ref={filterRef}
                item
                xs={12}
                sm={12}
                sx={{
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "4vw",
                    color: "#9e9e9e",
                    marginLeft: "3%",
                  }}
                >
                  Status
                  <CloseIcon
                    onClick={handleCloseFilter}
                    sx={{
                      marginTop: "2%",
                      fontSize: "5vw",
                      color: "#888888",
                      marginLeft: "80%",
                    }}
                  />
                </Typography>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />
                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <CasinoIcon
                    sx={{ color: "#888888", marginLeft: "3%", fontSize: "7vw" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    All
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />
                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <CasinoIcon
                    sx={{ color: "#888888", marginLeft: "3%", fontSize: "7vw" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Processing
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />
                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <CasinoIcon
                    sx={{ color: "#888888", marginLeft: "3%", fontSize: "7vw" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Transferring
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />

                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <CasinoIcon
                    sx={{ color: "#888888", marginLeft: "3%", fontSize: "7vw" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Audit Failure
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />

                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <CasinoIcon
                    sx={{ color: "#888888", marginLeft: "3%", fontSize: "7vw" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Withdraw Success
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />

                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <CasinoIcon
                    sx={{ color: "#888888", marginLeft: "3%", fontSize: "7vw" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Withdraw Failed
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        )}
        {loading && (
          <Modal
            open={loading}
            BackdropProps={{
              style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              outline: "none",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#4d4d4d",
                opacity: "90%",
                p: 3,
                borderRadius: "5%",
                textAlign: "center",
                flexDirection: "column",
                outline: "none",
              }}
            >
              <CircularProgress sx={{ color: "#FFFFFF", fontSize: "13vw" }} />
            </Box>
          </Modal>
        )}
        {scrollData.length === 0 && !loading && (
          <Grid
            container
            sx={{
              marginTop: "30%",
              justifyContent: "center",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <InboxIcon sx={{ color: "#1296db", fontSize: "40vw" }} />
            <Typography
              sx={{ textAlign: "center", color: "#1296db", fontSize: "6vw" }}
            >
              No Data
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DepositRecordPageMobile;
