import { useState } from "react";
import { Tab, Toolbar, styled } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
// import AllRecords from "./transaction_records/AllRecords";
import AllBettingRecords from "./AllBettingRecord";

const StyledTab = styled(Tab)({
  textTransform: "none",
  "&.Mui-selected": {
    color: "red",
  },
});

const StyledTabList = styled(TabList)({
  ".MuiTabs-indicator": {
    backgroundColor: "red",
  },
});

const BettingRecord = () => {
  // Initialization
  const defaultTransactionType = "transaction_records";

  const [selectedTransactionType, setSelectedTransactionType] = useState(
    defaultTransactionType
  );

  const handleChangeTransactionTab = (event, newValue) => {
    setSelectedTransactionType(newValue);
  };

  return (
    <>
      <TabContext
        value={selectedTransactionType}
        textColor="danger" //red
      >
        <StyledTabList onChange={handleChangeTransactionTab}>
          <StyledTab label="Bingo" value="transaction_records" />
          <StyledTab label="Slot" value="deposit_records" />
          <StyledTab label="Live" value="withdrawal_records" />
          <StyledTab label="Fishing" value="Fishing" />
          <StyledTab label="PVP" value="PVP" />
        </StyledTabList>
      </TabContext>

      <Toolbar variant="dense">
        {selectedTransactionType && (
          <AllBettingRecords
            selectedTransactionType={selectedTransactionType}
          />
        )}
      </Toolbar>
    </>
  );
};
export default BettingRecord;
