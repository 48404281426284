import React from "react";
import ResetPasswordForm from "./components/ResetPasswordForm";

const ResetPassword = ({
  closeForgotPassword,
  open,
  setShowForgotPassword,
}) => {

  return (
    <>
      <ResetPasswordForm
        setShowForgotPassword={setShowForgotPassword}
        // data={data}
        // isLoading={isLoading}
        closeForgotPassword={closeForgotPassword}
        open={open}
      />
    </>
  );
};

export default ResetPassword;
