import { Box, Typography, styled } from "@mui/material";

const NotesContainer = styled(Box)({
  border: "1px solid #f9dacb",
  borderRadius: "12px",
  background: "linear-gradient(90deg,rgba(255,253,251,.2) 0,#ffe7d6 100%)",
  padding: "15px",
  width: "288px",
});

const NotesHeader = styled(Typography)({
  backgroundColor: "#ffe6d1",
  borderRadius: "11px 0 11px 0",
  paddingY: "5px",
  width: "125px",
  color: "#f55600",
  textAlign: "center",
  paddingRight: "5px",
});

const DurationHeader = styled(Typography)({
  fontSize: "0.95rem",
  fontWeight: "bold",
  color: "#FF0000",
  textAlign: "start",
});

const TipsHeader = styled(Typography)({
  backgroundColor: "#d1e3ff",
  borderRadius: "11px 0 11px 0",
  paddingY: "2.5px",
  color: "#2a74e7",
  textAlign: "center",
  minWidth: "68px",
  maxHeight: "21px",
  marginRight: "5px",
});

const NotesBody = styled(Typography)({
  color: "#535353",
  lineHeight: "1.5",
  letterSpacing: "1px",
  fontSize: "0.9rem",
});

const NotesBody2 = styled(Typography)();

export default function WithdrawalNotes() {
  return (
    <NotesContainer>
      <NotesHeader variant="body2">Withdrawal Time :</NotesHeader>
      <DurationHeader variant="body2">00:00 - 23:59</DurationHeader>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <TipsHeader variant="body2">Tips</TipsHeader>
        <NotesBody>Note: For withdrawal</NotesBody>
        <NotesBody>
          requests, please allow 24-48 hours upon the submission of your
          request. Due to security reasons, please take note that 1 withdrawal
          request is permitted per hour at a time.
        </NotesBody>
      </Box>
    </NotesContainer>
  );
}
