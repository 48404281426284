import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createDeposit = createAsyncThunk(
  "player/deposit/create",
  async (id) => {
    try {
      return id;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  deposit: {
    amount: "",
    promotion: "",
    provider: "",
    data: [],
  },
};

const depositsSlice = createSlice({
  name: "deposits",
  initialState,
  reducers: {
    setAmount: (state, action) => {
      state.deposit.amount = action.payload;
    },
    setPromotion: (state, action) => {
      state.deposit.promotion = action.payload;
    },
    setProvider: (state, action) => {
      state.deposit.provider = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setAmount, setPromotion, setProvider } = depositsSlice.actions;
export default depositsSlice.reducer;
