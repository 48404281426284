import React from "react";
import { Typography } from "@mui/material";

const Cookies = () => {
  return (
    <>
      <Typography
        id="about-modal"
        variant="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        COOKIE POLICY OF FBM E-MOTION
      </Typography>
      <Typography sx={{ marginBottom: "40px", textAlign: "center" }}>
        (Last updated 17/11/2022)
      </Typography>
      <Typography
        variant="body1"
        sx={{ display: "inline", textAlign: "justify" }}
      >
        Please take the time to read and understand this Cookie Policy ("Cookie
        Policy") which has been provided for Your information, as a User ("You"
        or "User") of the FBM E-Motion Website https://fbmemotion.ph/en-ph
        ("Website"), for a clarity and transparency purposes on how We collect
        and use cookies and pixels.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        WHAT ARE COOKIES
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The Website uses "cookies" to make the Website experience better. A
        "cookie" is a small piece of text that may be sent from your computer,
        via the web browser, to the Website. These allow the Website to collect
        information about Your visit are being used and to manage them more
        efficiently.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "40px", textAlign: "justify" }}
      >
        None of the cookies We use to collect your personal information (e.g.
        names, addresses, telephone numbers, email addresses) can be used to
        identify You as an individual. Cookies typically collect anonymous
        identifiers associated with your device, browser, referring site URLs,
        time or usage information, Website preferences, settings, etc. (as
        further provided in this Policy).
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "40px", textAlign: "justify" }}
      >
        We do not, under any circumstance, sell the information collected via
        cookies.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Cookies can be classified as either "session" or "persistent", depending
        on how long they last after they are placed on Your browser. Session
        cookies last for as long as You keep your browser open. They expire when
        You close Your browser. Persistent cookies expire at a fixed point of
        time or if You manually delete them from Your browser, whichever occurs
        first.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        HOW TO MANAGE COOKIES
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Should You wish to reject all or certain cookies used by our Website,
        You may modify your Web browser preferences to do so. If, however, You
        reject all cookies then you might be unable to use some of the services
        available on Our Website. You may also set your browser to notify You
        when You receive a cookie, giving You the opportunity to choose whether
        or not You wish to accept it. In the case of some mobile devices, it may
        be necessary to consult the device’s instruction manual to manage
        cookies effectively. You should note however that if you do so, this may
        distort the quality of service and data you receive.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        BROWSER-SPECIFIC COOKIE GUIDE
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        In certain instances, We use third-party cookies, which are cookies
        provided by parties other than Us. We always ensure that these parties
        are reputable and will respect Your privacy. You can learn how to clear
        cookies and cache for your specific web browser by clicking one of the
        below:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Google Chrome → Clear cache & cookies - Computer - Google Account Help
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Mozilla Firefox → How to clear the Firefox cache | Firefox Help
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Safari → Clear the history and cookies from Safari on your iPhone, iPad,
        or iPod touch
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Microsoft Edge → Delete cookies in Microsoft Edge
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Alternatively, All About Cookies (www.allaboutcookies.org) provides
        advice on how to do this, along with further information regarding
        cookies and how to manage them.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        If You wish to disconnect cookies of specific parties, You can do this
        through Your Online Choices.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Please note that We aren't responsible for the content of external
        websites. If You have any questions, please contact Us at
        csupport@fbmemotion.ph
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        WHY WE USE COOKIES
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We use cookies for a number of reasons, including, but not limited to
        the following:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Assisting new customers during the sign-up process.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Allowing customers to set Website preferences.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Allowing customers to navigate between pages of the Website more
        efficiently.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Running the functionality of the Website optimally and efficiently.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Activating certain features of the Website.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gathering information about Customer activity on the Website and
        understanding how it is used.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Detecting and preventing fraudulent use of the Website.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Tracking how Customers arrive at Our Website for internal marketing
        purposes and to track referrals.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Delivering relevant advertising to Our Customers on the websites and
        mobile applications.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Research and analytical purposes to deduce which Customers interact with
        the Website.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        CONTACT
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        If You have questions, comments, requests, or complaints about how
        cookies have been managed by FBM E-Motion, the first thing You can do is
        raise any concerns with Our highly-trained Customer Support team at
        csupport@fbmemotion.ph to try and resolve them.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We will then look into your issue to ensure your information has been
        handled appropriately and in accordance with this Cookie Notice, Our
        legal obligations, and Our internal policies and procedures. We will
        then contact You regarding the outcome of the investigation and any
        steps that have been taken as a result.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        CHANGES TO COOKIE POLICY
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We may update this Cookie Policy from time to time. If We make
        significant changes, we will let You know but please regularly check
        this Cookie Policy to ensure You are aware of the most updated version.
      </Typography>
    </>
    // </CustomModal>
  );
};

export default Cookies;
