import React, { useState } from "react";
import { Stack } from "@mui/material";
import BindEWallet from "../withdraw/components/BindEWallet";

export default function AccountManagement() {
  const [isAcoountManagement] = useState(true);
  return (
    <Stack sx={{ width: "820px", height: "650px" }}>
      <BindEWallet isAcoountManagement={isAcoountManagement} />
    </Stack>
  );
}
