import { makeStyles } from "@mui/styles";

const depositStyles = makeStyles(() => ({
  cardContent: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: "100%",
    backgroundColor: "#fff",
    margin: "5px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    // border: "1px solid rgb(255, 0, 0)",
    padding: "20px 20px",
  },
  overlayIcon: {
    position: "absolute",
    bottom: -3,
    right: 0,
    zIndex: "1",
  },
  viewAllButton: {
    position: "absolute",
    top: "0px",
    right: 0,
    zIndex: "1",
  },
}));

export default depositStyles;
