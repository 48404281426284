import "../style.css";
import { usePlayer } from "../../../../contexts/PlayerContext";
import { useState } from "react";
import FavoriteModal from "../../Buttons/PromptFavoriteModal";
import { Typography } from "@mui/material";
import axiosInstance from "../../../../axiosConfig";

export default function Game({
  gameId,
  data,
  openLogin,
  setGameIframe,
  setGameIframeUrl,
}) {
  const { isMobile } = usePlayer();
  const pxToVw = (pxWidth) => {
    const screenWidth = window.innerWidth;
    return (pxWidth / screenWidth) * 100;
  };

  const widthInFontSize = pxToVw(14);
  const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const username = localStorage.getItem("username");
  const authToken = localStorage.getItem("authToken");

  const handleGame = (game) => {
    if (username) {
      axiosInstance
        .post(`player/game/launch`, {
          user: username,
          token: authToken,
          game_provider_id: game.provider_id,
          game_code: game.code,
        })
        .then((res) => {
          setGameIframe(true);
          setGameIframeUrl(res.data.url);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      openLogin();
    }
  };

  return (
    <>
      {isMobile ? (
        <div key={gameId} className="game-container">
          <div className="img-container">
            <div className="game-logo-container">
              <img
                style={{ width: "20px" }}
                alt="Game Provider Icon"
                src={`${IMAGE_BASE_URL}/game_provider/${data.provider_id}.png`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}/gameprovider-default.png`;
                }}
              />
            </div>
            <img
              className="game-img"
              src={`${IMAGE_BASE_URL}/${data.mobile_icon}`}
              alt={`${data.title}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/gameicon-default.png`;
              }}
              style={{ width: "100%" }}
            />
            <button
              formTarget="_blank"
              onClick={() => handleGame(data)}
              className="play-now"
            >
              Play Now
            </button>
          </div>
          <Typography
            variant="body1"
            sx={{
              color: "#ffffff",
              textAlign: "center",
              marginTop: "-50px",
              marginBottom: "10px",
              fontSize: `${widthInFontSize}vw`,
            }}
          >
            {data.title}
          </Typography>
          {showModal && (
            <FavoriteModal open={showModal} onClose={handleCloseModal} />
          )}
        </div>
      ) : (
        <div key={gameId} className="game-container">
          <div className="img-container">
            <div className="game-logo-container">
              <img
                alt="Game Provider Icon"
                src={`${IMAGE_BASE_URL}/game_provider/${data.provider_id}.png`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}/gameprovider-default.png`;
                }}
              />
            </div>
            <img
              className="game-img"
              src={`${IMAGE_BASE_URL}/${data.desktop_icon}`}
              alt={`${data.title}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/gameicon-default.png`;
              }}
            />
            <button
              formTarget="_blank"
              onClick={() => handleGame(data)}
              className="play-now"
            >
              Play Now
            </button>
          </div>
          <p className="game-name">{data.title}</p>
          {showModal && (
            <FavoriteModal open={showModal} onClose={handleCloseModal} />
          )}
        </div>
      )}
    </>
  );
}
