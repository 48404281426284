import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { addDays } from "date-fns";
import { format } from "date-fns";
import "./../../css/style.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import axiosInstance from "../../../axiosConfig";
import { usePlayer } from "../../../contexts/PlayerContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import BettingRecordFilter from "./BettingRecordFilter";
import BettingRecordCalendar from "./BettingRecordCalendar";
import BettingRecordStatusFilter from "./BettingRecordStatusFilter";
import CircleIcon from "@mui/icons-material/Circle";
import InboxIcon from "@mui/icons-material/Inbox";

const BettingRecordsMobile = () => {
  const navigate = useNavigate();
  const { isMobile } = usePlayer();

  const [selectedCategory, setSelectedCategories] = useState("All");
  const [loading, setLoading] = useState(false);
  const [gameTypes, setGameTypes] = useState([]);
  const [error, setError] = useState(null);
  const [gameProviderOpen, setGameProviderOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const calendarRef = useRef(null);
  const filterRef = useRef(null);
  const statusRef = useRef(null);
  const prevStartDate = useRef();
  const prevEndDate = useRef();
  const [page, setPage] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollData, setScrollData] = useState([]);
  const [statusFilterOpen, setStatusFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("Unsettled");
  const [providers, setProviders] = useState([]);
  const [gameProvider, setGameProvider] = useState("All");
  const [betAmount, setBetAmount] = useState("");
  const [validBet, setValidBet] = useState("");
  const [profitLoss, setProfitLoss] = useState("");
  const [winAmount, setWinAmount] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, page, statusFilter, gameProvider, selectedCategory]);

  useEffect(() => {
    setScrollData([...scrollData, ...initialData]);
  }, [initialData]);

  useEffect(() => {
    const fetchGameTypes = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = {};
        params.device = "web";
        if (isMobile) {
          params.device = "mobile";
        }

        const response = await axiosInstance.get(`game/list/type`, {
          params,
        });

        setGameTypes(response.data);
      } catch (error) {
        setError("Error fetching game type");
        console.error("Error fetching game type:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchGameTypes();
  }, [isMobile]);

  useEffect(() => {
    if (calendarOpen || gameProviderOpen || statusFilterOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarOpen, gameProviderOpen, statusFilterOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight && !loading && hasMore) {
        setPage(page + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  const fetchData = () => {
    setLoading(true);
    axiosInstance
      .get(`player/game/logs`, {
        params: {
          page: page,
          pageSize: 5,
          startDate: startDate,
          endDate: endDate,
          status: statusFilter,
          provider: gameProvider === "All" ? "" : gameProvider,
          game_type: selectedCategory === "All" ? "" : selectedCategory,
        },
      })
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setInitialData(data);
          setBetAmount(response.data.bet_amount);
          setValidBet(response.data.valid_bet_amount);
          setProfitLoss(response.data.profit_and_loss);
          setWinAmount(response.data.win_amount);
          setLoading(false);
          setHasMore(true);
        } else {
          setHasMore(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    prevStartDate.current = startDate;
    prevEndDate.current = endDate;
  };

  const handleCloseCalendar = () => {
    setPage(0);
    const formatDate = (date) => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };
    if (
      formatDate(dateRange[0].startDate) !== prevStartDate.current ||
      formatDate(dateRange[0].endDate) !== prevEndDate.current
    ) {
      setScrollData([]);
    }
    setStartDate(formatDate(dateRange[0].startDate));
    setEndDate(formatDate(dateRange[0].endDate));
    setCalendarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setGameProviderOpen(false);
    }
    if (statusRef.current && !statusRef.current.contains(event.target)) {
      setStatusFilterOpen(false);
    }
  };

  const handleStatusFilter = () => {
    setStatusFilterOpen(true);
  };

  const handleCloseStatusFilter = () => {
    setStatusFilterOpen(false);
  };

  const handleTypes = (data) => {
    setPage(0);
    setScrollData([]);
    setStatusFilter(data);
    setStatusFilterOpen(false);
  };

  const handleProvider = (data) => {
    setPage(0);
    setScrollData([]);
    setGameProvider(data);
    setGameProviderOpen(false);
  };

  const handleCategories = (e) => {
    setPage(0);
    setScrollData([]);
    setSelectedCategories(e);
  };

  const handleGameProviderOpen = () => {
    setGameProviderOpen(true);
  };

  const handleCloseFilter = () => {
    setGameProviderOpen(false);
  };

  const handleClearButton = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setEndDate(format(new Date(), "yyyy-MM-dd"));
  };

  const handleBackButton = () => {
    navigate("/m/member/home");
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "14.5%", fontSize: "3.8vw" }}>
                Betting Record
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "3%" }}>
          <Swiper
            modules={[FreeMode, Pagination]}
            freeMode={true}
            spaceBetween={1}
            slidesPerView={4}
            style={{
              justifyContent: "center",
              paddingBottom: "1.7%",
            }}
          >
            <Grid container xs={4} sm={4}>
              <SwiperSlide
                onClick={() => handleCategories("All")}
                style={{
                  borderBottom:
                    selectedCategory === "All"
                      ? ".6vw solid #1c93ea"
                      : ".1vw solid #dddddd",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "3.8vw",
                    color: selectedCategory === "All" ? "#1c93ea" : "#000000",
                    marginBottom: "8%",
                  }}
                >
                  All
                </Typography>
              </SwiperSlide>
            </Grid>
            {gameTypes.map((row, index) => (
              <>
                <Grid container xs={4} sm={4}>
                  <SwiperSlide
                    onClick={() => handleCategories(row.game_type_name)}
                    key={row.id}
                    style={{
                      borderBottom:
                        selectedCategory === row.game_type_name
                          ? ".6vw solid #1c93ea"
                          : ".1vw solid #dddddd",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "3.8vw",
                        color:
                          selectedCategory === row.game_type_name
                            ? "#1c93ea"
                            : "#000000",
                        marginBottom: "8%",
                      }}
                    >
                      {row.game_type_name}
                    </Typography>
                  </SwiperSlide>
                </Grid>
              </>
            ))}
          </Swiper>
        </Grid>
        <Grid container sx={{ height: "8vw", marginBottom: "5%" }}>
          <Grid
            onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
            sx={{ marginLeft: "4%", marginRight: "3%" }}
          >
            <Grid
              sx={{
                border: ".4vw solid #1c93ea",
                borderRadius: "1vw",
                display: "flex",
                width: "28vw",
                height: "8vw",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CalendarMonthIcon sx={{ fontSize: "4.5vw", color: "#1c93ea" }} />
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                }}
              >
                {startDate.substring(5, 10).replace(/-/g, "/")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                }}
              >
                -
              </Typography>
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                }}
              >
                {endDate.substring(5, 10).replace(/-/g, "/")}
              </Typography>
            </Grid>
          </Grid>
          <Grid onClick={handleGameProviderOpen} sx={{ marginRight: "3%" }}>
            <Grid
              sx={{
                border: ".4vw solid #1c93ea",
                borderRadius: "1vw",
                width:
                  gameProvider === "All"
                    ? "13vw"
                    : gameProvider === "Hub 88"
                    ? "20vw"
                    : "30vw",
                height: "8vw",
                display: "flex",
              }}
            >
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  fontSize: "6vw",
                  color: "#1c93ea",
                  marginTop:
                    gameProvider === "All" || gameProvider === "Hub 88"
                      ? "4%"
                      : "2%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color: "#1c93ea",
                  marginTop:
                    gameProvider === "All"
                      ? "8%"
                      : gameProvider === "Hub 88"
                      ? "6%"
                      : "4%",
                }}
              >
                {gameProvider}
              </Typography>
            </Grid>
          </Grid>
          <Grid onClick={handleStatusFilter}>
            <Grid
              sx={{
                border: ".4vw solid #1c93ea",
                borderRadius: "1vw",
                width:
                  statusFilter === "Unsettled"
                    ? "23vw"
                    : statusFilter === "Settled"
                    ? "20vw"
                    : "15vw",
                height: "8vw",
                display: "flex",
              }}
            >
              <KeyboardArrowDownOutlinedIcon
                sx={{ fontSize: "6vw", color: "#1c93ea", marginTop: "2%" }}
              />
              <Typography
                sx={{ fontSize: "3.3vw", color: "#1c93ea", marginTop: "4%" }}
              >
                {statusFilter}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {scrollData.map((row, index) => (
          <Grid
            container
            sx={{
              marginBottom: index === scrollData.length - 1 ? "30%" : "7%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                marginLeft: "4.5%",
                marginRight: "5%",
                marginBottom: "2%",
                backgroundColor: "#f6f6f6",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  backgroundColor: "#FFFFFF",
                  paddingBottom: "1%",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    paddingBottom: "1%",
                  }}
                >
                  <CircleIcon
                    sx={{ fontSize: "3vw", color: "#108ee9", marginTop: "5%" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "4vw",
                      color: "#333333",
                      fontWeight: "bold",
                      marginLeft: "2%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {row.game_name}
                  </Typography>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    zIndex: 1,
                    color: "#333333",
                    fontWeight: "100",
                  }}
                >
                  {row.created_at.substring(0, 19).replace("T", " ")}
                </Typography>
              </Grid>
              <Grid item sx={{ marginLeft: "2.5%", marginTop: "3%" }}>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                  }}
                >
                  Order no.:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.9vw",
                    color: "#333333",
                  }}
                >
                  {row.transaction_id}
                </Typography>
                <Grid container>
                  <Grid item sx={{ marginTop: "3%" }} xs={6} sm={6}>
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#333333",
                      }}
                    >
                      Bet Amount: {row.bet_amount}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: "3%" }} xs={6} sm={6}>
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#333333",
                      }}
                    >
                      Valid Bet: {row.valid_bet_amount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sx={{ marginTop: "3%" }} xs={6} sm={6}>
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#333333",
                      }}
                    >
                      Profit and loss
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: "3%" }} xs={6} sm={6}>
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#333333",
                      }}
                    >
                      Winnings
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "red",
                        marginBottom: "4%",
                        fontWeight: "bold",
                      }}
                    >
                      {row.profit_and_loss}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#333333",
                        marginBottom: "4%",
                        fontWeight: "bold",
                      }}
                    >
                      {row.win_amount === "0.00" ? "-" : row.win_amount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid
          container
          sx={{
            backgroundColor: "#e8e8e8",
            position: "fixed",
            height: "25vw",
            bottom: 0,
            zIndex: 2,
          }}
        >
          <Grid container sx={{ marginLeft: "10vw", marginTop: "3vw" }}>
            <Grid xs={6} sm={6}>
              <Typography sx={{ fontSize: "3.5vw" }}>Bet Amount:</Typography>
              <Typography
                sx={{ fontSize: "3.5vw", fontWeight: "bold", color: "red" }}
              >
                {betAmount ? betAmount : 0.0}
              </Typography>
            </Grid>
            <Grid xs={6} sm={6}>
              <Typography sx={{ fontSize: "3.5vw" }}>Valid Bet:</Typography>
              <Typography
                sx={{ fontSize: "3.5vw", fontWeight: "bold", color: "red" }}
              >
                {validBet ? validBet : 0.0}
              </Typography>
            </Grid>
            <Grid xs={6} sm={6}>
              <Typography sx={{ fontSize: "3.5vw" }}>Winnings:</Typography>
              <Typography
                sx={{ fontSize: "3.5vw", fontWeight: "bold", color: "red" }}
              >
                {winAmount ? winAmount : 0.0}
              </Typography>
            </Grid>
            <Grid xs={6} sm={6}>
              <Typography sx={{ fontSize: "3.5vw" }}>
                Profit and Loss:
              </Typography>
              <Typography
                sx={{ fontSize: "3.5vw", fontWeight: "bold", color: "red" }}
              >
                {profitLoss ? profitLoss : 0.0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {calendarOpen && (
          <BettingRecordCalendar
            calendarOpen={calendarOpen}
            calendarRef={calendarRef}
            startDate={startDate}
            endDate={endDate}
            handleClearButton={handleClearButton}
            setDateRange={setDateRange}
            dateRange={dateRange}
            handleCloseCalendar={handleCloseCalendar}
          />
        )}
        {gameProviderOpen && (
          <BettingRecordFilter
            gameProviderOpen={gameProviderOpen}
            filterRef={filterRef}
            handleCloseFilter={handleCloseFilter}
            setProviders={setProviders}
            providers={providers}
            handleProvider={handleProvider}
          />
        )}
        {statusFilterOpen && (
          <BettingRecordStatusFilter
            statusFilterOpen={statusFilterOpen}
            handleCloseStatusFilter={handleCloseStatusFilter}
            statusRef={statusRef}
            handleTypes={handleTypes}
          />
        )}
        {scrollData.length === 0 && (
          <Grid
            container
            sx={{
              marginTop: "30%",
              justifyContent: "center",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <InboxIcon sx={{ color: "#1296db", fontSize: "40vw" }} />
            <Typography
              sx={{ textAlign: "center", color: "#1296db", fontSize: "6vw" }}
            >
              No Data
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BettingRecordsMobile;
