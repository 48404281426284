import { createSlice } from "@reduxjs/toolkit";

// async request

const initialState = {
  paymentProvidedrs: [],
  status: "idle",
  error: null,
};

const paymentProvidersSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
});

export default paymentProvidersSlice.reducer;
