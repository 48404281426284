import { Button, Grid, Typography } from "@mui/material";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import Game from "./components/Game";

export default function GameSection({
  description,
  tag,
  games,
  onClickViewAll,
  openLogin,
  setGameIframe,
  setGameIframeUrl,
}) {
  const gameCards = games.map((game, key) => {
    return (
      <>
        <SwiperSlide key={key} className="swiper-slide-container">
          <Game
            key={game.id}
            data={game}
            openLogin={openLogin}
            setGameIframe={setGameIframe}
            setGameIframeUrl={setGameIframeUrl}
          />
        </SwiperSlide>
      </>
    );
  });

  return (
    <Grid className="game-contents">
      <Grid className="game-list-header">
        <Typography sx={{ color: "#FFFFFF" }}>{description}</Typography>
        <Button
          variant="filled"
          sx={{
            background: "#16213e",
            color: "#FFFFFF",
            border: "none",
            borderRadius: "18px",
            width: "6rem",
            height: "2.4rem",
            fontWeight: "bold",
            fontSize: "15px",
            textTransform: "none",
            "&:hover": {
              background: "#16213e",
              color: "#FFFFFF",
            },
          }}
          onClick={() => onClickViewAll(tag)}
        >
          View All
        </Button>
      </Grid>
      <Grid className="game-list">
        <Swiper
          slidesPerView={gameCards.length <= 6 ? gameCards.length : 5.99}
          spaceBetween={1}
          freeMode={true}
          modules={[FreeMode, Pagination]}
          className="swiperjs hot-games-swipper"
        >
          {gameCards.slice(0, 6)}
        </Swiper>
      </Grid>
    </Grid>
  );
}
