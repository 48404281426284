import { useState } from "react";
import { TextField, Typography, Grid, Button, Dialog, DialogTitle, DialogContentText, DialogActions } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import axiosInstance from "../../../axiosConfig";

export default function ResetPasswordEmail({ setUsername = "", setEmail = "" }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([null]);
  const [values, setValues] = useState({
    username: setUsername,
    email: setEmail
  });

  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value
    setValues(values => ({
      ...values,
      [key]: value,
    }))
  }

  const submit = (e) => {
    e.preventDefault();
    axiosInstance.post(`player/request-email-otp`, {
      username: values.username,
      email: values.email
    })
      .then(function (status) {
        setStatus(status.data.message)
        handleClickOpen()
      })
      .catch(function (errors) {
        setStatus(errors.response.data.message)
        handleClickOpen()
      })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStatus([null])
    setOpen(false);
  };

  return (
    <>
      <form onSubmit={submit} id="form">
        <Grid container justifyContent="center" gap={4} >
          <Grid item xs="10" >
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <span style={{ color: "red" }}>*</span> Username
            </Typography>
            <TextField id="username"
              sx={{
                height: "auto",
                borderRadius: "40px",
                backgroundColor: "#16213e",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent', // Removes the border
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent', // Removes the hover border
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // Removes the focused border
                  },
                  '& input': {
                    color: 'white', // Optional: sets the text color inside the input
                  },
                },
              }}

              focused={false}
              fullWidth
              required
              value={values.username}
              onChange={handleChange}
            />
            <Typography
              sx={{
                color: "#555556",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Please enter 6 - 13 alphanumeric without special character.
            </Typography>
          </Grid>
          <Grid item xs="10">
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <span style={{ color: "red" }}>*</span> Email
            </Typography>
            <TextField id="email"
              sx={{
                height: "auto",
                borderRadius: "40px",
                backgroundColor: "#16213e",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent', // Removes the border
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent', // Removes the hover border
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // Removes the focused border
                  },
                  '& input': {
                    color: 'white', // Optional: sets the text color inside the input
                  },
                },
              }}
              focused={false}
              fullWidth
              required
              value={values.email}
              onChange={handleChange}
            />
            <Typography
              sx={{
                color: "#555556",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Please enter 1 - 255 characters in mailbox format
            </Typography>
          </Grid>
          <Button

            variant="contained"
            sx={{
              backgroundColor: "#da1212",
              borderRadius: "60px",
              width: "70%",
              fontWeight: "bold",
              fontSize: "24px",
              height: "44px",
              "&:hover": {
                background: "#da1212",
              },
            }}
            fullWidth
            type="submit"
          >
            OK
          </Button>
        </Grid>
      </form>

      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title " justifyContent="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            backgroundColor: '#334155'
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="yellow" className="size-6" width={48} height={48}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
        </DialogTitle>
        <DialogContent sx={{
          backgroundColor: '#334155',
          color: "white"
        }}>
          <DialogContentText id="alert-dialog-description" color={"white"} >
            {status}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          backgroundColor: "#da1212",
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} >
          <Button onClick={handleClose} autoFocus fullWidth style={{ color: 'white' }}  >
            OK
          </Button>
        </DialogActions>
      </Dialog >
    </>
  )
}