import React, { useState, useRef } from "react";
import { usePlayer } from "../../../../../contexts/PlayerContext";
import { Pagination, Navigation, A11y } from "swiper/modules";
import { useSearchParams, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Box, Typography } from "@mui/material";
import GameProviderIcon from "./GameProviderIcon";

export default function GameProviderSwiper({ gameProviders }) {
  const { isMobile } = usePlayer();
  const swiperRef = useRef();
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

  const [searchParams] = useSearchParams();
  const paramGameType = searchParams.get("type") || "";
  const paramProvider = searchParams.get("provider") || "";

  const handleGameProviderFilter = (providerID) => {
    if (isMobile) {
      navigate(`/m/games?type=${paramGameType}&provider=${providerID}`);
    } else {
      navigate(`?type=${paramGameType}&provider=${providerID}`);
    }
  };

  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {gameProviders.length > 0 && (
            <>
              <div
                style={{
                  color: "#ffffff",
                  marginTop: 35,
                  cursor: "pointer",
                }}
                id="prev-icon"
                onClick={() => swiperRef.current.slidePrev()}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    marginTop: "-10px",
                    fontSize: "34px",
                    marginRight: "12px",
                  }}
                />
              </div>
              <Swiper
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: "20px",
                  overflowX: "hidden",
                }}
                spaceBetween={10}
                slidesPerView={4}
                modules={[Navigation, Pagination, A11y]}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                <SwiperSlide
                  key={0}
                  style={{
                    backgroundColor:
                      paramProvider == 0 ||
                      paramProvider == "" ||
                      hoveredIndex === 0
                        ? "#da1212"
                        : "#16213e",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 36,
                    fontSize: 30,
                    fontWeight: 700,
                    cursor: "pointer",
                    textColor: "white",
                  }}
                  onClick={() => handleGameProviderFilter(0)}
                  onMouseEnter={() => setHoveredIndex(0)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Typography
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    All
                  </Typography>
                </SwiperSlide>
                {gameProviders.map((gameProvider, key) => {
                  return (
                    <SwiperSlide
                      key={key + 1}
                      style={{
                        backgroundColor:
                          paramProvider == gameProvider.id ||
                          hoveredIndex === key + 1
                            ? "#da1212"
                            : "#16213e",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 36,
                        fontSize: 30,
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                      onClick={() => handleGameProviderFilter(gameProvider.id)}
                      onMouseEnter={() => setHoveredIndex(key + 1)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <img
                        alt="img"
                        style={{ height: 25, fontSize: 25 }}
                        src={`${IMAGE_BASE_URL}/game-provider/${gameProvider.id}.png`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${process.env.PUBLIC_URL}/gameprovider-default.png`;
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div
                style={{
                  color: "#ffffff",
                  marginTop: 35,
                  cursor: "pointer",
                }}
                onClick={() => swiperRef.current.slideNext()}
                id="next-icon"
              >
                <KeyboardArrowRightIcon
                  sx={{
                    marginTop: "-10px",
                    fontSize: "34px",
                    marginRight: "12px",
                  }}
                />
              </div>
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          {gameProviders.length > 0 && (
            <>
              <div
                style={{
                  color: "#ffffff",
                  marginTop: 35,
                  cursor: "pointer",
                }}
                id="prev-icon"
                onClick={() => swiperRef.current.slidePrev()}
              >
                <KeyboardArrowLeftIcon />
              </div>
              <Swiper
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: "20px",
                  marginRight: 10,
                  marginLeft: 10,
                  overflowX: "hidden",
                }}
                spaceBetween={10}
                slidesPerView={8}
                modules={[Navigation, Pagination, A11y]}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                <SwiperSlide
                  key={0}
                  style={{
                    backgroundColor:
                      paramProvider == 0 ||
                      paramProvider == "" ||
                      hoveredIndex === 0
                        ? "#da1212"
                        : "#16213e",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 56,
                    fontSize: 30,
                    fontWeight: 700,
                    cursor: "pointer",
                    textColor: "white",
                  }}
                  onClick={() => handleGameProviderFilter(0)}
                  onMouseEnter={() => setHoveredIndex(0)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    All
                  </Typography>
                </SwiperSlide>
                {gameProviders.map((gameProvider, key) => {
                  return (
                    <SwiperSlide
                      key={key + 1}
                      style={{
                        backgroundColor:
                          paramProvider == gameProvider.id ||
                          hoveredIndex === key + 1
                            ? "#da1212"
                            : "#16213e",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 56,
                        fontSize: 30,
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                      onClick={() => handleGameProviderFilter(gameProvider.id)}
                      onMouseEnter={() => setHoveredIndex(key + 1)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <img
                        alt="img"
                        style={{
                          height: 50,
                          width: "auto",
                          fontSize: 25,
                          objectFit: "contain",
                        }}
                        src={`${IMAGE_BASE_URL}/game-provider/${gameProvider.id}.png`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${process.env.PUBLIC_URL}/gameprovider-default.png`;
                        }}
                      />
                      <GameProviderIcon
                        isHot={gameProvider.hot == true}
                        isNew={gameProvider.new == true}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div
                style={{
                  color: "#ffffff",
                  marginTop: 35,
                  cursor: "pointer",
                }}
                onClick={() => swiperRef.current.slideNext()}
                id="next-icon"
              >
                <KeyboardArrowRightIcon />
              </div>
            </>
          )}
        </Box>
      )}
    </>
  );
}
