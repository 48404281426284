import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  TextField,
  Modal,
  Grid,
} from "@mui/material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import axiosInstance from "../../../../../../axiosConfig";
import { dataOccupation } from "../../../../../../staticData/dataOccupation";
import { dataSourceOfIncome } from "../../../../../../staticData/dataSourceOfIncome";

const styles = {
  inputStyle: {
    display: "inline-block",
    verticalAlign: "middle",
    height: "34px",
    width: "100%",
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#fff",
    color: "#646464",
    outline: "0",
    padding: "2px",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0, 0, 0, .08) inset",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f5f5f5",
      borderBottom: "none",
    },
    "& label": {
      fontSize: "12px",
    },
    "& .MuiInputBase-root": {
      fontSize: "12px",
      width: "100%",
      height: "100%",
    },
  },
  selectStyle: {
    verticalAlign: "middle",
    height: "40px",
    width: "220px",
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#f5f5f5",
    color: "#646464",
    outline: "0",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0, 0, 0, .08) inset",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      border: "1px solid #e5e5e5",
    },
  },
  buttonStyle: {
    display: "inline-block",
    height: "34px",
    lineHeight: "34px",
    padding: "0 10px",
    color: "white !important",
    verticalAlign: "middle",
    textAlign: "center",
    borderRadius: "20px",
    cursor: "pointer !important",
    width: "98px",
    transition: ".2s ease-in-out",
    marginLeft: "10px",
    "&:hover": {
      cursor: "pointer !important",
      backgroundColor: "#ff0000",
    },
  },
};

export default function PersonalInformation() {
  const uname = localStorage.getItem("username");

  const [nickname, setNickname] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [sourceOfIncome, setSourceOfIncome] = useState("");
  const [occupation, setOccupation] = useState("");
  const [accountData, setAccountData] = useState({});
  const [errors, setErrors] = useState({});
  const [isInput, setIsInput] = useState("");
  const [username, setUsername] = useState("");
  const [profile, setProfile] = useState({});
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isBirthdayValid, setIsBirthdayValid] = useState(true);
  const [functionalSettings, setFunctionalSettings] = useState("");
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);

  const [qq, setQQ] = useState("");
  const [weChat, setWeChat] = useState("");
  const [line, setLine] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [zalo, setZalo] = useState("");

  const [nicknameHelperText, setNicknameHelperText] = useState("");
  const [nicknameError, setNicknameError] = useState("");
  const [fullNameHelperText, setFullNameHelperText] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [IDNumberHelperText, setIDNumberHelperText] = useState("");
  const [IDNumberError, setIDNumberError] = useState("");
  const [mobileNOHelperText, setMobileNOHelperText] = useState("");
  const [mobileNOError, setMobileNOError] = useState("");
  const [invitationCodeHelperText, setInvitationCodeHelperText] = useState("");
  const [invitationCodeError, setInvitationCodeError] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [birthdayHelperText, setBirthdayHelperText] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [QQHelperText, setQQHelperText] = useState("");
  const [QQError, setQQError] = useState("");
  const [weChatHelperText, setWeChatHelperText] = useState("");
  const [weChatError, setWeChatError] = useState("");
  const [lineHelperText, setLineHelperText] = useState("");
  const [lineError, setLineError] = useState("");
  const [whatsAppHelperText, setWhatsAppHelperText] = useState("");
  const [whatsAppError, setWhatsAppError] = useState("");
  const [facebookHelperText, setFacebookHelperText] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [zaloHelperText, setZaloHelperText] = useState("");
  const [zaloError, setZaloError] = useState("");
  const calendarRef = useRef(null);

  const SAPMSWA = /^[A-Za-z][A-Za-z0-9]*$/;
  const SEF = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const SAP = /^[a-zA-Z0-9]+$/;
  const SR1LA1NMIU = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d_]+$/;
  const NO = /^[0-9]*$/;
  const SAPWALOUC = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/;
  const AASOALOA = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
  const ABSNA = /^\S+$/;
  const YYYYMMDD = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  const AASALTAWS = /^(?=.*[A-Za-z].*[A-Za-z])[A-Za-z ]+$/;
  const MMDDYYYY = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  const BAANR = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
  const minDate = dayjs().subtract(80, "year");
  const maxDate = dayjs().add(-21, "year");
  const numberAccount = /^09\d{9}$/;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance
      .get(`player/player-profile`)
      .then((response) => {
        const data = response.data;
        setAccountData(data);
        setUsername(uname);
        setNickname(data.nickname);
        setBirthday(data.birthdate);
        setEmail(data.email);
        setFullName(data.full_name);
        setIdNumber(data.id_number);
        setMobileNumber(data.mobile_no);
        setSourceOfIncome(data.soi);
        setOccupation(data.occupation);
        setQQ(data.qq);
        setWeChat(data.wechat);
        setLine(data.line);
        setWhatsApp(data.whatsapp);
        setFacebook(data.facebook);
        setZalo(data.zalo);
        setProfile(data);

        if (
          data.nickname != null &&
          data.birthdate != null &&
          data.email != null &&
          data.id_number != null &&
          data.full_name != null &&
          data.soi != null &&
          data.occupation != null
        ) {
          setIsDisabledSubmit(true);
        } else {
          setIsDisabledSubmit(false);
        }
      })
      .catch((err) => console.error(err));

    axiosInstance
      .get(`platform/management/functional/settings`)
      .then((response) => {
        setFunctionalSettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleBirthday = (e) => {
    if (accountData.birthdate === null) {
      let birthday = e.target.value.replace(/\D/g, "");
      if (birthday.length > 4) {
        birthday = birthday.slice(0, 4) + "-" + birthday.slice(4);
      }
      if (birthday.length > 7) {
        birthday = birthday.slice(0, 7) + "-" + birthday.slice(7);
      }
      if (birthday.length > 10) {
        birthday = birthday.slice(0, 10);
      }
      setBirthday(birthday);
    } else {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 1500);
    }
  };

  const handleChange = (e, row, fieldName) => {
    const { value } = e.target;
    let errors = false;
    let helperTexts = "";

    if (row.pattern === "Any") {
      errors = false;
      helperTexts = "";
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern, must start with alphabetical"
    ) {
      if (SAPMSWA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must start with a letter and be followed by alphanumeric characters only";
      }
    } else if (row.pattern === "Standard email format") {
      if (SEF.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter a valid email address";
      }
    } else if (row.pattern === "Standard alphanumeric pattern") {
      if (SAP.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Please enter only letters and numbers. Special characters and spaces are not allowed";
      }
    } else if (
      row.pattern ===
      "Strictly require 1 letter and 1 number, may include underscore"
    ) {
      if (SR1LA1NMIU.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must include at least one letter and one number. An underscore (_) is optional";
      }
    } else if (row.pattern === "Number only") {
      if (NO.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter only alphanumeric characters";
      }
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern with at least one uppercase character"
    ) {
      if (SAPWALOUC.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and numbers, with at least one uppercase letter";
      }
    } else if (
      row.pattern === "Alphabet and spacing only, at least one alphabet"
    ) {
      if (AASOALOA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Only alphabetic characters and spaces allowed. Must include at least one letter";
      }
    } else if (row.pattern === "Any but spacing not allowed") {
      if (ABSNA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Only non-spacing characters are allowed";
      }
    } else if (row.pattern === "yyyy-MM-dd") {
      if (YYYYMMDD.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format yyyy-MM-dd";
      }
    } else if (
      row.pattern ===
      "Alphabet and spacing only, at least two alphabets with spacing"
    ) {
      if (AASALTAWS.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and spaces, with at least two letters and at least one space.";
      }
    } else if (row.pattern === "mm-dd-yyyy") {
      if (MMDDYYYY.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format MM-DD-YYYY";
      }
    } else if (row.pattern === "Both alphabets and numbers required") {
      if (BAANR.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Must include both letters and numbers";
      }
    }
    if (fieldName === "nickname") {
      setNickname(value);
      setNicknameError(errors);
      setNicknameHelperText(helperTexts);
    } else if (fieldName === "fullName") {
      setFullName(value);
      setFullNameError(errors);
      setFullNameHelperText(helperTexts);
    } else if (fieldName === "birthday") {
      setBirthday(value);
      setBirthdayError(errors);
      setBirthdayHelperText(helperTexts);
    } else if (fieldName === "idNumber") {
      setIdNumber(value);
      setIDNumberError(errors);
      setIDNumberHelperText(helperTexts);
    } else if (fieldName === "mobileNO") {
      setMobileNumber(value);
      setMobileNOError(errors);
      setMobileNOHelperText(helperTexts);
      if (numberAccount.test(value)) {
        setIsAccountNumberValid(true);
      } else {
        setIsAccountNumberValid(false);
      }
    } else if (fieldName === "invitationCode") {
      setInvitationCodeError(errors);
      setInvitationCodeHelperText(helperTexts);
    } else if (fieldName === "email") {
      setEmail(value);
      setEmailError(errors);
      setEmailHelperText(helperTexts);
    } else if (fieldName === "qq") {
      setQQ(value);
      setQQError(errors);
      setQQHelperText(helperTexts);
    } else if (fieldName === "wechat") {
      setWeChat(value);
      setWeChatError(errors);
      setWeChatHelperText(helperTexts);
    } else if (fieldName === "line") {
      setLine(value);
      setLineError(errors);
      setLineHelperText(helperTexts);
    } else if (fieldName === "whatsapp") {
      setWhatsApp(value);
      setWhatsAppError(errors);
      setWhatsAppHelperText(helperTexts);
    } else if (fieldName === "facebook") {
      setFacebook(value);
      setFacebookError(errors);
      setFacebookHelperText(helperTexts);
    } else if (fieldName === "zalo") {
      setZalo(value);
      setZaloError(errors);
      setZaloHelperText(helperTexts);
    }
  };

  const submitValidation =
    emailError ||
    fullNameError ||
    IDNumberError ||
    mobileNOError ||
    QQError ||
    weChatError ||
    lineError ||
    whatsAppError ||
    facebookError ||
    zaloError ||
    (mobileNumber && !isAccountNumberValid && accountData.mobile_no === null);

  const handleSubmit = () => {
    const newErrors = {};
    const selectedDate = dayjs(birthday);
    const twentyOneYearsAgo = dayjs().subtract(21, "year");
    if (selectedDate.isAfter(twentyOneYearsAgo)) {
      setIsBirthdayValid(false);
      return;
    }
    if (Object.keys(newErrors).length === 0) {
      axiosInstance
        .post(`player/personalinformation`, {
          nickname,
          fullName,
          birthday,
          email,
          idNumber,
          mobileNumber,
          sourceOfIncome,
          occupation,
          qq,
          weChat,
          line,
          whatsApp,
          facebook,
          zalo,
        })
        .then((response) => {
          setIsBirthdayValid(true);
          const timer = setTimeout(() => {
            fetchData();
          }, 1000);
          return () => clearTimeout(timer);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Stack
      sx={{
        color: "#666",
        width: "402px",
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "0px",
          height: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
      }}
    >
      <style>
        {`
          input::placeholder {
            color: #a5a5a5;
          }
        `}
      </style>
      <Box sx={{ height: "620px", paddingX: "30px", paddingTop: "20px" }}>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "50px",
            paddingX: "15px",
            borderLeft: "5px solid #22D72A",
          }}
        >
          Personal Information
        </Typography>
        <Stack
          direction="row"
          sx={{
            marginBottom: "12px",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <img
            style={{ width: "75px", borderRadius: "100%" }}
            src="https://images.1097638.com/TCG_PROD_IMAGES/B2C/01_PROFILE/PROFILE/0.png"
            alt="Profile"
          />
          <Typography variant="body2">{username}</Typography>
        </Stack>
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "nickname" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Nickname : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.nickname
                          ? accountData.nickname.slice(0, 1) +
                            "*".repeat(accountData.nickname.length - 1)
                          : nickname
                      }
                      onChange={(e) => handleChange(e, row, "nickname")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: nicknameHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.nickname === null ? "#fff" : "#f5f5f5",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={nicknameError}
                      helperText={nicknameHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.nickname !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}

        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "birthday" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                    Birthday :{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    {accountData.birthdate === null ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            height: 36,
                            width: "230px",
                            backgroundColor:
                              accountData.birthdate === null &&
                              row.info_update !== 1
                                ? "#f5f5f5"
                                : "#fff",
                            "& .MuiOutlinedInput-root": {},
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ggg",
                              boxShadow: "0 1px 5px rgba(0, 0, 0, .08) inset",
                              color: "#646464",
                            },
                            "& input": {
                              fontSize: "12px",
                              color: "#646464",
                            },
                            "& .MuiInputBase-input": {
                              padding: "12px",
                              backgroundColor:
                                accountData.birthdate === null &&
                                row.info_update !== 1
                                  ? "#f5f5f5"
                                  : "#fff",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: "25px",
                              color:
                                accountData.birthdate === null &&
                                row.info_update !== 1
                                  ? "#ggg"
                                  : "#0D6EFD",
                            },
                          }}
                          minDate={minDate}
                          maxDate={maxDate}
                          value={
                            accountData.birthdate
                              ? accountData.birthdate.slice(0, 1) +
                                "*".repeat(accountData.birthdate.length - 1)
                              : birthday
                          }
                          onChange={(newValue) =>
                            setBirthday(newValue.format("YYYY-MM-DD"))
                          }
                          disabled={
                            profile.birthdate !== null
                              ? true
                              : row.info_update !== 1
                              ? true
                              : false
                          }
                        />
                      </LocalizationProvider>
                    ) : (
                      <input
                        value={
                          accountData.birthdate !== null &&
                          accountData.birthdate !== undefined
                            ? accountData.birthdate.slice(0, 2) +
                              "*".repeat(accountData.birthdate.length - 2)
                            : birthday
                        }
                        onChange={
                          accountData.birthdate === null && handleBirthday
                        }
                        sx={styles.inputStyle}
                        style={{
                          backgroundColor:
                            accountData.birthdate === null ? "#fff" : "#f5f5f5",
                          height: "36px",
                          borderRadius: "5px",
                          width: "225px",
                          color: "#646464",
                          border: "1px solid #e5e5e5",
                        }}
                        disabled={
                          profile.birthdate !== null
                            ? true
                            : row.info_update !== 1
                            ? true
                            : false
                        }
                      />
                    )}
                  </Box>
                </Stack>
              );
            }
          })}
        {!isBirthdayValid && (
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ marginTop: "1.5%", textAlign: "center", marginLeft: "40%" }}
          >
            <Typography
              sx={{ fontSize: ".55vw", fontWeight: "bold", color: "red" }}
            >
              Player should be 21 years old and above
            </Typography>
          </Grid>
        )}

        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "email" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Email : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.email
                          ? accountData.email.slice(0, 1) +
                            "*".repeat(accountData.email.length - 1)
                          : email
                      }
                      onChange={(e) => handleChange(e, row, "email")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: emailHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={emailError}
                      helperText={emailHelperText}
                      disabled={
                        profile.email !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "full_name" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Full name : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.full_name
                          ? accountData.full_name.slice(0, 1) +
                            "*".repeat(accountData.full_name.length - 1)
                          : fullName
                      }
                      onChange={(e) => handleChange(e, row, "fullName")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: fullNameHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={fullNameError}
                      helperText={fullNameHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.full_name !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "id_number" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Id Number : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.id_number
                          ? accountData.id_number.slice(0, 3) +
                            "*".repeat(accountData.id_number.length - 1)
                          : idNumber
                      }
                      onChange={(e) => handleChange(e, row, "idNumber")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: IDNumberHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={IDNumberError}
                      helperText={IDNumberHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.id_number !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}

        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "mobile_no" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Mobile Number : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.mobile_no
                          ? accountData.mobile_no.slice(0, 3) +
                            "*".repeat(accountData.mobile_no.length - 1)
                          : mobileNumber
                      }
                      onChange={(e) => handleChange(e, row, "mobileNO")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: mobileNOHelperText ? "20px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={mobileNOError}
                      helperText={mobileNOHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "5px",
                        },
                      }}
                      disabled={
                        profile.mobile_no !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                    <Box>
                      {mobileNumber &&
                      !isAccountNumberValid &&
                      accountData.mobile_no === null ? (
                        <>
                          <Typography
                            style={{
                              color: "#d32f2f",
                              fontSize: "9px",
                              marginLeft: 3,
                            }}
                          >
                            Please enter 11 characters and start with 09, only
                            numbers allowed.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography />
                        </>
                      )}
                    </Box>
                  </Box>
                </Stack>
              );
            }
          })}

        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "qq" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">QQ : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.qq
                          ? accountData.qq.slice(0, 1) +
                            "*".repeat(accountData.qq.length - 1)
                          : qq
                      }
                      onChange={(e) => handleChange(e, row, "qq")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: QQHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.qq === null ? "#fff" : "#f5f5f5",
                        width: "100%",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={QQError}
                      helperText={QQHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.qq !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}

        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "wechat" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">WeChat : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      value={
                        accountData.wechat
                          ? accountData.wechat.slice(0, 1) +
                            "*".repeat(accountData.wechat.length - 1)
                          : weChat
                      }
                      onChange={(e) => handleChange(e, row, "wechat")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: weChatHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.wechat === null ? "#fff" : "#f5f5f5",
                        width: "100%",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={weChatError}
                      helperText={weChatHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.wechat !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "line" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Line : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      type="text"
                      value={
                        accountData.line
                          ? accountData.line.slice(0, 1) +
                            "*".repeat(accountData.line.length - 1)
                          : line
                      }
                      onChange={(e) => handleChange(e, row, "line")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: lineHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.line === null ? "#fff" : "#f5f5f5",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={lineError}
                      helperText={lineHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.line !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "whatsapp" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">WhatsApp : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      type="text"
                      value={
                        accountData.whatsapp
                          ? accountData.whatsapp.slice(0, 1) +
                            "*".repeat(accountData.whatsapp.length - 1)
                          : whatsApp
                      }
                      onChange={(e) => handleChange(e, row, "whatsapp")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: whatsAppHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.whatsapp === null ? "#fff" : "#f5f5f5",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={whatsAppError}
                      helperText={whatsAppHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.whatsapp !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "facebook" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Facebook : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      type="text"
                      value={
                        accountData.facebook
                          ? accountData.facebook.slice(0, 1) +
                            "*".repeat(accountData.facebook.length - 1)
                          : facebook
                      }
                      onChange={(e) => handleChange(e, row, "facebook")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: facebookHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.facebook === null ? "#fff" : "#f5f5f5",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={facebookError}
                      helperText={facebookHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.facebook !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        {functionalSettings &&
          functionalSettings.map((row) => {
            if (row.userInfo === "zalo" && row.info_enabled === 1) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2">Zalo : </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "226px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      type="text"
                      value={
                        accountData.zalo
                          ? accountData.zalo.slice(0, 1) +
                            "*".repeat(accountData.zalo.length - 1)
                          : zalo
                      }
                      onChange={(e) => handleChange(e, row, "zalo")}
                      sx={styles.inputStyle}
                      style={{
                        marginBottom: zaloHelperText ? "20px" : "0px",
                        backgroundColor:
                          accountData.zalo === null ? "#fff" : "#f5f5f5",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={zaloError}
                      helperText={zaloHelperText}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "9px",
                          textAlign: "left",
                          marginTop: "10px",
                        },
                      }}
                      disabled={
                        profile.zalo !== null
                          ? true
                          : row.info_update !== 1
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Stack>
              );
            }
          })}
        <Stack
          direction="row"
          sx={{
            marginBottom: "12px",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
            Source of Income :{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "226px",
            }}
          >
            <select
              value={sourceOfIncome}
              onChange={(e) => setSourceOfIncome(e.target.value)}
              style={styles.selectStyle}
            >
              {dataSourceOfIncome.map((row, index) => (
                <option key={index} value={row}>
                  {row}
                </option>
              ))}
            </select>
          </Box>
        </Stack>
        <Stack
          direction="row"
          sx={{
            marginBottom: "12px",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "50px",
          }}
        >
          <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
            Occupation :{" "}
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "226px" }}
          >
            <select
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
              type="text"
              style={styles.selectStyle}
            >
              {dataOccupation.map((row, index) => (
                <option key={index} value={row}>
                  {row}
                </option>
              ))}
            </select>
          </Box>
        </Stack>
        <Button
          onClick={handleSubmit}
          sx={styles.buttonStyle}
          style={{ backgroundColor: submitValidation ? "#d5d5d5" : "#ff1931" }}
          disabled={submitValidation}
        >
          Submit
        </Button>
      </Box>
    </Stack>
  );
}
