import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DepositDetails from "./deposit_details/DepositDetails";
import AccountStatus from "./account_status/AccountStatus";
import AccountOptions from "./account_options/AccountOptions";
import CloseIcon from "@mui/icons-material/Close";

import icon_1 from "../../../assets/icons/IconPerson.png";
import icon_12 from "../../../assets/icons/icon-12.png";
import icon_11 from "../../../assets/icons/icon-11.png";
import icon_10 from "../../../assets/icons/icon-10.png";
import icon_3 from "../../../assets/icons/icon-3.png";
import icon_6 from "../../../assets/icons/phone-disabled.png";
import DrawerContent from "./account_options/content/DrawerContent";
import DialogPassword from "./account_options/content/DialogPassword";
import DialogTransactionPassword from "./account_options/content/pages/DialogTransactionPassword";
import DialogWrongPassword from "./account_options/content/DialogWrongPassword";
import axiosInstance from "../../../axiosConfig";

export default function MyAccounts({
  setActiveComponent,
  balance,
  setBalance,
  setSelectedNavBarPath,
  setGameIframe,
  handleClose,
}) {
  const [open, setOpen] = useState(false);
  const [activeContent, setActiveContent] = useState("personal_information");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTransactionOpen, setDialogTransactionOpen] = useState(false);
  const [dialogWrongPassword, setDialogWrongPassword] = useState(false);
  const [playerIDVerification, setPlayerIDVerification] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`member/getMemberData`)
      .then((response) => {
        setPlayerIDVerification(response.data.id_verification);
      })
      .catch((error) => {});
  }, []);

  const toggleDrawer = (index) => {
    setOpen(!open);
    setActiveContent(index);
  };

  const handleSetActiveContent = (index) => {
    setActiveContent(index);
  };

  return (
    <Grid sx={{ display: "flex", overflow: "hidden" }}>
      <DepositDetails
        setActiveComponent={setActiveComponent}
        balance={balance}
        setBalance={setBalance}
      />
      <AccountStatus />
      <AccountOptions
        toggleDrawer={toggleDrawer}
        setSelectedNavBarPath={setSelectedNavBarPath}
        setGameIframe={setGameIframe}
        handleClose={handleClose}
      />
      {dialogOpen && (
        <DialogPassword dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
      )}
      {dialogTransactionOpen && (
        <DialogTransactionPassword
          dialogTransactionOpen={dialogTransactionOpen}
          setDialogTransactionOpen={setDialogTransactionOpen}
        />
      )}

      {/* Drawer inside the component */}
      <Backdrop onClick={toggleDrawer} open={open}></Backdrop>
      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        transitionDuration={{
          enter: 1500,
          exit: 1500,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            position: "absolute",
            minWidth: "270px",
            overflow: "visible",
            border: "none",
            borderRadius: "0px",
            transition: ".2s ease-in-out",
          },
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "33px",
            left: "-34px",
            width: "0",
            height: "0",
            borderTop: "0px solid transparent",
            borderBottom: "13px solid transparent",
            borderRight: "34px solid #FEFEFE",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "-33px",
            backgroundColor: "#FEFEFE",
            padding: "8px",
          }}
          onClick={toggleDrawer}
        >
          <Box
            sx={{
              backgroundColor: "#FF0000",
              padding: "0.5px",
              color: "#FFFFFF",
              borderRadius: "100%",
              fontSize: "0.15rem",
              cursor: "pointer",
            }}
          >
            <CloseIcon sx={{ fontSize: "1rem" }} />
          </Box>
        </Box>
        <Stack
          sx={{
            position: "absolute",
            top: "87px",
            left: "-57px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleSetActiveContent("personal_information")}
          >
            <img
              src={icon_1}
              style={{ width: "40px", height: "40px", marginLeft: "5px" }}
            />
          </div>
          {playerIDVerification ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleSetActiveContent("account_verification");
              }}
            >
              <img src={icon_12} style={{ width: "50px", height: "50px" }} />
            </div>
          ) : (
            <></>
          )}

          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleSetActiveContent("login_password")}
          >
            <img src={icon_11} style={{ width: "50px", height: "50px" }} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleSetActiveContent("bind_ewallet")}
          >
            <img src={icon_10} style={{ width: "50px", height: "50px" }} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleSetActiveContent("transaction_password")}
          >
            <img src={icon_3} style={{ width: "50px", height: "50px" }} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <img src={icon_6} style={{ width: "50px", height: "50px" }} />
          </div>
        </Stack>
        <Box>
          <DrawerContent
            activeContent={activeContent}
            setDialogOpen={setDialogOpen}
            setDialogTransactionOpen={setDialogTransactionOpen}
            toggleDrawer={toggleDrawer}
            setDialogWrongPassword={setDialogWrongPassword}
          />
        </Box>
      </Drawer>
    </Grid>
  );
}
