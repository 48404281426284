const Maintenance = () => {
  return (
    <img
      src="maintenance.gif"
      alt="Your GIF"
      style={{
        maxWidth: "100%",
        height: "auto",
        marginLeft: "35%",
        marginTop: "5%",
      }}
    />
  );
};

export default Maintenance;
