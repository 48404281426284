import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import mnews1 from "../../assets/images/news/mnews1.jpg";
import mnews2 from "../../assets/images/news/mnews2.jpg";
import mnews3 from "../../assets/images/news/mnews3.jpg";

import Footer from "../../assets/containers/section4-footer/Footer";
import axiosInstance from "../../axiosConfig";

const NewsMobile = () => {
  const navigate = useNavigate();
  const [playerRanking, setPlayerRanking] = useState("");
  const [winnersData, setWinnersData] = useState([]); 

  const [selectedModalMenu, setSelectedModalMenu] = useState("about");
  const [isOpenHelpCenter, setIsOpenHelpCenter] = useState(false);
  const handleOpenModalParent = (newValue) => {
    setSelectedModalMenu(newValue);
    setIsOpenHelpCenter(true);
  };

  const handleCloseModal = () => setIsOpenHelpCenter(false);
  const [open, setOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`member/getMemberData`)
      .then((response) => {
        setPlayerRanking(response.data.player_ranking);
      })
      .catch((error) => {});

    axiosInstance
      .get("/player/winnersPageMobile") 
      .then((response) => {
        if (response.data) {
          setWinnersData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching winners:", error);
      });
  }, []);

  const handleSeeAllWinner = () => {
    navigate("/m/winnerPageMobile");
  };

  const handleClickOpen = () => {
    setSelectedNews({
      title: "News Detail",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNews(null);
  };

  const latestWinnerData = winnersData.map((winner) => ({
    id: winner.id, 
    logo: "../gameicon-default.png",
    name: winner.game,
    price: winner.amount,
  }));

  const latestNewsData = [
    {
      id: 1,
      title: "FBM Bingo Pilipino: Champion’s Favorite",
      img: mnews1,
      context1:
        "Bingo Pilipino has emerged as the undisputed Champion of electronic bingo gaming in the Philippines.",
      readmore: "1",
    },
    {
      id: 2,
      title: "Reeling Victories in Fishing Games",
      img: mnews2,
      context1:
        "In the world of online gaming, it's not just about luck and strategy; ",
    },
    {
      id: 3,
      title: "Etiquette Guides in Live Games",
      img: mnews3,
      context1:
        "Bingo Pilipino has emerged as the undisputed Champion of electronic bingo gaming in the Philippines.",
    },
  ];

  return (
    <>
      <Grid
        sx={{
          backgroundColor: "#090929",
          width: "100%",
          minHeight: "100vh",
          pb: "4rem",
          marginTop: "20px",
          overflowX: "hidden",
          overflow: "hidden",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        <Grid container sx={{ marginTop: "12vw" }}>
          <Grid item xs={12}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
              }}
              alt="Description of the image"
              src="https://images.9183749.com/mcs-images/announcement/emotionf2/mcs_1697159952854_NEWS%20PAGE%20BANNER-MOBILE%20NEW.png"
            />
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "700",
            fontSize: "4vw",
            marginTop: "4vw",
            marginLeft: "3vw",
          }}
        >
          Latest Winners
        </Typography>
        <div
          style={{
            display: "grid",
            padding: "3px",
            gridTemplateColumns: "repeat(auto-fill, minmax(45vw, 1fr))",
            gap: "2vw",
            width: "95%",
            margin: "0 auto",
          }}
        >
          {latestWinnerData.map((row) => (
            <div
              key={row.id}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#16213e",
                alignItems: "center",
              }}
            >
              <img
                src={row.logo}
                alt="Winner"
                style={{
                  width: "30%",
                  padding: "1vw",
                }}
              />
              <div style={{ padding: "1vw" }}>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    marginBottom: "1vw",
                    fontSize: "3vw",
                    fontWeight: "bold",
                  }}
                >
                  {row.name}
                </Typography>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    fontSize: "3vw",
                    backgroundImage:
                      "linear-gradient(180deg, #f88000 25%, #facc22 82.81%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  ₱{row.price}
                </Typography>
              </div>
            </div>
          ))}
        </div>

        {playerRanking && (
          <Grid
            onClick={handleSeeAllWinner}
            container
            sm={12}
            md={12}
            sx={{
              marginTop: "2%",
              justifyContent: "flex-end",
              p: 1.5,
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#16213e",
                color: "#fff",
                cursor: "pointer",
                justifyContent: "space-between",
                pl: 1,
                width: "35%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "3vw",
                }}
              >
                See All Winners
              </Typography>
              <DoubleArrowIcon
                sx={{
                  backgroundColor: "#da1212",
                  width: "15%",
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ px: 1.5, borderRadius: "12px" }}>
          {latestNewsData.map((news) => (
            <Grid item xs={12} key={news.id}>
              <Grid
                container
                sx={{
                  width: "100%",
                  marginTop: "3vw",
                  height: "auto",
                  borderRadius: "12px",
                }}
              >
                <Grid
                  item
                  sx={{
                    height: "10vw",
                    width: "100%",
                    color: "#fff",
                    alignContent: "center",
                    backgroundColor: "red",
                    display: "flex",
                    px: 1,
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {news.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                  }}
                >
                  <Typography variant="body1" sx={{ flex: 1, padding: 1 }}>
                    {news.context1}
                    <Typography
                      sx={{
                        color: "red",
                        cursor: "pointer",
                        marginTop: "40px",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleClickOpen(news)}
                    >
                      Read more
                    </Typography>
                  </Typography>
                  <img
                    src={news.img}
                    alt={news.title}
                    style={{ width: "45vw", height: "50vw" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          {selectedNews && (
            <Dialog open={open} onClose={handleClose} fullScreen>
              <DialogTitle
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#16213e",
                  alignItems: "center",
                }}
              >
                <IconButton color="inherit" onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
                {selectedNews.title}
                <IconButton
                  color="transparent"
                  onClick={handleClose}
                  disabled
                ></IconButton>
              </DialogTitle>

              <DialogContent>
                <DialogContentText sx={{ marginBottom: "1%", fontSize: "4vw" }}>
                  {selectedNews.context1}
                </DialogContentText>
                <DialogContentText
                  sx={{ marginBottom: "1%", marginTop: "1.5%" }}
                >
                  {selectedNews.context2}
                </DialogContentText>
                <DialogContentText
                  sx={{
                    fontWeight: "bold",
                    fontSize: "4vw",
                    marginTop: "2%",
                    marginBottom: "2.5%",
                    color: "#da1212",
                    lineHeight: "2",
                  }}
                >
                  FBM Bingo Pilipino: Champion's Favorite
                </DialogContentText>
                <DialogContentText
                  sx={{
                    marginBottom: "2%",
                    marginTop: "5%",
                    fontSize: "3vw",
                    lineHeight: "2",
                    color: "#000",
                  }}
                >
                  These venues offer a comfortable and engaging environment
                  where players can gather, socialize, and immerse themselves in
                  the excitement of Bingo Pilipino. With FBM®'s support, these
                  venues have seamlessly integrated technology into their
                  operations, enhancing the overall gaming experience and
                  creating a dynamic community of players.
                </DialogContentText>
                <DialogContentText
                  sx={{
                    fontWeight: "bold",
                    fontSize: "4vw",
                    marginTop: "2%",
                    marginBottom: "2.5%",
                    color: "#000",
                    lineHeight: "2",
                  }}
                >
                  Play Anytime & Anywhere, Thanks to FBM® E-Motion
                </DialogContentText>
                <DialogContentText
                  sx={{
                    lineHeight: "2",
                    marginBottom: "3%",
                    fontSize: "3vw",
                    color: "#000",
                  }}
                >
                  But the good news does not stop there. Bingo Pilipino is now
                  one click away through our FBM® E-Motion Gaming Platform for
                  enjoyment anytime and anywhere. The transformed epic game
                  ensures that Bingo Pilipino can now be played on both mobile
                  and web browsers. In a bingo hall place or online, both cannot
                  describe how Bingo Pilipino captures each player's heart in
                  the Philippines making this rising favorite among champions
                  truly live up to its name.
                </DialogContentText>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      </Grid>
      <Grid item sx={{ display: { xs: "none", lg: "block" } }}>
        <Footer
          isOpen={isOpenHelpCenter}
          setIsOpenHelpCenter={setIsOpenHelpCenter}
          handleOpenModalParent={handleOpenModalParent}
          selectedModalMenu={selectedModalMenu}
          setSelectedModalMenu={setSelectedModalMenu}
          handleCloseModal={handleCloseModal}
        />
      </Grid>
    </>
  );
};

export default NewsMobile;
