import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Link,
  Checkbox,
  Dialog,
  CircularProgress,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  fetchPlayerDetails,
  storeProfile,
} from "../../../slices/profile.slice";
import { BACKEND_SANCTUM } from "../../../config";
import axiosInstance from "../../../axiosConfig";
import { ToastContainer, toast } from "react-toastify";

const LoginForm = ({
  open,
  handleClose,
  openRegistration,
  setShowForgotPassword,
  setPlayerFavorites,
  handleOpenModalParent,
  setBalance,
  setData,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const [playerPassword, setPlayerPassword] = useState("");
  const [playerUname, setPlayerUname] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [circularLoading, setCircularLoading] = useState(false);
  // const [openFPassword, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    setCircularLoading(true);
    e.preventDefault();
    try {
      await axiosInstance.get(`${BACKEND_SANCTUM}sanctum/csrf-cookie`);
      const loginResponse = await axiosInstance.post(`player/login`, {
        username: playerUname,
        password: playerPassword,
      });

      if (loginResponse.status === 200) {
        const { token, user, ip_address } = loginResponse.data;
        localStorage.setItem("authToken", token);
        localStorage.setItem("username", user.username);
        localStorage.setItem("ip_address", ip_address);
        handleClose();
        setBalance(user.balance);
        setPlayerPassword("");
        setErrorMessage(null);
        setTermsChecked(false);
        axiosInstance
          .get(`player/game/favorite/all-favorite/${user.player_id}`)
          .then((res) => {
            dispatch(storeProfile(user));
            dispatch(fetchPlayerDetails(user.player_id));
            localStorage.setItem("user_id", user.player_id);
            axiosInstance
              .get(`player/game/favorite/all-favorite/${user.player_id}`)
              .then((response) => {
                setPlayerFavorites(response.data);
              })
              .catch((error) => {
                console.error("Error fetching favorites data:", error);
              });
            axiosInstance
              .get(`player/profile/my-account/${user.player_id}`)
              .then((response) => {
                setData(response.data);
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });
            axiosInstance
              .get("/check/reset")
              .then((response) => {
                if (response.data.is_reset) {
                  setOpen(true);
                  setCircularLoading(false);
                }
                setCircularLoading(false);
              })
              .catch((error) => {
                console.error("Error fetching reset flag:", error);
              });
          })
          .catch((err) => console.error(err));
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setCircularLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPlayerPassword(e.target.value);
    setIsPasswordValid(validatePassword(newPassword));
  };

  const validatePassword = (input) => {
    const upperCaseAndLowerAndDigitAndSpecial = /^[A-Za-z0-9]{6,12}$/;
    return upperCaseAndLowerAndDigitAndSpecial.test(input);
  };

  const handleTermsChange = () => {
    setTermsChecked((prev) => !prev);
  };

  const handleForgotPasswordModal = () => {
    setShowForgotPassword(true);
    handleClose();
  };

  const handleOpenRegister = () => {
    handleClose();
    openRegistration();
    setPlayerUname("");
    setPlayerPassword("");
    setIsUsernameValid(true);
    setIsPasswordValid(true);
    setShowPassword(false);
    setErrorMessage(null);
    setTermsChecked(false);
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setPlayerUname(newUsername);
    setIsUsernameValid(validateUsername(newUsername));
  };

  const validateUsername = (input) => {
    const usernameRegex = /^[A-Za-z\d]{6,13}$/;
    return usernameRegex.test(input);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleTerms = () => {
    handleOpenModalParent("terms");
    handleClose();
    setPlayerUname("");
    setPlayerPassword("");
    setIsUsernameValid(true);
    setIsPasswordValid(true);
    setShowPassword(false);
    setErrorMessage(null);
    setTermsChecked(false);
  };

  const handlePrivacy = () => {
    handleOpenModalParent("privacy");
    handleClose();
    setPlayerUname("");
    setPlayerPassword("");
    setIsUsernameValid(true);
    setIsPasswordValid(true);
    setShowPassword(false);
    setErrorMessage(null);
    setTermsChecked(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: "#090929",
          boxShadow: "0px 0px 7px 0px white",
          borderRadius: "35px",
        },
      }}
      sx={{
        backdropFilter: "blur(7px)",
        alignItems: "center",
        "& .MuiPaper-root": {
          backgroundColor: "rgb(0,0,0,0)",
        },
      }}
    >
      <Container
        component="main"
        maxWidth="lg"
        style={{
          background: "#090929",
          width: "920px",
          height: "702px",
        }}
      >
        <img
          src="/loginBanner.png"
          alt="LoginBanner"
          style={{
            marginLeft: "-24px",
            marginBottom: "4.1px",
            position: "absolute",
            borderRadius: "30px",
          }}
        />
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h3"
            component="div"
            color="#fafafa"
            fontWeight="bold"
            marginLeft="410px"
            marginTop="80px"
          >
            Login
          </Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            component="div"
            color="#fafafa"
            marginLeft="410px"
          >
            Welcome back player!
          </Typography>
        </Grid>
        <Typography
          sx={{
            color: "white",
            marginLeft: "420px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          <span style={{ color: "red" }}>*</span> Username
        </Typography>
        <TextField
          sx={{
            height: "40px",
            borderRadius: "20px",
            backgroundColor: "#16213e",
            marginLeft: "420px",
            width: "calc(47% - 0px)",
            "& label": {
              marginTop: "0px",
              marginLeft: "10px",
            },
            "& .MuiInputBase-root": {
              padding: "-10px",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "&:hover .MuiInput-underline:after": {
              borderBottom: "none !important",
            },
          }}
          focused={false}
          fullWidth
          required
          variant="standard"
          onChange={handleUsernameChange}
          error={!isUsernameValid}
          InputProps={{
            style: {
              height: "50px",
              width: "85%",
              color: "#FFFFFF",
              marginLeft: "25px",
              marginTop: "3px",
            },
          }}
          FormHelperTextProps={{
            style: { display: "none" },
          }}
        />
        <Typography
          sx={{
            color: isUsernameValid ? "#555556" : "red",
            marginBottom: "5px",
            fontSize: "11px",
            textAlign: "center",
            marginLeft: "400px",
            marginTop: "5px",
          }}
        >
          Please enter 6 - 13 alphanumeric without special character
        </Typography>
        <Typography
          sx={{
            color: "white",
            marginLeft: "420px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          <span style={{ color: "red" }}>*</span> Password
        </Typography>
        <TextField
          fullWidth
          sx={{
            height: "40px",
            borderRadius: "20px",
            backgroundColor: "#16213e",
            width: "calc(47% - 0px)",
            marginLeft: "420px",
            "& label": {
              marginTop: "0px",
              marginLeft: "10px",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "&:hover .MuiInput-underline:after": {
              borderBottom: "none !important",
            },
          }}
          focused={false}
          required
          variant="standard"
          id="player_password"
          type={showPassword ? "text" : "password"}
          value={playerPassword}
          onChange={handlePasswordChange}
          autoComplete="new-password"
          error={!isPasswordValid}
          InputProps={{
            style: {
              height: "50px",
              color: "#FFFFFF",
              marginLeft: "25px",
              width: "90%",
              marginTop: "3px",
            },
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  position: "relative",
                  bottom: "15px",
                  left: "15px",
                }}
              >
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  style={{
                    color: "white",
                    top: "12px",
                    right: "15px",
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          sx={{
            color: !isPasswordValid ? "red" : "#555556",
            fontSize: "11px",
            textAlign: "center",
            marginLeft: "400px",
            marginBottom: "5px",
            marginTop: "5px",
            ".MuiOutlinedInput-input": {
              border: "none !important",
            },
          }}
        >
          Please enter 6 - 12 alphanumeric without special character
        </Typography>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ position: "relative" }}>
            {circularLoading ? (
              <>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{
                    pointerEvents: circularLoading ? "none" : "auto",
                    borderRadius: "20px",
                    padding: "8px 40px",
                    fontSize: "18px",
                    color: "#fafafa",
                    backgroundColor: circularLoading ? "gray" : "#fd2f2f",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    margin: "90px 0px 10px 210px",
                  }}
                  disabled={circularLoading}
                >
                  Sign In
                </Button>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "green",
                    position: "absolute",
                    top: "70%",
                    left: "76%",
                  }}
                />
              </>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{
                  pointerEvents:
                    !termsChecked || playerUname === "" || playerPassword === ""
                      ? "none"
                      : "auto",
                  borderRadius: "20px",
                  padding: "8px 40px",
                  fontSize: "18px",
                  color: "#fafafa",
                  backgroundColor:
                    !termsChecked || playerUname === "" || playerPassword === ""
                      ? "gray"
                      : "#fd2f2f",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  margin: "90px 0px 10px 210px",
                }}
                disabled={
                  !termsChecked || playerUname === "" || playerPassword === ""
                }
              >
                Sign In
              </Button>
            )}
          </Box>
        </Grid>
        {errorMessage && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: "-50px", marginLeft: "200px" }}
          >
            <Typography
              variant="body2"
              style={{ color: "red", marginTop: "30px" }}
            >
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Link
            onClick={handleForgotPasswordModal}
            style={{
              color: "#fafafa",
              margin: "-200px 0px 0px 580px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Forgot Password
          </Link>
        </Grid>
        <Typography
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#fafafa",
              marginLeft: "680px",
              marginBottom: "5px",
              marginTop: "-10px",
            }}
          >
            No account yet?
          </span>

          <Button
            style={{
              width: "",
              backgroundColor: "#16213e",
              fontWeight: "bold",
              marginLeft: "-132px",
              marginTop: "-83px",
              padding: "8px 37px",
              fontSize: "18px",
              borderRadius: "20px",
            }}
          >
            <Link
              onClick={handleOpenRegister}
              style={{ color: "#fafafa", textTransform: "capitalize" }}
            >
              Register
            </Link>
          </Button>
        </Typography>
        <Grid
          item
          sx={{ marginLeft: "420px", marginTop: "50px", display: "flex" }}
        >
          <Checkbox
            checked={termsChecked}
            onChange={handleTermsChange}
            {...label}
            defaultChecked
            sx={{
              color: "#FFFFFF",
              "&.Mui-checked": {
                color: "#66bb6a",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              alignContent: "center",
              color: "white",
            }}
          >
            I am at least 21 years of age and I accept the
            <Link
              onClick={handleTerms}
              sx={{
                color: "red",
                fontWeight: "bold",
                textDecorationColor: "red",
                cursor: "pointer",
                marginLeft: "3px",
              }}
            >
              Terms of Use
            </Link>
            <br />
            and
            <Link
              onClick={handlePrivacy}
              sx={{
                color: "red",
                fontWeight: "bold",
                textDecorationColor: "red",
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Container>
      <ToastContainer />
    </Dialog>
  );
};

export default LoginForm;


