import { Grid, Stack, Typography, styled } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import icon_1 from "../../../../assets/icons/IconPerson.png";
import icon_3 from "../../../../assets/icons/icon-3.png";
import icon_6 from "../../../../assets/icons/phone-disabled.png";
import icon_10 from "../../../../assets/icons/icon-10.png";
import icon_11 from "../../../../assets/icons/icon-11.png";
import icon_12 from "../../../../assets/icons/icon-12.png";
import icon_13 from "../../../../assets/icons/icon-13.png";
import axiosInstance from "../../../../axiosConfig";

const HeaderText = styled(Typography)({
  color: "#6F6F6F",
  fontWeight: "0.8rem",
});

const DescriptionText = styled(Typography)({
  color: "#AFAFAF",
  fontSize: "0.8rem",
});

const InformationContainer = styled(Grid)({
  width: "325px",
  display: "flex",
  marginBottom: "25px",
  cursor: "pointer",
});

const LogoutInfoContainer = styled(Grid)({
  marginLeft: "18px",
});

const styles = {
  optionIcon: {
    maxHeight: "70px",
    maxWidth: "70px",
    marginRight: "10px",
  },
  accountOptionsContainer: {
    marginX: "10px",
    marginY: "40px",
    overflowY: "hidden",
    height: "580px",
    "&::-webkit-scrollbar-track": {
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "5px",
      backgroundColor: "#FFFFFF",
    },

    "&::-webkit-scrollbar": {
      width: "7px",
      backgroundColor: " #F5F5F5",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: "#AFAFAF",
    },
    "&:hover": {
      overflowY: "scroll",
    },
  },
  logoutIcon: { width: "55px", height: "55px", marginLeft: "7.5px" },
  disabledIcon: {
    height: "72px",
    width: "72px",
    marginRight: "10px",
  },
};
export default function AccountOptions({
  toggleDrawer,
  setSelectedNavBarPath,
  setGameIframe,
  handleClose,
}) {
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const [playerIDVerification, setPlayerIDVerification] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`member/getMemberData`)
      .then((response) => {
        setPlayerIDVerification(response.data.id_verification);
      })
      .catch((error) => { });
  }, []);

  const handleDrawer = (content) => {
    toggleDrawer(content);
  };

  const handleLogout = () => {
    axiosInstance
      .post(`player/logout`, {
        player_username: username,
        activity: "Logout",
      })
      .then((res) => {
        handleClose(false);
        localStorage.clear();
        setSelectedNavBarPath("landingpage");
        setGameIframe(false);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Stack sx={styles.accountOptionsContainer}>
      <InformationContainer
        onClick={() => handleDrawer("personal_information")}
      >
        <img
          src={icon_1}
          style={{
            width: "55px",
            height: "55px",
            margin: "5px",
            borderRadius: "50%",
            boxShadow: "9px 9px 21px rgba(254, 235, 107, .35)",
          }}
        />
        <Grid sx={{ marginLeft: "10px" }}>
          <HeaderText>Personal Information</HeaderText>
          <DescriptionText>
            Complete the personal information to improve account security.
          </DescriptionText>
        </Grid>
      </InformationContainer>

      {playerIDVerification ? (
        <InformationContainer
          onClick={() => handleDrawer("account_verification")}
        >
          <img src={icon_12} style={styles.optionIcon} />
          <Grid>
            <HeaderText>Account Verification</HeaderText>
            <DescriptionText>Verify to secure your account.</DescriptionText>
          </Grid>
        </InformationContainer>
      ) : (
        <></>
      )}

      <InformationContainer onClick={() => handleDrawer("login_password")}>
        <img src={icon_11} style={styles.optionIcon} />
        <Grid>
          <HeaderText>Login Password</HeaderText>
          <DescriptionText>
            Recommended password with combination of letters and numbers, mixed
            with uppercase and lowercase.
          </DescriptionText>
        </Grid>
      </InformationContainer>

      <InformationContainer onClick={() => handleDrawer("bind_ewallet")}>
        <img src={icon_10} style={styles.optionIcon} />
        <Grid>
          <HeaderText>Bind E-Wallet</HeaderText>
          <DescriptionText>Bind E-Wallet for withdrawal.</DescriptionText>
        </Grid>
      </InformationContainer>

      <InformationContainer
        onClick={() => handleDrawer("transaction_password")}
      >
        <img src={icon_3} style={styles.optionIcon} />
        <Grid>
          <HeaderText>Transaction Password</HeaderText>
          <DescriptionText>
            Transaction password will use to verify and identify for any fund
            related operation for account safety purposes.
          </DescriptionText>
        </Grid>
      </InformationContainer>

      <InformationContainer>
        <img src={icon_6} style={styles.disabledIcon} />
        <Grid>
          <HeaderText>Phone Verification</HeaderText>
          <DescriptionText>
            Bind your phone to retrieve your password.
          </DescriptionText>
        </Grid>
      </InformationContainer>

      <InformationContainer onClick={handleLogout}>
        <img src={icon_13} style={styles.logoutIcon} />
        <LogoutInfoContainer>
          <HeaderText>Logout</HeaderText>
          <DescriptionText>Logout Safely.</DescriptionText>
        </LogoutInfoContainer>
      </InformationContainer>
    </Stack>
  );
}
