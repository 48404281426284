import { Modal, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingModal({ loading }) {
  return (
    <Modal
      open={loading}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        outline: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#4d4d4d",
          opacity: "90%",
          p: 3,
          borderRadius: "5%",
          textAlign: "center",
          flexDirection: "column",
          outline: "none",
        }}
      >
        <CircularProgress sx={{ color: "#FFFFFF", fontSize: "13vw" }} />
        {/* <img
          src={`${process.env.PUBLIC_URL}/loading.png`}
          alt="Loading..."
          style={{ width: "100px", height: "100px" }}
        /> */}
      </Box>
    </Modal>
  );
}
