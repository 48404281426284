import React, { useState } from "react";
import axiosInstance from "../../../axiosConfig";
import { CASINO_SERVER } from "../../../config";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, IconButton, TextField, Dialog, DialogTitle, DialogContentText, DialogActions, Stack, Chip, InputAdornment, DialogContent } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const commonStyles = {
  borderRadius: "20px",
  backgroundColor: "#16213e",
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
};

export default function MobileNumberTab({ setMobileNum = "", setOtp = "", setPassword = "" }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([null]);
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setValid] = useState([true]); // Correctly initialized
  const [values, setValues] = useState({
    mobile_no: setMobileNum,
    otp: setOtp,
    password: setPassword
  }); // Correctly initialized

  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value
    setValues(values => ({
      ...values,
      [key]: value,
    }))
    setValid(/^[a-zA-Z0-9]{6,12}$/.test(value));
  }

  function generateOTP(e) {
    e.preventDefault();
    axiosInstance.post(`${CASINO_SERVER}player/request-mobile-otp`, {
      mobile_no: values.mobile_no
    })
      .then(function (status) {
        setStatus(status.data.message)
        handleClickOpen()
      })
      .catch(function (errors) {
        setStatus(errors.response.data.message)
        handleClickOpen()
      })
  }

  const submit = (e) => {
    e.preventDefault();
    axiosInstance.post(`${CASINO_SERVER}player/reset-password`, {
      mobile_no: values.mobile_no,
      otp: values.otp,
      password: values.password
    })
      .then(function (status) {
        setStatus(status.data.message)
        handleClickOpen()
        navigate("/m/login");
      })
      .catch(function (errors) {
        setStatus(errors.response.data.message)
        handleClickOpen()
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStatus([null])
    setOpen(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}>
          <span style={{ color: "red" }}>*</span> Mobile Number
        </Typography>
        <TextField
          id="mobile_no"
          sx={{ ...commonStyles, mt: 1 }}
          fullWidth
          required
          variant="standard"
          value={values.mobile_no}
          onChange={handleChange}
          error={!isValid}
          InputProps={{
            style: { height: 40, color: "#FFFFFF", marginLeft: 20 },
          }}
        />
        <Typography
          sx={{
            color: isValid ? "#555556" : "red",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          Please enter 11 characters, only numbers allowed.
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ color: "white", fontWeight: "bold", fontSize: 15, mb: 1 }}
        >
          <span style={{ color: "red" }}>*</span> OTP
        </Typography>
        <TextField
          id="otp"
          sx={commonStyles}
          fullWidth
          variant="standard"
          value={values.otp}
          onChange={handleChange}
          InputProps={{
            inputProps: { maxLength: 6 },
            style: { height: 40, color: "#FFFFFF", marginLeft: 30 },
            endAdornment: (
              <Stack
                direction="row"
                sx={{
                  marginLeft: "-105px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Chip
                  size="small"
                  label="Get Code"
                  onClick={generateOTP}
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    backgroundColor: "red",
                    fontSize: 11,
                  }}
                />
              </Stack>
            ),
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{ color: "white", fontWeight: "bold", fontSize: 15, mb: 1 }}
        >
          <span style={{ color: "red" }}>*</span> Password
        </Typography>
        <TextField
          id="password"
          sx={{ ...commonStyles, mt: 1 }}
          fullWidth
          variant="standard"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange}
          autoComplete="new-password"
          error={!isValid}
          InputProps={{
            style: {
              height: 40,
              color: "#FFFFFF",
              marginLeft: 32,
              width: "90%",
            },
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  position: "relative",
                  bottom: 15,
                  left: 15,
                }}
              >
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  style={{
                    color: "white",
                    top: 15,
                    right: 38,
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          sx={{
            color: !isValid ? "red" : "#555556",
            fontSize: "11px",
            textAlign: "center",
            mb: 1,
            mt: 1,
          }}
        >
          Please enter 6 - 12 alphanumeric without special character
        </Typography>
        <Button
          variant="contained"
          color="error"
          fullWidth
          size="large"
          sx={{
            borderRadius: 10,
            textTransform: "capitalize",
            backgroundColor: "red",
            fontWeight: "bold",
            fontSize: "2vh",
            color: "white",
            width: "100%",
          }}
          onClick={submit}
        >
          Confirm
        </Button>
      </Box>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title " justifyContent="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
            backgroundColor: '#334155'
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="yellow" className="size-6" width={48} height={48}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
        </DialogTitle>
        <DialogContent sx={{
          backgroundColor: '#334155',
          color: "white"
        }}>
          <DialogContentText id="alert-dialog-description" color={"white"} >
            {status}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          backgroundColor: "#da1212",
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} >
          <Button onClick={handleClose} autoFocus fullWidth style={{ color: 'white' }}  >
            OK
          </Button>
        </DialogActions>
      </Dialog >
    </Box>

  );
}
