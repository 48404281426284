import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  Modal,
  CardActions,
} from "@mui/material";
import axiosInstance from "../../axiosConfig";

const images = [
  { src: "welcomeregistrationbonus.jpeg", title: "Welcome Registration Bonus" },
  { src: "50percentbonus.jpg", title: "50% First Deposit Bonus" },
  { src: "championbonus.png", title: "Champion Birthday Bonus" },
  { src: "happyhourbonus.png", title: "Happy Hour Bonus" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PromotionPage = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [promotion, setPromotion] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axiosInstance.get("/banner", {
          params: { type: "promotion" },
        });
        const data = response.data.data;
        setPromotion(data);
      } catch (err) {}
    };

    fetchBanners();
  }, []);
  const handleOpen = (promotion) => {
    setSelectedPromotion(promotion);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPromotion(null);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{ marginTop: "60px", marginBottom: "60px" }}
    >
      {promotion.map((item, key) =>
        item.banners.map((banner, bannerKey) => (
          <img
            key={bannerKey}
            src={banner.banner_url}
            style={{ width: "100%", margin: 0, marginTop: "20px" }}
            alt="Banner"
          />
        ))
      )}
      {images.map((promotion, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#16213E",
              color: "#fff",
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              component="img"
              image={promotion.src}
              alt={promotion.title}
            />
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }} gutterBottom variant="h5">
                {promotion.title}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="body2">
                Example description or details.
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "red",
                  fontWeight: "bold",
                  color: "#fff",
                }}
                size="small"
                onClick={() => handleOpen(promotion)}
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}

      {/* Modal for displaying promotion details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="promotion-modal"
        aria-describedby="promotion-details"
      >
        <Box sx={style}>
          <Typography variant="h3" component="h2" gutterBottom>
            {selectedPromotion && selectedPromotion.title}
          </Typography>
          <Typography gutterBottom>
            Promotion details or content here.
          </Typography>
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    </Grid>
  );
};

export default PromotionPage;
