import React, { useState, useEffect } from "react";
import { Typography, Grid, useTheme, Stack } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import welcomeregistrationbonus from "../../../assets/images/promotion/welcomeregistrationbonus.jpg";
import fiftypercentbonus from "../../../assets/images/promotion/50percentbonus.jpg";
import happyhourbonus from "../../../assets/images/promotion/happyhourbonus.png";
import championbonus from "../../../assets/images/promotion/championbonus.png";

import Footer from "../../../assets/containers/section4-footer/Footer";
import BlurOverlay from "../../../assets/components/modals/BlurOverlay";
import PromotionCard from "../../../pages/promotion/PromotionCard";
const images = [
  {
    src: welcomeregistrationbonus,
    title: "Welcome Registration Bonus!",
    period: "Products: This bonus can be wagered in all FBM E-motion Games.",
  },
  {
    src: fiftypercentbonus,
    title: "50% First Deposit Bonus",
    period: "Products: This bonus can be wagered in all FBM E-motion Games.",
  },
  {
    src: championbonus,
    title: "Champion Birthday Bonus",
    period: "Products: This bonus can be wagered in all FBM E-motion Games.",
  },
  {
    src: happyhourbonus,
    title: "Happy Hour Bonus",
    period: "Products: This bonus can be wagered in all FBM E-motion Games.",
  },
];

const PromotionMobile = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [promotion, setPromotion] = useState("");
  const [isOpenHelpCenter, setIsOpenHelpCenter] = useState(false);
  const [selectedModalMenu, setSelectedModalMenu] = useState("about");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleOpen = (name) => {
    setOpen(true);
    setPromotion(name);
  };
  const handleClose = () => setOpen(false);
  const handleOpenModalParent = (newValue) => {
    setSelectedModalMenu(newValue);
    setIsOpenHelpCenter(true);
  };
  const handleCloseModal = () => setIsOpenHelpCenter(false);

  return (
    <>
      <Grid
        sx={{
          backgroundColor: "#090929",
          height: { sm: "100vh", xl: "100%" },
          width: "100%",
          minHeight: "100vh",
          pb: "4rem",
          marginTop: "50px",
        }}
      >
        <Grid>
          <Box
            sx={{
              mb: "20px",
              width: "100%",
              maxWidth: 1400,
              height: 400,
              mx: "auto",
              [theme.breakpoints.down("sm")]: {
                width: "100vw",
                position: "relative",
                left: "50%",
                right: "50%",
                marginLeft: "-50vw",
                marginRight: "-50vw",
                maxWidth: "none",
                height: 250,
              },
            }}
          >
            <Box
              component="img"
              src={
                "https://images.9183749.com/mcs-images/announcement/emotionf2/mcs_1696573817639_more bonuses more fun banner copy.jpg"
              }
              alt="More Bonus More Fun"
              sx={{
                display: { xs: "none", lg: "block" },
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
            <Box
              component="img"
              src={
                "https://images.9183749.com/mcs-images/announcement/emotionf2/mcs_1696576561989_MOBILE BANNER-PROMOTION copy.jpg"
              }
              alt="More Bonus More Fun"
              sx={{
                display: { lg: "none" },
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
          <Grid container spacing={{ xs: 1, md: 3 }}>
            {images.map((row) => (
              <PromotionCard row={row} handleOpen={handleOpen} />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ marginTop: "30px", overflow: "hidden" }}
          ></Grid>

          <BlurOverlay open={open} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "100%", sm: 900 },
                height: { xs: "100%", sm: 700 },
                bgcolor: "background.paper",

                borderRadius: {
                  xs: "0px",
                  xl: "24px",
                },
                boxShadow: 24,
                backgroundColor: { xs: "#090929", sm: "#16213e" },
                p: 4,
                overflowY: "auto",
                maxHeight: "100%",
                zIndex: 400,
              }}
            >
              <Grid
                paddingX={"12px"}
                item
                sx={{
                  background: { xs: "#16213e", sm: "none" },
                  height: "4rem",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  color: "white",
                }}
              >
                <ArrowBackIosRoundedIcon
                  onClick={handleClose}
                  sx={{
                    display: { sm: "none" },
                    position: "absolute",
                    left: "12px",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
                <CloseRoundedIcon
                  onClick={handleClose}
                  sx={{
                    display: { xs: "none", sm: "flex " },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "white",
                    fontWeight: "bold",
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    margin: "0 auto",
                    fontWeight: "bold",
                    display: { lg: "none" },
                  }}
                >
                  Details
                </Typography>
              </Grid>
              <Grid
                item
                spacing={5}
                sx={{
                  height: "100%",
                  padding: "12px",
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <img
                      src={promotion.src}
                      alt="Promotion"
                      style={{
                        borderRadius: { xs: "15px", lg: "0px" },
                        width: "100%",
                        height: "auto",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography
                        variant="h6"
                        component="h5"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: { xs: "3vw", lg: "24px" },
                        }}
                      >
                        {promotion.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          width: "100%",
                        }}
                      >
                        2024-06-05 00:00:00 - 2024-12-31 23:59:59
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AccessTimeIcon sx={{ marginRight: "0.5rem" }} />
                        Remaining Time:
                        <Typography
                          sx={{
                            color: "red",
                            fontWeight: "bold",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Long term
                        </Typography>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    textAlign={"center"}
                    sx={{ maxWidth: "600px", margin: "auto" }}
                  >
                    <Typography
                      variant="h6"
                      component="h5"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: { xs: "3vw", lg: "24px" },
                      }}
                    >
                      {promotion.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h5"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: { xs: "3vw", lg: "16px" },
                      }}
                    >
                      {promotion.period}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h5"
                      letterSpacing={2}
                      sx={{
                        display: { lg: "none" },
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "3vw",
                      }}
                    >
                      __________________________________
                    </Typography>
                  </Grid>

                  <Typography
                    sx={{
                      mt: 4,
                      pb: 12,
                      color: "white",
                    }}
                  >
                    Register on FBM E-motion: Visit our website or download the
                    FBM E-motion app and complete the registration process.
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      `{" "}
      <Grid item sx={{ display: { xs: "none", lg: "block" } }}>
        <Footer
          isOpen={isOpenHelpCenter}
          setIsOpenHelpCenter={setIsOpenHelpCenter}
          handleOpenModalParent={handleOpenModalParent}
          selectedModalMenu={selectedModalMenu}
          setSelectedModalMenu={setSelectedModalMenu}
          handleCloseModal={handleCloseModal}
        />
      </Grid>
      `
    </>
  );
};

export default PromotionMobile;
