import { Box, Typography, Stack, styled } from "@mui/material";
import download from "../../../../../assets/images/icons/download.png";
import downloadRed from "../../../../../assets/images/icons/download-red.png";
import { useState, useEffect } from "react";
import "./css/style.css";
import axiosInstance from "../../../../../axiosConfig";

const AmountContainer = styled(Typography)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const AmountValue = styled(Typography)({
  color: "#0094d1",
  fontSize: "1.3rem",
  fontWeight: "bold",
});

const FormGroupOne = styled(Stack)({
  display: "flex",
  justifyContent: "flex-start",
  gap: "15px",
  alignItems: "center",
  marginBottom: "20px",
});

const FormGroupTwo = styled(Stack)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "20px",
  gap: "5px",
});

const styles = {
  input: {
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#f5f5f5",
    color: "#646464",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0,0,0,.08) inset",
    height: "30px",
    width: "130px",
    paddingLeft: "5px",
  },
};

export default function BalanceDetails({
  amount,
  setAmount,
  txnPw,
  setTxnPw,
  remainingTurnover,
  balance,
}) {
  const [icon, setICon] = useState(download);
  const [refreshValue, setRefreshValue] = useState(0);
  const [
    playerTransactionPasswordVerification,
    setPlayerTransactionPasswordVerification,
  ] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`member/getMemberData`)
      .then((response) => {
        setPlayerTransactionPasswordVerification(
          response.data.password_verification
        );
      })
      .catch((error) => {});
  }, []);

  const handleRefresh = () => {
    axiosInstance
      .post(`player/recall/balance`)
      .then((response) => {
        console.log("Success recall balance");
      })
      .catch((error) => {
        console.log("Failed recall balance");
      });
    setRefreshValue(1);
    const refreshBalance = document.getElementById("reload");
    refreshBalance.classList.add("open");
    if (refreshValue == 1) {
      refreshBalance.classList.remove("open");
      setRefreshValue(0);
    }
  };

  const handleMouseEnter = () => {
    setICon(downloadRed);
    const element = document.getElementById("returnBalance");
    element.style.color = "#FF0000";
  };

  const handleMouseExit = () => {
    setICon(download);
    const element = document.getElementById("returnBalance");
    element.style.color = "#646464";
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (numericValue === "") {
      setAmount("");
    } else {
      const number = parseInt(numericValue, 10);
      if (number <= 49999) {
        setAmount(numericValue);
      }
    }
  };

  const handleTxnPwChange = (e) => {
    const value = e.target.value;
    setTxnPw(value);
  };

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Box sx={{ width: "40%", marginBottom: "10px" }}>
        <AmountContainer variant="body2">
          Central Wallet: <AmountValue>{parseFloat(balance).toFixed(2)}</AmountValue>
        </AmountContainer>
        <AmountContainer variant="body2">
          Available Amount:
          <AmountValue>{parseFloat(balance).toFixed(2)}</AmountValue>
        </AmountContainer>
      </Box>
      {parseFloat(remainingTurnover) === 0.0 && (
        <Box sx={{ width: "99%" }}>
          <FormGroupOne direction="row">
            <Typography
              variant="body2"
              sx={{ width: "60px", marginRight: "25px" }}
            >
              Withdrawal Amount :
            </Typography>
            <input
              value={amount}
              onChange={handleAmountChange}
              style={styles.input}
              type="text"
              placeholder="100 ~ 49, 999"
            />
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                margin: 0,
                cursor: "pointer",
                "&:hover": {
                  color: "#FF0000",
                },
              }}
              onClick={handleRefresh}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseExit}
            >
              <img
                id="reload"
                className="reload-arrow"
                src={icon}
                height={15}
                width={15}
              />
              <Typography
                variant="body2"
                id="returnBalance"
                sx={{ whiteSpace: "nowrap" }}
              >
                Return Balance
              </Typography>
            </div>
          </FormGroupOne>
          {playerTransactionPasswordVerification ? (
            <FormGroupTwo direction="row">
              <Typography
                variant="body2"
                sx={{ width: "75px", marginRight: "20px" }}
              >
                Transaction Password :
              </Typography>
              <input
                style={styles.input}
                type="password"
                value={txnPw}
                onChange={handleTxnPwChange}
              />
            </FormGroupTwo>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
}
