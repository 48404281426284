import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Link,
  Container,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../../axiosConfig";
import { toast, ToastContainer } from "react-toastify";

export default function RegisterFormMobile({ handleOpenModalParent }) {
  const [playerUname, setPlayerUname] = useState("");
  const [playerContact, setPlayerContact] = useState("");
  const [playerPassword, setPlayerPassword] = useState("");
  const [playerOtp, setPlayerOtp] = useState("");
  const [playerCpassword, setPlayerCpassword] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isContactValid, setIsContactValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");

  const [functionalSettings, setFunctionalSettings] = useState("");
  const [nickname, setNickname] = useState("");
  const [fullName, setFullName] = useState("");
  const [idNumber, setIDNumber] = useState("");
  const [email, setEmail] = useState("");
  const [qq, setQq] = useState("");
  const [wechat, setWechat] = useState("");
  const [line, setLine] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [zalo, setZalo] = useState("");
  const [transactionPassword, setTransactionPassword] = useState("");
  const [showTransactionPassword, setShowTransactionPassword] = useState(false);

  const [nicknameHelperText, setNicknameHelperText] = useState("");
  const [nicknameError, setNicknameError] = useState("");
  const [usernameHelperText, setUsernameHelperText] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [transactionPasswordHelperText, setTransactionPasswordHelperText] =
    useState("");
  const [transactionPasswordError, setTransactionPasswordError] = useState("");
  const [fullNameHelperText, setFullNameHelperText] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [IDNumberHelperText, setIDNumberHelperText] = useState("");
  const [IDNumberError, setIDNumberError] = useState("");
  const [mobileNOHelperText, setMobileNOHelperText] = useState("");
  const [mobileNOError, setMobileNOError] = useState("");
  const [invitationCodeHelperText, setInvitationCodeHelperText] = useState("");
  const [invitationCodeError, setInvitationCodeError] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [emailError, setEmailError] = useState("");
  const [QQHelperText, setQQHelperText] = useState("");
  const [QQError, setQQError] = useState("");
  const [weChatHelperText, setWeChatHelperText] = useState("");
  const [weChatError, setWeChatError] = useState("");
  const [lineHelperText, setLineHelperText] = useState("");
  const [lineError, setLineError] = useState("");
  const [whatsAppHelperText, setWhatsAppHelperText] = useState("");
  const [whatsAppError, setWhatsAppError] = useState("");
  const [facebookHelperText, setFacebookHelperText] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [zaloHelperText, setZaloHelperText] = useState("");
  const [zaloError, setZaloError] = useState("");
  const [circularLoading, setCircularLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (playerCpassword !== "") {
      const doPasswordsMatch = playerPassword === playerCpassword;
      setPasswordMatch(doPasswordsMatch);
    } else {
      setPasswordMatch(true);
    }
  }, [playerPassword, playerCpassword]);

  useEffect(() => {
    axiosInstance
      .get(`platform/management/functional/settings`)
      .then((response) => {
        setFunctionalSettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [
    isUsernameValid,
    isPasswordValid,
    playerUname,
    playerContact,
    playerPassword,
    playerCpassword,
    playerOtp,
    passwordMatch,
    termsChecked,
    isContactValid,
  ]);

  const validationButton =
    functionalSettings && Array.isArray(functionalSettings)
      ? functionalSettings.some(
          (row) =>
            (row.userInfo === "username" &&
              row.register_required === 1 &&
              playerUname === "") ||
            (row.userInfo === "nickname" &&
              row.register_required === 1 &&
              nickname === "") ||
            (row.userInfo === "full_name" &&
              row.register_required === 1 &&
              fullName === "") ||
            (row.userInfo === "id_number" &&
              row.register_required === 1 &&
              idNumber === "") ||
            (row.userInfo === "id_number" &&
              row.register_required === 1 &&
              idNumber.length !== 11) ||
            (row.userInfo === "password" &&
              row.register_required === 1 &&
              playerPassword === "") ||
            (row.userInfo === "transaction_password" &&
              row.register_required === 1 &&
              transactionPassword === "") ||
            (row.userInfo === "mobile_no" &&
              row.register_required === 1 &&
              playerContact === "") ||
            (row.userInfo === "mobile_no" &&
              row.register_required === 1 &&
              playerContact.length !== 11) ||
            (row.userInfo === "invitation_code" &&
              row.register_required === 1 &&
              invitationCode === "") ||
            (row.userInfo === "email" &&
              row.register_required === 1 &&
              email === "") ||
            (row.userInfo === "qq" &&
              row.register_required === 1 &&
              qq === "") ||
            (row.userInfo === "wechat" &&
              row.register_required === 1 &&
              wechat === "") ||
            (row.userInfo === "line" &&
              row.register_required === 1 &&
              line === "") ||
            (row.userInfo === "whatsapp" &&
              row.register_required === 1 &&
              whatsapp === "") ||
            (row.userInfo === "facebook" &&
              row.register_required === 1 &&
              facebook === "") ||
            (row.userInfo === "zalo" &&
              row.register_required === 1 &&
              zalo === "") ||
            isUsernameValid === false ||
            isPasswordValid === false ||
            passwordMatch === false ||
            playerOtp.length !== 6 ||
            usernameHelperText !== "" ||
            nicknameHelperText !== "" ||
            fullNameHelperText !== "" ||
            IDNumberHelperText !== "" ||
            passwordHelperText !== "" ||
            transactionPasswordHelperText !== "" ||
            mobileNOHelperText !== "" ||
            invitationCodeHelperText !== "" ||
            emailHelperText !== "" ||
            QQHelperText !== "" ||
            weChatHelperText !== "" ||
            lineHelperText !== "" ||
            whatsAppHelperText !== "" ||
            facebookHelperText !== "" ||
            zaloHelperText !== "" ||
            playerOtp === "" ||
            !termsChecked
        )
      : false;

  //REGEX VALIDATION
  const SAPMSWA = /^[A-Za-z][A-Za-z0-9]*$/;
  const SEF = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const SAP = /^[a-zA-Z0-9]+$/;
  const SAP = /^[a-zA-Z0-9\s]+$/;
  const SR1LA1NMIU = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d_]+$/;
  const NO = /^[0-9]*$/;
  const SAPWALOUC = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/;
  const AASOALOA = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
  const ABSNA = /^\S+$/;
  const YYYYMMDD = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  const AASALTAWS = /^(?=.*[A-Za-z].*[A-Za-z])[A-Za-z ]+$/;
  const MMDDYYYY = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  const BAANR = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;

  const handleChangeValidation = (e, row, fieldName) => {
    const { value } = e.target;
    let errors = false;
    let helperTexts = "";
    if (row.pattern === "Any") {
      errors = false;
      helperTexts = "";
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern, must start with alphabetical"
    ) {
      if (SAPMSWA.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must start with a letter and be followed by alphanumeric characters only";
      }
    } else if (row.pattern === "Standard email format") {
      if (SEF.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter a valid email address";
      }
    } else if (row.pattern === "Standard alphanumeric pattern") {
      if (SAP.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Please enter only letters and numbers. Special characters and spaces are not allowed";
      }
    } else if (
      row.pattern ===
      "Strictly require 1 letter and 1 number, may include underscore"
    ) {
      if (SR1LA1NMIU.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must include at least one letter and one number. An underscore (_) is optional";
      }
    } else if (row.pattern === "Number only") {
      if (NO.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter only alphanumeric characters";
      }
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern with at least one uppercase character"
    ) {
      if (SAPWALOUC.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and numbers, with at least one uppercase letter";
      }
    } else if (
      row.pattern === "Alphabet and spacing only, at least one alphabet"
    ) {
      if (AASOALOA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Only alphabetic characters and spaces allowed. Must include at least one letter";
      }
    } else if (row.pattern === "Any but spacing not allowed") {
      if (ABSNA.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Only non-spacing characters are allowed";
      }
    } else if (row.pattern === "yyyy-MM-dd") {
      if (YYYYMMDD.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format yyyy-MM-dd";
      }
    } else if (
      row.pattern ===
      "Alphabet and spacing only, at least two alphabets with spacing"
    ) {
      if (AASALTAWS.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and spaces, with at least two letters and at least one space.";
      }
    } else if (row.pattern === "mm-dd-yyyy") {
      if (MMDDYYYY.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format MM-DD-YYYY";
      }
    } else if (row.pattern === "Both alphabets and numbers required") {
      if (BAANR.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Must include both letters and numbers";
      }
    }
    if (fieldName === "nickname") {
      setNicknameError(errors);
      setNicknameHelperText(helperTexts);
    } else if (fieldName === "username") {
      setUsernameError(errors);
      setUsernameHelperText(helperTexts);
    } else if (fieldName === "password") {
      setPasswordError(errors);
      setPasswordHelperText(helperTexts);
    } else if (fieldName === "transactionPassword") {
      setTransactionPasswordError(errors);
      setTransactionPasswordHelperText(helperTexts);
    } else if (fieldName === "fullname") {
      setFullNameError(errors);
      setFullNameHelperText(helperTexts);
    } else if (fieldName === "IDNumber") {
      setIDNumberError(errors);
      setIDNumberHelperText(helperTexts);
    } else if (fieldName === "mobileNO") {
      setMobileNOError(errors);
      setMobileNOHelperText(helperTexts);
    } else if (fieldName === "invitationCode") {
      setInvitationCodeError(errors);
      setInvitationCodeHelperText(helperTexts);
    } else if (fieldName === "email") {
      setEmailError(errors);
      setEmailHelperText(helperTexts);
    } else if (fieldName === "qq") {
      setQQError(errors);
      setQQHelperText(helperTexts);
    } else if (fieldName === "wechat") {
      setWeChatError(errors);
      setWeChatHelperText(helperTexts);
    } else if (fieldName === "line") {
      setLineError(errors);
      setLineHelperText(helperTexts);
    } else if (fieldName === "whatsapp") {
      setWhatsAppError(errors);
      setWhatsAppHelperText(helperTexts);
    } else if (fieldName === "facebook") {
      setFacebookError(errors);
      setFacebookHelperText(helperTexts);
    } else if (fieldName === "zalo") {
      setZaloError(errors);
      setZaloHelperText(helperTexts);
    }
  };

  const handleTermsOfUse = (data) => {
    navigate(data);
  };

  const handleTermsChange = () => {
    setTermsChecked((prev) => !prev);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleToggleTransactionPasswordVisibility = () => {
    setShowTransactionPassword((prev) => !prev);
  };

  const handleSubmit = () => {
    setCircularLoading(true);
    axiosInstance
      .post(`player/account/register`, {
        nickname,
        username: playerUname,
        password: playerPassword,
        email,
        mobile_no: playerContact,
        id_number: idNumber,
        invitation_code: invitationCode,
        full_name: fullName,
        transaction_password: transactionPassword,
        qq,
        wechat,
        line,
        whatsapp,
        facebook,
        zalo,
        otp_code: playerOtp,
      })
      .then((res) => {
        if (res.data.status === "200") {
          setPlayerUname("");
          setPlayerContact("");
          setPlayerPassword("");
          setPlayerCpassword("");
          setPlayerOtp("");
          setSuccessModalOpen(true);
          setCircularLoading(false);
        } else if (res.data.msg === "Existing email. Use another email.") {
          toast.error("Existing email. Use another email.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setCircularLoading(false);
        } else if (
          res.data.msg === "No valid OTP was found. Please generate a new one."
        ) {
          toast.error("No valid OTP was found. Please generate a new one.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setCircularLoading(false);
        } else if (res.data.msg === "Invalid OTP") {
          toast.error("Invalid OTP.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setCircularLoading(false);
        } else if (res.data.msg === "OTP expired. Please generate a new one.") {
          toast.error("OTP expired. Please generate a new one.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setCircularLoading(false);
        } else if (
          res.data.msg ===
          "Player with the same Username, Contact Number already exists!"
        ) {
          toast.error(
            "Player with the same Username, Contact Number already exists!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
          setCircularLoading(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          console.error("Error registering user:", error.response.data.msg);
          setCircularLoading(false);
          toast.error(error.response.data.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.error("Error registering user:", error.message);
          toast.error("An unexpected error occurred. Please try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setCircularLoading(false);
        }
      });
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
    navigate("/m/login");
  };

  const handleOpenLogin = () => {
    setPlayerUname("");
    setPlayerContact("");
    setPlayerPassword("");
    setPlayerCpassword("");
    setPlayerOtp("");
    setIsPasswordValid(true);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setIsContactValid(true);
    setIsUsernameValid(true);
    navigate("/m/login");
  };

  const handleClose = () => {
    navigate("/m");
  };

  const handleOtp = () => {
    axiosInstance
      .post(`player/request-mobile-otp`, {
        mobile_no: playerContact,
        email: email,
      })
      .then((res) => {
        if (
          res.data.message === "OTP has been successfully sent to your Email."
        ) {
          toast.success("OTP has been successfully sent to your Email.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (res.data.msg === "Existing email. Use another email.") {
          toast.error("Existing email. Use another email.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        alert(error.response.data.message);
      });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <Container
      sx={{
        background: "#090929",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": { display: "none" },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingY: 1,
          }}
        >
          <CloseIcon
            onClick={handleClose}
            sx={{ color: "white", fontWeight: "bold" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <img alt="logo" src="../fbmlogoform.png" style={{ width: "50vw" }} />
        </Box>

        <Typography
          sx={{ fontSize: 18, color: "#fafafa", fontWeight: "bold", mb: 2 }}
        >
          Register
        </Typography>

        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "username" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Username
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={playerUname}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "username",
                          setPlayerUname(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: usernameHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={usernameError}
                      helperText={usernameHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "nickname" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Nickname
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={nickname}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "nickname",
                          setNickname(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: nicknameHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          width: "90%",
                          marginTop: "5px",
                          color: "#FFFFFF",
                          marginLeft: "30px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={nicknameError}
                      helperText={nicknameHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "full_name" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Fullname
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={fullName}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "fullname",
                          setFullName(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: fullNameHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          width: "90%",
                          marginTop: "5px",
                          color: "#FFFFFF",
                          marginLeft: "30px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={fullNameError}
                      helperText={fullNameHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "id_number" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      ID Number
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={idNumber}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "IDNumber",
                          setIDNumber(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: IDNumberHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          width: "90%",
                          marginTop: "5px",
                          color: "#FFFFFF",
                          marginLeft: "30px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={IDNumberError}
                      helperText={IDNumberHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "password" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      required
                      variant="standard"
                      id="player_password"
                      type={showPassword ? "text" : "password"}
                      value={playerPassword}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "password",
                          setPlayerPassword(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: passwordHelperText ? "25px" : "0px",
                      }}
                      autoComplete="new-password"
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              position: "relative",
                              bottom: "15px",
                              left: "15px",
                            }}
                          >
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                              style={{
                                color: "white",
                                top: "15px",
                                right: "38px",
                              }}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={passwordError}
                      helperText={passwordHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "password" && row.register_enabled === 1) {
                return (
                  <Box>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      <span style={{ color: "red" }}>*</span> Confirm Password
                    </Typography>
                    <TextField
                      sx={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      fullWidth
                      focused={false}
                      required
                      variant="standard"
                      id="player_cpassword"
                      type={showConfirmPassword ? "text" : "password"}
                      value={playerCpassword}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "confirmPassword",
                          setPlayerCpassword(e.target.value)
                        )
                      }
                      autoComplete="new-password"
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "3px",
                          width: "90%",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleToggleConfirmPasswordVisibility}
                              edge="end"
                              style={{
                                right: "20px",
                                color: "white",
                              }}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!passwordMatch && (
                      <Typography
                        sx={{
                          color: !passwordMatch ? "red" : "#555556",
                          fontSize: "11px",
                          textAlign: "center",
                          marginTop: "3px",
                        }}
                      >
                        Password do not match
                      </Typography>
                    )}
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (
                row.userInfo === "transaction_password" &&
                row.register_enabled === 1
              ) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Transaction Password
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      fullWidth
                      focused={false}
                      required
                      variant="standard"
                      type={showTransactionPassword ? "text" : "password"}
                      value={transactionPassword}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "transactionPassword",
                          setTransactionPassword(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: transactionPasswordHelperText
                          ? "25px"
                          : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              position: "relative",
                              bottom: "15px",
                              left: "15px",
                            }}
                          >
                            <IconButton
                              onClick={
                                handleToggleTransactionPasswordVisibility
                              }
                              edge="end"
                              style={{
                                color: "white",
                                top: "15px",
                                right: "38px",
                              }}
                            >
                              {showTransactionPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={transactionPasswordError}
                      helperText={transactionPasswordHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "mobile_no" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Mobile Number
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      fullWidth
                      focused={false}
                      required
                      variant="standard"
                      type="text"
                      value={playerContact}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "mobileNO",
                          setPlayerContact(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: mobileNOHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                        classes: {
                          underline: "textFieldUnderline",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          marginLeft: "10px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={mobileNOError}
                      helperText={mobileNOHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (
                row.userInfo === "invitation_code" &&
                row.register_enabled === 1
              ) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Invitation Code
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      fullWidth
                      focused={false}
                      required
                      variant="standard"
                      type="text"
                      value={invitationCode}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "invitationCode",
                          setInvitationCode(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: invitationCodeHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          height: "50px",
                          color: "#FFFFFF",
                          width: "85%",
                          marginLeft: "30px",
                        },
                        classes: {
                          underline: "textFieldUnderline",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          marginLeft: "10px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={invitationCodeError}
                      helperText={invitationCodeHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "email" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Email
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={email}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "email",
                          setEmail(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: emailHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={emailError}
                      helperText={emailHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "qq" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      QQ
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={qq}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "qq",
                          setQq(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: QQHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={QQError}
                      helperText={QQHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "wechat" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      WeChat
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={wechat}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "wechat",
                          setWechat(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: weChatHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={weChatError}
                      helperText={weChatHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "line" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Line
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={line}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "line",
                          setLine(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: lineHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={lineError}
                      helperText={lineHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "whatsapp" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      WhatsApp
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={whatsapp}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "whatsapp",
                          setWhatsapp(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: whatsAppHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={whatsAppError}
                      helperText={whatsAppHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "facebook" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Facebook
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={facebook}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "facebook",
                          setFacebook(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: facebookHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={facebookError}
                      helperText={facebookHelperText}
                    />
                  </Box>
                );
              }
            })}

          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "zalo" && row.register_enabled === 1) {
                return (
                  <Box key={row.userInfo}>
                    <Typography
                      sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
                    >
                      {row.register_required === 1 && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                      Zalo
                    </Typography>
                    <TextField
                      sx={{
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: "#16213e",
                        width: "100%",
                        "& label": {
                          marginTop: "0px",
                          marginLeft: "10px",
                        },
                        "& .MuiInputBase-root": {
                          padding: "-10px",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "&:hover .MuiInput-underline:before": {
                          borderBottom: "none !important",
                        },
                        "&:hover .MuiInput-underline:after": {
                          borderBottom: "none !important",
                        },
                      }}
                      focused={false}
                      fullWidth
                      required
                      variant="standard"
                      value={zalo}
                      onChange={(e) =>
                        handleChangeValidation(
                          e,
                          row,
                          "zalo",
                          setZalo(e.target.value)
                        )
                      }
                      style={{
                        marginBottom: zaloHelperText ? "25px" : "0px",
                      }}
                      inputProps={{
                        minLength: row.min,
                        maxLength: row.max,
                      }}
                      InputProps={{
                        style: {
                          color: "#FFFFFF",
                          marginLeft: "30px",
                          marginTop: "5px",
                          width: "90%",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          fontSize: "11px",
                          textAlign: "center",
                          paddingTop: "5px",
                        },
                      }}
                      error={zaloError}
                      helperText={zaloHelperText}
                    />
                  </Box>
                );
              }
            })}

          <Box>
            <Typography
              sx={{ color: "white", fontWeight: "bold", fontSize: 15 }}
            >
              <span style={{ color: "red" }}>*</span> OTP
            </Typography>
            <TextField
              sx={{
                width: "100%",
                height: "40px",
                borderRadius: "20px",
                backgroundColor: "#16213e",
                "& label": {
                  marginTop: "0px",
                  marginLeft: "10px",
                },
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "&:hover .MuiInput-underline:before": {
                  borderBottom: "none !important",
                },
                "&:hover .MuiInput-underline:after": {
                  borderBottom: "none !important",
                },
              }}
              fullWidth
              focused={false}
              variant="standard"
              value={playerOtp}
              onChange={(e) => {
                // Allow only numeric input
                const numericValue = e.target.value
                  .replace(/\D/g, "")
                  .slice(0, 6);
                setPlayerOtp(numericValue);
              }}
              InputProps={{
                inputProps: {
                  maxLength: 6,
                },
                style: {
                  height: "50px",
                  color: "#FFFFFF",
                  marginLeft: "30px",
                },
                endAdornment: (
                  <Stack
                    direction="row"
                    sx={{
                      marginLeft: "-105px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      onClick={handleOtp}
                      size="small"
                      label="Get Code"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "red",
                        fontSize: "11px",
                        padding: 0,
                      }}
                    />
                  </Stack>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 2,
              position: "relative",
            }}
          >
            {circularLoading ? (
              <>
                <Button
                  onClick={handleSubmit}
                  sx={{
                    width: "45%",
                    height: "35px",
                    backgroundColor: !circularLoading ? "red" : "gray",
                    fontWeight: "bold",
                    fontSize: 18,
                    borderRadius: 20,
                    color: "#fafafa",
                    textTransform: "capitalize",
                  }}
                  disabled={circularLoading}
                >
                  Register
                </Button>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "green",
                    position: "absolute",
                    top: "20%",
                    left: "18%",
                  }}
                />
              </>
            ) : (
              <Button
                onClick={handleSubmit}
                sx={{
                  width: "45%",
                  height: "35px",
                  backgroundColor: !validationButton ? "red" : "gray",
                  fontWeight: "bold",
                  fontSize: 18,
                  borderRadius: 20,
                  color: "#fafafa",
                  textTransform: "capitalize",
                }}
                disabled={validationButton}
              >
                Register
              </Button>
            )}
            <Button
              onClick={handleOpenLogin}
              sx={{
                width: "45%",
                height: "35px",
                backgroundColor: "#16213e",
                fontWeight: "bold",
                fontSize: 18,
                borderRadius: 20,
                color: "#fafafa",
                textTransform: "capitalize",
              }}
            >
              Login
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              mb: 4,
            }}
          >
            <Checkbox
              checked={termsChecked}
              onChange={handleTermsChange}
              sx={{
                color: "#FFFFFF",
                "&.Mui-checked": { color: "#66bb6a" },
              }}
            />
            <Typography
              variant="body2"
              sx={{ color: "white", fontSize: "14px" }}
            >
              I am at least 21 years of age and I accept the
              <Link
                onClick={(e) => handleTermsOfUse("/m/helpterms#about")}
                sx={{
                  color: "red",
                  fontWeight: "bold",
                  ml: 1,
                  cursor: "pointer",
                }}
              >
                Terms of Use
              </Link>
              &nbsp;and
              <Link
                onClick={(e) => handleTermsOfUse("/m/helpterms#privacy")}
                sx={{
                  color: "red",
                  fontWeight: "bold",
                  ml: 1,
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Dialog open={successModalOpen} onClose={handleSuccessModalClose}>
        <DialogContent>
          <Typography>
            Registration successful! Redirecting to the login page...
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Container>
  );
}
