import React from "react";
import { Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <div>
      <Typography
        id="about-modal"
        variant="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        PRIVACY POLICY OF FBM E-MOTION
      </Typography>
      <Typography sx={{ marginBottom: "40px", textAlign: "center" }}>
        (Last updated 17/11/2022)
      </Typography>
      <Typography
        variant="body1"
        sx={{ display: "inline", textAlign: "justify" }}
      >
        This Privacy policy is created in accordance with the Philippines’ Data
        Privacy Act of 2012 ("Data Privacy Act"). Our company fully respects and
        values your data privacy rights, and ensures that all personal data
        collected from you, including but not limited to, our guests, users,
        players, employees, suppliers, and partners ("You", "Your", "Players"),
        are processed in accordance with the general principles of transparency,
        and legitimate purpose, and proportionality.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        All personnel of our company, regardless of the type of employment or
        contractual arrangement, must comply with the Data Privacy Act and other
        related laws and/or regulations in handling the personal and sensitive
        information of the Players.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The purpose of this Privacy Policy is to inform You of how the Company
        collects, processes, uses, stores and protects Your Personal Data as
        well as the rights You have with respect to the processing of Your
        Personal Data.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        By visiting FBM E-Motion https://fbmemotion.ph/en-ph website (“Website”
        or “FBM E-Motion) and/or using our services, you consent to the
        collection, use, and disclosure of your information in accordance with
        this Privacy Policy. If you do not agree with the terms of this Privacy
        Policy, please do not use the Website or provide us with your personal
        information.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        If You choose not to provide Your Personal Information it may prevent Us
        from meeting legal obligations, fulfilling a contract, or performing
        services required to run Your account. Not providing Your Personal
        Information may mean We are unable to provide You Our products and
        services.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "15px" }}>
        DEFINITIONS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        “Personal Information” or “Personal Data”– refers to any information
        whether recorded in a material form or not, from which the identity of
        an individual is apparent or can be reasonably and directly ascertained
        by the entity holding the information, or when put together with other
        information would directly and certainly identify an individual.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        “Processing” refers to any operation or any set of operations performed
        upon personal information including, but not limited to, the collection,
        recording, organization, storage, updating or modification, retrieval,
        consultation, use, consolidation, blocking, erasure or destruction of
        data.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        “Services” – the provision of remote online games provided by the
        Company through the Website.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "15px" }}>
        WHO WE ARE
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Memo Multinational Corp, a company organized and existing under the laws
        of the Philippines, licensed to transact business by the Philippines
        Security and Exchange Commission, with SEC registration number
        2020120004440-13 and its principal address on One Corporate Centre,
        Julia Vargas Ave. Cor. Meralco Ave. Ortigas Centre- Pasig City,
        Philippines. (“Company”, “We”, “Us”, “Our” and “FBM E-Motion”) is the
        data controller of Your personal data and control the ways and the
        purposes for which Your Personal data is used by the Website, as defined
        by law, including those that may be sensitive or confidential, as
        disclosed by You in any activity related to the Company, including
        registration, participation, and enjoyment of any promos, programs,
        rewards, and privileges provided by FBM E-Motion.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "15px" }}>
        USE OF INFORMATION
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We fully respect Your fundamental rights and consider the protection of
        Your Personal Data to be a priority. Accordingly, when processing Your
        Personal Data, We respect the following basic principles:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        We submit Your Personal Data only to lawful and fair processing, and we
        maintain complete transparency vis-à-vis the way we handle Your Personal
        Data.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        We collect and process Your Personal Data only for specified, explicit,
        and legitimate purposes as outlined in this Privacy Notice and We do not
        process it further in any manner incompatible with these purposes.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        We process Your Personal Data only to the extent that it is necessary
        and appropriate to the purposes for which it is collected.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        We make reasonable efforts to ensure that Your Personal Data is accurate
        and updated, taking all reasonable steps to immediately delete or
        correct it in case of inaccuracy.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        We process Your Personal Data in a manner that guarantees its security
        by using appropriate technical and organizational measures.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        In general, we comply with all applicable laws and statutory obligations
        as Data Controller of Your Personal Data.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "15px" }}>
        TYPES OF PERSONAL DATA WE PROCESS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The information and data about You which We may collect, use and process
        includes the following:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Information that you provide to us by filling in forms on the Website or
        any other information you submit to us via the website or email;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Records of correspondence, whether via the website, email, telephone or
        other means;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Your responses to surveys or customer research that we carry out;
        Details of the transactions you carry out with us, whether via the
        website, telephone or other means;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Details of Your visits to the Website;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Any other Personal Data directly provided by You during Your interaction
        with Us.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Certain non-personal information is recorded by the standard operation
        of FBM E-Motion servers. Information such as the type of browser being
        used, its operating system, and your IP address is gathered in order to
        enhance Your online experience.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        From time to time, FBM E-Motion gathers Players' data via marketing
        activities such as promos, surveys, registration forms, downloads, etc.
        Participation in these activities happens on a voluntary basis,
        willingly - which allows the Players to decide whether to disclose any
        personal data. Such data may include contact information (e.g. name,
        email, phone number), demographic information (e.g. date of birth), or
        others. The data will be used to provide everyone with the service
        requested and to improve our services and may be used for marketing and
        promotional purposes by FBM E-Motion and its subsidiaries and may be
        shared with our promotional partners. Personal information is never sold
        or leased to any third parties.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The Player may choose to provide additional non-mandatory information
        that enables Us to provide more personalized service.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We may use Your contact information to send You information about Our
        companies and what we're doing.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "15px" }}>
        PURPOSES AND LEGAL BASIS FOR DATA PROCESSING
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The Company may process Your Personal Data to fulfill its obligations
        under the Terms and Conditions, for the following purposes:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Administration and development of the Website and the Services;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Enhancement of user experience, including the provision of personalized
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Services and improvement of the Website and the Services;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Development of new products, utilities and offerings;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Communicating with You in case You contact the Company or in case We
        need to provide You with important information or request Your feedback;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        and Verifying compliance with the Terms and Conditions of the Website.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The Company may process Your Personal Data only with Your lawful consent
        for the following purposes:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Commercial communication, marketing, and advertising of Our Services.
        You have the right to withdraw Your consent at any time in writing to
        Our contact details mentioned in this Privacy Policy. Withdrawal of Your
        consent does not affect the lawfulness of the treatment of Your data
        prior to its revocation.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The Company may process Your Personal Data based on its legitimate
        interest for the following purpose:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Defending the Company against any legal claims.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        DATA RECIPIENTS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        For the execution of the purposes mentioned in this Privacy Policy, We
        may provide access or transmit Your Personal Data to the following
        recipients (“Data Processors”):
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Law enforcement agencies, regulators, and other authorities;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Credit reference companies; Fraud prevention companies;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Identity verification companies;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Third parties You ask Us (or permit us) to share Your data with;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        And Third parties necessary to provide products or services which you
        have requested.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Our internet and data hosting providers for hosting purposes; and
        third-party providers, such as external services providers and/or
        employees or service providers of related companies, for the smooth
        operation of the Website and Our information and communication systems.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The processing of Your Personal Data by Our Data Processors is done
        under a contract compelling Data Processors to the same level of data
        protection provided under this Privacy Notice.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        All employees and personnel of the company shall maintain the
        confidentiality and secrecy of all personal data that come to their
        knowledge and possession, even after resignation, termination of
        contract, or other contractual relations. Personal data under the
        custody of the company shall be disclosed only pursuant to a lawful
        purpose, and to authorized recipients of such data.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Under conditions as allowed/permitted by law, FBM E-Motion may disclose
        user information in special cases when We have reasons to believe that
        disclosing this information is necessary to identify, contact, or bring
        legal action against someone/ any organization who/ that may be causing
        injury to or interference (either intentionally or unintentionally) with
        the Company's rights or property, other FBM E-Motion site users, or
        anyone else that could be harmed by such activities.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Lest otherwise provided herein, any other communications or materials
        You transmit to the Company's site by electronic mail or otherwise will
        be treated as non-confidential and non-proprietary. Anything You
        transmit or post may be used by FBM E-Motion or its affiliates for any
        purpose, including, but not limited to, reproduction, disclosure,
        transmission, publication, broadcast, and posting. Furthermore, FBM
        E-Motion is free to use any ideas, concepts, know-how, or techniques
        contained in any communication You send to the Company's site for any
        purpose whatsoever including, but not limited to, developing
        manufacturing, and marketing of products/ services using such
        information.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        In the event that We are required by a court or other administrative
        authority, pursuant to an investigation relating to unlawful activities
        and in any other case that We are legally bound to do so, the Company
        may transfer Your Personal Data to public authorities to the extent
        specified by law.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        SECURITY AND CONFIDENTIALITY
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        In the event that We are required by a court or other administrative
        authority, pursuant to an investigation relating to unlawful activities
        and in any other case that We are legally bound to do so, the Company
        may transfer Your Personal Data to public authorities to the extent
        specified by law.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        The processing of Your Personal Data by the Company is conducted in a
        manner that ensures confidentiality and security, taking into account
        the latest developments, implementation costs, and the nature, scope,
        context, and purposes of the processing, as well as the risks for Your
        rights and freedoms, which are applicable in each circumstance.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Your Personal Data is processed solely by authorized personnel of the
        Company, bound by strict obligations of confidentiality.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        DATA RETENTION
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We retain Your Personal Data for as long as is necessary to fulfill the
        relevant purposes of processing explained in this Privacy Policy.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Furthermore, the Company may retain Your Personal Data after the
        expiration of the relevant processing purposes based on Our legitimate
        interest to defend the Company against any potential legal claims,
        before any competent court or public authority. After the period of
        retention, Your Personal Data is erased from Our databases and systems.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        YOUR RIGHTS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We will assist You if You choose to exercise any of Your rights over
        Your Personal Data, including:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Withdrawing Your consent in any situation where We have asked for it,
        though this will not invalidate any previous processing from when We had
        Your consent;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Access to Your Personal Data that We hold or process;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        The ability to contest a decision made entirely by automated processing,
        to express Your point of view and to request that a human review the
        decision.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        For any requests relevant to these rights You can contact:
        [csupport@fbmemotion.ph].
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        YOUR OBLIGATIONS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        By using Our Website and by providing Your Personal Data, You
        acknowledge that You are required to provide Your actual, accurate, and
        complete data as requested by the Company. Furthermore, You must inform
        Us of any changes to Your information so as to ensure it is kept
        up-to-date and accurate.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        If You are found to be in breach of Your obligations or if We have
        reasonable suspicion that the information You provide is false or
        incomplete or in any way contrary to applicable law or this Privacy
        Policy, We retain the right to take appropriate legal measures.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        USE OF COOKIES
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Our Website uses cookies. To find out more about how We use cookies in
        Our Cookie Policy.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        CONTACT AND UNSUBSCRIBE
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        If You have any concerns or doubts, You can contact Us using:
        [csupport@fbmemotion.ph].
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        Players who wish to no longer receive Our promotional emails may
        withdraw their given consent and opt-out by clicking the unsubscribe
        link provided in every electronic message sent by Us.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        CHANGES TO OUR PRIVACY POLICY
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We may update this Privacy Policy from time to time, so please review it
        frequently.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "15px", textAlign: "justify" }}
      >
        We will not enforce material changes to the Privacy Policy without Your
        express consent. If You decline to accept the changes to the Privacy
        Policy, or otherwise do not accept the changes within the time period,
        we may not be able to continue to provide some or all products and
        services.
      </Typography>
      {/* </CustomModal> */}
    </div>
  );
};

export default PrivacyPolicy;
