import { Grid, Typography, Modal, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CASINO_SERVER } from "../../config";
import axios from "axios";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { format } from "date-fns";
import "./../css/style.css";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import InboxIcon from "@mui/icons-material/Inbox";

const ClaimHistory = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [initialData, setInitialData] = useState([]);
  const [scrollData, setScrollData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [filterButton, setFilterButton] = useState("All");
  const [filterOpen, setFilterOpen] = useState(false);
  const [totals, setTotals] = useState({ totalAmount: 0.0, totalPoints: 0.0 });
  const [filteredData, setFilteredData] = useState([]);

  const prevStartDate = useRef();
  const prevEndDate = useRef();
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const transactionStatus = {
    0: "Failed",
    1: "Success",
    7: "Pending",
  };
  const handleFilterButton = () => {
    setFilterOpen(true);
  };
  const handleClearButton = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setEndDate(format(new Date(), "yyyy-MM-dd"));
  };
  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    setScrollData([...scrollData, ...initialData]);
  }, [initialData]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight && !loading && hasMore) {
        fetchData();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${CASINO_SERVER}player/depositrecords/${username}`, {
        params: {
          page: page,
          pageSize: 5,
          startDate: startDate,
          endDate: endDate,
        },
      })
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          const transformedData = data.map((item) => ({
            ...item,
            trans_state:
              transactionStatus[item.trans_state] || item.trans_state,
          }));
          setInitialData(transformedData);
          setLoading(false);
          setHasMore(true);
        } else {
          setHasMore(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    prevStartDate.current = startDate;
    prevEndDate.current = endDate;
  };

  const calculateTotals = (data) => {
    return data.reduce(
      (acc, row) => {
        acc.totalAmount += parseFloat(row.real_amount || 0.0);
        acc.totalPoints += parseFloat(row.bonus_amount || 0.0);
        return acc;
      },
      { totalAmount: 0.0, totalPoints: 0.0 }
    );
  };

  useEffect(() => {
    const newTotals = calculateTotals(filteredData);
    setTotals(newTotals);
  }, [filteredData]);
  const handleDividerSelection = (selectedOption) => {
    setFilterButton(selectedOption);
    handleCloseFilter();
  };
  const handleCloseFilter = () => {
    setFilterOpen(false);
  };
  const handleBackButton = () => {
    navigate("/m/receivingCenter");
  };
  const handleCloseCalendar = () => {
    setPage(0);
    const formatDate = (date) => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };
    if (
      formatDate(dateRange[0].startDate) !== prevStartDate.current ||
      formatDate(dateRange[0].endDate) !== prevEndDate.current
    ) {
      setScrollData([]);
    }
    setStartDate(formatDate(dateRange[0].startDate));
    setEndDate(formatDate(dateRange[0].endDate));
    setCalendarOpen(false);
  };
  const totalAmount = scrollData.reduce((acc, transaction) => {
    const amount = Number(transaction.real_amount) || 0;
    return acc + amount;
  }, 0);
  const totalPoints = scrollData.reduce((acc, transaction) => {
    const points = transaction.real_amount / 0.0125;
    return acc + points;
  }, 0);
  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "14.5%", fontSize: "3.8vw" }}>
                Ticket Record
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}></Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          height: "30px",
          marginTop: "1%",
          display: "flex",
          borderBottom: ".1vw solid #dddddd",
        }}
      >
        {filterOpen && (
          <Modal
            open={filterOpen}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              marginTop: "auto",
              outline: "none",
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                outline: "none",
                padding: 2,
              }}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    fontSize: "4vw",

                    display: "flex",
                    justifyContent: "space-between",
                    color: "#9e9e9e",
                  }}
                >
                  Ticket type
                  <CloseIcon
                    open={filterOpen}
                    onClick={handleCloseFilter}
                    sx={{
                      marginTop: "2%",
                      color: "#888888",
                    }}
                  />
                </Typography>
                <Divider
                  sx={{
                    width: "calc(100% + 32px)",
                    marginLeft: "-16px",
                    marginTop: "8px",
                  }}
                />
                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <Typography
                    onClick={() => handleDividerSelection("All")}
                    sx={{
                      fontSize: "4vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    All
                  </Typography>
                </Grid>
                <Divider
                  sx={{
                    width: "calc(100% + 32px)",
                    marginLeft: "-16px",
                    marginTop: "8px",
                  }}
                />
                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <Typography
                    onClick={() => handleDividerSelection("Red Package")}
                    sx={{
                      fontSize: "4vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Red Package
                  </Typography>
                </Grid>
                <Divider
                  sx={{
                    width: "calc(100% + 32px)",
                    marginLeft: "-16px",
                    marginTop: "8px",
                  }}
                />
                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <Typography
                    onClick={() => handleDividerSelection("Golden Egg")}
                    sx={{
                      fontSize: "4vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Golden Egg
                  </Typography>
                </Grid>
                <Divider
                  sx={{
                    width: "calc(100% + 32px)",
                    marginLeft: "-16px",
                    marginTop: "8px",
                  }}
                />

                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <Typography
                    onClick={() => handleDividerSelection("Prize Wheel")}
                    sx={{
                      fontSize: "4vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Prize Wheel
                  </Typography>
                </Grid>
                <Divider
                  sx={{
                    width: "calc(100% + 32px)",
                    marginLeft: "-16px",
                    marginTop: "8px",
                  }}
                />

                <Grid container sx={{ height: "8vw", alignItems: "center" }}>
                  <Typography
                    onClick={() => handleDividerSelection("Cash Voucher")}
                    sx={{
                      fontSize: "4vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    Cash Voucher
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        )}
        <Grid onClick={handleFilterButton} container xs={6} sm={6} sx={{}}>
          <Grid
            container
            sx={{
              border: ".4vw solid #1c93ea",
              borderRadius: "1vw",
              width: "22vw",
              height: "100%",
              justifyContent: "center",
              alignContent: "center",
              marginLeft: "8%",
            }}
          >
            <Typography
              sx={{ fontSize: "3.3vw", color: "#1c93ea", marginTop: "4.5%" }}
            >
              {filterButton}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
          container
          xs={6}
          sm={6}
          sx={{
            flexDirection: "row-reverse",
            direction: "flex",
          }}
        >
          <Grid
            container
            sx={{
              border: ".4vw solid #1c93ea",
              borderRadius: "1vw",
              width: "56%",
              alignContent: "center",
              justifyContent: "center",
              marginLeft: "-63%",
            }}
          >
            <CalendarMonthIcon
              sx={{ marginLeft: "-5%", fontSize: "4.5vw", color: "#1c93ea" }}
            />
            <Typography
              sx={{
                fontSize: "3.3vw",
                color: "#1c93ea",
                marginTop: "1.5%",
                marginLeft: "5%",
              }}
            >
              {startDate.substring(5, 10).replace(/-/g, "/")}
            </Typography>
            <Typography
              sx={{
                fontSize: "3.3vw",
                color: "#1c93ea",

                marginTop: "1.5%",
              }}
            >
              -
            </Typography>
            <Typography
              sx={{
                fontSize: "3.3vw",
                color: "#1c93ea",
                marginTop: "1.5%",
              }}
            >
              {endDate.substring(5, 10).replace(/-/g, "/")}
            </Typography>
          </Grid>
        </Grid>
        {scrollData.map((row, index) => (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                margin: 1,

                backgroundColor: "yellow",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "4vw",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Product Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "4vw",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </Typography>
                <Typography
                  sx={{
                    fontSize: "4vw",
                    zIndex: 1,
                    color: "#333333",
                    fontWeight: "bold",
                    marginLeft: "13%",
                  }}
                >
                  Used Time
                </Typography>
              </Grid>
              <Grid>
                <Grid container direction="row" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "4vw",
                      color: "#333333",
                      display: "flex",
                      margin: 1,
                    }}
                  >
                    Ticket type:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "4vw",
                      color: "#333333",
                    }}
                  >
                    {row.real_amount}
                  </Typography>
                  <Grid container direction="row" alignItems="center">
                    <Typography
                      sx={{
                        fontSize: "4vw",
                        color: "#333333",
                        margin: 1,
                      }}
                    >
                      Ticket name:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "4vw",
                        color: "#333333",
                        marginBottom: "4%",
                        marginTop: "2%",
                        marginLeft: "17vw",
                      }}
                    ></Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {calendarOpen && (
          <Modal
            open={calendarOpen}
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#FFFFFF",
                outline: "none",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "2%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#898989",
                    fontWeight: "bold",
                    marginLeft: "36%",
                  }}
                >
                  {startDate.substring(5, 10).replace(/-/g, "/")}{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#898989",
                    fontWeight: "bold",
                    marginLeft: "3%",
                  }}
                >
                  -
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.7vw",
                    color: "#898989",
                    fontWeight: "bold",
                    marginLeft: "3%",
                  }}
                >
                  {endDate.substring(5, 10).replace(/-/g, "/")}{" "}
                </Typography>
                <Typography
                  onClick={handleClearButton}
                  sx={{
                    fontSize: "4vw",
                    color: "#4c84f7",
                    marginLeft: "23%",
                  }}
                >
                  Clear
                </Typography>
              </Grid>
              <Divider
                sx={{ width: "100%", marginTop: ".5%", height: "100%" }}
              />
              <Grid item xs={12} sm={12} sx={{ height: "100%", width: "100%" }}>
                <DateRange
                  onChange={(item) => setDateRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  showDateDisplay={false}
                  months={1}
                  className="calendarElement"
                  showMonthAndYearPickers={false}
                />
              </Grid>
              <Grid
                onClick={handleCloseCalendar}
                item
                xs={12}
                sm={12}
                sx={{
                  height: "10vw",
                  backgroundColor: "#4c84f7",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "4vw",
                    color: "#FFFFFF",
                    textAlign: "center",
                    marginTop: "2%",
                  }}
                >
                  OK
                </Typography>
              </Grid>
            </Grid>
          </Modal>
        )}
        <Grid
          container
          sx={{
            position: "fixed",
            bottom: "0",
            opacity: "100%",
            backgroundColor: "#fff",
            alignContent: "center",
            width: "100%",
            height: "10vw",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1,
          }}
        >
          <Grid
            container
            item
            xs={6}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", fontSize: "3vw", color: "#666" }}
            >
              Total Amount
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", fontSize: "3vw", color: "#666" }}
            >
              Total Points
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3vw",
              color: "#fd2f2f",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", fontSize: "3vw", color: "#fd2f2f" }}
            >
              {totalAmount}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3vw",
              color: "#fd2f2f",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", fontSize: "3vw", color: "#fd2f2f" }}
            >
              0.00
            </Typography>
          </Grid>
        </Grid>
        {scrollData.length === 0 && !loading && (
          <Grid
            container
            sx={{
              marginTop: "30%",
              justifyContent: "center",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <InboxIcon sx={{ color: "#1296db", fontSize: "40vw" }} />
            <Typography
              sx={{ textAlign: "center", color: "#1296db", fontSize: "6vw" }}
            >
              No Data
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default ClaimHistory;
