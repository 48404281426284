import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig.js";
import MainNavbarv1 from "../../pages/landingpage/Navbars/MainNavBar/MainNavBarv1";
import DashboardCarousel from "../../pages/landingpage/Banner/Banner.js";
import NewsMobile from "./NewsMobile.js";
import Gamepage from "../../pages/landingpage/Navbars/NavBarPages/GamePage.js";
import Footer from "../../assets/containers/section4-footer/Footer.js";
import PromotionMobile from "./promotion/PromotionMobile.js";
import HotGamesPage from "../../pages/landingpage/Navbars/NavBarPages/HotGamesPage.js";
import GameIframe from "../../pages/iframe/GameIframe.js";
import WinnerPageMobile from "./WinnerPageMobile.js";
import DepositRecordPageMobile from "./DepositRecordPageMobile.js";
import FooterAppbar from "./FooterAppbar.js";
import NotificationMobile from "./NotificationMobile.js";
import DialogForgotPasswordModal from "../../dialog/DialogForgotPasswordModal.js";

const Platform = (props) => {
  const { authToken, balance, setBalance, currentRoute } = props;
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const [gameIframeUrl, setGameIframeUrl] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const openLogin = () => setShowLogin(true);
  const closeLogin = () => setShowLogin(false);
  const [gameIframe, setGameIframe] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showRegistration, setShowRegistration] = useState(false);
  const openRegistration = () => setShowRegistration(true);
  const [showProfile, setShowProfile] = useState(false);
  const [favoriteButton, setFavoriteButton] = useState(false);
  const [data, setData] = useState("");
  const [isOpenHelpCenter, setIsOpenHelpCenter] = useState(false);
  const [selectedModalMenu, setSelectedModalMenu] = useState("about");
  const handleCloseModal = () => setIsOpenHelpCenter(false);
  const [gameTypes, setGameTypes] = useState([]);
  const [openForgotDialog, setOpenForgotDialog] = useState(false)

  const handleOpenModalParent = (newValue) => {
    setSelectedModalMenu(newValue);
    setIsOpenHelpCenter(true);
  };

  useEffect(() => {
    if (username) {
      axiosInstance
        .get(`player/balance`)
        .then((response) => {
          setBalance(response.data.balance);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const fetchData = () => {
    if (authToken) {
      axiosInstance
        .get(`player/profile/my-account`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  useEffect(() => {
    fetchData();

    axiosInstance.get('/check/reset')
      .then(response => {
        if (response.data.is_reset) {
          // setValues({ username: response.data.username })
          setOpenForgotDialog(true);
        }
      })
      .catch(error => {
        console.error('Error fetching reset flag:', error);
      });
  }, [authToken]);


  const handleClose = () => {
    setOpenForgotDialog(false);
  };


  const openProfile = (index) => {
    setShowProfile(true);
  };

  return (
    <Grid
      sx={{
        height: "100%",
        width: "100%",

        backgroundColor: "#090929",
        overflowX: "hidden",
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
      }}
    >
      <Grid container>
        {/* Pages */}
        <Grid item xs={12} sm={12}>
          <MainNavbarv1
            handleOpen={openLogin}
            handleRegistration={openRegistration}
            handleProfile={openProfile}
            data={data}
            gameIframe={gameIframe}
            authToken={authToken}
            setFavoriteButton={setFavoriteButton}
            setSearchQuery={setSearchQuery}
            setData={setData}
            balance={balance}
            setBalance={setBalance}
            gameTypes={gameTypes}
            setGameTypes={setGameTypes}
          />
        </Grid>
        {gameIframe ? (
          <>
            <GameIframe gameIframeUrl={gameIframeUrl} />
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12}>
              {currentRoute !== "/m/news" &&
                currentRoute !== "/m/activity" &&
                currentRoute !== "/m/winnerPageMobile" && <DashboardCarousel />}
            </Grid>
            {currentRoute === "/m/news" ? (
              <NewsMobile />
            ) : currentRoute === "/m/vouReport" ? (
              <DepositRecordPageMobile />
            ) : currentRoute === "/m/notification" ? (
              <NotificationMobile />
            ) : currentRoute === "/m/activity" ? (
              <PromotionMobile />
            ) : currentRoute === "/m/winnerPageMobile" ? (
              <WinnerPageMobile />
            ) : currentRoute === "/m/games/hot" ? (
              <HotGamesPage
                setGameIframe={setGameIframe}
                setGameIframeUrl={setGameIframeUrl}
                openLogin={openLogin}
              />
            ) : (
              <Gamepage
                setGameIframe={setGameIframe}
                setGameIframeUrl={setGameIframeUrl}
                openLogin={openLogin}
                gameTypes={gameTypes}
              />
            )}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ marginTop: "30px", overflow: "hidden" }}
            >
              {currentRoute !== "/m/news" && currentRoute !== "/m/activity" && (
                <Footer
                  isOpen={isOpenHelpCenter}
                  setIsOpenHelpCenter={setIsOpenHelpCenter}
                  handleOpenModalParent={handleOpenModalParent}
                  selectedModalMenu={selectedModalMenu}
                  setSelectedModalMenu={setSelectedModalMenu}
                  handleCloseModal={handleCloseModal}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <FooterAppbar
                currentRoute={currentRoute}
                navigate={navigate}
                authToken={authToken}
              />
            </Grid>
          </>
        )}
      </Grid>

      {openForgotDialog && <DialogForgotPasswordModal open={openForgotDialog} handleClose={handleClose}
      />}
    </Grid>
  );
};

export default Platform;
