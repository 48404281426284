import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchWithdrawalHistory = createAsyncThunk(
  "player/withdrawals",
  async (id) => {
    try {
      return id;
    } catch (error) {
      return error;
    }
  }
);

export const createWithdrawal = createAsyncThunk(
  "player/withdrawals/create",
  async ({ id, withdrawal }) => {
    try {
      return id;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  withdrawal: {
    centralWallet: "",
    availableAmount: "",
    eWallet: "",
    data: [],
  },
};

const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState,
  reducers: {
    setEWallet: (state, action) => {
      state.withdrawal.eWallet = action.payload;
    },
  },
});

export const { setEWallet } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
