import React from "react";
import { Grid } from "@mui/material";
import "./iframe.css";
const GameIframe = ({ gameIframeUrl }) => {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div className="wrapper">
        return (
        <iframe src={gameIframeUrl} title="Game Iframe" allowFullScreen />
        );
      </div>
    </Grid>
  );
};

export default GameIframe;
