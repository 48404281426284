import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import DialogMaximumWarning from "./DialogMaximumWarning";
import DialogInvalidTransactionPassword from "./DialogInvalidTransactionPassword";
import axiosInstance from "../../../../../axiosConfig";
import DialogMaximumRegisteredDesktop from "./DialogMaximumRegisteredDesktop";

const BindEwalletContainer = styled(Box)({
  width: "100%",
  padding: "20px",
  height: "100%",
});

const BindEwalletTextContainer = styled(Box)({
  borderLeft: "5px solid #23e63a",
  marginBottom: "20px",
});

const FormGroup = styled(Stack)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
});

const SelectedContainer = styled(Box)({
  borderRadius: "6px",
  border: "1px solid #FF0000",
  paddingTop: "10px",
  paddingBottom: "10px",
  height: "15px",
  width: "205px",
  textAlign: "center",
  backgroundColor: "rgba(255,48,57,.06)",
});

const RegisteredEwalletContainer = styled(Box)({
  width: "100%",
  padding: "20px",
  borderLeft: "1px solid #D5D5D5",
});

const RegisteredEwalletTextContainer = styled(Box)({
  borderLeft: "5px solid #FF0000",
});

const EwalletCardContainer = styled(Box)({
  background:
    "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/bgbank1-list.71c5a1a4.png)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  padding: "15px",
  marginBottom: "10px",
});

const EwalletCardContainer2 = styled(Box)({
  background:
    "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/bgbank2-list.9907e1cc.png)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  padding: "15px",
});

const EwalletLogoContainer = styled(Box)({
  backgroundColor: "#FFF",
  width: "83px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
});

const styles = {
  inputStyle: {
    display: "inline-block",
    verticalAlign: "middle",
    height: "34px",
    width: "200px",
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#ffffff",
    color: "#646464",
    outline: "0",
    padding: "2px",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0, 0, 0, .08) inset",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f5f5f5",
      borderBottom: "none",
    },
    "& label": {
      fontSize: "12px",
    },
    "& .MuiInputBase-root": {
      fontSize: "12px",
      width: "100%",
      height: "100%",
    },
  },
  select: {
    borderRadius: "5px",
    border: "1px solid #e5e5e5",
    backgroundColor: "#fff",
    color: "#646464",
    fontSize: "12px",
    boxShadow: "0 1px 5px rgba(0,0,0,.08) inset",
    height: "30px",
    verticalAlign: "middle",
    width: "205px",
    height: "40px",
    padding: "0 34px 0 5px",
  },
};

const BindEWallet = (props) => {
  const { openWithdraw, isAcoountManagement } = props;

  const [accountNumber, setAccountNumber] = useState("");
  const [transactionPassword, setTransactionPassword] = useState("");
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);
  const [dialogMaximumLimit, setDialogMaximumLimit] = useState(false);
  const [dialogInvalidTransactionPass, setDialogInvalidTransactionPass] =
    useState(false);
  const [dialogMaximumWarning, setdialogMaximumWarning] = useState(false);
  const [fullName, setFullName] = useState("");
  const [accountData, setAccountData] = useState({});
  const [profile, setProfile] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [fullNameHelperText, setFullNameHelperText] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [accountNumberHelperText, setAccountNumberHelperText] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [transactionPasswordHelperText, setTransactionPasswordHelperText] =
    useState("");
  const [transactionPasswordError, setTransactionPasswordError] = useState("");
  const [functionalSettings, setFunctionalSettings] = useState("");
  const [dataPlayerKeys, setDataPlayerKeys] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const SAPMSWA = /^[A-Za-z][A-Za-z0-9]*$/;
  const SEF = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const SAP = /^[a-zA-Z0-9]+$/;
  const SR1LA1NMIU = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d_]+$/;
  const NO = /^[0-9]*$/;
  const SAPWALOUC = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/;
  const AASOALOA = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
  const ABSNA = /^\S+$/;
  const YYYYMMDD = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  const AASALTAWS = /^(?=.*[A-Za-z].*[A-Za-z])[A-Za-z ]+$/;
  const MMDDYYYY = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  const BAANR = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;

  const numberAccount = /^09/;

  useEffect(() => {
    fetchFunctionalData();
    fetchPlayerKeys();
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance
      .get(`player/paymentmethod`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPlayerKeys = () => {
    axiosInstance
      .get(`player/playerkeys`)
      .then((response) => {
        setDataPlayerKeys(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  const fetchFunctionalData = () => {
    axiosInstance
      .get(`player/player-profile`)
      .then((response) => {
        const data = response.data;
        setAccountData(data);
        setFullName(data.full_name);
        setProfile(data);
      })
      .catch((err) => console.error(err));

    axiosInstance
      .get(`platform/management/functional/settings`)
      .then((response) => {
        setFunctionalSettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleChange = (e, row, fieldName) => {
    const { value } = e.target;
    let errors = false;
    let helperTexts = "";

    if (row.pattern === "Any") {
      errors = false;
      helperTexts = "";
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern, must start with alphabetical"
    ) {
      if (SAPMSWA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must start with a letter and be followed by alphanumeric characters only";
      }
    } else if (row.pattern === "Standard email format") {
      if (SEF.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter a valid email address";
      }
    } else if (row.pattern === "Standard alphanumeric pattern") {
      if (SAP.test(value)) {
        errors = false;
        helperTexts = "";
      } else if (value === "") {
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Please enter only letters and numbers. Special characters and spaces are not allowed";
      }
    } else if (
      row.pattern ===
      "Strictly require 1 letter and 1 number, may include underscore"
    ) {
      if (SR1LA1NMIU.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must include at least one letter and one number. An underscore (_) is optional";
      }
    } else if (row.pattern === "Number only") {
      if (NO.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter only alphanumeric characters";
      }
    } else if (
      row.pattern ===
      "Standard alphanumeric pattern with at least one uppercase character"
    ) {
      if (SAPWALOUC.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and numbers, with at least one uppercase letter";
      }
    } else if (
      row.pattern === "Alphabet and spacing only, at least one alphabet"
    ) {
      if (AASOALOA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Only alphabetic characters and spaces allowed. Must include at least one letter";
      }
    } else if (row.pattern === "Any but spacing not allowed") {
      if (ABSNA.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Only non-spacing characters are allowed";
      }
    } else if (row.pattern === "yyyy-MM-dd") {
      if (YYYYMMDD.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format yyyy-MM-dd";
      }
    } else if (
      row.pattern ===
      "Alphabet and spacing only, at least two alphabets with spacing"
    ) {
      if (AASALTAWS.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts =
          "Must contain only letters and spaces, with at least two letters and at least one space.";
      }
    } else if (row.pattern === "mm-dd-yyyy") {
      if (MMDDYYYY.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Please enter the date in the format MM-DD-YYYY";
      }
    } else if (row.pattern === "Both alphabets and numbers required") {
      if (BAANR.test(value)) {
        errors = false;
        helperTexts = "";
      } else {
        errors = true;
        helperTexts = "Must include both letters and numbers";
      }
    }
    if (fieldName === "fullName") {
      setFullName(value);
      setFullNameError(errors);
      setFullNameHelperText(helperTexts);
    } else if (fieldName === "account_number") {
      setAccountNumber(value);
      setAccountNumberError(errors);
      setAccountNumberHelperText(helperTexts);
      if (numberAccount.test(value)) {
        setIsAccountNumberValid(true);
      } else {
        setIsAccountNumberValid(false);
      }
    } else if (fieldName === "transaction_password") {
      setTransactionPassword(e.target.value);
      setIsAccountNumberValid(true);
      setTransactionPasswordError(errors);
      setTransactionPasswordHelperText(helperTexts);
    }
  };

  const maskAccountNumber = (accountNumber) => {
    accountNumber = String(accountNumber);
    const visibleDigits = 4;
    const maskedPart = "*".repeat(accountNumber.length - visibleDigits);
    const visiblePart = accountNumber.slice(-visibleDigits);
    return maskedPart + visiblePart;
  };

  // useEffect(() => {
  //   if (data && data.length === 2 && openWithdraw) {
  //     setdialogMaximumWarning(true);
  //   } else {
  //     setdialogMaximumWarning(false);
  //   }
  // }, [data, openWithdraw]);

  if (isLoading) {
    return <div>loading</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  const handleSubmit = () => {
    if (data.length <= 1) {
      axiosInstance
        .post(`paymentmethod/update/ewallet`, {
          type: "GCASH",
          account_number: accountNumber,
          name: fullName,
          transaction_password: transactionPassword,
        })
        .then((res) => {
          fetchFunctionalData();
          fetchData();
          setErrorMessage(res.data.msg);
          setDialogInvalidTransactionPass(true);
          setAccountNumber("");
          setTransactionPassword("");
        })
        .catch((error) => {});
    } else {
      setdialogMaximumWarning(true);
    }
  };
  return (
    <Stack direction="row" sx={{ height: "650px", width: "800px" }}>
      <BindEwalletContainer>
        <Box sx={{ height: "570px" }}>
          {isAcoountManagement ? (
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                border: "1px solid #ff2f34",
                maxWidth: "90px",
                lineHeight: "45px",
                marginBottom: "20px",
                borderRadius: "6px",
              }}
            >
              E-Wallet
            </Typography>
          ) : (
            <BindEwalletTextContainer>
              <Typography variant="body2" sx={{ paddingLeft: "20px" }}>
                Bind E-Wallet
              </Typography>
            </BindEwalletTextContainer>
          )}
          <FormGroup direction="row">
            <Typography variant="body2">E-wallet :</Typography>
            <select style={styles.select}>
              <option value="1">GCASH</option>
            </select>
          </FormGroup>
          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "full_name" && row.info_enabled === 1) {
                return (
                  <Stack
                    direction="row"
                    sx={{
                      marginBottom: "12px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">Name on Card :</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "205px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        type="text"
                        value={fullName}
                        onChange={(e) => handleChange(e, row, "fullName")}
                        sx={styles.inputStyle}
                        style={{
                          marginBottom: fullNameHelperText ? "20px" : "0px",
                        }}
                        error={fullNameError}
                        helperText={fullNameHelperText}
                        FormHelperTextProps={{
                          style: {
                            fontSize: "9px",
                            textAlign: "left",
                            marginTop: "10px",
                          },
                        }}
                        inputProps={{
                          minLength: row.min,
                          maxLength: row.max,
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        disabled={
                          profile.full_name !== null
                            ? true
                            : row.info_update !== 1
                            ? true
                            : false
                        }
                      />
                    </Box>
                  </Stack>
                );
              }
            })}
          <FormGroup direction="row">
            <Typography variant="body2">E-wallet Type :</Typography>
            <SelectedContainer>
              <Typography variant="body2" sx={{ color: "#FF0000" }}>
                GCASH
              </Typography>
            </SelectedContainer>
          </FormGroup>
          {functionalSettings &&
            functionalSettings.map((row) => {
              if (row.userInfo === "id_number" && row.info_enabled === 1) {
                return (
                  <>
                    <Stack
                      direction="row"
                      sx={{
                        marginBottom: "12px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2">Account Number :</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "205px",
                          paddingBottom: "0",
                        }}
                      >
                        <TextField
                          variant="standard"
                          type="text"
                          value={accountNumber}
                          onChange={(e) =>
                            handleChange(e, row, "account_number")
                          }
                          sx={styles.inputStyle}
                          style={{
                            marginBottom: accountNumberHelperText
                              ? "20px"
                              : "0px",
                            backgroundColor:
                              accountData.nickname === null
                                ? "#fff"
                                : "#f5f5f5",
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          error={accountNumberError}
                          helperText={accountNumberHelperText}
                          FormHelperTextProps={{
                            style: {
                              fontSize: "9px",
                              textAlign: "left",
                              marginTop: "10px",
                            },
                          }}
                          inputProps={{
                            minLength: row.min,
                            maxLength: row.max,
                          }}
                          disabled={row.info_update !== 1}
                        />
                        <Box>
                          {!isAccountNumberValid && accountNumber !== "" ? (
                            <>
                              <Typography
                                style={{
                                  color: "#d32f2f",
                                  fontSize: "9px",
                                  // marginTop: 10,
                                  marginLeft: 6,
                                }}
                              >
                                Please enter mobile number in 09 start format.
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography />
                            </>
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  </>
                );
              }
            })}
          {dataPlayerKeys.data.transaction_password !== null && (
            <>
              {functionalSettings &&
                functionalSettings.map((row) => {
                  if (
                    row.userInfo === "transaction_password" &&
                    row.info_enabled === 1
                  ) {
                    return (
                      <Stack
                        direction="row"
                        sx={{
                          marginBottom: "12px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2">
                          Transaction Password:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "205px",
                          }}
                        >
                          <TextField
                            variant="standard"
                            type="password"
                            value={transactionPassword}
                            onChange={(e) =>
                              handleChange(e, row, "transaction_password")
                            }
                            sx={styles.inputStyle}
                            style={{
                              marginBottom: transactionPasswordHelperText
                                ? "20px"
                                : "0px",
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            error={transactionPasswordError}
                            helperText={transactionPasswordHelperText}
                            FormHelperTextProps={{
                              style: {
                                fontSize: "9px",
                                textAlign: "left",
                                marginTop: "10px",
                              },
                            }}
                            inputProps={{
                              minLength: row.min,
                              maxLength: row.max,
                            }}
                            disabled={row.info_update !== 1}
                          />
                        </Box>
                      </Stack>
                    );
                  }
                })}
            </>
          )}{" "}
          {dataPlayerKeys.data.transaction_password === null && (
            <FormHelperText id="component-error-text" sx={{ color: "red" }}>
              Please set up your transaction password first. Go to{" "}
              <span style={{ fontWeight: "bold" }}>My Account</span>{" "}
              <span style={{ fontWeight: "bold" }}>{">"}</span>{" "}
              <span style={{ fontWeight: "bold" }}>Transaction Password</span>
            </FormHelperText>
          )}
        </Box>

        <div
          style={{
            borderTop: "1px solid #d3d3d3",
            width: "100%",
            marginTop: "-60px",
          }}
        >
          <Button
            sx={{
              backgroundColor:
                isAccountNumberValid && transactionPassword === ""
                  ? "#d5d5d5"
                  : isAccountNumberValid
                  ? "#fd2f2f"
                  : "#d5d5d5",

              border: "none",
              width: "30%",
              borderRadius: "20px",
              color: "#fff",
              cursor: "pointer",
              boxShadow: "0 0 10px #d5d5d5",
              marginTop: "10px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: isAccountNumberValid ? "#fd2f2f" : "#d5d5d5",
              },
            }}
            // disabled={dataPlayerKeys.data.transaction_password === null}
            disabled={transactionPassword === ""}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </BindEwalletContainer>
      <RegisteredEwalletContainer>
        <RegisteredEwalletTextContainer>
          <Typography
            variant="body2"
            sx={{ paddingLeft: "20px", marginBottom: "20px" }}
          >
            {data && <> Registered E-Wallet ({data.length}/2)</>}
          </Typography>
        </RegisteredEwalletTextContainer>
        {data &&
          data.map((row, index) => (
            <>
              {index === 0 ? (
                <>
                  <EwalletCardContainer key={index}>
                    <Stack direction="row">
                      <EwalletLogoContainer>
                        <img
                          style={{ width: "100%", height: "auto" }}
                          src="https://images.b51613.com:42666/mcs-images/bank/mcs_1632999242223_V3-MCS-CONSOLE-UPLOAD.jpg"
                        />
                      </EwalletLogoContainer>
                      <Box sx={{ marginLeft: "10px" }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          {row.e_wallet_type}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#666" }}>
                          {maskAccountNumber(row.account_number)}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ marginRight: "50px" }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: "#999", marginRight: "20px" }}
                      >
                        {formatDate(row.created_at)}
                      </Typography>
                    </Stack>
                  </EwalletCardContainer>
                </>
              ) : (
                <>
                  <EwalletCardContainer2 key={index}>
                    <Stack direction="row">
                      <EwalletLogoContainer>
                        <img
                          style={{ width: "100%", height: "auto" }}
                          src="https://images.b51613.com:42666/mcs-images/bank/mcs_1632999242223_V3-MCS-CONSOLE-UPLOAD.jpg"
                        />
                      </EwalletLogoContainer>
                      <Box sx={{ marginLeft: "10px" }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          {row.e_wallet_type}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#666" }}>
                          {maskAccountNumber(row.account_number)}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ marginRight: "50px" }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: "#999", marginRight: "20px" }}
                      >
                        {formatDate(row.created_at)}
                      </Typography>
                    </Stack>
                  </EwalletCardContainer2>
                </>
              )}
            </>
          ))}
      </RegisteredEwalletContainer>

      {dialogInvalidTransactionPass && (
        <DialogInvalidTransactionPassword
          dialogInvalidTransactionPass={dialogInvalidTransactionPass}
          setDialogInvalidTransactionPass={setDialogInvalidTransactionPass}
          errorMessage={errorMessage}
        />
      )}

      {dialogMaximumWarning && openWithdraw && (
        <DialogMaximumWarning
          dialogMaximumWarning={dialogMaximumWarning}
          setdialogMaximumWarning={setdialogMaximumWarning}
        />
      )}
      {dialogMaximumLimit && (
        <DialogMaximumRegisteredDesktop
          dialogMaximumLimit={dialogMaximumLimit}
          setDialogMaximumLimit={setDialogMaximumLimit}
        />
      )}
    </Stack>
  );
};

export default BindEWallet;
