import React, { useState, useEffect, useRef, useCallback } from "react";
import { usePlayer } from "../../../../contexts/PlayerContext";
import { useSearchParams, useLocation } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { Grid } from "@mui/material";
import GameNameSearch from "./components/GameNameSearch";
import SortButton from "./components/SortButton";
import FavoritesButton from "./components/FavoritesButton";
import GameProviderSwiper from "./components/GameProviderSwiper";
import GameList from "./components/GameList";
import LoadingModal from "../../../../utils/LoadingModal";
import GameTypesIcon from "../../GameTypes/GameTypesIcon";
import LandingPageMobile from "./LandingPageMobile";
import HotGamesPage from "./HotGamesPage";
import StraightIcon from "@mui/icons-material/Straight";
import axiosInstance from "../../../../axiosConfig";
export default function Gamepage({
  setGameIframe,
  setGameIframeUrl,
  openLogin,
  gameTypes,
}) {
  const { isMobile } = usePlayer();
  const [searchParams] = useSearchParams();
  const paramGameType = searchParams.get("type") || "";
  const paramProvider = searchParams.get("provider") || "";
  const location = useLocation();
  const currentRoute = location.pathname;

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const skipDebouncedQueryRef = useRef(false);
  const skipSortingTypeQueryRef = useRef(false);

  const [gameProviders, setGameProviders] = useState([]);
  const [filteredGameList, setFilteredGameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const pageSize = 18;
  const observer = useRef();
  const prevPageRef = useRef();
  const prevParamProviderRef = useRef();
  const prevParamGameTypeRef = useRef();
  const prevParamSortingTypeRef = useRef();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortingType, setSortingType] = useState("default");

  const [favoriteButtonState, setFavoriteButtonState] = useState(false);

  const handleSortAZ = () => {
    if (sortingType !== "ascending") {
      setSortingType("ascending");
    } else {
      setSortingType("default");
    }
  };

  const handleSortZA = () => {
    if (sortingType !== "descending") {
      setSortingType("descending");
    } else {
      setSortingType("default");
    }
  };

  const handleFavoriteClick = () => {
    setFavoriteButtonState((prevState) => !prevState);
  };

  const resetPage = (reset) => {
    setFilteredGameList([]);
    setPage(1);
    setHasMore(true);
    if (reset === true) {
      setSearchQuery("");
      setDebouncedQuery("");
      setSortingType("default");
      skipDebouncedQueryRef.current = true;
      skipSortingTypeQueryRef.current = true;
      fetchGames(1, reset);
    } else {
      fetchGames(1);
    }
  };

  useEffect(() => {
    fetchGameProviders();
  }, [paramGameType, isMobile]);

  useEffect(() => {
    let pageNow = 1;

    if (prevParamGameTypeRef.current !== paramGameType) {
      resetPage(true);
    } else if (prevParamProviderRef.current !== paramProvider) {
      resetPage(false);
    } else if (prevParamSortingTypeRef.current !== sortingType) {
      if (skipSortingTypeQueryRef.current && sortingType === "default") {
        skipSortingTypeQueryRef.current = false;
        return;
      }
      resetPage(false);
    } else if (prevPageRef.current !== page) {
      if (page !== pageNow) {
        fetchGames(page);
      }
    } else {
      if (skipDebouncedQueryRef.current && debouncedQuery === "") {
        skipDebouncedQueryRef.current = false;
        return;
      }
      resetPage(false);
    }

    prevParamProviderRef.current = paramProvider;
    prevParamGameTypeRef.current = paramGameType;
    prevParamSortingTypeRef.current = sortingType;
    prevPageRef.current = page;
  }, [paramProvider, paramGameType, page, debouncedQuery, sortingType]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 900);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const fetchGameProviders = async () => {
    const params = {
      type_id: paramGameType,
      provider_id: paramProvider,
      device: isMobile ? "mobile" : "web",
    };

    try {
      const response = await axiosInstance.get(`game/list/provider`, {
        params,
      });

      const uniqueSliderProvider = Array.from(
        response.data
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values()
      );
      setGameProviders(uniqueSliderProvider);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchGames = async (paramPage, resetSearchKey) => {
    if (isMobile && initialLoad && currentRoute === "/m") {
      return;
    }

    setLoading(true);

    const params = {
      type_id: paramGameType,
      provider_id: paramProvider,
      search: resetSearchKey ? "" : searchQuery,
      device: isMobile ? "mobile" : "web",
      page: paramPage ? paramPage : 1,
      size: pageSize,
      sorting: resetSearchKey ? "default" : sortingType,
    };

    try {
      const response = await axiosInstance.get(`game/list`, { params });
      const newGames = response.data.data.filter((game) => game !== null);

      if (newGames.length > 0) {
        setTimeout(() => {
          setLoading(false);
          setFilteredGameList((prevGames) => [...prevGames, ...newGames]);
        }, 200);

        if (newGames.length < pageSize) {
          setHasMore(false);
        }
      } else {
        setTimeout(() => {
          setLoading(false);
          setHasMore(false);
        }, 200);
      }
    } catch (err) {
      console.error(err);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  // const gameNameSearchRef = useRef(null);

  const lastGameElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          if (initialLoad) {
            setInitialLoad(false);
          } else {
            setPage((prevPage) => prevPage + 1);
            // if (gameNameSearchRef.current) {
            //   gameNameSearchRef.current.blur();
            // }
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, initialLoad]
  );

  return (
    <>
      {isMobile ? (
        <>
          <Grid
            container
            sx={{
              marginTop: "-45px",
              marginBottom: "70px",
              // marginLeft: "10px",
            }}
          >
            {currentRoute === "/m" ? (
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                <GameNameSearch
                  searchValue={searchQuery}
                  setSearchValue={setSearchQuery}
                  // inputRef={gameNameSearchRef}
                />
              </Grid>
            ) : (
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <GameNameSearch
                  searchValue={searchQuery}
                  setSearchValue={setSearchQuery}
                  // inputRef={gameNameSearchRef}
                />
              </Grid>
            )}

            {/* Sort Buttons AZ-ZA  */}
            {currentRoute === "/m" ? (
              <></>
            ) : (
              <>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <SortButton
                    onClick={handleSortAZ}
                    status={sortingType === "ascending"}
                    symbol={
                      <StraightIcon
                        sx={{
                          marginTop: "12px",
                          fontSize: "28px",
                          marginLeft: "-7px",
                        }}
                      />
                    }
                  >
                    A<br />Z
                  </SortButton>
                </Grid>

                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <SortButton
                    onClick={handleSortZA}
                    status={sortingType === "descending"}
                    symbol={
                      <StraightIcon
                        sx={{
                          rotate: "180deg",
                          marginTop: "12px",
                          fontSize: "28px",
                          marginLeft: "-7px",
                        }}
                      />
                    }
                  >
                    Z<br />A
                  </SortButton>
                </Grid>
              </>
            )}
            {/* <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <FavoritesButton
                state={favoriteButtonState}
                onClick={handleFavoriteClick}
              />
            </Grid> */}

            {/* REMOVE MARGIN TOP WHEN PUTTING BACK FAVORITES BUTTON*/}
            <Grid item xs={12} sm={12} sx={{ marginTop: "10%" }}>
              <GameTypesIcon gameTypes={gameTypes} />
            </Grid>

            {currentRoute === "/m" ? (
              <>
                {searchQuery !== "" ? (
                  <>
                    <GameList
                      filteredGameList={filteredGameList}
                      loading={loading}
                      setGameIframe={setGameIframe}
                      setGameIframeUrl={setGameIframeUrl}
                      openLogin={openLogin}
                    />
                  </>
                ) : (
                  <LandingPageMobile
                    setGameIframe={setGameIframe}
                    setGameIframeUrl={setGameIframeUrl}
                    openLogin={openLogin}
                    gameTypes={gameTypes}
                  />
                )}
              </>
            ) : currentRoute === "/m/games/hot" ? (
              <>
                {console.log("games/hot")}
                <HotGamesPage
                  setGameIframe={setGameIframe}
                  setGameIframeUrl={setGameIframeUrl}
                  openLogin={openLogin}
                  gameTypes={gameTypes}
                />
              </>
            ) : (
              <>
                <GameProviderSwiper gameProviders={gameProviders} />
                <GameList
                  filteredGameList={filteredGameList}
                  loading={loading}
                  setGameIframe={setGameIframe}
                  setGameIframeUrl={setGameIframeUrl}
                  openLogin={openLogin}
                />
              </>
            )}

            {currentRoute === "/m" ? (
              <>
                {searchQuery !== "" && (
                  <>
                    {loading && <LoadingModal loading={loading} />}
                    <div ref={lastGameElementRef}></div>
                  </>
                )}
              </>
            ) : (
              <>
                {loading && <LoadingModal loading={loading} />}
                <div ref={lastGameElementRef}></div>
              </>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid sx={{ marginTop: "60px" }}>
            <GameNameSearch
              searchValue={searchQuery}
              setSearchValue={setSearchQuery}
            />
            <SortButton
              onClick={handleSortAZ}
              status={sortingType === "ascending"}
            >
              A<br />Z
            </SortButton>
            <SortButton
              onClick={handleSortZA}
              status={sortingType === "descending"}
            >
              Z<br />A
            </SortButton>
            <FavoritesButton
              state={favoriteButtonState}
              onClick={handleFavoriteClick}
            />
          </Grid>

          <GameProviderSwiper gameProviders={gameProviders} />

          <GameList
            filteredGameList={filteredGameList}
            loading={loading}
            setGameIframe={setGameIframe}
            setGameIframeUrl={setGameIframeUrl}
            openLogin={openLogin}
          />

          {loading && <LoadingModal loading={loading} />}

          <div ref={lastGameElementRef}></div>
        </>
      )}
    </>
  );
}
