import Logo from "../assets/images/fbm/fbmemotionlogo.png";
import GnosisImg from "../assets/images/section3/image1.png";
import AvalancheImg from "../assets/images/section3/image1.png";
import ArbitrumImg from "../assets/images/section3/image1.png";
import FantomImg from "../assets/images/section3/image1.png";
import KlaytnImg from "../assets/images/section3/image1.png";
import AuroraImg from "../assets/images/section3/image1.png";
import FbmDrawer from "../assets/images/fbm/fbm-emotion-drawer.png";
import FooterLogo from "../assets/images/fbm/footer-fbmpagcor.png";

export const sideDrawer = {
  FbmDrawer,
};

export const footerContent = {
  FooterLogo,
};

export const navbarContent = {
  Logo,
};

export const section3Content = {
  title: "Hot Games",
  ITEMS: [
    { logo: GnosisImg, name: "Gnosis" },
    { logo: AvalancheImg, name: "Avalanche" },
    { logo: ArbitrumImg, name: "Arbitrum" },
    { logo: FantomImg, name: "Fantom" },
    { logo: KlaytnImg, name: "Klaytn" },
    { logo: AuroraImg, name: "Aurora" },
  ],
};
