import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { addDays } from "date-fns";
import AccountRecordCalendar from "./AccountRecordCalendar";
import AccountRecordTransaction from "./AccountRecordTransaction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const AccountRecordsMobile = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [scrollData, setScrollData] = useState([]);
  const [page, setPage] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const prevStartDate = useRef();
  const prevEndDate = useRef();
  const transactionRef = useRef(null);
  const calendarRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [transaction, setTransaction] = useState("All");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const transactionType = {
    1: "Deposit",
    2: "Withdrawal",
    3: "Betting",
    4: "Winning",
    5: "Bonus",
    6: "Rebate",
    7: "Agent Rebate",
  };

  const transactionInt = {
    Deposit: 1,
    Withdrawal: 2,
    Betting: 3,
    Winning: 4,
    Bonus: 5,
    Rebate: 6,
    "Agent Rebate": 7,
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, page, transaction]);

  useEffect(() => {
    setScrollData([...scrollData, ...initialData]);
  }, [initialData]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight && !loading && hasMore) {
        setPage(page + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  useEffect(() => {
    if (calendarOpen || transactionOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarOpen, transactionOpen]);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
    if (
      transactionRef.current &&
      !transactionRef.current.contains(event.target)
    ) {
      setTransactionOpen(false);
    }
  };

  const fetchData = () => {
    setLoading(true);
    const transactionId = transactionInt[transaction];
    axiosInstance
      .get(`player/transactionrecords`, {
        params: {
          page: page,
          pageSize: 5,
          startDate: startDate,
          endDate: endDate,
          transaction_type: transactionId,
        },
      })
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          const transformedData = data.map((item) => ({
            ...item,
            trans_type: transactionType[item.trans_type] || item.trans_type,
          }));
          setInitialData(transformedData);
          setLoading(false);
          setHasMore(true);
        } else {
          setHasMore(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    prevStartDate.current = startDate;
    prevEndDate.current = endDate;
  };

  const handleTransaction = (data) => {
    setPage(0);
    setScrollData([]);
    setTransaction(data);
    setTransactionOpen(false);
  };

  const handleTransactionOpen = () => {
    setTransactionOpen(true);
  };

  const handleBackButton = () => {
    navigate("/m/member/home");
  };

  const handleToday = () => {
    const today = format(new Date(), "yyyy-MM-dd");
    if (startDate !== today || endDate !== today) {
      setScrollData([]);
      setPage(0);
      setStartDate(format(new Date(), "yyyy-MM-dd"));
      setEndDate(format(new Date(), "yyyy-MM-dd"));
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    } else {
      setPage(0);
      setStartDate(format(new Date(), "yyyy-MM-dd"));
      setEndDate(format(new Date(), "yyyy-MM-dd"));
    }
  };

  const handleClearButton = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setEndDate(format(new Date(), "yyyy-MM-dd"));
  };

  const handleCloseCalendar = () => {
    setPage(0);
    const formatDate = (date) => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };
    if (
      formatDate(dateRange[0].startDate) !== prevStartDate.current ||
      formatDate(dateRange[0].endDate) !== prevEndDate.current
    ) {
      setScrollData([]);
    }
    setStartDate(formatDate(dateRange[0].startDate));
    setEndDate(formatDate(dateRange[0].endDate));
    setCalendarOpen(false);
  };

  const handleCloseFilter = () => {
    setTransactionOpen(false);
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Grid container sx={{ position: "fixed" }}>
          <Grid
            container
            sx={{
              height: "13.5vw",
              backgroundColor: "#1b2033",
            }}
          >
            <Grid item xs={4} sm={4}>
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <ArrowBackIosIcon
                  onClick={handleBackButton}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "6.5vw",
                    marginTop: "10%",
                    marginLeft: "-20%",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Grid
                container
                sx={{
                  color: "#FFFFFF",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ marginTop: "14.5%", fontSize: "3.8vw" }}>
                  Account Record
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            sx={{
              marginBottom: "5%",
              backgroundColor: "#f5f5f5",
              paddingTop: "2.5%",
              paddingBottom: "3%",
            }}
          >
            <Grid onClick={handleTransactionOpen}>
              <Grid
                sx={{
                  backgroundColor: "#e1e1e1",
                  borderRadius: "1vw",
                  width:
                    transaction === "All"
                      ? "13vw"
                      : transaction === "Deposit" ||
                        transaction === "Betting" ||
                        transaction === "Winning" ||
                        transaction === "Others"
                      ? "20vw"
                      : transaction === "Withdrawal"
                      ? "25vw"
                      : transaction === "Bonus" || transaction === "Rebate"
                      ? "19vw"
                      : "28vw",
                  height: "8vw",
                  display: "flex",
                }}
              >
                <KeyboardArrowDownOutlinedIcon
                  sx={{
                    fontSize: "6vw",
                    color: "#626262",
                    marginTop: transaction === "All" ? "5%" : "2%",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "3.3vw",
                    color: "#626262",
                    marginTop:
                      transaction === "All"
                        ? "8%"
                        : transaction === "Hub 88"
                        ? "6%"
                        : "4%",
                  }}
                >
                  {transaction}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              onClick={handleToday}
              sx={{
                backgroundColor:
                  startDate === format(new Date(), "yyyy-MM-dd") &&
                  endDate === format(new Date(), "yyyy-MM-dd")
                    ? "#108ee9"
                    : "#e1e1e1",
                borderRadius: "1vw",
                width: "20vw",
                height: "8vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: "5vw",
                  color:
                    startDate === format(new Date(), "yyyy-MM-dd") &&
                    endDate === format(new Date(), "yyyy-MM-dd")
                      ? "#FFFFFF"
                      : "#626262",
                }}
              />
              <Typography
                sx={{
                  fontSize: "3.3vw",
                  color:
                    startDate === format(new Date(), "yyyy-MM-dd") &&
                    endDate === format(new Date(), "yyyy-MM-dd")
                      ? "#FFFFFF"
                      : "#626262",
                  marginLeft: "2%",
                }}
              >
                Today
              </Typography>
            </Grid>
            <Grid
              onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
              sx={{ marginLeft: "4%", marginRight: "3%" }}
            >
              <Grid
                sx={{
                  backgroundColor: "#e1e1e1",
                  borderRadius: "1vw",
                  display: "flex",
                  width: "28vw",
                  height: "8vw",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CalendarMonthIcon
                  sx={{ fontSize: "4.5vw", color: "#626262" }}
                />
                <Typography
                  sx={{
                    fontSize: "3.3vw",
                    color: "#626262",
                  }}
                >
                  {startDate.substring(5, 10).replace(/-/g, "/")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.3vw",
                    color: "#626262",
                  }}
                >
                  -
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.3vw",
                    color: "#626262",
                  }}
                >
                  {endDate.substring(5, 10).replace(/-/g, "/")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "32%" }}>
          {scrollData.map((row, index) => (
            <Grid
              container
              sx={{
                marginBottom: "7%",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  marginLeft: "4.5%",
                  marginRight: "5%",
                  marginBottom: "2%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    paddingBottom: "1%",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      backgroundColor: "#FFFFFF",
                      paddingBottom: "1%",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="https://www.fbmemotion.ph/mobile/mc/bill.b01b2d35.png"
                      alt="icon"
                      style={{
                        width: "9vw",
                        height: "9vw",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "4vw",
                        color: "#626262",
                        fontWeight: "bold",
                        marginLeft: "2%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.trans_type}
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: "3.9vw",
                      color: "#626262",
                      fontWeight: "100",
                      alignContent: "center",
                    }}
                  >
                    {row.created_at.substring(0, 19).replace("T", " ")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    paddingBottom: "1%",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      backgroundColor: "#FFFFFF",
                      paddingBottom: "1%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#626262",
                        fontWeight: "100",
                      }}
                    >
                      Order no.: {row.trans_id}
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: "3.9vw",
                      color: "#626262",
                      fontWeight: "100",
                    }}
                  >
                    {row.real_amount > 0 ? "Debit" : "Credit"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      backgroundColor: "#FFFFFF",
                      paddingBottom: "1%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#626262",
                        fontWeight: "bold",
                      }}
                    >
                      Balance
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: "3.9vw",
                      color: "#626262",
                      fontWeight: "bold",
                    }}
                  >
                    Transaction amount
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    paddingBottom: "1%",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "3.9vw",
                        color: "#626262",
                        fontWeight: "bold",
                      }}
                    >
                      {row.post_balance}
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: "3.9vw",
                      color: row.real_amount > 0 ? "#5dd26d" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {row.real_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {calendarOpen && (
          <AccountRecordCalendar
            calendarOpen={calendarOpen}
            calendarRef={calendarRef}
            startDate={startDate}
            endDate={endDate}
            handleClearButton={handleClearButton}
            setDateRange={setDateRange}
            dateRange={dateRange}
            handleCloseCalendar={handleCloseCalendar}
          />
        )}
        {transactionOpen && (
          <AccountRecordTransaction
            transactionOpen={transactionOpen}
            transactionRef={transactionRef}
            handleCloseFilter={handleCloseFilter}
            setTransaction={setTransaction}
            handleTransaction={handleTransaction}
          />
        )}
      </Grid>
    </>
  );
};

export default AccountRecordsMobile;
