import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Link } from "@mui/material";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handlePrivacyPolicy = () => {
    navigate("/privacypolicy");
  };

  const handleCookies = () => {
    navigate("/cookies");
  };

  return (
    <div>
      <Typography
        id="about-modal"
        variant="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        TERMS AND CONDITIONS
      </Typography>
      <Typography sx={{ marginBottom: "40px", textAlign: "center" }}>
        Version 4.2, 24.01.2024
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
        INTRODUCTION
      </Typography>
      <Typography
        variant="body1"
        sx={{ display: "inline", textAlign: "justify" }}
      >
        These terms and conditions (hereinafter referred to as "Terms &
        Conditions" are a legally binding agreement that governs the
        relationship between Memo Multinational Corp and its related companies
        hereinafter referred to as "Company" or "We" or "Us" or "Our" and the
        individual hereinafter referred to as "Player" or "User" or "You" or
        “Your”, who accesses, registers as a User, visits, plays and uses FBM
        E-Motion platform and its content, which includes but is not limited to
        the games, services, texts, images, sounds, animations, videos, logos,
        game names, marks, trademarks, brands, features, mascots, graphics,
        source codes and all other pieces of information and the way they are
        graphically represented in the website{" "}
        <Typography
          variant="body1"
          sx={{ display: "inline", textDecoration: "underline" }}
        >
          fbmemotion.ph
        </Typography>
        , as well as their layout and structure hereinafter referred to as "FBM
        E-Motion".
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textAlign: "justify" }}
      >
        We kindly request that you carefully read these Terms & Conditions,
        including Our Cookie Policy ("Cookies Policy") and Privacy Policy
        ("Privacy Policy") which sets out how we collect and use your personal
        information. You can consult the{" "}
        <Link
          onClick={handlePrivacyPolicy}
          sx={{ margin: "0px -5px 0px -8px", textTransform: "none" }}
        >
          Privacy Policy
        </Link>
        and{" "}
        <Link
          onClick={handleCookies}
          sx={{ margin: "0px -5px 0px -8px", textTransform: "none" }}
        >
          Cookie Policy
        </Link>
        .
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: "20px",
          marginLeft: "50px",
          fontStyle: "italic",
          textAlign: "justify",
        }}
      >
        BY ACCESSING OR USING FBM E-MOTION AND ACCEPTING THESE TERMS &
        CONDITIONS, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE
        BOUND BY THESE TERMS & CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS &
        CONDITIONS AND/OR PRIVACY POLICY AND/OR COOKIE POLICY AND/OR RESPONSIBLE
        GAMING AWARENESS PROGRAM, YOU ARE KINDLY REQUESTED TO IMMEDIATELY STOP
        THE USE OF FBM E-MOTION AND REQUEST AN ACCOUNT CLOSURE FROM OUR CUSTOMER
        SUPPORT TEAM IF YOU ALREADY HAVE AN ACCOUNT.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "20px", textAlign: "justify" }}
      >
        Memo Multinational Corp., a company organized and existing under the
        laws of the Philippines, licensed to transact business in the
        Philippines by the Philippines Security and Exchange Commission, with
        SEC registration number 2020120004440-13, and has its Philippine
        principal address.We are regulated and accredited by the Philippines
        Amusement and Gaming Corporation (“PAGCOR”) under the provisions of the
        REGULATORY FRAMEWORK FOR THE REMOTE GAMING PLATFORM & REGULATORY
        FRAMEWORK FOR THE ACCREDITATION OF GAMING SYSTEM SERVICE PROVIDERS FOR
        E-BINGO AND E-CASINO, and any subsequent amendments if applicable, for
        the purposes of operating and offering an online channel developed to
        allow the players to remotely access the games deployed in the
        electronic gaming network at the land-based gaming operations through
        the internet via a website or mobile or desktop application made
        available on the operator’s site authorized by PAGCOR (“ hereinafter
        referred to as “Gaming Venue”)
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "20px", textAlign: "justify" }}
      >
        The games that our company promotes on the FBM E-Motion website are
        operated by us under our license.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        1. GENERAL INFORMATION AND USAGE
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.1. FBM E-motion addressed only persons who are not prohibited under
        the applicable law of their citizenship, domicile, or residence from
        having access to FBM E-motion and You may use it only as permitted by
        applicable laws and regulations.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.2. We only accept domestic bets within the Philippines. All bets
        accepted by FBM E-Motion are subjected to these Terms & Conditions. We
        reserve the right to amend these Terms & Conditions at any time without
        prior notification. All editions will show the version number and
        validity start date. It is recommended that you check this page
        periodically if you would like to monitor changes.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.3. You must register an account with FBMEmotion.ph before you can
        access the games.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.4. You must be at least twenty-one (21) years of age to open an
        account and not be under the persons that are not allowed to open an
        account and/or play with us described in clause 1.10 below.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.5. You must provide the following information and/or documents about
        yourself for the account registration, all these fields are mandatory to
        be completed:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Username
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Password
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Mobile Number
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.6. Foreign nationals are required to present a valid Alien
        Certification card, as well as a supporting document (e.g., Tax
        Certificate) in order to register with FBM E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.7. We reserve the right, at any time, to request from you any
        additional information and/or documentation if deemed necessary, for the
        account registration. If we are unable to verify or validate the
        information, we reserve the right to reject or cancel or terminate your
        account registration without notice and without liability to the Player.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.8. In case of any changes in the information and/or documents provided
        by you during the account registration, you must update your account
        details by contacting the Customer Support Team.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.9. You are responsible for completing the registration form and all
        information provided must be true, complete, and correct, under penalty
        of having your registration rejected, canceled, or blocked, in addition
        to being liable for any damages caused to us or third parties.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.10. The following persons are not allowed to open an account and/or
        play with us:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Persons under 21 years of age or students of any school, college, or
        university in the Philippines.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Government officials or employees connected directly with the operation
        of the government or any of its agencies.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Members of the Armed Forces of the Philippines, including the Army,
        Navy, Air Force, or Philippine National Police.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        PAGCOR officials and employees.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Persons included in the National Database of Restricted Persons (NDRP).
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Unregistered Players.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Gaming license holders and their employees.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        FBM E-Motion employees and/or of an employee of FBM E-motion or any
        affiliates of FBM E-motion ("relative" shall mean spouse, partner,
        parent, child, or sibling), or former employees or relative of a former
        employee of FBM E-motion within a period of 180 days.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.11. Submitting your registration with us is a confirmation that you
        are not present on any sanction list, you are not a Politically Exposed
        Person and Your funding is not gained through financial crime. It is the
        exclusive responsibility of each Player to ensure due compliance with
        the above‐mentioned rules, before registering with FBM E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.12. FBM E-Motion shall treat any breach of these Terms & Conditions as
        a serious matter and we may take any steps at law, which we deem fit and
        proper to protect our interests.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.13. The use of FBM E-Motion shall be exclusively personal, any kind of
        distribution, reproduction, commercialization, incorporation, public
        performance, reproduction in other websites and/or computers in a
        network environment, and any other kind of exploitation, for commercial
        and/or non-commercial purposes, and/or interference on the FBM E-Motion,
        and/or gain of unauthorized access to the systems or other Player’s
        accounts, are prohibited.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        1.14. By playing real money games, the Player accepts that the winnings
        due to the faulty software, by way of example but not limited to bugs,
        faulty codes, loopholes, and back doors, will not be paid, with the
        possible closure of the account.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        2. ACCOUNT
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.1. The Player warrants that all the information and documents he/she
        provided during the account registration, or at any time thereafter, are
        correct, accurate, and true.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.2. We reserve the right to immediately close any account if, according
        to our discretion, it was found that the information provided is
        inaccurate or insufficient. We also reserve the right to close a
        customer's account at any time, without stating the reason. All of the
        Player’s winnings shall be forfeited, and the amount deposited in the
        Player's account shall be returned to the Player, minus the number of
        winnings the Player has already cashed out.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.3. In order to access FBM E-Motion, You must first register with the
        FBM E-Motion website by completing the online registration form at
        https://fbmemotion.ph/en-ph.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.4. FBM E-Motion carries out verification procedures by completing the
        online registration form.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.5. Your failure to provide FBM E-Motion with true, complete, and
        correct information or documentation required to fulfill the FBM
        E-Motion’s compliance and regulatory obligations will result in the
        restriction, suspension, or closure of Your account, and the withholding
        of any funds in Your account until the FBM E-Motion’s verification
        process is complete.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.6. We reserve the right to immediately close any account if it was
        found that the information provided is inaccurate or insufficient. We
        also reserve the right to verify the information provided by the Player
        from time to time and take the necessary actions or even close a
        Player's account at any time, without stating the reason. All of the
        Player’s winnings shall be forfeited, and the amount deposited in the
        Player's account shall be returned to the player, minus the number of
        winnings the Player has already cashed out.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.7. We do not tolerate any act of identity theft and shall
        automatically deny account registration or close a registered account,
        forfeiting any amount deposited or winnings in it, if it finds that the
        Player is guilty of identity theft. FBM E-Motion is also not precluded
        to take the appropriate actions, as provided by law, in dealing with
        cases of identity theft on its platform.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.8. The Player warrants that he/she is the real and true owner of
        his/her account with FBM E-Motion and that he/she is not acting as a
        nominee of another person.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.9. The Player is responsible for the secrecy of his/her own account
        number and passwords. The account is personal to the Player who
        registered it and is not assignable or transferable to another person.
        The Player shall not allow another person to use his/her Player account.
        In the event the Player suspects that their personal information is
        stolen, please inform us immediately to change the detailed information.
        In case the Player shares his/her account details, such as username and
        password to a third party, the Player shall take full responsibility for
        the consequences of allowing such activity and, without derogating from
        the generality of any other indemnity contained herein, indemnifies hold
        FBM E-Motion free and harmless from, and indemnify FBM E-Motion for, all
        the consequences of such actions.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.10. You shall not impersonate other individuals when logging in or
        making use of the FBM E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.11. You are allowed to open and use one (1) account with the FBM
        E-Motion website. In the event FBM E-Motion finds or has reasonable
        suspicions that the Player has created multiple accounts, the company
        reserves the right to suspend or close all of the Player’s accounts and
        forfeit all of the player's winnings in all his/her accounts. Players
        shall also be liable towards FBM E-Motion for damages and costs incurred
        as a result of the fraud.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.12. We specifically do not permit the Players to do any of the
        following:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        To act on behalf of another person or a third party;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        To deposit money originating from criminal activity;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        To use in any manner whatsoever their Player account or that of third
        parties for money laundering or any other illegal purpose.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.13. The player shall not use any "bots" or artificial intelligence in
        the creation, maintenance, and use of his/her account.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.14. The Player is responsible for the secrecy of the account number,
        login names, and passwords and accepts responsibility for all
        activities, charges, and damages that occur under his/her account. Any
        online bet in the account will be effective. In the event the player
        suspects that his/her personal information is stolen, please inform our
        company immediately to change the detail information. We will not be
        responsible for any loss or damage resulting from your failure to notify
        us of unauthorized use.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.15. The Player must not be a compulsive gambler. In the event that the
        Player is showing signs of being a compulsive gambler, we reserve the
        right to suspend, cancel or terminate the Player's registration with,
        and access to FBM E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.16. For Players who requested self-exclusion, we will suspend their
        account up to the requested time.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.17. For those players who are subject to family-requested exclusion or
        PAGCOR initiated exclusion, FBM E-Motion will suspend the Player’s
        account. Once the account has been suspended, the same can be activated
        only after 24 hours from the time the request for exclusion has been
        canceled, lifted, or revoked. Please note that, once an exclusion order
        is submitted with PAGCOR, such a request cannot be revoked before the
        expiration date, and self-exclusion cannot be lifted before the chosen
        self-exclusion period.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.18. Following a self-exclusion request, we will use all reasonable
        efforts not to send You any further marketing materials.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.19. You are not allowed to transfer funds from your account to other
        users’ accounts, or to receive funds from other users into your account,
        or to sell, transfer and/or acquire accounts to/from other users.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        2.20. Users can temporarily or permanently close his/her account at any
        time for any reason by contacting the Customer Service Team of FBM
        E-Motion. You can find more information in our FAQ.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        3. DEPOSIT
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.1. In order to play and bet on the games available in the FBM
        E-Motion, you shall deposit into his/her FBM E-Motion account.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.2. The deposit into their FBM E-Motion account can only be done
        directly at FBM E-Motion Website.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        The only accepted currency for any transaction with FBM E-Motion is the
        Philippine Peso (PHP).
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        3.2.1. The Player must make an initial cash deposit, in the amount of
        1000 PHP as per Pagcor compliance monitoring letter “F”. This amount can
        be used by the player after 30 days from the date of deposit.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.3. It is your responsibility to notify our Customer Support Team
        immediately should funds be credited to your account in error. Should
        this occur, these funds are not available for use and we reserve the
        right to cash-out these funds and void any transactions placed using
        them.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.4. FBM E-Motion may set off any positive balance on the Player's
        account against any amount that the Player owes to FBM E-Motion,
        including but not limited to, re-settling of bets, payment errors, or
        negative balances.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.5. The Player warrants that the money deposited or cashed-in in
        his/her account does not originate from any criminal or illegal
        activity, and that his/her FBM E-Motion account shall not be used for
        money laundering and/or commission of fraudulent or illegal acts. In the
        event that FBM E-Motion has reasonable suspicion that the Player
        violated this provision, We will automatically close the Player's
        account and forfeit all amounts (whether deposits or winnings) in the
        account.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.6. The Player holds free and harmless and shall indemnify FBM
        E-Motion, from any claims, damages, injuries, liabilities, losses, and
        suits arising from the player's fault, negligence, omission, including
        those arising from Player's criminal, illegal, or fraudulent activity.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.7. We respect the confidentiality of the Player's account and will not
        reveal the Player's transactions and current balance to any third party,
        unless it is required by law or ordered by a competent court or
        government agency or that the money deposited is subject to an
        investigation or a pending case before the court or any government
        agency.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        3.8. Funds deposited in the Player's account do not earn any interest.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        4. GAMES
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.1 The Player must ensure that he/she has read, understood, and agree
        to these Terms & Conditions and Game Rules, especially those regarding
        the Player's gaming activity and account usage, before confirming
        his/her bet. The Player must ensure that he/she understands the terms of
        the registration of the bet request before he/she confirms the placing
        of the bet.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.2. The Company reserves the right, at its absolute discretion, to stop
        offering any of its games at any point. Any money staked or bets placed
        on such a game will be refunded back to your account.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.3. The Player shall use the games in a proper and careful manner and
        in compliance with all laws, ordinances, and regulations regarding the
        use of the games.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.4. You understand that by participating in the games You take the risk
        of losing money deposited in your Account. As with any game of chance,
        FBM E-Motion does not guarantee the Player any winning for his/her bets.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.5. The necessary funds must be deposited into the Player's gaming
        account before the bet can be accepted by FBM E-Motion. All bets must be
        supported by sufficient funds in the Player's account FBM E-Motion
        reserves the right to void any bet which has been placed when the
        Player's account does not have sufficient funds to support the gameplay.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.6. The winnings of the Player will automatically reflect in his/her
        account after it has been confirmed by FBM E-Motion. It is the Player's
        responsibility to monitor his/her account balance and winnings and
        notify FBM E-Motion's Customer Support Team via email or live chat of
        any mistake or delay in the crediting of his/her winnings to his/her
        account. Winnings credited to an account in error are not available for
        use and we reserve the right to void any transaction involving such
        funds.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.7. In the event of a discrepancy between the result showing on the
        Player's account and the FBM E-Motion's server software, the result
        showing on the FBM E-Motion's server software shall be the official and
        governing results.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.8. FBM E-Motion may set off any positive balance on the Player's
        account against any amount that the Player owes to FBM E-Motion,
        including but not limited to, re-settling of bets, payment errors, or
        negative balances.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.9. We shall not be responsible for failure or error (e.g., duplicates
        and discrepancies) in the Player's placement of bets due to technical
        problems or due to the Player's fault or negligence.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.10. We reserve the right to void bets placed using funds credited to
        the Player's account in error and to recover such funds by account
        adjustment at any time. In the event that the error is proven to be
        caused by game errors, internet connections, and crashed games, FBM
        E-Motions will reset the player's balance to the balance prior to when
        the error occurred.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.11. Players may place bets up to the game's bet limit. We reserve the
        right in our sole discretion to amend both its cash‐in, cash‐out, and
        betting limits, per game or per Player's account anytime.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.12. It is the Player's responsibility to own account transactions and
        to ensure bet details are correct (press play on that Game, he/she
        wishes to bet). Once bets have been placed, the player can no longer
        cancel or change them.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.13. We do not assume liability for correctness, completeness, or
        up‐to‐dateness of the information services provided. Although We will
        endeavor to ensure that the information/data we provide is correct and
        accurate, if an error is made, be it a human error or an error resulting
        from technical problems, we do not assume any liability whatsoever
        resulting from that error or from reliance on anyone's part of our data.
        It is the Player's responsibility to notify our Customer Support Team
        immediately should funds be credited to their FBM E-Motion account in
        error. Should this occur, these funds are not available for use and we
        reserve the right to withdraw these funds and void any transactions
        placed using them.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.14. Players may cash out from their account at any time provided that
        payments have been confirmed.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        4.15. Only the Player can request his/her winnings or account balance
        for withdrawal from his/her FBM E-Motion account.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        5. BONUS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        5.1. The Company may offer a bonus, at the sole discretion, from time to
        time, for certain games available at FBM E-Motion. Bonus funds may be
        withdrawn, at any time, provided that the player has:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Made a successful deposit (If it was required in that bonus promotion);
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Verified his/her age and identity accurately within the FBM E-Motion;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Wagered his/her bonus (fulfilled the bonus conditions).
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        5.2. All withdrawal requests of bonus funds are subject to existing
        withdrawal wagering requirements and conditions. Withdrawal requests
        will be subject to verification of duplicate accounts and screening for
        circumvention of these Terms & Conditions.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        5.3. Bonus funds will be void and removed from the Player's account
        after the bonus is expired.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        6. PLAYERS CONDUCT
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.1. The Player must abide by all the game rules provided by FBM
        E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.2. The Player can only access and use his/her account with FBM
        E-Motion within the Philippines territory.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.3. The Player must not use his/her account or access to FBM E-Motion
        for any purpose against the law, public policy, or public morals.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.4. In the event that FBM E-Motion or related parties offer game promos
        to its players, the Player must strictly adhere to the promo mechanics.
        FBM E-Motion will not tolerate any manipulation committed by the Player
        to be eligible for the game promotions, and will automatically suspend
        or terminate the Player's account for such manipulation or fraud, and
        forfeit all amounts (whether deposits or winnings) in the account.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.5. The Player holds FBM E-Motion free and harmless from any claims,
        liabilities, or damages, arising from the Player's involvement with and
        conduct in FBM E-Motion and these Terms & Conditions.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.6. In case FBM E-Motion is impleaded in any claims or suits because of
        the Player, the Player shall indemnify FBM E-Motion for damages,
        injuries, losses, liabilities, and all the costs and expenses that FBM
        E-Motion incurred.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        6.7. A Player who has any concerns, questions, or complaints should
        contact the Customer Support Team via live chat, or email
        (csupport@fbmemotion.ph).
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        7. FINANCE
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        7.1. By accepting these Terms & Conditions, the Player warrants that
        he/she is the rightful owner of the money which he/she deposits to his
        gaming account.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        7.2. In the event that FBM E-Motion finds that the players deposits into
        the player's FBM E-Motion account do not come from the Player or the
        full name of the depositor does not match the Player's registered name
        at FBM E-Motion, we reserve the right to reject such deposit
        transactions, and any winnings from such deposits will be considered
        cancelled. We require verification documents from the Player before
        allowing any deposit transactions to the Player's account and we might
        ask for verification documents prior to withdrawal requests.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        7.3. In accordance with the requirements of the PAGCOR regulations on
        the protection of customer funds, FBM E-Motion is obliged to inform the
        Players about the disposition of funds that FBM E-Motion holds on
        account for the players, and the extent to which such funds are
        protected in the unlikely event of FBM E-Motion's insolvency.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        7.4. FBM E-Motion holds all players' funds wholly separate from other
        FBM E-Motion's funds in a legally designated Player account. This means
        that all such funds are protected, and in the case of any operational
        difficulties, the Company can remit to the Players the funds that are
        due and payable to the Players.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        8. VERIFICATION
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        8.1. A Player's withdrawal request may only be made after fulfilling the
        identity verification procedures.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        8.2. Upon confirmation of money transfer from FBM E-Motion to the
        payment service provider, when it is available, we shall not be liable
        for any damages, injuries, liabilities, loss, or cost, suffered or
        incurred by a Player (including any damages, loss, or costs suffered or
        incurred as a result of a failure to pay or delay in payment).
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        8.3. A Player may request for a withdrawal of funds from the Player's
        account, at any time, provided all:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Funds in the Player’s account have been confirmed as cleared;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Verification and identification checks have been completed to FBM
        E-Motion's satisfaction;
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        Conditions of withdrawal that may be applied to the Player’s account
        have been met.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        8.4. Subject to the above conditions, a refund will be issued in the
        event that a withdrawal request is made without the wagering of funds.
        The funds will be issued as a refund in accordance with the payment
        instrument used by the Player.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        8.5. Players are NOT permitted to deposit or through a third-party
        person or account, in any circumstance.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        9. DORMANT ACCOUNTS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        9.1. A Player's account will be categorized as a dormant account if
        there is no transaction made for a continuous period of 12 (twelve)
        months since the Player's last login date.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        9.2. The player's remaining balance (if any) will be forfeited upon the
        closure of the player's account. In the event that a player wishes to
        re-open or reinstate the closed account or redeem the withheld balance,
        the player may contact our Customer Support Team. In such a case, the
        Player will have to roll over the equal balance amount by once and must
        update its registration information, including by sending the documents
        initially requested.
      </Typography>
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
      >
        10. MISCELLANEOUS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.1. As a condition of your use of the FBM E-Motion, You warrant to FBM
        E-Motion that You will not use it for any purpose that is unlawful or
        prohibited by these Terms & Conditions, and any notices.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.2. This document was written in English. In the event of a
        discrepancy between the and any other language version, the English
        language version shall prevail.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.3. Any rights not expressly granted herein are reserved.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.4. We do not accept any responsibility for failure in any equipment
        or telecommunication that prevents the placing of correct bets.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.5. All rights granted to FBM E-Motion in this Terms & Conditions are
        equally granted, to the juridical entity that owns and operates FBM
        E-Motion. Thus, we can use it interchangeably in this Terms &
        Conditions.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.6. We make no representations or warranties for any linked websites
        or advertisements that the Player encounters while using the FBM
        E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.7. Although we will exert our best efforts to ensure that the
        information provided on its website is correct and accurate, FBM
        E-Motion shall not be held liable for any incorrectness, inaccuracy, or
        outdatedness of the information on its website. E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.8. The Player allows Us to send him/her regular updates in the email
        address and contact number he/she provided in the account registration.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "80px", marginTop: "10px", textAlign: "justify" }}
      >
        If you choose not to receive promotional material, we will use all
        reasonable endeavors not to send you any further promotional or
        marketing material.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.9. All trademarks, marks, design rights, interfaces, copyrights,
        images, animations, audio, videos, text, publication materials, codes,
        source codes, and software, even if not registered or incapable of
        registration, made available by Us to the Players and/or to the public
        are owned exclusively by or were licensed for the use of FBM E-Motion
        and shall not be used or reproduced by any Player or third party. You
        shall have no right, title, interest, and or any intellectual property
        owned or controlled by the Company or its licensors.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.10. The Player shall not, in any way, integrally or partially,
        duplicate, copy, use, modify, alter, reproduce, adapt, decode, reverse
        engineer, decompile, translate, convert, as a basis to create any
        derivative work based on FBM E-Motion content, source codes, software,
        and software development programs, under penalty of the applicable
        judicial measures.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.11. Any modification, adaptation, enhancement, updates, and upgrade
        of the intellectual property are, and shall at all times be and remain,
        the sole and exclusive property of the Company or its licensors. You
        shall have no right, title, interest, and or any intellectual property
        owned or controlled by the Company or its licensors.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.12. Your registration and use of the FBM E-Motion website and Games
        does not confer any rights whatsoever to the intellectual property
        contained in or on the same FBM E-Motion website and Games.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.13. FBM E-Motion and its affiliates, officers, directors, and
        employees shall not be held liable to the Player or to any third party
        for the consequences, damages, losses, liabilities, or costs and
        expenses caused by the fault, negligence, or omission of the Player
        while using the FBM E-Motion.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.14. FBM E-Motion shall comply with the Philippine Data Privacy Act
        and other related laws and/or regulations in handling the personal and
        sensitive information of the Players.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.15. We cannot guarantee that FBM E-Motion works without errors, nor
        that resulting failures or defects are corrected, nor that the
        respective server and/or download are free of virus or other eventually
        damaging or defective components or other errors or faults.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        10.16. We do not warrant that FBM E-Motion is safe from unauthorized
        access or attacks from third parties.
      </Typography>
      <Typography sx={{ marginTop: "50px", textAlign: "center" }}>
        ***********************************************************************
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
          marginTop: "50px",
        }}
      >
        ACCEPTANCE OF TERMS AND CONDITIONS
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        These Terms & Conditions shall govern the relationship between Us and
        the Player and shall be subject to the rules and regulations of PAGCOR.
        In case of conflict between these Terms & Conditions and the PAGCOR
        rules and regulations, the latter shall prevail.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        FBM E-Motion may change, amend, or modify these Terms & Conditions at
        any time, without any prior notification to the Player.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        It is the Player's responsibility to check whether there are changes or
        updates made in these Terms & Conditions. If the Player's continued use
        of his/her account and FBM Group's after the update signifies the
        Player's acceptance of said changes, amendments, modifications, or
        updates in the Terms & Conditions.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        In case, any part of these Terms & Conditions, or its amendments is
        deemed invalid or unenforceable, the remaining unaffected provisions
        shall continue to be valid and enforceable.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        It is the responsibility of the Player to ensure his/her compliance with
        these Terms & Conditions and its amendments thereafter.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        We can automatically and unilaterally terminate the Player's account for
        any breach of these Terms & Conditions. The aforesaid right shall not
        prejudice the right of FBM E-Motion to secure such other remedies
        provided by law and equity.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        We reserve the right to take the necessary legal actions, aside from the
        closure of the Player's account, to protect its interest in the event
        that the Player breaches any of the provisions in these Terms &
        Conditions.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        These Terms & Conditions shall be governed by, construed, and enforced
        in accordance with the laws of the Philippines. Any action arising from
        the provisions of these Terms & Conditions shall be exclusively resolved
        by the proper court of Pasig City, Philippines.
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", marginTop: "15px" }}
      >
        The Player confirms that he/she has fully read, understood, and agrees
        with these Terms & Conditions.
      </Typography>
      {/* </CustomModal> */}
    </div>
  );
};

export default TermsAndConditions;
