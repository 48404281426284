import { createTheme } from "@mui/material/styles";

export const customInputStyle = {
  height: "40px",
  borderRadius: "20px",
  backgroundColor: "#16213e",
  width: "calc(75% - 0px)",
  "& label": {
    marginTop: "0px",
    marginLeft: "10px",
  },
  "& .MuiInputBase-root": {
    padding: "-10px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "&:hover .MuiInput-underline:before": {
    borderBottom: "none !important",
  },
  "&:hover .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
};

export const customPasswordStyle = {
  width: "calc(75% - 0px)",
  height: "40px",
  borderRadius: "20px",
  backgroundColor: "#16213e",
  "& label": {
    marginTop: "0px",
    marginLeft: "10px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "&:hover .MuiInput-underline:before": {
    borderBottom: "none !important",
  },
  "&:hover .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
};

export const theme = createTheme({
  typography: {
    fontFamily: [
      "Nunito",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});
