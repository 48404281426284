import { Box, Button, Stack, Typography, styled } from "@mui/material";
import empty_withdrawal from "../../../assets/images/icons/empty_withdrawals.png";

const WithdrawalWrapper = styled(Box)({
  backgroundColor: "#f5f5f5",
  height: "100",
  padding: "20px",
  width: "250px",
});

const WithdrawalContainer = styled(Box)({
  backgroundColor: "#FFF",
  boxShadow: "0 5px 24px 0 rgba(0,0,0,.08)",
  padding: "20px",
  borderRadius: "12px",
  color: "#d1d1d1",
  paddingBottom: "40px",
  paddingTop: "40px",
});

const WithdrawalHeader = styled(Typography)({
  paddingLeft: "10px",
  paddingRight: "50px",
  marginBottom: "20px",
  borderLeft: "5px solid #4c8bff",
  color: "#535353",
  width: "150px",
});

const MoreButton = styled(Button)({
  paddingRight: "20px",
  paddingLeft: "20px",
  backgroundColor: "#FFFFFF",
  marginBottom: "20px",
  marginTop: "20px",
  color: "#535353",
  width: "120px",
  borderRadius: "20px",
  boxShadow: "0 5px 24px 0 rgba(0,0,0,.08)",
});

export default function WithdrawalRecords() {
  return (
    <WithdrawalWrapper>
      <Stack direction="column" sx={{ marginX: "10px", alignItems: "center" }}>
        <WithdrawalHeader variant="body2">Recent Withdrawal</WithdrawalHeader>
        {/* List ng withdrawals */}
        <WithdrawalContainer>
          {/*if the recent withdrawals is empty then render this component*/}
          <Stack direction="row">
            <span style={{ paddingRight: "18px" }}>
              <img src={empty_withdrawal} />
            </span>
            <Typography variant="body2">No Withdrawal Request</Typography>
          </Stack>
        </WithdrawalContainer>
        <MoreButton disableRipple>More</MoreButton>
      </Stack>
    </WithdrawalWrapper>
  );
}
