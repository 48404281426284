import { Divider, Grid, Modal, Typography } from "@mui/material";
import CasinoIcon from "@mui/icons-material/Casino";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import axiosInstance from "../../../axiosConfig";

const BettingRecordFilter = ({
  gameProviderOpen,
  filterRef,
  handleCloseFilter,
  setProviders,
  providers,
  handleProvider
}) => {

  useEffect(() => {
    axiosInstance
      .get(`game/list/provider`)
      .then((response) => {
        const data = response.data;
        setProviders(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <Modal
        open={gameProviderOpen}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "auto",
          outline: "none",
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            outline: "none",
          }}
        >
          <Grid
            ref={filterRef}
            item
            xs={12}
            sm={12}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontSize: "4vw",
                color: "#9e9e9e",
                marginLeft: "3%",
              }}
            >
              Status
              <CloseIcon
                onClick={handleCloseFilter}
                sx={{
                  marginTop: "2%",
                  fontSize: "5vw",
                  color: "#888888",
                  marginLeft: "80%",
                }}
              />
            </Typography>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            <Grid container sx={{ height: "8vw", alignItems: "center" }} onClick={() => handleProvider("All")}>
              <Typography
                sx={{
                  fontSize: "4.8vw",
                  marginTop: "1%",
                  color: "#2b2b2b",
                  marginLeft: "2%",
                  display: "flex",
                }}
              >
                All
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            {providers.map((row, index) => (
              <>
                <Grid container sx={{ height: "8vw", alignItems: "center" }} onClick={() => handleProvider(row.gp_display_name)}>
                  <Typography
                    sx={{
                      fontSize: "4.8vw",
                      marginTop: "1%",
                      color: "#2b2b2b",
                      marginLeft: "2%",
                      display: "flex",
                    }}
                  >
                    {row.gp_display_name}
                  </Typography>
                </Grid>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />
              </>
            ))}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default BettingRecordFilter;
