import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBetRecords = createAsyncThunk(
  "player/bet_records",
  async (id) => {
    try {
      return id;
    } catch (error) {
      return error;
    }
  }
);
const initialState = {
  bets: [],
  status: "idle",
  error: null,
};

const betRecordsSlice = createSlice({
  name: "betRecords",
  initialState,
  reducers: {
    filterBetRecords: (state, action) => {
      const {
        today,
        yesterday,
        sevenDays,
        start,
        end,
        vendor,
        settlement,
      } = action.payload;
      const bets = state.bets;
      // eslint-disable-next-line
      const filteredBets = bets.filter((bet) => {
        return (
          (bet.createdAt === today ||
            bet.createdAt === yesterday ||
            bet.createdAt === sevenDays ||
            (bet.createdAt >= start && bet.createdAt <= end)) &&
          bet.vendor === vendor &&
          bet.isSettled === settlement
        );
      });
    },
  },
  extraReducers: (builder) => {},
});

export const { filterBetRecords } = betRecordsSlice.actions;
export default betRecordsSlice.reducer;
