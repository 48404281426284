import { React, useState } from "react";
import { usePlayer } from "../../../../contexts/PlayerContext";
import { Grid } from "@mui/material";
import WalletIcon from "@mui/icons-material/Wallet";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import "../../../css/platform.css";
import useStyles from "../../../css/useStylesPlatform";
import axiosInstance from "../../../../axiosConfig";
import DialogWalletModal from "../../../profile/withdrawal/withdraw/DialogWalletModal";
import DropDownContent from "./DropDownContent";

export const ProfileComponentv1 = ({
  user,
  handleProfile,
  setSelectedNavBarPath,
  gameIframe,
  setGameIframe,
  balance,
}) => {
  const { isMobile } = usePlayer();
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();
  const [dialogWalletModal, setDialogWalletModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    const logActivity = (redirectPath) => {
      axiosInstance
        .post(`player/logout`, {
          player_username: username,
          activity: "Logout",
        })
        .then(() => {
          localStorage.clear();
          navigate(redirectPath);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    localStorage.clear();
    if (isMobile) {
      logActivity("/m");
    } else {
      setSelectedNavBarPath("landingpage");
      setGameIframe(false);
      logActivity("/");
    }
  };

  const handleMenuNavigate = (data) => {
    if (data === "Account Record") {
      if (isMobile) {
        navigate("/m/player/accountrecords");
      } else {
        handleProfile("account_record");
      }
    } else if (data === "Member") {
      if (isMobile) {
        navigate("/m/member/home");
      } else {
        handleProfile("my_account");
      }
    } else if (data === "Betting Record") {
      if (isMobile) {
        navigate("/m/player/bettingrecords");
      } else {
        handleProfile("betting_record");
      }
    }
  };

  const handleModalProfile = () => {
    setDialogWalletModal(true);
  };

  return (
    <>
      {!gameIframe ? (
        <>
          {isMobile ? (
            <>
              <Grid container>
                <Grid
                  sx={{
                    width: "20vw",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "4vw",
                      textAlign: "center",
                      marginLeft: "10%",
                    }}
                  >
                    {user.profile.player_uname ??
                      localStorage.getItem("username")}
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "13vw",
                      width: "23vw",
                      height: "4vw",
                      color: "black",
                      display: "flex",
                    }}
                  >
                    <WalletIcon
                      onClick={handleModalProfile}
                      sx={{
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: "3vw",
                        cursor: "pointer",
                        fontSize: "4vw",
                        p: 0.1,
                        zIndex: 1,
                      }}
                    />
                    {dialogWalletModal && (
                      <DialogWalletModal
                        dialogWalletModal={dialogWalletModal}
                        setDialogWalletModal={setDialogWalletModal}
                      />
                    )}
                    {balance ? (
                      <Typography
                        sx={{
                          marginLeft: "5%",
                          textAlign: "center",
                          alignContent: "center",
                          fontSize: "3vw",
                        }}
                      >
                        ₱ {balance.toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          marginLeft: "5%",
                          textAlign: "center",
                          alignContent: "center",
                          fontSize: "3vw",
                        }}
                      >
                        ₱ 0.00
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  <DropDownContent
                    handleMenu={handleMenu}
                    menuOpen={menuOpen}
                    user={user}
                    handleProfile={handleProfile}
                    balance={balance}
                    handleMenuNavigate={handleMenuNavigate}
                    handleLogout={handleLogout}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                >
                  {user.profile.player_uname ??
                    localStorage.getItem("username")}
                </Typography>
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "30px 30px 30px 30px",
                      width: "140px",
                      color: "black",
                      marginLeft: "-5px",
                      display: "flex",
                    }}
                  >
                    <WalletIcon
                      onClick={() => handleProfile("deposit")}
                      sx={{
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: "20px",
                        cursor: "pointer",
                        fontSize: "26px",
                        p: 0.1,
                        zIndex: 1,
                      }}
                    />
                    {balance ? (
                      <Typography
                        sx={{
                          marginRight: "6px",
                          textAlign: "center",
                          alignContent: "center",
                        }}
                      >
                        ₱ {balance.toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          marginRight: "6px",
                          textAlign: "center",
                          alignContent: "center",
                        }}
                      >
                        ₱ 0.00
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <DropDownContent
                handleMenu={handleMenu}
                menuOpen={menuOpen}
                user={user}
                handleProfile={handleProfile}
                balance={balance}
                handleMenuNavigate={handleMenuNavigate}
                handleLogout={handleLogout}
              />
            </>
          )}
        </>
      ) : (
        <>
          <DropDownContent
            handleMenu={handleMenu}
            menuOpen={menuOpen}
            user={user}
            handleProfile={handleProfile}
            balance={balance}
            handleMenuNavigate={handleMenuNavigate}
            handleLogout={handleLogout}
          />
        </>
      )}
    </>
  );
};
