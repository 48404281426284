import React from "react";
import { Typography, Link } from "@mui/material";

const ResponsibleGaming = () => {
  const handleLufeChangerCenter = () => {
    window.location.href = "http://www.lifechangerecoverycenter.com";
  };

  const handleBridgesOfHope = () => {
    window.location.href = "  http://www.bridgesofhope.com.ph";
  };

  return (
    // <CustomModal open={open}>
    <div>
      <Typography
        id="about-modal"
        variant="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        RESPONSIBLE GAMING
      </Typography>
      <Typography sx={{ marginBottom: "40px", textAlign: "center" }}>
        "Keep it fun, game responsibly"
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        COMMUNITY RESPONSIBILITY
      </Typography>

      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", fontStyle: "italic", display: "inline" }}
      >
        FBM E-Motion
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginLeft: "5px",
          marginTop: "10px",
          textAlign: "justify",
          display: "inline",
        }}
      >
        is committed to Responsible Gambling.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Remote gambling is a legitimate form of entertainment that is
        experienced by millions of players worldwide. For most players remote
        gambling is an enjoyable experience, however, the reality must be
        accepted that a small percentage of players engaging in remote gambling
        might have a problem with gambling taking over their life and finances.
        Being a socially responsible company means taking care of our players,
        means having a proactive approach to the problems that affect the
        community. We fully support and proactively promote and advocate the
        Philippine Code of Practice on Responsible Gaming which aptly states
        that while gaming is an "enjoyable form of entertainment", it can have a
        negative impact on the Player, his family, and his community when done
        in excess. This is why we have adopted and are fully committed to the
        strictest application and enforcement of the following policies:
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginLeft: "50px",
          marginTop: "10px",
          textAlign: "justify",
          fontStyle: "italic",
        }}
      >
        Restricting Access by Minors or Non-qualified Players.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        FBM E-Motion requires new players to go through a KYC process to verify
        that they are of lawful age in their respective jurisdiction and at
        least 21 years old and qualified as remote gaming players. Should We
        have a suspicion of a false declaration or minors attempting to use our
        services we will further pursue reasonable measures to verify this. Any
        attempt to violate this restriction, such as misinformation, lying about
        your age during the registration/members sign-in process, sharing your
        account with a minor, and other attempts to circumvent this restriction,
        intentionally or accidentally, will be dealt with accordingly by
        suspending your account in question and would require further proof and
        verification of your account name, age, and identity.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "40px" }}>
        FBM E-Motion restricts its advertising, marketing, and promotional
        activities to minors or any non-qualified Players. In this respect, if
        we have a suspicion to this extent, we reserve the right to suspend an
        account and request proof of identity and age from the player to verify
        compliance with this policy. We also ensure that our advertising,
        sponsorship, and promotional activity are not targeted at minors or any
        non-qualified players. We do not allow anybody under the age of 21 or
        non-qualified players to use our services. This policy is in full
        compliance and exceeds the requirements of the rules and regulations of
        the remote gambling authority that oversees and licenses our operation;
        Philippine Amusement and Gaming Corporation (PAGCOR), Philippines.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "40px" }}>
        We pledge to do all we can but we need your help. What can be done from
        your end?
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        You can use child protection software to block remote gambling sites
        from computers minors might have access to.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        There are many free options, such as - Norton, Netnanny, Famisafe,
        Lifewire, AVG, Cyberpatrol.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Do not leave the computer/mobile devices unattended when you are logged
        on to our site.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Do not leave the "Save Password" option enabled on any FBM E-Motion
        login screen.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Create separate profiles for minors on computers/mobile devices so that
        when they log in they cannot access your information.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        If you suspect someone under the age of 21 is registered with us as a
        player, please contact us immediately.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        CONTROLLING COMPULSIVE GAMBLING
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Compulsive gambling does not benefit FBM E-Motion. We strive to have a
        safe, fun environment on Our site. Compulsive gamblers are a problem for
        their families, friends, and their lives, creating an unstable, unsafe
        environment. We are committed to detecting and reducing compulsive
        gambling.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        We have a system to track players' activities, and We are constantly
        working to design and enforce controls designed to limit gambling
        patterns that are out of control. While the majority of people do gamble
        within their means, for some, it can spiral out of control. We strive
        not only to identify compulsive gamblers but also to help them address
        their problem and prevent other players from becoming compulsive
        gamblers.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        In order to help you keep control, we would like you to remember the
        following:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gambling should be seen as entertainment, address it as if You are
        paying for entertainment so do not overpay for your entertainment.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gambling should not be seen as a genuine way of making money.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        The games are random, remote games use random game generators to ensure
        fair play, nobody knows the outcome and the time or the amount of the
        bet is not affecting the game. The random game generators ensure that
        there is no pattern or any repeating:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Avoid chasing losses.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Only gamble what you can afford to lose.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Keep track of the time and amount of money you spend gambling.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        FBM E-Motion games are based on a pre-set mathematical probability that
        is not affected in any way by anyone’s lucky charm.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        If you feel that way, you need a little break from gambling, please
        contact PAGCOR directly by using this email address:
        <Typography
          sx={{
            margin: "0px 5px 0px 5px",
            textTransform: "none",
            textDecoration: "underline",
            display: "inline",
            fontStyle: "italic",
          }}
        >
          keepitfun@pagcor.ph
        </Typography>
        or Visit the PAGCOR website.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        RAISING STANDARDS WORLDWIDE
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        FBM E-Motion wants to increase the standards in Our target territories.
        We are self-imposing standards of utmost responsibility, integrity,
        transparency, legitimacy, and awareness. We work to raise the bar of
        integrity worldwide because We believe that operating at the highest
        standards benefits everyone.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        ACCOUNTABILITY AND INTEGRITY
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        We believe that We are only as good as Our player’s perception of Us.
        Therefore, the highest level of integrity is the only standard by which
        We can operate to have Our players have that perception of Us.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        PAYOUTS
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        We are committed to prompt payment of customer cash-out requests.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        COMMITMENT TO CUSTOMER SERVICE
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        At FBM E-Motion, Our highest priority is customer satisfaction. That's
        why You can contact Us in various ways. Our highly-trained Customer
        Service Support Team is available 24 hours a day, seven days a week to
        offer technical support, aid in dispute resolution, and answer your
        questions.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        GAMING LIMITATIONS
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Pursuant to Presidential Decree 1869, as amended by Republic Act 9487,
        persons under 21 years of age or students of any school, college, or
        university in the Philippines are not allowed to play in this gaming
        establishment. Pursuant to Malacañang Memorandum Circular No. 8, the
        following are not allowed to enter, stay and/or play in this gaming
        establishment:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Government officials and employees connected directly with the operation
        of the government or any of its agencies; and Active employees of the
        Philippine National Police (PNP) and Armed Forces of the Philippines
        (AFP).
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        In accordance with the recent policy pronouncement of the President of
        the Philippines, immediate relatives (i.e. spouse, children, and
        parents) of government officials are not allowed to play.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        FBM E-Motion Players shall not play in open venues such as streets,
        parks, malls, restaurants, churches or places of worship, markets, and
        other venues which are in direct and full view of the public.
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        STRATEGIES ON GAMING
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Here are some strategies You can use to play safe and have fun:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Don't gamble when you are upset or stressed.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Limit your alcohol intake while gambling.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Know how the games work before you wager.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Set budget and time limits
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        There are tools available on many slot machines, mobile, and internet
        gambling sites to help You monitor your play.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Take frequent breaks
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Move around, get some fresh air, and have something to eat or a coffee.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Only gamble with money you have
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Never borrow money or use money intended for necessities, like rent or
        food.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Don't try to win back what you've lost
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gambling is not a way to make money so don't think of it as a chance to
        win money for a trip, to pay bills, or to pay off debt.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Stick to your budget
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        By leaving your credit and debit cards at home.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Balance gambling with other recreational activities
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        If you're no longer having fun, stop playing.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        Don't depend on "good luck" strategies
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        They don't increase your chances of winning.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        SOURCES OF FUNDS AND DEPOSIT LIMITS
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        FBM E-Motion adheres to the Republic Act No. 9160 or the Anti-Money
        Laundering Act of 2001 and may take steps to verify the source of funds
        of the Player in relation to this government mandate.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        It is part of Our Terms & Conditions policy on responsible gaming to
        provide the option for players to adjust their maximum deposit limits at
        any time. To change your deposit limits or to make such a request,
        please contact Our 24/7 Customer Support Team.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        TAXES ON YOUR WINNINGS
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        The Philippine government, through the Bureau of Internal Revenue,
        imposes taxes on prizes and winnings. As part of FBM E-Motion's drive
        for responsible gaming, but also encourage You to consult with your
        Accountant or your Lawyer about whether the number of your winnings is
        taxable or not, and how much to pay if it is taxable; and, arrange for
        the payment of the appropriate taxes.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        We suggest You consult with your accountant or lawyer on how to best
        deal with your winnings.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        TYPE OF EXCLUSION PROGRAMS
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        SELF EXCLUSION
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        One can exclude themselves from any gaming establishments operated and
        regulated by PAGCOR. One may request for an exclusion period of 6
        months, 1 year, or 5 years and lifetime.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: "10px", textDecoration: "underline" }}
      >
        FAMILY EXCLUSION
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        One may even exclude their family members such as a spouse, child at
        least 18 years old, or even their parents. One may request for an
        exclusion period of 3 months, 6 months, 1 or 3 years.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        All exclusion orders shall take effect in all PAGCOR-operated and
        regulated gaming facilities. Once the PAGCOR Exclusion Form is fully
        accomplished, submit it inside a sealed long-sized brown envelope with
        the following written on it:
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        “APPLICATION FOR EXCLUSION ORDER”
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gaming Licensing and Development Department 12th Floor |
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        PAGCOR Main Corporate Office IMET BPO Tower, CBP-1A Metropolitan Park,
        Roxas Boulevard, Pasay City | Contact No.: 755-3904
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        EARLY SIGNS OF POSSIBLE GAMBLING PROBLEM
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Feeling unhappy, anxious, frustrated, or worried about your gambling
        behavior.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Continuously thinking or speaking too much about gambling.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gamble with the hope to recover from the losses.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Experiencing extreme highs from wins and extreme lows from losses.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Finding it difficult to control or stop gambling.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Emotional instability.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Asking for money, selling things, committing (or considering committing)
        criminal activity to obtain money for gambling.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Having increased debt, unpaid bills, or other financial troubles because
        of your gambling.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Gambling to escape personal problems or to relieve feelings of anxiety,
        depression, anger, or loneliness.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginLeft: "50px", marginTop: "10px", textAlign: "justify" }}
      >
        Neglecting family or household responsibilities because of gambling.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        CUSTOMER SERVICE TEAM
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        At FBM E-Motion, We make sure our Player's well-being comes first by
        providing them with a safe and secure online gaming environment, and to
        ensure all of these, Our friendly and accommodating Customer Service
        Team is always within reach via email [csupport@fbmemotion.ph] and live
        chat to address your concerns.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Apart from live chat and email support, We created a HOTLINE, a landline
        number, which specifically has one purpose: to be available for any
        Players, who feel that they need immediate help in relation to their
        gambling habits. This phone number is available 24/7.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "40px" }}>
        HELP CENTRE
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        FBM E-Motion follows the guidelines of institutions dedicated to
        addressing the social impact of gambling. To help players gamble
        responsibly, we commit to working with these institutions to develop
        responsible policies and practices:
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: "10px",
          textDecoration: "underline",
          fontStyle: "italic",
        }}
      >
        Life Change Recovery Center
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Dr. Randy Misael S. Dellosa
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        105 Scout Rallos Street, Kamuning Brgy. Sacred Heart, Quezon City
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Telephone numbers: (02) 415-7964 / 415-6529
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Website:
        <Link
          onClick={handleLufeChangerCenter}
          sx={{ margin: "0px -5px 0px 5px", textTransform: "none" }}
        >
          http://www.lifechangerecoverycenter.com
        </Link>
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: "40px",
          textDecoration: "underline",
          fontStyle: "italic",
        }}
      >
        Bridges of Hope Drugs and Alcohol Rehabilitation Foundation, Inc.
        Headquarters
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        364 Aguirre Avenue, Phase 3, BF Homes, Parañaque City, Metro Manila
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Tel. No. (02) 622-0193
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        New Manila Branch 12 Orestes Lane, Mariposa Street
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Tel. No. (02) 502-0600; (0917) 856-0623
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Email:{" "}
        <Typography
          sx={{
            margin: "0px -5px 0px 0px",
            textTransform: "none",
            textDecoration: "underline",
            display: "inline",
            fontStyle: "italic",
          }}
        >
          help@bridgesofhope.ph
        </Typography>
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        Website:
        <Link
          onClick={handleBridgesOfHope}
          sx={{ margin: "0px -5px 0px 5px", textTransform: "none" }}
        >
          http://www.bridgesofhope.com.ph
        </Link>
      </Typography>
    </div>
    // </CustomModal>
  );
};

export default ResponsibleGaming;
