import { configureStore } from "@reduxjs/toolkit";
import profileSlice from "../slices/profile.slice";
import betsSlice from "../slices/bets.slice";
import depositsSlice from "../slices/deposits.slice";
import ewalletSlice from "../slices/ewallet.slice";
import gamesSlice from "../slices/games.slice";
import paymentProviderSlice from "../slices/payment.provider.slice";
import transactionSlice from "../slices/transaction.slice";
import withdrawalsSlice from "../slices/withdrawals.slice";

const store = configureStore({
  reducer: {
    profile: profileSlice,
    bets: betsSlice,
    deposits: depositsSlice,
    ewallet: ewalletSlice,
    games: gamesSlice,
    paymentProvider: paymentProviderSlice,
    transaction: transactionSlice,
    withdrawals: withdrawalsSlice,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
