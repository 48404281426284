import React, { useState, useEffect } from "react";
import { usePlayer } from "./contexts/PlayerContext";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import LandingPage from "./pages/landingpage/LandingPage";
import MyAccount from "./mobile/pages/MyAccount";
import DepositPageMobile from "./mobile/pages/DepositPageMobile";
import Platform from "./mobile/pages/Platform";
import WithdrawPageMobile from "./mobile/pages/WithdrawPageMobile";
import MyCardsMobile from "./mobile/pages/MyCardsMobile";
import WithdrawalRecordMobile from "./mobile/pages/WithdrawalRecordMobile";
import SecurityCenter from "./mobile/pages/SecurityCenter";
import LoginFormMobile from "./mobile/pages/LoginFormMobile";
import RegisterFormMobile from "./mobile/pages/RegisterFormMobile";
import DepositRecordPageMobile from "./mobile/pages/DepositRecordPageMobile";
import axios from "axios";
import { CASINO_SERVER, WEB_SOCKET } from "./config";
import Maintenance from "./pages/maintenance/Maintenance";
import RewardCenterMobile from "./mobile/pages/RewardCenterMobile";
import ReceivingCenter from "./mobile/pages/ReceivingCenter";
import ClaimHistory from "./mobile/pages/ClaimHistory";
import MyAccountDetails from "./mobile/pages/myaccountdetails/MyAccountDetails";
import HelpCenter from "./mobile/pages/TermsOfUse";
import Logout from "./pages/landingpage/Buttons/Logout";
import BindEWalletMobile from "./mobile/pages/BindEWalletMobile";
import AccountVerificationMobile from "./mobile/pages/AccountVerificationMobile";
import TransactionPasswordMobile from "./mobile/pages/TransactionPasswordMobile";
import LoginPasswordMobile from "./mobile/pages/LoginPasswordMobile";
import ForgotPassword from "./mobile/pages/ForgotPasswordMobile";
import BettingRecordsMobile from "./mobile/pages/BettingRecord/BettingRecordsMobile";
import AccountRecordsMobile from "./mobile/pages/AccountRecord/AccountRecordsMobile";

function MobilePlatformWrapper(props) {
  return <Platform {...props} />;
}

function WebPlatformWrapper(props) {
  return <LandingPage {...props} />;
}

function App() {
  const { isMobile } = usePlayer();
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const username = localStorage.getItem("username");
  const [balance, setBalance] = useState(0.0);
  const currentRoute = location.pathname;

  useEffect(() => {
    axios
      .get(`${CASINO_SERVER}platform/management/platform/settings`)
      .then((response) => {
        if (response.data.data.maintenance === "1") {
          navigate("/maintenance");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [isMobile]);

  // Web Socket connection
  useEffect(() => {
    if (username) {
      const ws = new WebSocket(`${WEB_SOCKET}`);

      ws.onopen = () => {
        const authData = JSON.stringify({ user: username });
        ws.send(authData);
      };

      ws.onmessage = (event) => {
        try {
          const message = typeof event.data === "string" ? event.data : "";
          const parsedData = JSON.parse(message);
          if (parsedData.newBalance !== undefined) {
            setBalance(parsedData.newBalance);
          }
        } catch (error) {
          console.error("Error parsing message data:", error);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed");
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      return () => {
        ws.close();
      };
    }
  }, [username]);

  return (
    <>
      <Routes>
        <Route path="/maintenance" element={<Maintenance />} />
        {/* Desktop Routes Start ======================================================*/}

        {/* Routes/Pages that uses MainNavBar */}

        <Route
          path="/*"
          element={
            <WebPlatformWrapper
              currentRoute={currentRoute}
              authToken={authToken}
              balance={balance}
              setBalance={setBalance}
            />
          }
        />
        {/* Desktop Routes End ======================================================*/}

        {/* Mobile Routes Start ======================================================*/}

        {/* Routes/Pages that uses MainNavBar */}
        <Route
          path="/m*"
          element={
            <MobilePlatformWrapper
              currentRoute={currentRoute}
              authToken={authToken}
              balance={balance}
              setBalance={setBalance}
            />
          }
        />
        <Route
          path="/m/player/accountrecords"
          element={<AccountRecordsMobile />}
        />
        <Route
          path="/m/player/bettingrecords"
          element={<BettingRecordsMobile/>}
        />
        <Route
          path="/m/member/home"
          element={<MyAccount balance={balance} setBalance={setBalance} />}
        />
        <Route
          path="/m/withdraw"
          element={
            <WithdrawPageMobile balance={balance} setBalance={setBalance} />
          }
        />
        <Route path="/m/securityCenter" element={<SecurityCenter />} />
        <Route
          path="/m/voucherCenter"
          element={<DepositPageMobile authToken={authToken} />}
        />
        <Route path="/m/myWithdrawCards" element={<MyCardsMobile />} />
        <Route path="/m/withdrawReport" element={<WithdrawalRecordMobile />} />
        <Route path="/m/vouReport" element={<DepositRecordPageMobile />} />
        <Route path="/m/login" element={<LoginFormMobile />} />
        <Route path="/m/register" element={<RegisterFormMobile />} />
        <Route
          path="/m/rewardCenter"
          element={
            <RewardCenterMobile balance={balance} setBalance={setBalance} />
          }
        />
        <Route
          path="m/receivingCenter"
          element={
            <ReceivingCenter balance={balance} setBalance={setBalance} />
          }
        />
        <Route path="/m/claimHistory" element={<ClaimHistory />} />
        <Route path="/m/myAccount/index" element={<MyAccountDetails />} />
        <Route path="/m/rewardCenter" element={<RewardCenterMobile />} />
        <Route path="/m/receivingCenter" element={<ReceivingCenter />} />
        <Route path="/m/claimHistory" element={<ClaimHistory />} />
        <Route path="/m/myEWallet" element={<BindEWalletMobile />} />
        <Route
          path="/m/accountVerification"
          element={<AccountVerificationMobile />}
        />
        <Route
          path="/m/securityCenter/loginChange"
          element={<LoginPasswordMobile />}
        />
        <Route
          path="/m/securityCenter/FundsPasswordBox"
          element={<TransactionPasswordMobile />}
        />
        <Route path="/m/securityCenter/Logout" element={<Logout />} />
        <Route path="/m/helpterms" element={<HelpCenter />} />
        <Route path="/m/forget" element={<ForgotPassword />} />

        {/* Mobile Routes End ======================================================*/}
      </Routes>
    </>
  );
}

export default App;
