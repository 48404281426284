import { Divider, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AccountRecordTransaction = ({
  transactionOpen,
  transactionRef,
  handleCloseFilter,
  handleTransaction,
}) => {
  const data = [
    "All",
    "Deposit",
    "Withdrawal",
    "Betting",
    "Winning",
    "Bonus",
    "Rebate",
    "Agent rebate",
    "Others",
  ];

  return (
    <>
      <Modal
        open={transactionOpen}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "auto",
          outline: "none",
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            outline: "none",
          }}
        >
          <Grid
            ref={transactionRef}
            item
            xs={12}
            sm={12}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontSize: "4vw",
                color: "#9e9e9e",
                marginLeft: "3%",
              }}
            >
              Status
              <CloseIcon
                onClick={handleCloseFilter}
                sx={{
                  marginTop: "2%",
                  fontSize: "5vw",
                  color: "#888888",
                  marginLeft: "80%",
                }}
              />
            </Typography>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            {data.map((row, key) => (
              <Grid
                key={key}
                container
                sx={{ height: "8vw", alignItems: "center" }}
                onClick={() => handleTransaction(row)}
              >
                <Typography
                  sx={{
                    fontSize: "4.8vw",
                    marginTop: "1%",
                    color: "#2b2b2b",
                    marginLeft: "2%",
                    display: "flex",
                  }}
                >
                  {row}
                </Typography>
                <Divider sx={{ width: "100%", marginTop: ".5%" }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AccountRecordTransaction;
