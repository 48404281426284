import React, { useState, useEffect } from "react";
import PromptModal from "./PromptModal.js";
import { TextField, Grid, Button } from "@mui/material";
import LoginForm from "../account/login/LoginForm.js";
import RegisterForm from "../account/register/RegisterForm.js";
import { PlayerDetails } from "./player-details/index.js";
import Footer from "../../assets/containers/section4-footer/Footer.js";
import DashboardCarousel from "./Banner/Banner.js";
import { LandingPageGames } from "./games/LandingPageGames.js";
import { ShortcutIcons } from "./section-two/index.js";
import GameIframe from "../iframe/GameIframe.js";
import SearchIcon from "@mui/icons-material/Search";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ResetPassword from "../resetpassword/ResetPassword.js";
import axiosInstance from "../../axiosConfig.js";
import NewsPage from "./NewsPage";
import WinnerPage from "./WinnerPage";
import PromotionPage from "../promotion/PromotionPage";
import MainNavbarv1 from "./Navbars/MainNavBar/MainNavBarv1.js";
import GamePage from "./Navbars/NavBarPages/GamePage";
import HotGamesPage from "./Navbars/NavBarPages/HotGamesPage";
import { usePlayer } from "../../contexts/PlayerContext.js";
import DialogForgotPassword from "../resetpassword/components/DialogForgotPassword.js";
import DialogForgotPasswordModal from "../../dialog/DialogForgotPasswordModal.js";

function LandingPage(props) {
  const [isReset, setIsReset] = useState(false);
  const [open, setOpen] = useState(false);
  const { authToken, balance, setBalance, currentRoute, status } = props;
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const openLogin = () => setShowLogin(true);
  const closeLogin = () => setShowLogin(false);
  const closeForgotPassword = () => setShowForgotPassword(false);
  const [gameIframe, setGameIframe] = useState(false);
  const [gameIframeUrl, setGameIframeUrl] = useState("");
  const [selectedNavBarPath, setSelectedNavBarPath] = useState("landingpage");
  const [activeComponent, setActiveComponent] = useState("my_account");
  const [searchQuery, setSearchQuery] = useState("");
  const [showRegistration, setShowRegistration] = useState(false);
  const openRegistration = () => setShowRegistration(true);
  const closeRegistration = () => setShowRegistration(false);
  const [showProfile, setShowProfile] = useState(false);
  const [hoverFavoritesButton, setHoverFavoritesButton] = useState(false);
  const [favoriteButton, setFavoriteButton] = useState(false);
  const [isOpenHelpCenter, setIsOpenHelpCenter] = useState(false);
  const [selectedModalMenu, setSelectedModalMenu] = useState("about");
  const { isMobile } = usePlayer();
  const [gameTypes, setGameTypes] = useState([]);
  const [showPassword, setShowPassword] = useState(false);


  const TogglePassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleOpenModalParent = (newValue) => {
    setSelectedModalMenu(newValue);
    setIsOpenHelpCenter(true);
  };

  const handleCloseModal = () => setIsOpenHelpCenter(false);

  const handleOpenRegistration = () => {
    closeLogin();
    openRegistration();
  };

  const openProfile = (index) => {
    setShowProfile(true);
    setActiveComponent(index);
  };

  const closeProfile = () => setShowProfile(false);

  const handleFavoriteClick = () => {
    setFavoriteButton((prevState) => !prevState);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // handleClickOpen();
    if (authToken) {
      axiosInstance
        .get(`player/balance`)
        .then((response) => {
          setBalance(response.data.balance);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    const checkSession = async () => {
      try {
        await axiosInstance.get("/check/session")
          .then(checkReset());

      } catch (error) {
        localStorage.clear();
      }
    };

    const checkReset = async () => {
      axiosInstance.get('/check/reset')
        .then(response => {
          setIsReset(response);
          if (response.data.is_reset) {
            handleClickOpen(true);
          }
        })
        .catch(error => {
          console.error('Error fetching reset flag:', error);
        });
    }
    checkSession();
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          backgroundColor: "#090929",
          width: "100%",
          height: "100vh",
          overflow: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        <Grid sx={{ width: "1400px" }}>
          <MainNavbarv1
            handleOpen={openLogin}
            handleRegistration={openRegistration}
            handleProfile={openProfile}
            setSelectedNavBarPath={setSelectedNavBarPath}
            selectedNavBarPath={selectedNavBarPath}
            setGameIframe={setGameIframe}
            gameIframe={gameIframe}
            isMobile={isMobile}
            authToken={authToken}
            balance={balance}
            gameTypes={gameTypes}
            setGameTypes={setGameTypes}
          />
          <LoginForm
            handleClose={closeLogin}
            open={showLogin}
            setOpen={setOpen}
            openRegistration={handleOpenRegistration}
            setShowForgotPassword={setShowForgotPassword}
            handleOpenModalParent={handleOpenModalParent}
            setBalance={setBalance}
          />
          <ResetPassword
            closeForgotPassword={closeForgotPassword}
            open={showForgotPassword}
            closeLogin={closeLogin}
            setShowForgotPassword={setShowForgotPassword}
          />
          <RegisterForm
            handleClose={closeRegistration}
            open={showRegistration}
            openLogin={openLogin}
            closeRegistration={closeRegistration}
            handleOpenModalParent={handleOpenModalParent}
          />
          {gameIframe ? (
            <>
              <GameIframe
                setSelectedNavBarPath={setSelectedNavBarPath}
                selectedNavBarPath={selectedNavBarPath}
                gameIframeUrl={gameIframeUrl}
              />
              <PlayerDetails
                closeProfile={closeProfile}
                open={showProfile}
                handleClose={closeProfile}
                activeComponent={activeComponent}
                setActiveComponent={setActiveComponent}
                balance={balance}
                setBalance={setBalance}
              />
            </>
          ) : (
            <>
              <Grid>
                {sessionStorage.getItem("accepted") === null ? (
                  <PromptModal />
                ) : (
                  <></>
                )}
                <PlayerDetails
                  closeProfile={closeProfile}
                  open={showProfile}
                  handleClose={closeProfile}
                  activeComponent={activeComponent}
                  setActiveComponent={setActiveComponent}
                  balance={balance}
                  setBalance={setBalance}
                />
                {currentRoute === "/games" ? (
                  <GamePage
                    setGameIframe={setGameIframe}
                    setGameIframeUrl={setGameIframeUrl}
                    openLogin={openLogin}
                  />
                ) : currentRoute === "/games/hot" ? (
                  <HotGamesPage
                    setGameIframe={setGameIframe}
                    setGameIframeUrl={setGameIframeUrl}
                    openLogin={openLogin}
                  />
                ) : currentRoute === "/news" ? (
                  <NewsPage />
                ) : currentRoute === "/winners" ? (
                  <WinnerPage />
                ) : currentRoute === "/promotions" ? (
                  <PromotionPage />
                ) : (
                  <>
                    <DashboardCarousel />
                    <ShortcutIcons
                      setShowLogin={setShowLogin}
                      openProfile={openProfile}
                      setSelectedNavBarPath={setSelectedNavBarPath}
                    />
                    <Grid>
                      <>
                        <TextField
                          variant="outlined"
                          placeholder="Game Name"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          sx={{
                            marginTop: "40px",
                            height: "33px",
                            borderRadius: "20px",
                            backgroundColor: "#16213e",
                            width: "calc(25% - 0px)",
                            "& .MuiInput-underline:before": {
                              borderBottom: "none",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottom: "none",
                            },
                            "&:hover .MuiInput-underline:before": {
                              borderBottom: "none !important",
                            },
                            "&:hover .MuiInput-underline:after": {
                              borderBottom: "none !important",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                height: "38px",
                                borderRadius: "10px",
                              },
                              "&:hover fieldset": {
                                borderColor: "red",
                                borderRadius: "10px",
                                borderWidth: "1px",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "red",
                                borderWidth: "1px",
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              color: "white",
                              backgroundColor: "#16213e",
                              borderRadius: "10px",
                              height: "33px",
                              fontSize: "13px",
                            },
                            endAdornment: (
                              <SearchIcon
                                style={{
                                  color: "white",
                                  fontSize: "24px",
                                  transform: "rotate(90deg)",
                                }}
                              />
                            ),
                          }}
                        />
                        {/* <Button
                          sx={{
                            marginTop: "40px",
                            color: "#FFFFFF",
                            backgroundColor: favoriteButton ? "red" : "#16213e",
                            textTransform: "none",
                            marginLeft: "13px",
                            fontSize: "16px",
                            borderRadius: "15px",
                            height: "35px",
                            position: "relative",
                            "&:hover": {
                              backgroundColor: "red",
                              color: "#FFFFFF",
                            },
                          }}
                          onMouseEnter={() => setHoverFavoritesButton(true)}
                          onMouseLeave={() => setHoverFavoritesButton(false)}
                          onClick={() => {
                            if (localStorage.getItem("user_id") !== null) {
                              handleFavoriteClick();
                            } else {
                              setShowLogin(true);
                            }
                          }}
                        >
                          <StarRoundedIcon
                            sx={{
                              color: "#ffc107",
                              marginRight: "5px",
                              border: "40px",
                              position: "relative",
                              zIndex: 1,
                            }}
                          />
                          <StarRoundedIcon
                            sx={{
                              color: "#white",
                              position: "absolute",
                              marginRight: "5px",
                              border: "40px",
                              fontSize: "30px",
                              zIndex: 0,
                              marginLeft: "-73.9px",
                              display:
                                hoverFavoritesButton || favoriteButton
                                  ? "inline"
                                  : "none",
                            }}
                          />
                          <span
                            style={{
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          >
                            Favorites
                          </span>
                        </Button> */}
                      </>
                    </Grid>

                    <LandingPageGames
                      setGameIframe={setGameIframe}
                      setGameIframeUrl={setGameIframeUrl}
                      setSelectedNavBarPath={setSelectedNavBarPath}
                      openLogin={openLogin}
                    />
                  </>
                )}
              </Grid>
              <Grid item sx={{ marginTop: "20px" }}>
                <Footer
                  isOpen={isOpenHelpCenter}
                  setIsOpenHelpCenter={setIsOpenHelpCenter}
                  handleOpenModalParent={handleOpenModalParent}
                  selectedModalMenu={selectedModalMenu}
                  setSelectedModalMenu={setSelectedModalMenu}
                  handleCloseModal={handleCloseModal}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {open &&
        <DialogForgotPasswordModal
          open={open}
          setOpen={setOpen}
          TogglePassword={TogglePassword}
          handleClose={handleClose}

        />}


    </>
  );
}

export default LandingPage;
