import AccountVerification from "./pages/AccountVerification";
import PersonalInformation from "./pages/PersonalInformation";
import React, { useState } from "react";
import TransactionPassword from "./pages/TransactionPassword";
import BindEWallet from "../../../withdrawal/withdraw/components/BindEWallet";
import LoginPassword from "./pages/LoginPassword";
export default function DrawerContent({
  activeContent,
  setDialogOpen,
  setDialogTransactionOpen,
  setDialogWrongPassword,
  toggleDrawer,
}) {
  const [openWithdraw] = useState(true);
  let content;
  switch (activeContent) {
    case "personal_information":
      content = <PersonalInformation />;
      break;
    case "account_verification":
      content = <AccountVerification />;
      break;
    case "login_password":
      content = (
        <LoginPassword
          setDialogOpen={setDialogOpen}
          setDialogWrongPassword={setDialogWrongPassword}
          toggleDrawer={toggleDrawer}
        />
      );
      break;
    case "bind_ewallet":
      content = <BindEWallet openWithdraw={openWithdraw} />;
      break;
    case "transaction_password":
      content = (
        <TransactionPassword
          setDialogTransactionOpen={setDialogTransactionOpen}
          setDialogWrongPassword={setDialogWrongPassword}
          toggleDrawer={toggleDrawer}
        />
      );
      break;
    case "phone_verification":
      content = "Phone Verification";
      break;
    default:
      break;
  }
  return content;
}
