import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  //navbar
  return {
    walletContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#ffffff",
      padding: "1px 1px, 1px, 1px",
      borderRadius: "20px",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      marginLeft: "20px",
      width: "18vw",
      height: "4vw",
    },
    walletIcon: {
      backgroundColor: "#f44336",
      width: "18px",
      height: "4vw",
      marginRight: "2px",
      borderRadius: "10px",
    },
    walletText: {
      fontSize: "3vw",
      color: "black",
    },
    walletAmount: {
      fontSize: "3vw",
      marginLeft: "4px",
      color: "black",
      marginRight: "10px",
    },
    userContainer: {
      display: "flex",
      alignItems: "center",
      padding: "4px 8px",
      backgroundColor: "#fff",
      borderRadius: "16px",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    },
    userName: {
      marginLeft: "60px",
      marginRight: "8px",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#fff",
    },
    userAvatar: {
      width: "24px",
      height: "24px",
      backgroundColor: "#3f51b5",
    },
    notificationDot: {
      backgroundColor: "#f44336",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      right: 0,
    },

    usernameProfile: {
      marginLeft: "14px",
      fontSize: "3.5vw",
    },

    //GAME LIST HEADER / VIEWALL BUTTON

    gameListContainer: {
      marginTop: "-10px",
    },

    headerList: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#ffffff",
    },

    //GAMES
    gameNameMobile: {
      color: "#ffffff",
      textAlign: "center",
      marginTop: "-40px",
      marginBottom: "10px",
    },

    //MY ACCOUNT
    mainContainer: {
      overflowY: "hidden",
      overflowX: "hidden",
    },

    headerContainer: {
      fontSize: "7vw",
      position: "relative",
      marginTop: "5%",
    },

    arrowButton: {
      display: "flex",
      height: "100%",
      marginLeft: "25%",
      marginTop: "-5%",
    },

    cardButton: {
      background: "linear-gradient(to bottom, #fbfcfd, #cdd4dc, #fbfcfd)",
      paddingTop: "1.5%",
      paddingBottom: "1.5%",
      paddingLeft: "3%",
      paddingRight: "3%",
      marginBottom: "3%",
      borderRadius: "4vw",
      textDecoration: "none",
      display: "flex",
      boxShadow: "0px 2px 5px 2px #b6bbc2",
      "&:hover": {
        background: "linear-gradient(to bottom,  #fbfcfd, #cdd4dc, #fbfcfd)",
      },
    },

    menuButton: {
      marginTop: "5%",
      marginLeft: "2%",
      marginRight: "2%",
    },

    myAccountsIcons: {
      color: "#d1a24f",
      backgroundColor: "#fff2db",
      borderRadius: "20vw",
      paddingLeft: "2vw",
      paddingRight: "2vw",
      paddingTop: "2vw",
      paddingBottom: "2vw",
      fontSize: "8.5vw",
    },

    myAccountsIconsBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    myAccountsIconsContainer: {
      marginTop: "5%",
      marginLeft: "2%",
      marginRight: "2%",
    },

    myAccountsModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
    },
  };
});

export default useStyles;
