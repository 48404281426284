import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { CASINO_SERVER } from "../../../../config";
import { Grid } from "@mui/material";
import GameSectionScrollable from "../../games/GameSectionScrollable";

export default function LandingPageMobile({
  setGameIframe,
  setGameIframeUrl,
  openLogin,
}) {
  const navigate = useNavigate();

  const [gameListData, setGameListData] = useState([]);

  function handleClickViewAll(tag) {
    if (tag === 0) {
      navigate(`/m/games/hot`);
    } else {
      navigate(`/m/games?type=${tag}&provider=`);
    }
  }

  useEffect(() => {
    const params = {
      device: "mobile",
    };

    axios
      .get(`${CASINO_SERVER}game/list/landing`, { params })
      .then((response) => {
        setGameListData(response.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <Grid item xs={12} sm={12}>
      {gameListData &&
        gameListData?.map((category) => (
          <GameSectionScrollable
            key={category.id}
            description={category.game_type_name}
            tag={category.id}
            onClickViewAll={handleClickViewAll}
            games={category.games}
            openLogin={openLogin}
            setGameIframe={setGameIframe}
            setGameIframeUrl={setGameIframeUrl}
          />
        ))}
    </Grid>
  );
}
