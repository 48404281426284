import React from "react";
import DepositPage from "../../../profile/deposit/DepositPage";
import AccountRecord from "../../../profile/account_record/AccountRecord";
import MyAccounts from "../../../profile/my_account/MyAccounts";
import Withdrawal from "../../../profile/withdrawal/Withdrawal";
import BettingRecord from "../../../profile/betting_record/BettingRecord";
import ProfitAndLoss from "../../../profile/profit_and_loss/ProfitAndLoss";
import RewardsCenter from "../../../profile/rewards_center/RewardsCenter";
import PlayerFeedback from "../../../profile/feedback/PlayerFeedback";
import AccountRecordsMobile from "../../../../mobile/pages/AccountRecord/AccountRecordsMobile";

function PersonalCenterContent({
  activeComponent,
  setActiveComponent,
  balance,
  setBalance,
  setSelectedNavBarPath,
  setGameIframe,
  handleClose,
}) {
  let content;

  switch (activeComponent) {
    case "my_account":
      content = (
        <MyAccounts
          setActiveComponent={setActiveComponent}
          balance={balance}
          setBalance={setBalance}
          setSelectedNavBarPath={setSelectedNavBarPath}
          setGameIframe={setGameIframe}
          handleClose={handleClose}
        />
      );
      break;

    case "ar_mobile":
      content = <AccountRecordsMobile />;
      break;

    case "deposit":
      content = <DepositPage />;
      break;

    case "withdrawal":
      content = <Withdrawal balance={balance} />;
      break;

    case "betting_record":
      content = <BettingRecord />;
      break;

    case "account_record":
      content = <AccountRecord />;
      break;

    case "profit_and_loss":
      content = <ProfitAndLoss />;
      break;

    case "reward_center":
      content = <RewardsCenter balance={balance} setBalance={setBalance} />;
      break;

    case "internal_message":
      content = "Internal Message here...";
      break;

    case "feedback":
      content = <PlayerFeedback />;
      break;

    default:
      break;

    //switch case
  }

  return content;
}

export default PersonalCenterContent;
