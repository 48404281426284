import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation } from "react-router-dom";

export default function HelpCenter() {
  const location = useLocation();
  const [expanded, setExpanded] = useState("");
  const currentRoute = location.pathname;
  const navigate = useNavigate();
  const { from } = location.state || {};
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (location.hash === "#privacy") {
      setExpanded("privacy");
    } else if (location.hash === "#about") {
      setExpanded("about");
    }
  }, [location]);

  const handleBackClick = () => {
    if (from === "/m/login") {
      navigate("/m/login");
    } else if (from === "/m/register") {
      navigate("/m/register");
    } else {
      navigate(-1);
    }
  };

  return (
    <Box sx={{ background: "#090929", minHeight: "100vh", color: "white" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          background: "#212b4b",
        }}
      >
        <IconButton sx={{ color: "white", mr: 2 }} onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Help Center</Typography>
      </Box>

      <Box sx={{ p: 2 }}>
        <Accordion
          expanded={expanded === "about"}
          onChange={handleChange("about")}
          sx={{
            bgcolor: "#232d3f",
            color: "white",
            "&:before": {
              display: "none",
            },
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            sx={{
              bgcolor: expanded === "about" ? "#dc3545" : "#232d3f",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottom: 1,
            }}
          >
            <Typography>About</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" sx={{ mb: 2 }}>
              ABOUT
            </Typography>
            <Typography paragraph>
              FBM E-Motion is an online gaming platform in the Philippines that
              ensures convenient access to all games through the internet.
            </Typography>
            <Typography paragraph>
              Licensed by PAGCOR, E-Motion offers a user-friendly gaming
              experience, allowing players to engage in games easily and
              intuitively, anytime and anywhere.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "terms"}
          onChange={handleChange("terms")}
          sx={{
            bgcolor: "#232d3f",
            color: "white",
            mt: 1,
            "&:before": {
              display: "none",
            },
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            sx={{
              bgcolor: expanded === "terms" ? "#dc3545" : "#232d3f",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottom: 1,
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          >
            <Typography>Terms and Conditions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Terms and conditions content here</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "privacy"}
          onChange={handleChange("privacy")}
          sx={{
            bgcolor: "#232d3f",
            color: "white",
            mt: 1,
            "&:before": {
              display: "none",
            },
            borderRadius: "10px",
          }}
        >
          <AccordionSummary
            sx={{
              bgcolor: expanded === "privacy" ? "#dc3545" : "#232d3f",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottom: 1,
            }}
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          >
            <Typography>Privacy Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Privacy policy content here</Typography>
          </AccordionDetails>
        </Accordion>

        {/* Add more accordions as needed */}
      </Box>
    </Box>
  );
}
