import { Grid, Box, Avatar, Typography, Modal } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useStyles from "../../pages/css/useStylesPlatform";
import axios from "axios";
import { WALLET } from "../../config";
import axiosInstance from "../../axiosConfig";

const RewardCenterMobile = ({ balance, setBalance }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [data, setData] = useState("");
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(`player/profile/my-account`)
      .then((response) => {
        const data = response.data[0];
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const username = localStorage.getItem("username");

  const handleBackButton = () => {
    navigate("/m/member/home");
  };
  const handleReceivingCenter = () => {
    navigate("/m/receivingCenter");
  };
  const handleBalanceRefresh = () => {
    setIsRefreshed(true);
    setTimeout(() => {
      setIsRefreshed(false);
    }, 1000);

    axios
      .get(`${WALLET}casino/balance/${username}`)
      .then((response) => {
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <img
          src="../../../../reward-center-banner.png"
          style={{
            width: "100%",
            position: "absolute",
          }}
          alt="Banner Background"
        />
        <Grid container className={classes.headerContainer}>
          <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
            <Grid className={classes.arrowButton}>
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "90%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5} xl={7.5}>
            <Grid
              sx={{
                color: "#FFFFFF",
                alignItems: "center",
                height: "100%",
                fontSize: "60%",
              }}
            >
              My Account
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "25%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                display: "flex",
                borderRadius: "15px 15px 15px 15px",
                position: "absolute",
                backgroundImage: "linear-gradient(to right, #e5edf4, #b4bbc6)",
                paddingTop: "2%",
                width: "85%",
                paddingBottom: "2%",
                paddingRight: "2%",
                paddingLeft: "2%",
                marginLeft: "5%",
              }}
            >
              <img
                src="../../../../my-account-border.png"
                style={{
                  width: "100%",
                  height: "55vw",
                }}
                alt="Profile border"
              />
              <img
                src="../../../../my-account-badge.png"
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "75%",
                  transform: "translate(-50%, -50%)",
                  width: "43%",
                  height: "auto",
                  zIndex: 1,
                }}
                alt="Profile badge"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ zIndex: 1 }}>
          <Grid item xs={3} sm={3}>
            <Avatar
              src="../../../../my-account-profile-picture.png"
              sx={{
                width: "53%",
                height: "53%",
                border: "0.9vw  solid #caa86d",
                marginLeft: "45%",
                marginTop: "45%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            sx={{ marginTop: "13%" }}
          >
            <Grid container alignItems="center" sx={{ marginLeft: "5%" }}>
              <Typography sx={{ fontSize: "4vw", fontWeight: "bold" }}>
                {username}
              </Typography>
              <CopyToClipboard text={username} onCopy={() => setCopied(true)}>
                <ContentCopyRoundedIcon
                  sx={{
                    fontSize: "5vw",
                    marginLeft: "2%",
                    marginTop: "-2%",
                    transform: "rotate(360deg)",
                  }}
                />
              </CopyToClipboard>
            </Grid>
            <Grid container alignItems="center" sx={{ marginLeft: "5%" }}>
              <Typography
                sx={{
                  fontSize: "5vw",
                  color: "#6b6e72",
                  fontWeight: "bold",
                }}
              >
                ₱{balance.toFixed(2)}
              </Typography>
              <RefreshIcon
                onClick={handleBalanceRefresh}
                sx={{
                  fontSize: "7vw",
                  marginLeft: "2%",
                  marginTop: "-2%",
                  color: "#6b6e72",
                  animation: isRefreshed ? "rotate 1s ease-in-out" : "none",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ marginLeft: "11%" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "3.2vw",
                marginTop: "5%",
                color: "#989da3",
              }}
            >
              <span
                style={{
                  fontSize: "6vw",
                  color: "#b9c6d8",
                  marginRight: "1%",
                  marginTop: "-1%",
                }}
              >
                •
              </span>
              <Typography
                sx={{
                  fontSize: "inherit",
                  color: "inherit",
                }}
              >
                Last login time: 2024/06/18 10:54:37
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          onClick={handleReceivingCenter}
          container
          sx={{
            marginTop: "30%",
            backgroundColor: "#fff",
            marginLeft: "5%",
            width: "40vw",
            zIndex: 1,
            height: "15vh",
            justifyContent: "center",
            borderRadius: "12px",
            alignContent: "center",
          }}
        >
          <img
            src="../../../../bubble.png"
            style={{
              width: "40%",
              position: "absolute",
            }}
            alt="Bubble Banner"
          />{" "}
          <img
            src="../../../../gift-box.png"
            style={{
              width: "10%",
              justifyContent: "center",
              position: "absolute",
              marginTop: "10%",
            }}
            alt="Bubble Banner"
          />
          <Typography
            sx={{
              marginTop: "40%",
              zIndex: 1,
              color: "#fff",
            }}
          >
            Bonus
          </Typography>
        </Grid>
        {copied && (
          <Modal
            open={copied}
            BackdropProps={{
              style: { backgroundColor: "rgba(0, 0, 0, 0.0)" },
            }}
            className={classes.myAccountsModal}
          >
            <Box
              sx={{
                backgroundColor: "#4d4d4d",
                opacity: "90%",
                p: 3,
                borderRadius: "5%",
                textAlign: "center",
                flexDirection: "column",
                outline: "none",
              }}
            >
              <CheckCircleOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "13vw" }}
              />
              <Typography sx={{ color: "#FFFFFF", fontSize: "3.5vw" }}>
                {username}
              </Typography>
              <Typography sx={{ color: "#FFFFFF", fontSize: "3.5vw" }}>
                Copy successful
              </Typography>
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default RewardCenterMobile;
