import React, { useState, useEffect } from "react";
import { Box, TextField, MenuItem, Select } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axiosInstance from "../../../../axiosConfig";

const AllRecords = ({ selectedTransactionType }) => {
  const username = localStorage.getItem("username");

  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));
  const [filterType, setFilterType] = useState("today");
  const [transactionType, setTransactionType] = useState("All");
  const [rowCount, setRowCount] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const transactionTypeMapping = {
    1: "Deposit",
    2: "Withdraw",
    3: "Manual Add",
    4: "Manual Deduct",
    5: "Transfer In",
    6: "Transfer Out",
  };

  const transactionStatus = {
    0: "Failed",
    1: "Success",
    2: "Pending",
  };

  useEffect(() => {
    fetchPlayerTransactionsData(
      selectedTransactionType,
      username,
      paginationModel.page,
      paginationModel.pageSize
    )
      .then((response) => {
        if (response) {
          const transformedData = response.data.map((item) => ({
            ...item,
            created_at: item.created_at.replace("T", " ").slice(0, 19),
            trans_type: transactionTypeMapping[item.trans_type] || "Unknown",
            trans_state:
            transactionStatus[item.trans_state] || item.trans_state,
          }));
          setData(transformedData);
          setRowCount(response.rowCount);
        } else {
          console.error("Data is null or undefined");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [
    selectedTransactionType,
    username,
    paginationModel.page,
    paginationModel.pageSize,
    startDate,
    endDate,
  ]);

  const fetchPlayerTransactionsData = async (
    selectedTransactionType,
    username,
    page = paginationModel.page,
    pageSize = paginationModel.pageSize
  ) => {
    let endpoint;
    switch (selectedTransactionType) {
      case "withdrawal_records":
        endpoint = `player/withdrawalrecords/${username}?page=${page}&pageSize=${pageSize}&startDate=${formatStartDate}&endDate=${formatEndDate}`;
        break;
      case "deposit_records":
        endpoint = `player/depositrecords/${username}?page=${page}&pageSize=${pageSize}&startDate=${formatStartDate}&endDate=${formatEndDate}`;
        break;
      case "transaction_records":
        endpoint = `player/transactionrecords/?page=${page}&pageSize=${pageSize}&startDate=${formatStartDate}&endDate=${formatEndDate}`;
        break;
      default:
        throw new Error(`Unknown transaction type: ${selectedTransactionType}`);
    }

    const response = await axiosInstance.get(`${endpoint}`);
    if (response.status !==200) {
      throw new Error("Network response was not ok");
    }
    return response.data;
  };

  const applyFilters = () => {
    if (!data) return;

    if (filterType === "today") {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: 0,
      }));
      setStartDate(dayjs().startOf("day"));
      setEndDate(dayjs().endOf("day"));
    } else if (filterType === "yesterday") {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: 0,
      }));
      setStartDate(dayjs().subtract(1, "day").startOf("day"));
      setEndDate(dayjs().subtract(1, "day").endOf("day"));
    } else if (filterType === "last7days") {
      setPaginationModel((prevModel) => ({
        ...prevModel,
        page: 0,
      }));
      setStartDate(dayjs().subtract(6, "days").startOf("day"));
      setEndDate(dayjs().endOf("day"));
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filterType, transactionType]);

  const handleFilterType = (type) => {
    setFilterType(type);
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFilterType("");
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFilterType("");
  };

  const handleTransactionTypeChange = (event) => {
    const selectedTransactionType = event.target.value;
    setTransactionType(selectedTransactionType);
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    fetchPlayerTransactionsData(
      selectedTransactionType,
      username,
      newPaginationModel.page,
      newPaginationModel.pageSize
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "trans_type",
      headerName: "Transaction Type",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "real_amount",
      headerName: "Transaction Amount",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "created_at",
      headerName: "Transaction Date",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const dateTime = new Date(params.value);
        const date = dateTime.toLocaleDateString();
        const time = dateTime.toLocaleTimeString();
        return (
          <div>
            <div>{date}</div>
            <div>{time}</div>
          </div>
        );
      },
    },
    {
      field: "trans_state",
      headerName: "Transaction Remark",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
  ];

  const formatStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const formatEndDate = dayjs(endDate).format("YYYY-MM-DD");

  console.log("data",data)

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            padding: "16px",
            flexWrap: "wrap",
          }}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="today"
                control={
                  <Radio
                    checked={filterType === "today"}
                    onClick={() => handleFilterType("today")}
                    sx={{
                      "&.Mui-checked": {
                        color: filterType === "today" ? "#fb322a" : "none",
                      },
                    }}
                  />
                }
                label="Today"
              />
              <FormControlLabel
                value="yesterday"
                control={
                  <Radio
                    onClick={() => handleFilterType("yesterday")}
                    sx={{
                      "&.Mui-checked": {
                        color: filterType === "yesterday" ? "#fb322a" : "none",
                      },
                    }}
                  />
                }
                label="Yesterday"
              />
              <FormControlLabel
                value="last7days"
                control={
                  <Radio
                    onClick={() => handleFilterType("last7days")}
                    sx={{
                      "&.Mui-checked": {
                        color: filterType === "last7days" ? "#fb322a" : "none",
                      },
                    }}
                  />
                }
                label="7-Days"
              />
            </RadioGroup>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                height: 36,
                width: 200,
                "& input": { fontSize: "12px" },
                "& label": { fontSize: "13px", marginTop: "-5px" },
                "& .MuiInputBase-input": { padding: "12px" },
                "& .MuiSvgIcon-root": { fontSize: "25px" },
              }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
              sx={{
                height: 36,
                width: 200,
                "& input": { fontSize: "12px" },
                "& label": { fontSize: "13px", marginTop: "-5px" },
                "& .MuiInputBase-input": { padding: "12px" },
                "& .MuiSvgIcon-root": { fontSize: "25px" },
              }}
            />
          </LocalizationProvider>
          {selectedTransactionType === "transaction_records" ? (
            <Select
              value={transactionType}
              onChange={handleTransactionTypeChange}
              size="small"
              label="Transaction Type"
              sx={{
                height: 40,
                width: 120,
              }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Transfer In">Withdraw</MenuItem>
              <MenuItem value="Transfer Out">Deposit</MenuItem>
            </Select>
          ) : (
            <Select
              value={transactionType}
              onChange={handleTransactionTypeChange}
              size="small"
              label="Transaction Type"
              sx={{
                height: 40,
                width: 120,
              }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Success">Success</MenuItem>
            </Select>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            padding: "0 16px 16px 16px",
            overflow: "hidden",
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            loading={isLoading}
            rowCount={rowCount}
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            pagination
            paginationMode="server"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            disableColumnMenu
            disableColumnFilter
            disableColumnReorder
            disableSelectionOnClick
            pageSizeOptions={[5, 10, 25, 50]}
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Box>
    </>
  );
};

export default AllRecords;
