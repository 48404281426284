// src/axiosConfig.js
import axios from "axios";
import { CASINO_SERVER } from "./config";
import Cookies from 'js-cookie';

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    baseURL: CASINO_SERVER,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
});

// Interceptor to add the Authorization cookie token to every request
axiosInstance.interceptors.request.use(
    (config) => {
        const xsrfToken = Cookies.get('XSRF-TOKEN');

        if (xsrfToken) {
            config.headers['X-XSRF-TOKEN'] = xsrfToken;
        }
        return config;
    },
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor to handle 401 on response
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
