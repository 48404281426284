import React, { useState } from "react";
import { Dialog, Button, Container, IconButton, Grid, Typography, TextField, InputAdornment, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../axiosConfig";

const DialogForgotPasswordModal = (props) => {
    const username = localStorage.getItem("username");
    const { open, setOpen, handleClose, setPassword = "", setConfirmPassword = "", } = props
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const closeForgotPassword = () => setShowForgotPassword(false);
    const [promt, setPrompt] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [status, setStatus] = useState([null]);
    const [values, setValues] = useState({
        username,
        password: setPassword,
        password_confirmation: setConfirmPassword,
    });
    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.value
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
    const TogglePassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleClickOpen = () => {
        setPrompt(true);
    };

    const closeDialog = () => {
        handleClose()
        setStatus([null])
        setPrompt(false);
    };

    const submit = (e) => {
        e.preventDefault();
        axiosInstance.post(`player/reset-password-login`, {
            username,
            password: values.password,
            password_confirmation: values.password_confirmation
        })
            .then(function (status) {
                if (status.data.message === 'Password changed succesfull.') {
                    // setInterval(() => {
                    //     handleClose()
                    // }, 3000)
                }
                setStatus(status.data.message)
                handleClickOpen()
            })
            .catch(function (errors) {
                setStatus(errors.response.data.message)
                handleClickOpen()
            });
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        boxShadow: "0px 0px 7px 0px white",
                        borderRadius: "20px",
                    },
                }}
                sx={{
                    backdropFilter: "blur(7px)",
                    "& .MuiPaper-root": {
                        backgroundColor: "rgba(0,0,0,0.5)",
                    },
                }}
            >
                <Container
                    component="main"
                    maxWidth="md"
                    sx={{
                        backgroundColor: "#090929",
                        borderRadius: "20px",
                        padding: 3,
                        position: 'relative',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            color: "white",
                        }}
                    >
                        <CloseRoundedIcon sx={{ fontSize: "24px" }} />
                    </IconButton>

                    <Typography
                        gutterBottom
                        sx={{
                            marginTop: "2rem",
                            color: "#FFFFFF",
                            fontSize: { xs: "1em", sm: "22px" },
                            fontWeight: "700",
                            textAlign: "center",
                        }}
                    >
                        Change Password (First Login or Password Expired)
                    </Typography>

                    <Typography
                        gutterBottom
                        sx={{
                            marginTop: "1rem",
                            color: "#FFFFFF",
                            fontSize: { xs: "0.700rem", sm: "15px" },
                            fontWeight: "700",
                            textAlign: "center",
                        }}
                    >
                        Changing passwords from time to time helps account security
                    </Typography>

                    <Grid container justifyContent={'center'} spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={12} md={10}>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "0.875rem", sm: "1rem" },
                                    // textAlign: 'start',
                                }}
                            >
                                <span style={{ color: "red" }}>*</span> Username
                            </Typography>
                            <TextField
                                id="username"
                                sx={{
                                    height: "auto",
                                    borderRadius: "20px",
                                    backgroundColor: "#16213e",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '& input': {
                                            color: 'white',
                                        },
                                    }, '& .MuiInputBase-input.Mui-disabled': {
                                        color: 'white',
                                        WebkitTextFillColor: 'unset',

                                    },
                                }}
                                type="text"
                                fullWidth
                                disabled
                                required
                                value={values.username}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "0.875rem", sm: "1rem" }, // Responsive font sizes
                                }}
                            >
                                <span style={{ color: "red" }}>*</span> Please enter your new password
                            </Typography>
                            <TextField
                                id="password"
                                sx={{
                                    height: "auto",
                                    borderRadius: "20px",
                                    backgroundColor: "#16213e",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '& input': {
                                            color: 'white',
                                        },
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        height: "50px",
                                        color: "#FFFFFF",
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                marginRight: "10px",
                                            }}
                                        >
                                            <IconButton
                                                onClick={TogglePassword}
                                                edge="end"
                                                sx={{
                                                    color: "white",
                                                }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                required
                                value={values.password}
                                onChange={handleChange}
                            />
                            <Typography
                                sx={{
                                    color: "#555556",
                                    marginBottom: "5px",
                                    fontWeight: "bold",
                                    fontSize: { xs: "0.75rem", sm: "0.800rem" }, // Responsive font sizes
                                    textAlign: "center",
                                    marginTop: "5px",
                                }}
                            >
                                Please enter 6 - 13 alphanumeric characters without special characters.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "0.875rem", sm: "1rem" }, // Responsive font sizes
                                }}
                            >
                                <span style={{ color: "red" }}>*</span> Confirm password
                            </Typography>
                            <TextField
                                id="password_confirmation"
                                sx={{
                                    height: "auto",
                                    borderRadius: "20px",
                                    backgroundColor: "#16213e",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '& input': {
                                            color: 'white',
                                        },
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        height: "50px",
                                        color: "#FFFFFF",
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                marginRight: "10px",
                                            }}
                                        >
                                            <IconButton
                                                onClick={TogglePassword}
                                                edge="end"
                                                sx={{
                                                    color: "white",
                                                }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                required
                                value={values.password_confirmation}
                                onChange={handleChange}
                            />
                            <Typography
                                sx={{
                                    color: "#555556",
                                    marginBottom: "5px",
                                    fontWeight: "bold",
                                    fontSize: { xs: "0.75rem", sm: "0.800rem" }, // Responsive font sizes
                                    textAlign: "center",
                                    marginTop: "5px",
                                }}
                            >
                                Please enter 6 - 13 alphanumeric characters without special characters.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button
                                disableRipple
                                variant="contained"
                                sx={{
                                    backgroundColor: "#da1212",
                                    borderRadius: "30px", // Adjusted for responsiveness
                                    fontWeight: "bold",
                                    fontSize: { xs: "1rem", sm: "1.5rem" }, // Responsive font sizes
                                    height: "44px",
                                    width: { xs: "90%", sm: "70%" }, // Adjust width for mobile
                                    "&:hover": {
                                        background: "#da1212",
                                    },
                                }}
                                type="submit"
                                onClick={submit}
                            >
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            <Dialog
                maxWidth="xs"
                open={promt}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title " justifyContent="center"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '16px',
                        backgroundColor: '#334155'
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="yellow" className="size-6" width={48} height={48}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                    </svg>
                </DialogTitle>
                <DialogContent sx={{
                    backgroundColor: '#334155',
                    color: "white"
                }}>
                    <DialogContentText id="alert-dialog-description" color={"white"} >
                        {status}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    backgroundColor: "#da1212",
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }} >
                    <Button onClick={closeDialog} autoFocus fullWidth style={{ color: 'white' }}  >
                        OK
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default DialogForgotPasswordModal