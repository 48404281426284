import {
  Box,
  Button,
  Typography,
  Grid,
  Slide,
  IconButton,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CloseIcon from "@mui/icons-material/Close";
import TicketsTable from "./tickets_table/Tickets_Table";
import ViewModal from "../profile_details/view_modal/ViewModal";

export default function TicketRecords({
  isPanelOpen,
  setPanelOpen,
  isModalOpen,
  closeModal,
}) {
  const handleButtonClick = () => {
    setPanelOpen(!isPanelOpen);
  };

  const handleClosePanel = () => {
    setPanelOpen(false);
    closeModal();
  };

  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
          height: "625px",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 35,
            px: 2,
            gap: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ gap: 0.5, display: "flex", alignItems: "center" }}>
            <Box sx={{ width: 5, height: 15, backgroundColor: "purple" }} />
            <Typography sx={{ fontSize: 16 }}>Receive Center</Typography>
          </Box>
          <Button
            onClick={handleButtonClick}
            sx={{
              gap: 1,
              px: 2,
              height: 25,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f3ebff",
              color: "purple",
            }}
          >
            <ConfirmationNumberIcon sx={{ fontSize: 15 }} />
            <Typography sx={{ fontSize: 12 }}>Ticket records</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            gap: 5,
            width: "100%",
            height: "30%",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img
            src="../../../../../receivecenterheader(1).jpg"
            alt="Rewards Background"
            style={{ height: "100%", width: "100%" }}
          />
          <Box
            sx={{
              top: "55%",
              left: "64%",
              transform: "translate(-50%, -50%)",
              zIndex: 10,
              width: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              justifyContent: "space-evenly",
              gap: 2,
            }}
          >
            <Typography sx={{ color: "white", fontSize: 12 }}>
              Voucher
            </Typography>
            <Typography
              sx={{ color: "white", fontSize: 30, fontWeight: "bold" }}
            >
              000
            </Typography>
            <Typography sx={{ color: "white", fontSize: 12 }}>
              Deadline: ----.--.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              pt: 1,
            }}
          >
            <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
              No Tickets Available
            </Typography>

            <Box sx={{ width: "auto", display: "flex" }}>
              <img
                src="../../../../../ticket_left.png"
                alt="Rewards Background"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: 3,
                  width: 100,
                  transform: "rotate(15deg)",
                  borderTopRightRadius: 1,
                  backgroundColor: "whitesmoke",
                  transformOrigin: "bottom left",
                  borderBottomRightRadius: 1,
                }}
              >
                <Typography sx={{ fontSize: 12, color: "gray" }}>
                  Remaining
                </Typography>
                <Box sx={{ display: "flex", alignItems: "end", color: "gray" }}>
                  <Typography
                    sx={{ fontSize: 40, fontWeight: "bold", lineHeight: 1 }}
                  >
                    00
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                    Days
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                  00:00
                </Typography>
                <Button
                  sx={{
                    gap: 1,
                    px: 1,
                    height: 25,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#cccaca",
                    borderRadius: 1,
                    color: "#ff4f4f",
                  }}
                >
                  <Typography sx={{ fontSize: 12 }}>Receive</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Slide direction="left" in={isPanelOpen} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 820,
              height: "100%",
              zIndex: 1200,
              display: "flex",
              alignItems: "start",
            }}
          >
            <IconButton
              onClick={handleClosePanel}
              sx={{
                color: "red",
                width: "35px",
                height: "35px",
                borderRadius: "0",
                color: "white",
                backgroundColor: "white",
              }}
            >
              <CloseIcon
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "red",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                paddingX: 2,
                boxSizing: "border-box",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  height: "5%",
                  gap: 0.5,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 3,
                  paddingY: "3px",
                }}
              >
                <Box sx={{ width: 5, height: 15, backgroundColor: "purple" }} />
                <Typography sx={{ fontSize: 16 }}>Receive Center</Typography>
              </Box>
              {!isModalOpen && <TicketsTable />}{" "}
              {/* Only show TicketsTable when modal is closed */}
              {isModalOpen && <ViewModal onClose={closeModal} />}
            </Box>
          </Box>
        </Slide>
      </Grid>
      {isPanelOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1100,
          }}
        />
      )}
    </>
  );
}
