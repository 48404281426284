import { useEffect, useState } from "react";
import axiosInstance from "../../../axiosConfig";
import { usePlayer } from "../../../contexts/PlayerContext";
import Carousel from "react-material-ui-carousel";
import CampaignIcon from "@mui/icons-material/Campaign";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./carousel.css";
import { Grid, Typography } from "@mui/material";

const DashboardCarousel = () => {
  const { isMobile } = usePlayer();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banners, setBanners] = useState([]);

  const imagesMobile = [
    `${process.env.PUBLIC_URL}/landing-page/mobile/first-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/second-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/third-banner.png`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/fourth-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/fifth-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/sixth-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/seventh-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/eight-banner.jpeg`,
    `${process.env.PUBLIC_URL}/landing-page/mobile/ninth-banner.jpeg`,
  ];

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axiosInstance.get("/banner", {
          params: { type: "games" },
        });
        const data = response.data.data;
        setBanners(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch banners");
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  const movingText = {
    "@keyframes moveLeftToRight": {
      "0%": {
        transform: "translateX(220%)",
      },
      "100%": {
        transform: "translateX(-100%)",
      },
    },
  };

  return (
    <>
      {isMobile && imagesMobile ? (
        <>
          <Grid sx={{ marginBottom: "2%" }}>
            <Grid
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "relative",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                height: "100%",
                marginTop: "13%",
              }}
            >
              <CampaignIcon
                sx={{
                  fontSize: "6vw",
                  color: "#FFFFFF",
                  marginLeft: "2%",
                  marginRight: "3%",
                }}
              />
              <Grid
                sx={{
                  display: "flex",
                  overflow: "hidden",
                  width: "calc(100% - 3vw)",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "4vw",
                    whiteSpace: "nowrap",
                    animation: "moveLeftToRight 10s linear infinite",
                    ...movingText,
                  }}
                >
                  Welcome to the FBM E-Motion! 50% BONUS for your First Deposit!
                  Claim Now!
                </Typography>
              </Grid>
            </Grid>
            <Carousel
              sx={{
                transitionDuration: "0s !important",
                marginTop: "-6%",
                zIndex: 0,
              }}
              swipe
              animation="slide"
              indicatorIconButtonProps={{
                style: {
                  display: "none",
                },
              }}
              navButtonsProps={{
                style: {
                  backgroundColor: "rgb(256, 256, 256, 0%)",
                  borderRadius: 0,
                },
              }}
              NextIcon={
                <ArrowForwardIosIcon
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                  }}
                />
              }
              PrevIcon={
                <ArrowBackIosIcon
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                  }}
                />
              }
              duration={750}
            >
              {imagesMobile.map((img, key) => (
                <>
                  <img
                    key={key}
                    src={img}
                    style={
                      img === "/landing-page/mobile/ninth-banner.jpeg"
                        ? { width: "104%", margin: 0, height: "100%" }
                        : { width: "100%", margin: 0, height: "100%" }
                    }
                    alt=""
                  />
                </>
              ))}
            </Carousel>
          </Grid>
        </>
      ) : (
        <>
          {banners.map((row, key) => (
            <Grid sx={{ marginTop: "65px" }} key={key}>
              <Grid
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  position: "relative",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <CampaignIcon
                  style={{
                    fontSize: "2.5rem",
                    color: "#FFFFFF",
                    marginLeft: "25px",
                    marginRight: "15px",
                  }}
                />
                <Grid
                  sx={{
                    display: "flex",
                    overflow: "hidden",
                    width: "calc(100% - 75px)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      animation: "moveLeftToRight 10s linear infinite",
                      ...movingText,
                    }}
                  >
                    {row.title}
                  </Typography>
                </Grid>
              </Grid>

              <Carousel
                sx={{
                  transitionDuration: "0s !important",
                  marginTop: "-40px",
                  zIndex: 0,
                }}
                swipe
                animation="slide"
                indicatorIconButtonProps={{
                  style: { display: "none" },
                }}
                navButtonsProps={{
                  style: {
                    backgroundColor: "rgb(256, 256, 256, 0%)",
                    borderRadius: 0,
                  },
                }}
                NextIcon={
                  <ArrowForwardIosIcon
                    style={{ color: "#fff", fontSize: "2.5rem" }}
                  />
                }
                PrevIcon={
                  <ArrowBackIosIcon
                    style={{ color: "#fff", fontSize: "2.5rem" }}
                  />
                }
                duration={750}
              >
                {row.banners.map((banner, idx) => (
                  <img
                    key={idx}
                    src={banner.banner_url}
                    style={{ width: "100%", margin: 0, marginTop: "-20px" }}
                    alt="Banner"
                  />
                ))}
              </Carousel>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

export default DashboardCarousel;
