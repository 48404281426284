import { Box, Typography } from "@mui/material";

export default function AccountStatus() {
  return (
    <Box sx={{ borderRadius: "25px 25px", marginX: "10px", marginY: "40px", boxShadow: "0 7px 62px 0 rgba(170,106,226,.19)", backgroundImage: "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/grade-mid-bg.305f19e1.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "330px", height: "540px", textAlign: "center", }} >
      {/* Status for the account verification */}
      <div style={{ marginTop: "8rem", }}>
        <Typography sx={{ color: "#3678c4", fontSize: "4.5em", lineHeight: "1", }} >
          Mid
        </Typography>
        <Typography sx={{ color: "#3678c4", }} >
          Good Percentage
        </Typography>
      </div>
      {/* Account Verification Scoure */}
      <div style={{ marginTop: "4rem", color: "#FFFFFF", textAlign: "center",}}>
        <p> Score is <span style={{ fontSize: "1.5rem" }}>66</span> point </p>
        <p>Your account security level is Mid</p>
      </div>
      {/* Recommended Settings */}
      <div style={{ textAlign: "center", marginTop: "55px" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#5F5F5F" }}>
          Recommended Settings
        </Typography>
        {/* Dynamic Recommend Settings */}
        <div>
            {/* RECOMMENDED SETTINGS */}
        </div>
      </div>
    </Box>
  );
}
