import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ReplayIcon from "@mui/icons-material/Replay";
import victory from "../../../../assets/images/icons/victory.png";
import axiosInstance from "../../../../axiosConfig";

export default function ProfileDetails({
  balance,
  setBalance,
  setModalOpen,
  setPanelOpen,
}) {
  const [copied, setCopied] = useState(false);
  const username = localStorage.getItem("username");

  const handleRefresh = (e) => {
    const refreshBalance = document.getElementById("refreshBalance");
    refreshBalance.classList.add("open");
    axiosInstance
      .get(`player/balance`)
      .then((response) => {
        setBalance(response.data.balance);
        setTimeout(() => {
          refreshBalance.classList.remove("open");
          refreshBalance.classList.add("close");
        }, 1000);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTimeout(() => {
          refreshBalance.classList.remove("open");
          refreshBalance.classList.add("close");
        }, 1000);
      });
  };

  const handleViewClick = () => {
    setModalOpen(true);
    setPanelOpen(true);
  };

  const handleCopy = () => {
    setCopied(true);
    const timer = setTimeout(() => {
      setCopied(false);
      alert(username + " is copied to clipboard.")
    }, 500);
    return () => clearTimeout(timer);
  };

  return (
    <Grid
      container
      sx={{
        p: "30px",
        width: "45%",
        height: "600px",
        display: "inline-flex",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          boxShadow: 5,
          borderRadius: "10px",
        }}
      >
        <img
          src="../../../../../rewardsbg.png"
          alt="Rewards Background"
          style={{
            height: "40%",
            width: "100%",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "start",
            width: "100%",
            height: "35%",
            position: "absolute",
            top: 0,
            left: 0,
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              p: 1,
              width: "100%",
              display: "flex",
              fontSize: "small",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <img
              style={{
                width: "38px",
                borderRadius: "50%",
                padding: "10px",
              }}
              src="https://images.21947392.com/TCG_PROD_IMAGES/B2C/01_PROFILE/PROFILE/0.png"
              alt="Profile"
            />
            <Typography variant="body2" sx={{ color: "white" }}>
              Username:&nbsp;
            </Typography>
            <CopyToClipboard text={username} onCopy={handleCopy}>
              <Box
                sx={{
                  gap: 1,
                  width: "fit-content",
                  borderRadius: "25px",
                  backgroundColor: "rgba(256, 256, 256, 0.5)",
                  padding: "2.5px 10px",
                  fontWeight: "bold",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
                variant="body2"
              >
                {username}
                <CopyAllIcon sx={{ fontSize: "small", cursor: "pointer" }} />
              </Box>
            </CopyToClipboard>
            <Typography>
              <ReplayIcon
                id="refreshBalance"
                style={{ fontSize: "2em", cursor: "pointer", color: "white" }}
                className="reload-arrow"
                onClick={handleRefresh}
              />
            </Typography>
          </Box>
          <Grid
            sx={{
              width: "100%",
              marginTop: "10px",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">Available Balance</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h4">₱</Typography>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                {balance ? <div>{balance.toFixed(2)}</div> : <div>0.00</div>}
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{
            p: 2,
            width: "100%",
            height: "65%",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              padding: 1,
              boxShadow: 2,
              width: "100%",
              height: "20%",
              display: "inline-flex",
              alignItems: "center",
              borderRadius: "10px",
              boxSizing: "border-box",
            }}
          >
            <img
              src={victory}
              style={{
                width: "20%",
                height: "100%",
              }}
              alt="Victory"
            />
            <Box sx={{ width: "55%", paddingX: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>Title Here</Typography>
              <Typography sx={{ fontWeight: "light" }}>
                Subtitle here
              </Typography>
            </Box>
            <Button
              sx={{ width: "15%", border: 1, borderRadius: "20px" }}
              onClick={handleViewClick}
            >
              View
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
