import { Box, Stack, styled } from "@mui/material";
import pen_icon from "../../../../../assets/images/icons/pen_icon.png";

const PaymentProviderSelection = styled(Box)({
  border: "1px solid #FF0000",
  borderRadius: "6px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "7px 6px",
  width: "110px",
  color: "#535353",
  letterSpacing: "1.25px",
  cursor: "pointer",
});

const EditButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "110px",
});

const styles = {
  paymentProviderImage: { height: "40px", width: "40px" },
  editButtonImg: {
    height: "20px",
    width: "20px",
    borderRadius: "100%",
    backgroundColor: "#23e63a",
    padding: "10px",
    boxShadow: "0 6px 18px 0 rgba(13,252,30,.33)",
    cursor: "pointer",
  },
};

export default function WithdrawSettings({ toggleDrawer }) {
  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <PaymentProviderSelection>
        <img
          src="https://images.9183749.com/mcs-images/withdraw_group_icon/EWALLET_BANK_DEFAULT_ICON.png"
          style={styles.paymentProviderImage}
        />
        GCASH
      </PaymentProviderSelection>
      <EditButtonContainer onClick={toggleDrawer}>
        <img src={pen_icon} style={styles.editButtonImg} />
      </EditButtonContainer>
    </Stack>
  );
}
