import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../../axiosConfig";
import { CASINO_SERVER } from "../../config";
import axios from "axios";

const styles = {
  container: {
    paddingTop: "25px",
    paddingX: "25px",
    color: "#666",
    width: "270px",
    height: "572px",
  },
  title: {
    borderLeft: "5px solid #22D72A",
    paddingLeft: "30px",
    marginBottom: "15px",
  },
  imageBox: {
    backgroundSize: "cover",
    width: "270px",
    height: "171px",
    boxShadow: "0 5px 30px 0 rgba(0,0,0,.08)",
    borderRadius: "12px",
    position: "relative",
    cursor: "pointer",
    textAlign: "center",
    margin: "15px auto 0",
  },
  fileInput: {
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  addIcon: {
    backgroundImage:
      "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/add-icon.820f266c.png)",
    position: "absolute",
    width: "32px",
    height: "32px",
    left: "50%",
    top: "57px",
    marginLeft: "-16px",
  },
  imageText: {
    paddingTop: "100px",
    height: "42.5px",
  },
  tipText: {
    fontSize: "0.75rem",
    textAlign: "center",
    marginBottom: "0px",
    marginTop: "auto",
    padding: "0px",
    color: "#989898",
  },
  uploadButtonContainer: {
    width: "100%",
    borderTop: "1px solid #efefef",
    marginBottom: "0px",
    marginTop: "auto",
  },
  uploadButton: {
    position: "relative",
    display: "inline-block",
    height: "34px",
    lineHeight: "34px",
    padding: "0 10px",
    backgroundColor: "#ff0000",
    color: "#fff",
    verticalAlign: "middle",
    textAlign: "center",
    borderRadius: "20px",
    cursor: "pointer",
    width: "98px",
    transition: ".2s ease-in-out",
    marginTop: "10px",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },
  deleteImage: {
    backgroundImage:
      "url(https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/dei-icon.9d6739e3.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#ff0000",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    position: "absolute",
    bottom: "8px",
    right: "8px",
    zIndex: 9999,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
};

const AccountVerificationMobile = () => {
  const [idImage, setIdImage] = useState(null);
  const [selfieImage, setSelfieImage] = useState(null);
  const [idImageUploaded, setIdImageUploaded] = useState(false);
  const [selfieImageUploaded, setSelfieImageUploaded] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false);

  useEffect(() => {
    axiosInstance
      .get(`player/profile/my-account/get/upload`)
      .then((response) => {
        const data = response.data;

        if (
          data[0].front_pic !== null &&
          data[0].selfie !== null &&
          data[0].is_verified_ID !== 1
        ) {
          setIsReviewing(true);
        } else if (
          data[0].front_pic !== null &&
          data[0].selfie !== null &&
          data[0].is_verified_ID !== 0
        ) {
          setIsReviewed(true);
          setIsReviewing(false);
        } else {
          setIsReviewing(false);
          setIsUpload(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleReUpload = () => {
    setIsUpload(true);
    setIsReviewing(false);
  };

  const navigate = useNavigate();

  const handleImageUpload = (event, setImage, setUploadedState) => {
    const file = event.target.files[0];
    event.target.value = null;
    // This is 1,000,000 = 1MB
    if (file.size > 10000000) {
      toast.dismiss();
      toast.error("Please upload smaller than 10MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setImage(file);
      setUploadedState(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if both images are selected
    if (!idImage || !selfieImage) {
      toast.error("Both images are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append("front_pic", idImage);
    formData.append("selfie", selfieImage);

    try {
      const response = await axiosInstance.post(
        `player/profile/my-account/upload-id`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        setIdImage(null);
        setSelfieImage(null);
        setIdImageUploaded(false);
        setSelfieImageUploaded(false);
        setIsReviewing(true);
        setIsUpload(false);
        toast.success("Upload Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      toast.error("Upload failed, File too large. Please try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const removeIdImage = () => {
    setIdImage(null);
    setIdImageUploaded(false);
  };

  const removeSelfieImage = () => {
    setSelfieImage(null);
    setSelfieImageUploaded(false);
  };

  const handleBackButton = () => {
    navigate("/m/securityCenter");
  };

  const renderImageBox = (
    image,
    setImage,
    removeImage,
    placeholderImage,
    text,
    uploadedState,
    setUploadedState
  ) => (
    <Box
      sx={{
        ...styles.imageBox,
        backgroundImage: image
          ? `url(${URL.createObjectURL(image)})`
          : `url(${placeholderImage})`,
      }}
    >
      {image && <div style={styles.deleteImage} onClick={removeImage}></div>}
      {!image && (
        <>
          <div style={styles.addIcon}></div>
          <Typography variant="body2" sx={styles.imageText}>
            {text}
          </Typography>
        </>
      )}
      {!uploadedState && (
        <input
          type="file"
          accept="image/*"
          style={styles.fileInput}
          onChange={(e) => handleImageUpload(e, setImage, setUploadedState)}
        />
      )}
    </Box>
  );

  return (
    <>
      <Grid
        container
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          sx={{
            height: "13.4vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid container xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.2vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography
              sx={{
                color: "#FFFFFF",
                marginTop: "6%",
                fontSize: "3.8vw",
              }}
            >
              Account Verification
            </Typography>
          </Grid>
        </Grid>
        {isReviewing && (
          <Grid container sm={12} xs={12} sx={{}}>
            <Grid
              item
              sm={12}
              xs={12}
              sx={{
                bgcolor: "#1296DB",
              }}
            >
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  textAlign: "center",
                  paddingRight: "5%",
                  paddingLeft: "5%",
                }}
              >
                <ErrorOutlineIcon sx={{ fontSize: "30vw", color: "#fff" }} />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "6vw" }}>
                  Reviewing
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sm={12}
              xs={12}
              sx={{ paddingRight: "4%", paddingLeft: "4%" }}
            >
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#ggg",
                    fontSize: "4.5vw",
                    marginRight: "2%",
                    marginTop: "5%",
                  }}
                >
                  Your request is still pending process, please be patient.
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    color: "#0E86D4",
                    fontSize: "4vw",
                    marginTop: "5%",
                    cursor: "pointer",
                  }}
                >
                  Customer Service
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  marginTop: "5%",
                  marginBottom: "5%",
                  backgroundColor: "#ff1931",
                  height: "12vw",
                  width: "100%",
                  borderRadius: "1vw",
                  boxShadow: "0 .3rem .4rem 0 rgba(255, 0, 16, .4)",
                  "&:hover": {
                    backgroundColor: "#ff1931",
                  },
                }}
              >
                <Typography
                  onClick={handleReUpload}
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "4.5vw",
                    width: "100%",
                    height: "12vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Reupload
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isUpload && (
          <Stack height="100%">
            <Stack sx={styles.container}>
              <Typography variant="body2" sx={styles.title}>
                Account Verification
              </Typography>
              <Typography variant="body2">
                Please upload your ID, Driver's License, Passport, or other
                Government-Issued ID. {"(Must have your Real Name on it)"}
              </Typography>

              {renderImageBox(
                idImage,
                setIdImage,
                removeIdImage,
                "https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/idcard-front.bd78c00c.png",
                "Front Side of Government Issued ID",
                idImageUploaded,
                setIdImageUploaded
              )}
              {renderImageBox(
                selfieImage,
                setSelfieImage,
                removeSelfieImage,
                "https://www.fbmemotion.ph/mc/v.1.0.1/manifest/images/idcard-back.ee19512d.png",
                "Selfie with ID",
                selfieImageUploaded,
                setSelfieImageUploaded
              )}
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={styles.uploadButtonContainer}
            >
              <Button
                sx={styles.uploadButton}
                onClick={handleSubmit}
                disabled={!idImageUploaded || !selfieImageUploaded}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        )}
        {isReviewed && (
          <Grid container sm={12} xs={12} sx={{}}>
            <Grid
              item
              sm={12}
              xs={12}
              sx={{
                bgcolor: "#5cb85c",
              }}
            >
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  textAlign: "center",
                  paddingRight: "5%",
                  paddingLeft: "5%",
                }}
              >
                <CheckCircleOutlinedIcon
                  sx={{ fontSize: "30vw", color: "#fff" }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "6vw" }}>
                  Reviewed
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sm={12}
              xs={12}
              sx={{ paddingRight: "4%", paddingLeft: "4%" }}
            >
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#ggg",
                    fontSize: "4.5vw",
                    marginRight: "2%",
                    marginTop: "5%",
                  }}
                >
                  Your request is accepted, thank you.
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    color: "#0E86D4",
                    fontSize: "4vw",
                    marginTop: "5%",
                    cursor: "pointer",
                  }}
                >
                  Customer Service
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <ToastContainer />
      </Grid>
    </>
  );
};

export default AccountVerificationMobile;
