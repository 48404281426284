import { Divider, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BettingRecordStatusFilter = ({
  statusFilterOpen,
  statusRef,
  handleCloseStatusFilter,
  handleTypes,
}) => {
  return (
    <>
      <Modal
        open={statusFilterOpen}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "auto",
          outline: "none",
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            outline: "none",
          }}
        >
          <Grid
            ref={statusRef}
            item
            xs={12}
            sm={12}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontSize: "4vw",
                color: "#9e9e9e",
                marginLeft: "3%",
              }}
            >
              Types
              <CloseIcon
                onClick={handleCloseStatusFilter}
                sx={{
                  marginTop: "2%",
                  fontSize: "5vw",
                  color: "#888888",
                  marginLeft: "80%",
                }}
              />
            </Typography>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            <Grid container sx={{ height: "8vw", alignItems: "center" }} onClick={() => handleTypes("Unsettled")}>
              <Typography
                sx={{
                  fontSize: "4.8vw",
                  marginTop: "1%",
                  color: "#2b2b2b",
                  marginLeft: "2%",
                  display: "flex",
                }}
              >
                Unsettled
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            <Grid container sx={{ height: "8vw", alignItems: "center" }} onClick={() => handleTypes("Settled")}>
              <Typography
                sx={{
                  fontSize: "4.8vw",
                  marginTop: "1%",
                  color: "#2b2b2b",
                  marginLeft: "2%",
                  display: "flex",
                }}
              >
                Settled
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: ".5%" }} />
            <Grid container sx={{ height: "8vw", alignItems: "center" }} onClick={() => handleTypes("Lost")}>
              <Typography
                sx={{
                  fontSize: "4.8vw",
                  marginTop: "1%",
                  color: "#2b2b2b",
                  marginLeft: "2%",
                  display: "flex",
                }}
              >
                Lost
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default BettingRecordStatusFilter;
