import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
const News = () => {
  const [playerRanking, setPlayerRanking] = useState("");

  const [latestWinners, setLatestWinner] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/player/winnersNews")
      .then((response) => {
        setLatestWinner(response.data);
      })
      .catch((error) => {
        console.error("Error fetching winners:", error);
      });
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`member/getMemberData`)
      .then((response) => {
        setPlayerRanking(response.data.player_ranking);
      })
      .catch((error) => {});
  }, []);

  const latestNewsData = [
    {
      id: 1,
      title: "FBM Bingo Pilipino: Champion’s Favorite",
      img: "bingoImg.551ac48b.jpg",
      context1:
        "Bingo Pilipino has emerged as the undisputed Champion of electronic bingo gaming in the Philippines. This thrilling game, available exclusively through FBM®'s bingo collection, has taken the nation by storm, captivating players with its unique features and captivating gameplay.",
      context2:
        "Bingo Pilipino has emerged as the undisputed Champion of electronic bingo gaming in the Philippines. This thrilling game, available exclusively through FBM®'s bingo collection, has taken the nation by storm, captivating players with its unique features and captivating gameplay.",
      readmore: "1",
    },
    {
      id: 2,
      title: "Reeling Victories in Fishing Games",
      img: "bingoImg.551ac48b.jpg",
      context1:
        "In the world of online gaming, it's not just about luck and strategy; being polite and responsible is crucial. Live dealers in online casinos are similar to the ones you see in real casinos. Whether they're dealing cards in poker or spinning the roulette wheel, the dealer doesn't control if you win or lose.",
      context2:
        "It's natural to feel upset when you lose money in an online casino game, but that's part of the game. Just like professional poker players and gamblers will tell you, sometimes you have to accept that luck isn't on your side, and losses happen.Whether you're new or experienced, you can follow this guide for good online gaming in Live Games at FBM E-Motion.",
    },
    {
      id: 3,
      title: "Etiquette Guides in Live Games",
      img: "bingoImg.551ac48b.jpg",
      context1:
        "Bingo Pilipino has emerged as the undisputed Champion of electronic bingo gaming in the Philippines. This thrilling game, available exclusively through FBM®'s bingo collection, has taken the nation by storm, captivating players with its unique features and captivating gameplay.",
      context2:
        "Bingo Pilipino has emerged as the undisputed Champion of electronic bingo gaming in the Philippines. This thrilling game, available exclusively through FBM®'s bingo collection, has taken the nation by storm, captivating players with its unique features and captivating gameplay.",
    },
  ];

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleSeeAllWinner = () => {
    navigate("/winners");
  };

  const handleReadMoreClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <Grid container sx={{ marginTop: "60px" }}>
        <Box
          container
          sx={{
            backgroundColor: "#090929",
            display: "flex",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <Box
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginTop: "2%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  marginBottom: "1%",
                  color: "#FFFFFF",
                  fontSize: "24px",
                }}
              >
                Latest Winners
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1.1fr 1.1fr 1.1fr 1.1fr 1fr",
                  gap: "10px",
                  width: "100%",
                }}
              >
                {latestWinners.map((winner, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor:
                        hoveredIndex === index ? "#ff0000" : "#16213e",
                      transition: "background-color 0.3s ease-in-out",
                      alignItems: "center",
                      borderColor: "blue",
                      width: "258.82px",
                    }}
                  >
                    <img
                      src="../gameicon-default.png"
                      alt="Winner"
                      style={{
                        paddingLeft: 10,
                        width: "35%",
                        height: "80%",
                      }}
                    />
                    <div style={{ padding: "10px" }}>
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          marginBottom: "5%",
                          display: "flex",
                          fontWeight: "bold",
                        }}
                      >
                        {winner.game}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          backgroundImage:
                            "linear-gradient(180deg, #f88000 25%, #facc22 82.81%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        ₱{winner.amount}
                      </Typography>
                    </div>
                  </div>
                ))}
              </Box>
              {playerRanking && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    marginTop: "2%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    onClick={handleSeeAllWinner}
                    container
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#16213e",
                      color: "#fff",
                      borderRadius: "12px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      display: "flex",
                      width: "200px",
                      justifyContent: "center",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.1)",
                        backgroundColor: "#ff0000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        marginLeft: "30px",
                      }}
                    >
                      See All Winners
                    </Typography>
                    <DoubleArrowIcon
                      sx={{
                        backgroundColor: "#da1212",
                        height: "45px",
                        marginLeft: "20px",
                        borderRadius: "0 12px 12px 0",
                      }}
                    />
                  </Box>
                </Box>
              )}

              {latestNewsData.map((row, index) => (
                <Grid
                  onClick={handleReadMoreClick}
                  container
                  sx={{
                    backgroundColor: "red",
                    borderRadius: "12px 12px 12px 12px",
                    marginTop: "2%",
                  }}
                >
                  <Grid container xs={12} sm={12} sx={{ height: "100%" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        alignContent: "center",
                        padding: 1,
                        color: "#fff",
                      }}
                    >
                      {row.title}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={6}
                    sx={{
                      width: "100%",
                      backgroundColor: "#fff",
                      flexDirection: "column",
                      borderRadius: "0 0 0 12px",
                      padding: 3,
                      alignContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        justifyContent: "center",
                        color: "#000",
                        padding: 2,
                      }}
                    >
                      {row.context1}
                    </Typography>
                    <Typography
                      sx={{
                        justifyContent: "center",
                        alignContent: "center",
                        color: "#000",
                        padding: 2,
                      }}
                    >
                      {row.context2}
                    </Typography>

                    <Typography
                      onClick={handleReadMoreClick}
                      sx={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        timeout: "500",
                        color: "red",
                        fontSize: "15px",
                        padding: 2,
                      }}
                    >
                      Read More
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={6}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={row.img}
                      alt="Bingo Pilipino"
                      style={{
                        width: "100%",
                        display: "flex",
                        height: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              sx={{
                borderRadius: "100%",
                " .MuiDialog-paper": {
                  maxWidth: "1200px",
                },
              }}
            >
              <DialogTitle
                sx={{
                  color: "red",
                  fontSize: "31px",
                  fontWeight: "bold",
                  marginTop: "3%",
                }}
              >
                FBM Bingo Pilipino: Champion’s Favorite
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ marginBottom: "1%" }}
                ></DialogContentText>
                <DialogContentText
                  sx={{ marginBottom: "1%", marginTop: "1.5%" }}
                ></DialogContentText>
                <DialogContentText sx={{ marginBottom: "2%", marginTop: "1%" }}>
                  These venues offer a comfortable and engaging environment
                  where players can gather, socialize, and immerse themselves in
                  the excitement of Bingo Pilipino. With FBM®'s support, these
                  venues have seamlessly integrated technology into their
                  operations, enhancing the overall gaming experience and
                  creating a dynamic community of players.
                </DialogContentText>
                <DialogContentText
                  sx={{
                    fontWeight: "bold",
                    marginTop: "2%",
                    marginBottom: "2.5%",
                  }}
                >
                  Play Anytime & Anywhere, Thanks to FBM® E-Motion
                </DialogContentText>

                <DialogContentText sx={{ marginBottom: "3%" }}>
                  But the good news does not stop there. Bingo Pilipino is now
                  one click away through our FBM® E-Motion Gaming Platform for
                  enjoyment anytime and anywhere. The transformed epic game
                  ensures that Bingo Pilipino can now be played on both mobile
                  and web browsers. In a bingo hall place or online, both cannot
                  describe how Bingo Pilipino captures each player's heart in
                  the Philippines making this rising favorite among champions
                  truly live up to its name.
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default News;
