import { usePlayer } from "../../../../../contexts/PlayerContext";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function GameNameSearch({ searchValue, setSearchValue }) {
  const { isMobile } = usePlayer();
  const handleSearchValue = (event) => {
    setSearchValue(event.target.value);
  };
  const location = useLocation();
  const currentRoute = location.pathname;
  return (
    <>
      {isMobile ? (
        <TextField
          variant="outlined"
          placeholder="Game Name"
          value={searchValue}
          onChange={(event) => handleSearchValue(event)}
          sx={{
            marginTop: "40px",
            marginBottom: "-40px",
            height: "33px",
            borderRadius: "16px",
            backgroundColor: "#16213e",
            marginLeft: "10px",
            width: currentRoute === "/m" ? "93%" : "107%",
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "&:hover .MuiInput-underline:after": {
              borderBottom: "none !important",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                height: "38px",
                borderRadius: "10px",
              },
              "&:hover fieldset": {
                borderColor: "red",
                borderRadius: "10px",
                borderWidth: "1px",
              },
              "&.Mui-focused fieldset": {
                borderColor: "red",
                borderWidth: "1px",
              },
            },
          }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: "#16213e",
              borderRadius: "10px",
              height: "33px",
              fontSize: "13px",
            },
            endAdornment: (
              <SearchIcon
                style={{
                  color: "white",
                  fontSize: "24px",
                  transform: "rotate(90deg)",
                }}
              />
            ),
          }}
        />
      ) : (
        <TextField
          variant="outlined"
          placeholder="Game Name"
          value={searchValue}
          onChange={(event) => handleSearchValue(event)}
          sx={{
            marginTop: "40px",
            marginBottom: "-40px",
            height: "33px",
            borderRadius: "20px",
            backgroundColor: "#16213e",
            width: "calc(25% - 0px)",
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "&:hover .MuiInput-underline:after": {
              borderBottom: "none !important",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                height: "38px",
                borderRadius: "10px",
              },
              "&:hover fieldset": {
                borderColor: "red",
                borderRadius: "10px",
                borderWidth: "1px",
              },
              "&.Mui-focused fieldset": {
                borderColor: "red",
                borderWidth: "1px",
              },
            },
          }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: "#16213e",
              borderRadius: "10px",
              height: "33px",
              fontSize: "13px",
            },
            endAdornment: (
              <SearchIcon
                style={{
                  color: "white",
                  fontSize: "24px",
                  transform: "rotate(90deg)",
                }}
              />
            ),
          }}
        />
      )}
    </>
  );
}
