import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CASINO_SERVER } from "../config";

export const fetchTransactions = createAsyncThunk(
  "player/transactions",
  async (id) => {
    try {
      const response = await axios.get(
        `${CASINO_SERVER}player/transactionrecords/${id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  transactions: {
    data: [],
    status: "idle",
    error: null,
  },
  transaction: {
    data: {},
    status: "idle",
    error: null,
  },
};

const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    filterTransaction: (state, action) => {
      const { today, yesterday, sevenDays, start, end, transactionType } =
        action.payload;
      const transactions = state.transactions;
      const filteredTransactions = transactions.filter((tx) => {
        return (
          tx.created_at === today ||
          tx.created_at === yesterday ||
          tx.created_at === sevenDays ||
          (tx.created_at >= start &&
            tx.created_at <= end &&
            tx.player_transaction_type === transactionType)
        );
      });
      state.transactions = filteredTransactions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.transactions.status = "loading";
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions.status = "succeeded";
        state.transactions.data.push([...action.payload]);
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.transactions.status = "failed";
        state.transactions.error = action.payload;
      });
  },
});

export const {filterTransaction} = transactionSlice.actions;

export default transactionSlice.reducer;
