export const dataOccupation = [
  "Cooperate executives",
  "Managers",
  "Supervisors",
  "Professionals",
  "Associate professionals",
  "Technicians",
  "Clerks",
  "Service workers",
  "Farmers",
  "Forestry workers",
  "Fishermen",
  "Traders",
  "Operators",
  "Machine assemblers",
  "Laborers",
  "Unskilled workers",
  "Special occupations",
  "Others",
];
