import { Grid, Typography, Box, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import axiosInstance from "../../axiosConfig";

const ReceivingCenter = (props) => {
  const { balance } = props;

  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const [data, setData] = useState("");

  const handleBackButton = () => {
    navigate("/m/rewardCenter");
  };

  useEffect(() => {
    axiosInstance
      .get(`player/profile/my-account`)
      .then((response) => {
        const data = response.data[0];
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  });

  const handleClaimHistory = () => {
    navigate("/m/claimHistory");
  };
  return (
    <>
      <Grid container sx={{}}>
        <Grid
          container
          sx={{
            height: "13.5vw",
            backgroundColor: "#1b2033",
          }}
        >
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
              }}
            >
              <ArrowBackIosIcon
                onClick={handleBackButton}
                sx={{
                  color: "#FFFFFF",
                  fontSize: "6.5vw",
                  marginTop: "10%",
                  marginLeft: "-20%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                color: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginTop: "14.5%", fontSize: "3.8vw" }}>
                Bonus
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Grid
              container
              sx={{
                height: "100%",
                alignContent: "center",
              }}
            >
              <ConfirmationNumberIcon
                onClick={handleClaimHistory}
                sx={{
                  color: "#FFFFFF",
                  cursor: "pointer",
                  fontSize: "6vw",
                  marginRight: "11%",
                  marginLeft: "62%",
                  marginTop: "-2%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <img
        src="../../../../blue-bubble_banner.png"
        style={{
          width: "100%",
          position: "absolute",
          zIndex: 0,
        }}
        alt="Banner Background"
      />
      <Grid container sx={{ zIndex: 1 }}>
        <Grid item xs={3} sm={3}>
          <Avatar
            src="../../../../my-account-profile-picture.png"
            sx={{
              width: "53%",
              height: "53%",
              border: "0.9vw  solid #caa86d",
              marginLeft: "45%",
              marginTop: "45%",
            }}
          />
        </Grid>
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9} sx={{ marginTop: "13%" }}>
          <Grid container alignItems="center" sx={{ marginLeft: "5%" }}>
            <Typography
              sx={{
                zIndex: 1,
                fontSize: "4vw",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {username}
            </Typography>
          </Grid>
          <Grid container alignItems="center" sx={{ marginLeft: "5%" }}>
            <Typography
              sx={{
                fontSize: "5vw",
                color: "#6b6e72",
                zIndex: 1,
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              ₱{balance.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ marginLeft: "11%" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "3.2vw",
              marginTop: "5%",
              color: "#989da3",
            }}
          ></Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          color: "#ccc",
          fontWeight: "400",
          marginTop: "20%",
          justifyContent: "center",
        }}
      >
        <Typography> No promotion available yet</Typography>
      </Grid>
    </>
  );
};

export default ReceivingCenter;
