import { Box } from "@mui/material";

const BlurOverlay = ({ open }) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)",
      zIndex: 399,
      transition: "opacity 0.3s ease",
      opacity: open ? 1 : 0,
      pointerEvents: open ? "auto" : "none",
    }}
  />
);
export default BlurOverlay;
