import "../styles/payment-provider-list.css";
import { Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import depositStyles from "./depositStyles";
import { useState } from 'react';
import React from "react";

export const PaymentProviderList = ({ paymentProviderList }) => {
  const classes = depositStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  localStorage.setItem('payment_platform', selectedIndex);

  const handleClick = (index) => {
    setSelectedIndex(index);
    localStorage.setItem('payment_platform', index);
  };

  return (
    <>
      {paymentProviderList && paymentProviderList.length > 0 && (
        <Grid
          style={{ width: "30%", height: "100%", backgroundColor: "#f7f7f7" }}
        >
          <Grid item style={{ width: "100%", height: "3%", marginTop: "20px" }}>
            {[0, 1, 2].map((index) => (
            <CardContent xs={1} key={index} className={classes.cardContent} onClick={() => handleClick(index)} sx={{border: selectedIndex === index ? '1px solid red' : '1px solid transparent'}}>
              <img
                alt="provider logo"
                className="payment-provider-logo"
                src={paymentProviderList[index].logo}
              />
              <p
                className="payment-provider-title"
              >
                {paymentProviderList[index].name}
              </p>

              <div className={classes.viewAllButton}>
                <img src="FBMLike.png" />
              </div>
              {selectedIndex === index && (
              <div className={classes.overlayIcon}>
                <img src="FBMCheck.svg" style={{ width: "20px" }} />
              </div>
              )}
            </CardContent>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
