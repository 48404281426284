import React from "react";

export const GameTypeHotIcon = () => {
  return (
    <img
      src="/hot_icon.png"
      alt="Hot"
      style={{
        position: "absolute",
        top: "0px",
        marginLeft:"-10px",
        height: "40px",
      }}
    />
  );
};

export const GameTypeNewIcon = () => {
  return (
    <img
      src="/new_icon.png"
      alt="New"
      style={{
        position: "absolute",
        top: "-25px",
        right: "10px",
        height: "40px",
      }}
    />
  );
};

export const GameProviderHotIcon = () => {
  return (
    <img
      src="/hot_icon.png"
      alt="Hot"
      style={{
        position: "absolute",
        top: "-13px",
        height: "50px",
        width: "50px",
        right: "23px",
      }}
    />
  );
};

export const GameProviderNewIcon = ({ moveRight }) => {
  return (
    <img
      src="/new_icon.png"
      alt="New"
      style={{
        position: "absolute",
        top: "-13px",
        height: "50px",
        width: "50px",
        right: moveRight ? "-6px" : "23px",
      }}
    />
  );
};
